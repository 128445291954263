import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  /* overflow-y: auto; */
`;

export const LayoutContent = styled.div`
  width: 50%;
  background-image: url(/images/auth-back.png);
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  overflow: hidden;
  position: relative;
  min-height: 500px;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 44px;
    background-image: url(/images/lines-auth.png);
    z-index: 2;
    top: 40px;
    right: 0;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 44px;
    background-image: url(/images/lines-auth.png);
    z-index: 2;
    bottom: 40px;
    right: 0;
    transform: rotate(180deg);
  }
`;

export const ColorBack = styled.div`
  background-color: #0a2142e5;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const PagesContent = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--back-color);
`;

export const LogoContent = styled.div`
  z-index: 2;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const Image = styled.img``;

export const Title = styled.h2`
  font-size: 22px;
  font-weight: 800;
  line-height: 38px;
  letter-spacing: 0em;
  color: var(--white);
`;

export const Vector1 = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;

export const Vector2 = styled.img`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;
