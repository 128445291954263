import React, { ReactNode } from 'react';
import { Switch } from 'antd';

import * as S from './styles';

interface IProps {
  text?: ReactNode;
  onChange?: (data: boolean) => void;
  style?: object;
  reverse?: boolean;
  bottomLine?: boolean;
  checked?: boolean;
  disabled?: boolean;
  theme?: 'one' | 'two';
  inputStyle?: object;
}

const AppSwitch: React.FC<IProps> = ({
  text,
  onChange,
  style,
  reverse,
  bottomLine,
  checked = false,
  // disabled,
  theme = 'one',
  inputStyle
}) => {
  // const [value, setValue] = useState(checked);

  return (
    <S.container style={style} className={`${bottomLine ? 'bottomLine' : ''} ${reverse ? 'reverse' : ''} ${theme}`}>
      <S.P className={theme === 'two' && checked ? 'active' : ''}>{text}</S.P>
      <S.box>
        <Switch
          defaultChecked
          onChange={onChange}
          checked={checked}
          style={{ ...inputStyle, color: 'var(--primary-green)' }}
        />
      </S.box>
    </S.container>
  );
};

export default AppSwitch;
