import styled from 'styled-components';

export const Content = styled.div``;

export const Title = styled.h2`
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: var(--primary-dark);
  margin: 20px 0;
`;

export const Name = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0a214280;
`;

export const Div = styled.div``;

export const Span = styled.span`
  color: #8a93ac;

  &.active {
    color: var(--primary-green);
  }

  &.deActive {
    color: #ff646480;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f7f8fc;
  padding: 7px 10px;
  border-radius: 12px;
  margin: 0 15px;
`;

export const FilterButoons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Button = styled.button`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 0;
  letter-spacing: 0em;
  text-align: center;
  color: var(--white);
  min-width: 71px;
  border: none;
  height: 28px;
  border-radius: 8px;
  padding: 0 8px;
  gap: 4px;
  background: #8a93ac;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
