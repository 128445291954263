// -- == //
import PanelBoxLayout from 'components/layout/panelBox';
import { Formik } from 'formik';
// import PaginationApp from "components/pagination";

import Gap from 'components/gap';
// import DropDown from "components/form/dropDown";
import DateInput from 'components/form/dateInput';
import TextInput from 'components/form/textInput';

import PrimaryButton from 'components/form/buttons/primaryButton';
import { ValidationCreateSurvey } from 'common/validations';
// import { SurveyStatusEnumArray } from "common/enums";
import { useActions } from 'common/hooks/useActions';
import { useNavigate } from 'react-router';
import { toast } from 'common/helpers/toastConfig';
import dayjs from 'dayjs';

// import * as T from "common/types";
import * as S from './styles';

interface IProps {
  questions: any[];
  data?: any;
}

export default function SurveyCreateFormInfo({ questions, data }: IProps) {
  const { surveyCreateRequestAction } = useActions();

  const navigate = useNavigate();

  return (
    <S.Content>
      <PanelBoxLayout style={{ padding: '20px 30px' }}>
        <S.Head>Survey Details</S.Head>
        <Gap size="30px" />
        <S.Detail>
          <Formik
            initialValues={{
              startsAt: data?.startsAt || '',
              endsAt: data?.endsAt || '',
              title: data?.title || ''
            }}
            enableReinitialize
            validationSchema={ValidationCreateSurvey}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(false);
              if (new Date(values?.startsAt) > new Date(values?.endsAt)) {
                toast({
                  model: 'error',
                  message: 'The end date cannot be smaller than the start date'
                });
                return;
              }
              if (!questions.length) {
                toast({ model: 'error', message: 'Please enter an question' });
                return;
              }
              surveyCreateRequestAction(
                {
                  ...values,
                  questions: questions.map((item: any, index: number) => {
                    return {
                      ...item,
                      index,
                      options: item.options.map((item: any) => item.title)
                    };
                  })
                },
                () => navigate('/panel/survey')
              );
            }}
          >
            {({
              values,
              errors,
              touched,
              // handleChange,
              handleSubmit,
              // isSubmitting,
              setValues
            }) => (
              <>
                <TextInput
                  label="Survey Title"
                  name="title"
                  value={values.title}
                  onChange={(e: any) => setValues({ ...values, title: e.target.value })}
                  placeholder="Please Enter Survey Title"
                  message={touched.title && (errors.title as any)}
                  error={errors.title as unknown as boolean}
                />
                <Gap size="20px" />

                <DateInput
                  onChange={(date) =>
                    setValues({
                      ...values,
                      startsAt: date || ''
                      // moment(date).format( "YYYY-MM")
                    })
                  }
                  label="Start Date"
                  defaultDate={values.startsAt ? dayjs(values.startsAt) : ''}
                  format="YYYY/MM/DD"
                  placeholder="From"
                  message={touched.startsAt && (errors.startsAt as any)}
                  error={touched.startsAt && (errors.startsAt as any)}
                />

                <Gap size="20px" />

                <DateInput
                  onChange={(date) =>
                    setValues({
                      ...values,
                      endsAt: date || ''
                      // moment(date).format( "YYYY-MM")
                    })
                  }
                  placeholder="To"
                  defaultDate={values.endsAt ? dayjs(values.endsAt) : ''}
                  format="YYYY/MM/DD"
                  label="End Date"
                  labelStyle={{ fontSize: 12 }}
                  message={touched.endsAt && (errors.endsAt as any)}
                  error={touched.endsAt && (errors.endsAt as any)}
                />

                <Gap size="20px" />

                {/* <DropDown
                  label="Status"
                  list={SurveyStatusEnumArray}
                  onChange={(e: T.Form.ListDropdownItem) => {
                    setValues({
                      ...values,
                      status: e.id as any,
                    });
                  }}
                  placeholder="Select Status"
                  message={touched.status && (errors.status as any)}
                  error={errors.status as unknown as boolean}
                />
                <Gap size="20px" /> */}

                <PrimaryButton onClick={handleSubmit} style={{ borderRadius: 10, marginLeft: 'auto' }}>
                  {data?.title ? 'Edit Survey' : 'Create Survey'}
                </PrimaryButton>
              </>
            )}
          </Formik>
        </S.Detail>
        <Gap size="20px" />
      </PanelBoxLayout>
    </S.Content>
  );
}
