/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
// import moment from "moment";

import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
// import PaginationApp from "components/pagination";
import { useActions } from 'common/hooks/useActions';
import PrimaryButton from 'components/form/buttons/primaryButton';
import QuestionModal from 'components/modal/question';
import DangerButton from 'components/form/buttons/danger';
import AppTreeTable from 'components/appTreeTable';
import { ReactSVG } from 'react-svg';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  data: [];
  loading: boolean;
}

export default function CategoriesTable({ data, loading }: IProps) {
  const { ticketCategoriesRequestAction, ticketCategoryDeleteRequestAction, ticketCategoryEnableRequestAction } =
    useActions();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showEnableModal, setShowEnableModal] = useState<boolean>(false);
  const [disableData, setDisableData] = useState<T.Pages.Category | null>(null);
  const [expandedItems, setExpandedItems] = useState<number[]>([]);
  const [enableData, setEnableData] = useState<T.Pages.Category | null>(null);

  const [meta, setMeta] = useState({
    query: '',
    type: ''
  });

  const fetchData = () => {
    ticketCategoriesRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  const handleShowDisableModal = (data: T.Pages.Category) => {
    setShowDeleteModal(true);
    setDisableData(data);
  };

  const handleCloseDisableModal = () => {
    setShowDeleteModal(false);
    setDisableData(null);
  };

  const handleDisableCategory = () => {
    ticketCategoryDeleteRequestAction({ id: disableData?.id }, () => {
      handleCloseDisableModal();
      // fetchData();
      window.location.reload();
    });
  };

  const handleShowEnableModal = (data: T.Pages.Category) => {
    setShowEnableModal(true);
    setEnableData(data);
  };

  const handleCloseEnableModal = () => {
    setShowEnableModal(false);
    setEnableData(null);
  };

  const handleEnableCategory = () => {
    ticketCategoryEnableRequestAction({ id: enableData?.id }, () => {
      handleCloseEnableModal();
      // fetchData();
      window.location.reload();
    });
  };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => setMeta({ ...meta, query: e })}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
            value={meta.query}
          />
        </S.Head>
        <Gap size="20px" />
        <AppTreeTable
          headers={[
            { id: 1, title: 'ID', dir: 'left', width: '3%' },
            { id: 2, title: 'Icon', width: '10%' },
            { id: 4, title: 'Title', dir: 'left', width: '40%' },
            { id: 5, title: 'Supporter', dir: 'left', width: '30%' },
            { id: 6, title: 'Management', width: '17%' }
          ]}
          style={{ minWidth: 'auto' }}
          loading={loading}
          data={data}
          tdFields={{
            id: (item: T.Pages.Category) => <S.Div className="text-left">{item?.id}</S.Div>,
            icon: (item: T.Pages.Category, child: boolean) => (
              <S.Div className="">
                <S.Icon className={child ? 'child' : ''}>
                  <S.Image src={item.icon} />
                </S.Icon>
              </S.Div>
            ),
            title: (item: T.Pages.Category, _child: boolean, hasChildren?: boolean, callBack?: (e: any) => void) => (
              <S.TitleTd
                className={`text-left captal ${_child ? 'child' : ''} ${item.level} ${
                  expandedItems.find((e) => e === item.id) ? 'active' : 'deActive'
                }`}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (expandedItems.find((e) => e === item.id))
                    setExpandedItems(expandedItems.filter((e) => e !== item.id));
                  else setExpandedItems([...expandedItems, item.id]);

                  callBack && callBack(item);
                }}
              >
                {item.title}
                {hasChildren && (
                  <S.ArrowIcon className="child-arrow-icon">
                    <ReactSVG
                      src="/icons/stroke/arrow-bottom.svg"
                      beforeInjection={(svg) => {
                        svg.setAttribute('stroke', `#616E84`);
                      }}
                    />
                  </S.ArrowIcon>
                )}
              </S.TitleTd>
            ),
            supporter: () => <S.Div className="text-left captal"></S.Div>,
            management: (item: T.Pages.Category) => (
              <S.Grid>
                <PrimaryButton
                  style={{
                    height: 28,
                    fontSize: 12,
                    background: '#8A93AC',
                    color: 'var(--white)',
                    width: 94,
                    borderRadius: 8
                  }}
                  link={`/panel/ticket/categories/${item.id}`}
                  disabled={!item.status}
                >
                  Edit
                </PrimaryButton>
                {item.status ? (
                  <DangerButton
                    onClick={() => handleShowDisableModal(item)}
                    style={{
                      height: 28,
                      fontSize: 12,
                      background: '#FDF0EF',
                      color: '#FF6464 !important',
                      width: 94,
                      borderRadius: 8
                    }}
                  >
                    Disable
                  </DangerButton>
                ) : (
                  <PrimaryButton
                    onClick={() => handleShowEnableModal(item)}
                    style={{
                      height: 28,
                      fontSize: 12,
                      background: '#DCF6F0',
                      color: '#1DBAAF',
                      width: 94,
                      borderRadius: 8
                    }}
                  >
                    Enable
                  </PrimaryButton>
                )}
              </S.Grid>
            )
          }}
        />
      </PanelBoxLayout>
      {/* {data.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.page_size * data.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={data.totalPages}
          pageSize={meta.page_size}
          handleChangeTake={handleChangePageSize}
        />
      ) : (
        <></>
      )} */}

      <QuestionModal
        show={showDeleteModal}
        handleClose={handleCloseDisableModal}
        model="delete"
        title="Disable Category"
        text="Do you want to Disable this Category?"
        handleSuccess={handleDisableCategory}
        successButtonText="Disable"
      />

      <QuestionModal
        show={showEnableModal}
        handleClose={handleCloseEnableModal}
        model="success"
        title="Enable Category"
        text="Do you want to enable this Category?"
        handleSuccess={handleEnableCategory}
        successButtonText="Enable"
      />
    </S.Content>
  );
}
