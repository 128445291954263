export enum DASHBOARD_CAFETERIA {
  REQUEST = 'DASHBOARD_CAFETERIA_REQUEST',
  SUCCESS = 'DASHBOARD_CAFETERIA_SUCCESS',
  FAILURE = 'DASHBOARD_CAFETERIA_FAILURE'
}

export enum DASHBOARD_STATIONARY {
  REQUEST = 'DASHBOARD_STATIONARY_REQUEST',
  SUCCESS = 'DASHBOARD_STATIONARY_SUCCESS',
  FAILURE = 'DASHBOARD_STATIONARY_FAILURE'
}

export enum DASHBOARD_MAINTENANCE {
  REQUEST = 'DASHBOARD_MAINTENANCE_REQUEST',
  SUCCESS = 'DASHBOARD_MAINTENANCE_SUCCESS',
  FAILURE = 'DASHBOARD_MAINTENANCE_FAILURE'
}

export enum DASHBOARD_HSSE {
  REQUEST = 'DASHBOARD_HSSE_REQUEST',
  SUCCESS = 'DASHBOARD_HSSE_SUCCESS',
  FAILURE = 'DASHBOARD_HSSE_FAILURE'
}

export enum DASHBOARD_EVENTS {
  REQUEST = 'DASHBOARD_EVENTS_REQUEST',
  SUCCESS = 'DASHBOARD_EVENTS_SUCCESS',
  FAILURE = 'DASHBOARD_EVENTS_FAILURE'
}
