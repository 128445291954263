import { ApiCaller } from 'common/helpers/apiCaller';
import convertToQueryString from 'common/helpers/convertToQueryString';

// ------ Events ------ //
export const eventsService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/event?${convertToQueryString(data)}`
  });
};

// ------ Create Event ------ //
export const eventCreateService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/event`,
    data
  });
};

// ------ Update Event ------ //
export const eventUpdateService = (data: object) => {
  return ApiCaller({
    method: 'PUT',
    url: `${process.env.REACT_APP_API_URL}api/admin/event`,
    data
  });
};

// ------ Event Detail ------ //
export const eventDetailService = (id?: string) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/event/details?id=${id}`
  });
};

// ------ Event Upload Image ------ //
export const eventUploadService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/event/upload`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// ------ Event Cancel ------ //
export const eventCancelService = (id?: number) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/event/cancelEvent`,
    data: { id }
  });
};

// ------ Event Change Visible ------ //
export const eventChangeVisibleService = (id?: number) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/event/changeVisible`,
    data: { id }
  });
};

// ------ Event Contributors ------ //
export const eventContributorsService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/event/contributors?${convertToQueryString(data)}`
  });
};

// ------ Event End ------ //
export const eventEndService = (id?: number) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/event/endEvent`,
    data: { id }
  });
};

// ------ Event Contributor Accept ------ //
export const eventContributorAcceptService = (id?: number) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/event/contributors/accept`,
    data: { id }
  });
};

// ------ Event Contributor Reject ------ //
export const eventContributorRejectService = (id?: number) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/event/contributors/reject`,
    data: { id }
  });
};

// ------ Guest Upload Icon ------ //
export const eventUploadGuestService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/event/upload-guest`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
