import styled from 'styled-components';

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eef1f6;
  padding: 7px 10px;
  border-radius: 12px;
  font-size: 14px;
  color: #8a93ac;
  font-weight: 500;
`;

export const Div = styled.div``;

export const GridButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: 20px;
`;
