// -- == //
import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'common/helpers/toastConfig';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';

import * as types from 'common/constants';
// import * as T from "common/types";
import * as services from 'common/services';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Event list actions
 */
export const eventsRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.EVENTS.REQUEST,
        payload: {}
      });

      const response: any = await services.eventsService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(eventsSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(eventsFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(eventsFailureAction(error));
      throw error;
    }
  };
};

export const eventsSuccessAction = (data: object) => {
  return {
    type: types.EVENTS.SUCCESS,
    payload: data
  };
};

export const eventsFailureAction = (error: object) => {
  return {
    type: types.EVENTS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Event Create actions
 */
export const eventCreateRequestAction = (data: any, callBack: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.EVENT_CREATE.REQUEST,
        payload: {}
      });

      const response: any = await services.eventCreateService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(eventCreateSuccessAction(response.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(eventCreateFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(eventCreateFailureAction(error));
      throw error;
    }
  };
};

export const eventCreateSuccessAction = (data: object) => {
  return {
    type: types.EVENT_CREATE.SUCCESS,
    payload: data
  };
};

export const eventCreateFailureAction = (error: object) => {
  return {
    type: types.EVENT_CREATE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Event Update actions
 */
export const eventUpdateRequestAction = (data: any, callBack: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.EVENT_UPDATE.REQUEST,
        payload: {}
      });

      const response: any = await services.eventUpdateService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(eventUpdateSuccessAction(response.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(eventUpdateFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(eventUpdateFailureAction(error));
      throw error;
    }
  };
};

export const eventUpdateSuccessAction = (data: object) => {
  return {
    type: types.EVENT_UPDATE.SUCCESS,
    payload: data
  };
};

export const eventUpdateFailureAction = (error: object) => {
  return {
    type: types.EVENT_UPDATE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * cafeteria Upload actions
 */
export const eventUploadRequestAction = (data: any, callBack: (fileName: string) => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.EVENT_UPLOAD.REQUEST,
        payload: {}
      });

      const formData = new FormData();
      formData.append('image', data.image);

      const response: any = await services.eventUploadService(formData);

      if (response.status === 200 || response.status === 201) {
        dispatch(eventUploadSuccessAction(response.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack(response.data?.data?.fileNameOnly);
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(eventUploadFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(eventUploadFailureAction(error));
      throw error;
    }
  };
};

export const eventUploadSuccessAction = (data: object) => {
  return {
    type: types.EVENT_UPLOAD.SUCCESS,
    payload: data
  };
};

export const eventUploadFailureAction = (error: object) => {
  return {
    type: types.EVENT_UPLOAD.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Event Detail actions
 */
export const eventDetailRequestAction = (id?: string) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.EVENT_DETAIL.REQUEST,
        payload: {}
      });

      const response: any = await services.eventDetailService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(eventDetailSuccessAction(response.data.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(eventDetailFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(eventDetailFailureAction(error));
      throw error;
    }
  };
};

export const eventDetailSuccessAction = (data: object) => {
  return {
    type: types.EVENT_DETAIL.SUCCESS,
    payload: data
  };
};

export const eventDetailFailureAction = (error: object) => {
  return {
    type: types.EVENT_DETAIL.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Event Cancel actions
 */
export const eventCancelRequestAction = (id?: number, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.EVENT_CANCEL.REQUEST,
        payload: {}
      });

      const response: any = await services.eventCancelService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(eventCancelSuccessAction(response.data.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(eventCancelFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(eventCancelFailureAction(error));
      throw error;
    }
  };
};

export const eventCancelSuccessAction = (data: object) => {
  return {
    type: types.EVENT_CANCEL.SUCCESS,
    payload: data
  };
};

export const eventCancelFailureAction = (error: object) => {
  return {
    type: types.EVENT_CANCEL.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Event Change Visible actions
 */
export const eventChangeVisibleRequestAction = (id?: number, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.EVENT_CHANGE_VISIBLE.REQUEST,
        payload: {}
      });

      const response: any = await services.eventChangeVisibleService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(eventChangeVisibleSuccessAction(response.data.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(eventChangeVisibleFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(eventChangeVisibleFailureAction(error));
      throw error;
    }
  };
};

export const eventChangeVisibleSuccessAction = (data: object) => {
  return {
    type: types.EVENT_CHANGE_VISIBLE.SUCCESS,
    payload: data
  };
};

export const eventChangeVisibleFailureAction = (error: object) => {
  return {
    type: types.EVENT_CHANGE_VISIBLE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Event list actions
 */
export const eventContributorsRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.EVENT_CONTRIBUTORS.REQUEST,
        payload: {}
      });

      const response: any = await services.eventContributorsService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(eventContributorsSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(eventContributorsFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(eventContributorsFailureAction(error));
      throw error;
    }
  };
};

export const eventContributorsSuccessAction = (data: object) => {
  return {
    type: types.EVENT_CONTRIBUTORS.SUCCESS,
    payload: data
  };
};

export const eventContributorsFailureAction = (error: object) => {
  return {
    type: types.EVENT_CONTRIBUTORS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Event End actions
 */
export const eventEndRequestAction = (id?: number, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.EVENT_CHANGE_VISIBLE.REQUEST,
        payload: {}
      });

      const response: any = await services.eventEndService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(eventEndSuccessAction(response.data.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(eventEndFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(eventEndFailureAction(error));
      throw error;
    }
  };
};

export const eventEndSuccessAction = (data: object) => {
  return {
    type: types.EVENT_CHANGE_VISIBLE.SUCCESS,
    payload: data
  };
};

export const eventEndFailureAction = (error: object) => {
  return {
    type: types.EVENT_CHANGE_VISIBLE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Event Contributor Accept actions
 */
export const eventContributorAcceptRequestAction = (id?: number, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.EVENT_CONTRIBUTOR_ACCEPT.REQUEST,
        payload: {}
      });

      const response: any = await services.eventContributorAcceptService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(eventContributorAcceptSuccessAction(response.data.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(eventContributorAcceptFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(eventContributorAcceptFailureAction(error));
      throw error;
    }
  };
};

export const eventContributorAcceptSuccessAction = (data: object) => {
  return {
    type: types.EVENT_CONTRIBUTOR_ACCEPT.SUCCESS,
    payload: data
  };
};

export const eventContributorAcceptFailureAction = (error: object) => {
  return {
    type: types.EVENT_CONTRIBUTOR_ACCEPT.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Event Contributor Reject actions
 */
export const eventContributorRejectRequestAction = (id?: number, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.EVENT_CONTRIBUTOR_REJECT.REQUEST,
        payload: {}
      });

      const response: any = await services.eventContributorRejectService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(eventContributorRejectSuccessAction(response.data.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(eventContributorRejectFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(eventContributorRejectFailureAction(error));
      throw error;
    }
  };
};

export const eventContributorRejectSuccessAction = (data: object) => {
  return {
    type: types.EVENT_CONTRIBUTOR_REJECT.SUCCESS,
    payload: data
  };
};

export const eventContributorRejectFailureAction = (error: object) => {
  return {
    type: types.EVENT_CONTRIBUTOR_REJECT.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * cafeteria Upload actions
 */
export const eventUploadGuestRequestAction = (data: any, callBack: (fileName: string) => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.EVENT_UPLOAD_GUEST.REQUEST,
        payload: {}
      });

      const formData = new FormData();
      formData.append('image', data.image);

      const response: any = await services.eventUploadGuestService(formData);

      if (response.status === 200 || response.status === 201) {
        dispatch(eventUploadGuestSuccessAction(response.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack(response.data?.data?.fileNameOnly);
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(eventUploadGuestFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(eventUploadGuestFailureAction(error));
      throw error;
    }
  };
};

export const eventUploadGuestSuccessAction = (data: object) => {
  return {
    type: types.EVENT_UPLOAD_GUEST.SUCCESS,
    payload: data
  };
};

export const eventUploadGuestFailureAction = (error: object) => {
  return {
    type: types.EVENT_UPLOAD_GUEST.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
