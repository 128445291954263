import styled from 'styled-components';

export const Content = styled.div``;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--primary-dark);
  margin: 20px 0;
`;

export const Name = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0a214280;
`;

export const Div = styled.div`
  &.active {
    color: var(--primary-green);
  }
  &.inactive {
    color: #ff646480;
  }
  &.captal {
    text-transform: capitalize;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f7f8fc;
  padding: 7px 10px;
  border-radius: 12px;
  margin: 0 15px;
`;

export const FilterButoons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Button = styled.button`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--white);
  background: #8a93ac;
  min-width: 68px;
  border: none;
  height: 28px;
  border-radius: 8px;
  padding: 0 8px;

  &.active {
    background: var(--primary-dark-two);
  }
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #616e84;
  line-height: 0;
  border-radius: 40px;
  margin: auto;
  /* padding: 10px; */
`;

export const Image = styled.img`
  max-width: 100%;
  padding: 10px;
`;

export const Span = styled.span``;

export const Floor = styled.span`
  width: 46px;
  height: 14px;
  padding: 0px 8px 0px 8px;
  font-size: 9px;
  border-radius: 8px;
  background: #e9f9f7;
  color: var(--primary-green);
`;
