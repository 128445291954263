/* eslint-disable react-hooks/exhaustive-deps */
// -- == //
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import TextInput from 'components/form/textInput';
import PrimaryButton from 'components/form/buttons/primaryButton';
import { Formik } from 'formik';
import { ValidationTicketCategory } from 'common/validations';
import UploadIcon from 'components/form/uploadIcon';
import CategoryUserMultiDropdown from '../../detail/lib/multiDropdown';
import TreeSelectInput from 'components/form/treeSelect';
import { useActions } from 'common/hooks/useActions';

import * as T from 'common/types';
import * as S from './styles';
import DropDown from 'components/form/dropDown';
import { CategoryTypeEnumArray } from 'common/enums';
import { toast } from 'common/helpers/toastConfig';

interface IProps {
  categories: any[];
  supporterList: any[];
  supporter: any[];
  data?: any;
}

export default function CategorieyDetailForm({ categories, supporterList, supporter, data }: IProps) {
  const {
    ticketCategoryDetailRequestAction,
    adminSupporterRequestAction,
    ticketAddSupporterListAction,
    ticketCategoriesRequestAction,
    ticketCategoryCreateRequestAction,
    ticketCategoryEditRequestAction,
    ticketCategoryIconRequestAction
  } = useActions();

  const navigate = useNavigate();
  const params: any = useParams();

  const fetchData = () => {
    adminSupporterRequestAction({});
    ticketCategoriesRequestAction({ status: true });
    if (params?.id) ticketCategoryDetailRequestAction(params?.id);
  };

  useEffect(() => {
    if (data?.admin?.length) ticketAddSupporterListAction(data?.admin);
  }, [data?.admin?.length]);

  useEffect(() => {
    fetchData();
  }, []);

  const title_en = data?.translation?.find((e: any) => e.language === 'en');
  const title_ar = data?.translation?.find((e: any) => e.language === 'ar');

  const renderTranslations = (titleEn: string, titleAr: string) => {
    return [
      {
        language: 'en',
        translate: titleEn
      },
      {
        language: 'ar',
        translate: titleAr
      }
    ];
  };

  return (
    <S.Content>
      <PanelBoxLayout style={{ padding: 20 }}>
        <Formik
          initialValues={{
            titleEn: title_en?.translation || '',
            titleAr: title_ar?.translation || '',
            dataURL: data?.image || '',
            file: undefined,
            parentCategory: undefined,
            type: data?.type || '',
            imageName: data?.icon || '',
            categoryId: params?.id ? parseInt(params?.id) : undefined
          }}
          enableReinitialize
          validationSchema={!params?.id ? ValidationTicketCategory : ''}
          onSubmit={({ ...values }, { setSubmitting }) => {
            setSubmitting(false);
            if (!supporterList.length) {
              toast({
                model: 'error',
                message: 'You must select at least one supporter'
              });
              return;
            }
            if (data?.id) {
              ticketCategoryEditRequestAction(
                {
                  id: data.id,
                  type: values.type,
                  categoryId: values.categoryId,
                  imageName: values.imageName,
                  parentCategory: values.parentCategory,
                  adminIds: supporterList.map((item) => item.id),
                  translations: renderTranslations(values.titleEn, values.titleAr)
                },
                () => navigate('/panel/ticket/categories')
              );
            } else {
              ticketCategoryCreateRequestAction(
                {
                  type: values.type,
                  categoryId: values.categoryId,
                  imageName: values.imageName,
                  parentCategory: values.parentCategory,
                  adminIds: supporterList.map((item) => item.id),
                  translations: renderTranslations(values.titleEn, values.titleAr)
                },
                () => navigate('/panel/ticket/categories')
              );
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            // isSubmitting,
            setValues
          }) => (
            <>
              <UploadIcon
                value={{ dataURL: values.dataURL, file: values.file }}
                onChange={(e: T.Form.FileUpload) => {
                  ticketCategoryIconRequestAction({ image: e?.file }, (fileNameOnly: string) => {
                    setValues({
                      ...values,
                      imageName: fileNameOnly,
                      dataURL: e.dataURL,
                      file: e.file as any
                    });
                  });
                }}
                label="Upload Icon"
                message={touched.imageName && (errors.imageName as string)}
                error={errors.imageName as unknown as boolean}
              />
              <Gap size="20px" />

              <TextInput
                label="Category Title En"
                type="text"
                name="titleEn"
                onChange={handleChange}
                placeholder="Please enter category title english"
                value={values.titleEn}
                message={touched.titleEn && (errors.titleEn as string)}
                error={errors.titleEn as unknown as boolean}
              />
              <Gap size="20px" />

              <TextInput
                label="Category Title Ar"
                type="text"
                name="titleAr"
                onChange={handleChange}
                placeholder="Please enter category title arabi"
                value={values.titleAr}
                message={touched.titleAr && (errors.titleAr as string)}
                error={errors.titleAr as unknown as boolean}
                inputStyle={{ textAlign: 'right' }}
              />
              <Gap size="20px" />

              <TreeSelectInput
                label="Assign Category Parent"
                list={categories}
                placeholder="Search or Select Category Parent"
                disabled={data?.id ? true : false}
                onChange={(e, item: any) => {
                  setValues({
                    ...values,
                    parentCategory: e as any,
                    type: item?.type || ''
                  });
                }}
              />
              <Gap size="20px" />

              <DropDown
                label="Category Type"
                name="type"
                list={CategoryTypeEnumArray}
                onChange={(e: T.Form.ListDropdownItem) => {
                  setValues({
                    ...values,
                    type: e.id
                  });
                }}
                placeholder="Please enter category type"
                defaultValue={{ title: values.type, id: values.type }}
                message={touched.type && (errors.type as string)}
                error={errors.type as unknown as boolean}
                disabled={values.parentCategory || data?.id ? true : false}
                // key={values.type}
              />

              <Gap size="20px" />

              <CategoryUserMultiDropdown
                label="Assign Supporter"
                list={supporter}
                placeholder="Select Supporter"
                defaultValues={supporterList}
                onChange={(list) => {
                  ticketAddSupporterListAction(list);
                }}
                // message={
                //   !supporterList.length &&
                //   ("You must select at least one supporter" as any)
                // }
                // error={!supporterList.length as boolean}
              />
              <Gap size="20px" />

              <PrimaryButton
                style={{
                  height: 32,
                  fontSize: 12,
                  borderRadius: 8,
                  marginLeft: 'auto'
                }}
                onClick={handleSubmit}
                // disabled={isSubmitting}
              >
                Save Category
              </PrimaryButton>
            </>
          )}
        </Formik>
      </PanelBoxLayout>
    </S.Content>
  );
}
