/* eslint-disable react-hooks/exhaustive-deps */
import Gap from 'components/gap';
import { useEffect } from 'react';
import { useParams } from 'react-router';

import ChangeRoleForm from '../lib/changeRoleForm';
import { useActions } from 'common/hooks/useActions';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function UsersChangeRolePage() {
  const params = useParams();

  const { userDetailRequestAction } = useActions();
  const { roles, changeRole, userDetail } = useTypedSelector((state: any) => state.Users);

  useEffect(() => {
    userDetailRequestAction(params?.id);
  }, []);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Change Role</S.Title>
        <S.Text>
          {userDetail.data?.displayName} ({userDetail.data?.mail})
        </S.Text>
      </S.Head>
      <Gap size="20px" />
      <ChangeRoleForm data={userDetail.data} roles={roles.data} />

      <AppLoading loading={changeRole.loading || roles.loading || userDetail.loading} />
    </S.Container>
  );
}
