/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import AppTable from 'components/appTable';
import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import { useActions } from 'common/hooks/useActions';
import PrimaryButton from 'components/form/buttons/primaryButton';
import DangerButton from 'components/form/buttons/danger';
import PaginationApp from 'components/pagination';
import EventFilterPopup from 'pages/panel/events/lib/filterForm';
import QuestionModal from 'components/modal/question';

import * as T from 'common/types';
import * as S from './styles';

interface IProps {
  data: { list: []; totalPages?: number; eventInfo?: T.Pages.EventItem };
  departments: [];
}

export default function EventsContributorsTable({ data, departments }: IProps) {
  const params = useParams();

  const [ShowAcceptModal, setShowAcceptModal] = useState(false);
  const [ShowRejectModal, setShowRejectModal] = useState(false);
  const [contributorData, setContributorData] = useState<T.Pages.ContributorItem | null>();

  const { eventContributorsRequestAction, eventContributorAcceptRequestAction, eventContributorRejectRequestAction } =
    useActions();

  const [meta, setMeta] = useState({
    page: 1,
    page_size: 1000,
    search: '',
    type: '',
    eventId: params?.id
  });

  const fetchData = () => {
    eventContributorsRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  const handleChangePage = (page: number) => {
    setMeta({ ...meta, page });
  };

  const handleChangePageSize = (page_size: number) => {
    setMeta({ ...meta, page_size });
  };

  const handleShowAcceptModal = (data: T.Pages.ContributorItem) => {
    setShowAcceptModal(true);
    setContributorData(data);
  };

  const handleHideAcceptModal = () => {
    setShowAcceptModal(false);
    setContributorData(null);
  };

  const handleAcceptContributor = () => {
    eventContributorAcceptRequestAction(contributorData?.id, () => {
      setShowAcceptModal(false);
      fetchData();
    });
  };

  const handleShowRejectModal = (data: T.Pages.ContributorItem) => {
    setShowRejectModal(true);
    setContributorData(data);
  };

  const handleHideRejectModal = () => {
    setShowRejectModal(false);
    setContributorData(null);
  };

  const handleRejectContributor = () => {
    eventContributorRejectRequestAction(contributorData?.id, () => {
      setShowRejectModal(false);
      fetchData();
    });
  };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => setMeta({ ...meta, search: e })}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
            value={meta.search}
            disabled={!data.list?.length ? true : false}
          />
          <S.Grid>
            <EventFilterPopup setFilter={setMeta} filter={meta} departments={departments} contributor />
          </S.Grid>
        </S.Head>
        <Gap size="20px" />
        {data.list?.length ? (
          <AppTable
            headers={[
              { id: 1, title: 'User ID', dir: 'left' },
              { id: 4, title: 'User Name', dir: 'left' },
              { id: 5, title: 'Job Title', dir: 'left' },
              { id: 6, title: 'Department', dir: 'left' },
              { id: 7, title: 'Status' },
              { id: 8, title: 'Management' }
            ]}
            data={data.list}
            tdFields={{
              id: (item: T.Pages.ContributorItem) => <S.Div className="text-left">{item?.id}</S.Div>,

              name: (item: T.Pages.ContributorItem) => (
                <S.Div className="text-left captal">{item.userDisplayName}</S.Div>
              ),
              jobTitle: (item: T.Pages.ContributorItem) => <S.Div className="text-left">{item.userJobTitle}</S.Div>,
              department: (item: T.Pages.ContributorItem) => <S.Div className="text-left">{item.userDepartment}</S.Div>,
              status: (item: T.Pages.ContributorItem) => <S.Status className={item?.status}>{item?.status}</S.Status>,

              requestLocation: (item: T.Pages.ContributorItem) => (
                <S.Grid className="flex-center">
                  {item.status === 'Pending' || item.status === 'Rejected' ? (
                    <PrimaryButton
                      onClick={() => handleShowAcceptModal(item)}
                      style={{
                        height: 28,
                        fontSize: 12,
                        background: '#E9F9F7',
                        width: 94,
                        borderRadius: 8,
                        color: '#1DBAAF'
                      }}
                    >
                      Accept
                    </PrimaryButton>
                  ) : (
                    <></>
                  )}
                  {item.status === 'Pending' || item.status === 'Attending' ? (
                    <DangerButton
                      onClick={() => handleShowRejectModal(item)}
                      style={{
                        height: 28,
                        fontSize: 12,
                        width: 94,
                        borderRadius: 8,
                        background: '#FDF0EF'
                      }}
                    >
                      Reject
                    </DangerButton>
                  ) : (
                    <></>
                  )}
                </S.Grid>
              )
            }}
          />
        ) : (
          <S.P>No contributors applied for this event yet.</S.P>
        )}
      </PanelBoxLayout>
      {data.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.page_size * data.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={data.totalPages}
          pageSize={meta.page_size}
          handleChangeTake={handleChangePageSize}
        />
      ) : (
        <></>
      )}

      <QuestionModal
        handleClose={handleHideAcceptModal}
        show={ShowAcceptModal}
        model="success"
        handleSuccess={handleAcceptContributor}
        title="Are you sure Accept to this Contributor?"
        successButtonText="Accept"
      />

      <QuestionModal
        handleClose={handleHideRejectModal}
        show={ShowRejectModal}
        model="delete"
        handleSuccess={handleRejectContributor}
        title="Are you sure Reject to this Contributor?"
        successButtonText="Reject"
      />
    </S.Content>
  );
}
