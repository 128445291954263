export enum EVENTS {
  REQUEST = 'EVENTS_REQUEST',
  SUCCESS = 'EVENTS_SUCCESS',
  FAILURE = 'EVENTS_FAILURE'
}

export enum EVENT_CREATE {
  REQUEST = 'EVENT_CREATE_REQUEST',
  SUCCESS = 'EVENT_CREATE_SUCCESS',
  FAILURE = 'EVENT_CREATE_FAILURE'
}

export enum EVENT_UPDATE {
  REQUEST = 'EVENT_UPDATE_REQUEST',
  SUCCESS = 'EVENT_UPDATE_SUCCESS',
  FAILURE = 'EVENT_UPDATE_FAILURE'
}

export enum EVENT_UPLOAD {
  REQUEST = 'EVENT_UPLOAD_REQUEST',
  SUCCESS = 'EVENT_UPLOAD_SUCCESS',
  FAILURE = 'EVENT_UPLOAD_FAILURE'
}

export enum EVENT_DETAIL {
  REQUEST = 'EVENT_DETAIL_REQUEST',
  SUCCESS = 'EVENT_DETAIL_SUCCESS',
  FAILURE = 'EVENT_DETAIL_FAILURE'
}

export enum EVENT_DELETE {
  REQUEST = 'EVENT_DELETE_REQUEST',
  SUCCESS = 'EVENT_DELETE_SUCCESS',
  FAILURE = 'EVENT_DELETE_FAILURE'
}

export enum EVENT_CONTRIBUTORS {
  REQUEST = 'EVENT_CONTRIBUTORS_REQUEST',
  SUCCESS = 'EVENT_CONTRIBUTORS_SUCCESS',
  FAILURE = 'EVENT_CONTRIBUTORS_FAILURE'
}

export enum EVENT_CANCEL {
  REQUEST = 'EVENT_CANCEL_REQUEST',
  SUCCESS = 'EVENT_CANCEL_SUCCESS',
  FAILURE = 'EVENT_CANCEL_FAILURE'
}

export enum EVENT_CHANGE_VISIBLE {
  REQUEST = 'EVENT_CHANGE_VISIBLE_REQUEST',
  SUCCESS = 'EVENT_CHANGE_VISIBLE_SUCCESS',
  FAILURE = 'EVENT_CHANGE_VISIBLE_FAILURE'
}

export enum EVENT_END {
  REQUEST = 'EVENT_END_REQUEST',
  SUCCESS = 'EVENT_END_SUCCESS',
  FAILURE = 'EVENT_END_FAILURE'
}

export enum EVENT_CONTRIBUTOR_ACCEPT {
  REQUEST = 'EVENT_CONTRIBUTOR_ACCEPT_REQUEST',
  SUCCESS = 'EVENT_CONTRIBUTOR_ACCEPT_SUCCESS',
  FAILURE = 'EVENT_CONTRIBUTOR_ACCEPT_FAILURE'
}

export enum EVENT_CONTRIBUTOR_REJECT {
  REQUEST = 'EVENT_CONTRIBUTOR_REJECT_REQUEST',
  SUCCESS = 'EVENT_CONTRIBUTOR_REJECT_SUCCESS',
  FAILURE = 'EVENT_CONTRIBUTOR_REJECT_FAILURE'
}

export enum EVENT_UPLOAD_GUEST {
  REQUEST = 'EVENT_UPLOAD_GUEST_REQUEST',
  SUCCESS = 'EVENT_UPLOAD_GUEST_SUCCESS',
  FAILURE = 'EVENT_UPLOAD_GUEST_FAILURE'
}
