import React from 'react';
import * as S from '../../styles';
import { Pie } from '@ant-design/charts';

type listItemType = {
  chartResultAvrCloseTime: number;
  chartResultAvrResponseTime: number;
  child: listItemType[];
  id: number;
  title: string;
  totalCount: string;
};

function DashboardMaintenanceChart({ data }: any) {
  const renderListChart = () => {
    let final = [];
    if (!data?.length) return [];
    if (!data[0]?.child?.length) return [];
    if (!data[0]?.child[0]?.child?.length) return [];
    final = data[0]?.child[0]?.child?.map((item: listItemType) => ({
      ...item,
      totalCount: Number(item.totalCount)
    }));
    return final;
  };

  const config = {
    appendPadding: 10,
    data: renderListChart(),
    angleField: 'totalCount',
    colorField: 'title',
    radius: 0.9,
    legend: {
      position: 'bottom'
    },
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }: any) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center'
      }
    },
    interactions: [
      {
        type: 'element-active'
      }
    ]
  };

  return (
    <S.DivChart>
      <Pie {...(config as any)} />
    </S.DivChart>
  );
}

export default React.memo(DashboardMaintenanceChart);
