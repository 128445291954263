import styled from 'styled-components';
import { device } from 'common/constants/mediaQueries';

export const RatesCon = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px;

  /* @media ${device.tabletL} {
    flex-grow: 1;
  } */
`;

export const Star = styled.div``;
