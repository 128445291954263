import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'common/helpers/toastConfig';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';

import * as types from 'common/constants';
import * as services from 'common/services';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Reservation list actions
 */
export const reservationsRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.RESERVATIONS.REQUEST,
        payload: {}
      });

      const response: any = await services.reservationsService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(reservationsSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(reservationsFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(reservationsFailureAction(error));
      throw error;
    }
  };
};

export const reservationsSuccessAction = (data: object) => {
  return {
    type: types.RESERVATIONS.SUCCESS,
    payload: data
  };
};

export const reservationsFailureAction = (error: object) => {
  return {
    type: types.RESERVATIONS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Reservation Detail actions
 */
export const reservationDetailRequestAction = (id?: string) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.RESERVATION_DETAIL.REQUEST,
        payload: {}
      });

      const response: any = await services.reservationDetailService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(reservationDetailSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(reservationDetailFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(reservationDetailFailureAction(error));
      throw error;
    }
  };
};

export const reservationDetailSuccessAction = (data: object) => {
  return {
    type: types.RESERVATION_DETAIL.SUCCESS,
    payload: data
  };
};

export const reservationDetailFailureAction = (error: object) => {
  return {
    type: types.RESERVATION_DETAIL.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Reservation Update Time actions
 */
export const reservationUpdateTimeRequestAction = (id: string | number, data: object, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.RESERVATION_UPDATE_TIME.REQUEST,
        payload: {}
      });

      const response: any = await services.reservationUpdateTimeService(id, data);

      if (response.status === 200 || response.status === 201) {
        dispatch(reservationUpdateTimeSuccessAction(response.data?.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(reservationUpdateTimeFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(reservationUpdateTimeFailureAction(error));
      throw error;
    }
  };
};

export const reservationUpdateTimeSuccessAction = (data: object) => {
  return {
    type: types.RESERVATION_UPDATE_TIME.SUCCESS,
    payload: data
  };
};

export const reservationUpdateTimeFailureAction = (error: object) => {
  return {
    type: types.RESERVATION_UPDATE_TIME.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Reservation Cancel actions
 */
export const reservationCancelRequestAction = (id: string | number, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.RESERVATION_CANCEL.REQUEST,
        payload: {}
      });

      const response: any = await services.reservationCancelService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(reservationCancelSuccessAction(response.data?.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(reservationCancelFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(reservationCancelFailureAction(error));
      throw error;
    }
  };
};

export const reservationCancelSuccessAction = (data: object) => {
  return {
    type: types.RESERVATION_CANCEL.SUCCESS,
    payload: data
  };
};

export const reservationCancelFailureAction = (error: object) => {
  return {
    type: types.RESERVATION_CANCEL.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Reservation Cancel actions
 */
export const reservationConfirmRequestAction = (id: string | number, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.RESERVATION_CONFIRM.REQUEST,
        payload: {}
      });

      const response: any = await services.reservationConfirmService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(reservationConfirmSuccessAction(response.data?.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(reservationConfirmFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(reservationConfirmFailureAction(error));
      throw error;
    }
  };
};

export const reservationConfirmSuccessAction = (data: object) => {
  return {
    type: types.RESERVATION_CONFIRM.SUCCESS,
    payload: data
  };
};

export const reservationConfirmFailureAction = (error: object) => {
  return {
    type: types.RESERVATION_CONFIRM.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Reservation Statistics actions
 */
export const reservationStatisticsRequestAction = () => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.RESERVATION_STATISTICS.REQUEST,
        payload: {}
      });

      const response: any = await services.reservationStatisticsService();

      if (response.status === 200 || response.status === 201) {
        dispatch(reservationStatisticsSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(reservationStatisticsFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(reservationStatisticsFailureAction(error));
      throw error;
    }
  };
};

export const reservationStatisticsSuccessAction = (data: object) => {
  return {
    type: types.RESERVATION_STATISTICS.SUCCESS,
    payload: data
  };
};

export const reservationStatisticsFailureAction = (error: object) => {
  return {
    type: types.RESERVATION_STATISTICS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
