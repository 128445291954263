// import TicketMenuManagementCreate from "./lib/create";
import TicketsTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';

import * as S from './styles';
import TicketCounts from './lib/ticketCounts';

export default function AccessManagementActivitiesPage() {
  const { tickets, ticketCategory } = useTypedSelector((state) => state.Tickets);
  const { departments } = useTypedSelector((state) => state.Departments);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Entrance and Exit Times</S.Title>
        <TicketCounts />
      </S.Head>
      <Gap size="20px" />
      <TicketsTable data={tickets.data} departments={departments.data} ticketCategory={ticketCategory.data} />

      <AppLoading loading={tickets.loading || departments.loading || ticketCategory.loading} />
    </S.Container>
  );
}
