import React, { useState } from 'react';
import { useLocation } from 'react-router';

import { Link } from 'react-router-dom';
import Gap from 'components/gap';
import NotificationCreateSendToAllForm from './lib/sendToAllForm';

import * as S from './styles';
import DepartmentAndFloorForm from './lib/departmentFloorForm';
import NotificationCreateSendToUserForm from './lib/sendToUserForm';
// import * as T from "common/types";

interface IProps {
  departments: string[];
  floors: { list: any[] };
  userList: {
    list: [];
    totalPages: null;
  };
}

const NotificationCreateTabs: React.FC<IProps> = ({ departments, floors, userList }) => {
  const location = useLocation();

  const [activeTab, setActiveTab] = useState<string | undefined>(location?.hash || '#sendToAll');

  const handleChangeTab = (tab: string) => {
    setActiveTab(tab);
  };

  return (
    <S.Container>
      <Gap size="20px" />
      <S.Head>
        <Link to="#sendToAll">
          <S.Tab onClick={() => handleChangeTab('#sendToAll')} className={activeTab === '#sendToAll' ? 'active' : ''}>
            Send to all
          </S.Tab>
        </Link>
        <Link to="#departmentAndFloor">
          <S.Tab
            onClick={() => handleChangeTab('#departmentAndFloor')}
            className={activeTab === '#departmentAndFloor' ? 'active' : ''}
          >
            Department and floor
          </S.Tab>
        </Link>
        <Link to="#sendToUsers">
          <S.Tab
            onClick={() => handleChangeTab('#sendToUsers')}
            className={activeTab === '#sendToUsers' ? 'active' : ''}
          >
            Send to users
          </S.Tab>
        </Link>
      </S.Head>
      <Gap size="20px" />
      <S.TabPanel>
        {activeTab === '#sendToAll' ? (
          <NotificationCreateSendToAllForm data={{}} />
        ) : activeTab === '#departmentAndFloor' ? (
          <DepartmentAndFloorForm departments={departments} floors={floors} />
        ) : activeTab === '#sendToUsers' ? (
          <NotificationCreateSendToUserForm userList={userList.list} />
        ) : (
          <></>
        )}
      </S.TabPanel>
    </S.Container>
  );
};

export default NotificationCreateTabs;
