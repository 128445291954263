import CafeteriaMenuManagementCreate from './lib/create';
import CafeteriaMenuManagementTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function CafeteriaMenuManagementPage() {
  // -- //
  const { menuList, createMenu, editMenu, upload }: any = useTypedSelector((state) => state.Cafeteria);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Menu Management</S.Title>
        <CafeteriaMenuManagementCreate />
      </S.Head>
      <Gap size="20px" />
      <CafeteriaMenuManagementTable menuList={menuList.data} loading={menuList.loading} />

      <AppLoading loading={createMenu.loading || editMenu.loading || upload.loading} />
    </S.Container>
  );
}
