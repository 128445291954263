import { SetStateAction } from 'react';
import { Formik } from 'formik';

import SecundButton from 'components/form/buttons/secundButton';
import MiniPopUp from 'components/modal/miniPopUp';
import PanelBoxLayout from 'components/layout/panelBox';
// import { useActions } from "common/hooks/useActions";

import Gap from 'components/gap';
import PrimaryButton from 'components/form/buttons/primaryButton';
import DateInput from 'components/form/dateInput';
import OutlineButton from 'components/form/buttons/outLineButton';

import * as S from './styles';

interface IProps {
  setFilter: SetStateAction<any>;
  filter: any;
}

export default function NewsFilterPopup({ setFilter, filter }: IProps) {
  return (
    <S.Content>
      <MiniPopUp
        popUpStyle={{ width: 245, padding: '0' }}
        buttonNode={
          <SecundButton
            hasIcon={true}
            icon="/icons/stroke/filter.svg"
            iconStyle={{ background: 'none' }}
            style={{ background: '#8A93AC' }}
            colorIcon="var(--white)"
          >
            Filters
          </SecundButton>
        }
      >
        <PanelBoxLayout
          style={{ padding: 15 }}
          titleStyle={{ color: '#2D3748', fontSize: 14 }}
          title="Filters"
          icon="/icons/stroke/filter.svg"
        >
          <Formik
            initialValues={{
              type: '',
              department: '',
              date: ''
            }}
            // validationSchema={ValidationLogin}
            onSubmit={(values, { setSubmitting }) => {
              setFilter({ ...filter, ...values, page: 1 });
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              // handleChange,
              handleSubmit,
              // isSubmitting,
              resetForm,
              setValues
            }) => (
              <>
                <DateInput
                  onChange={(e) => {
                    setValues({
                      ...values,
                      date: e
                    });
                  }}
                  placeholder="Date"
                  label="Date"
                  defaultDate={values.date}
                  message={touched.date && (errors.date as any)}
                  error={touched.date && (errors.date as unknown as boolean)}
                  inputStyle={{
                    height: 40,
                    borderRadius: 7,
                    fontSize: 12,
                    padding: '0 15px'
                  }}
                  labelStyle={{ fontSize: 12 }}
                />
                <Gap size="20px" />

                <PrimaryButton
                  style={{ width: '100%', height: 42, borderRadius: 8 }}
                  onClick={handleSubmit}
                  // disabled={isSubmitting}
                >
                  Filter
                </PrimaryButton>
                <Gap size="10px" />

                <OutlineButton
                  style={{ width: '100%', height: 42, borderRadius: 8 }}
                  onClick={() => {
                    resetForm();
                    handleSubmit();
                  }}
                  // disabled={isSubmitting}
                >
                  Reset
                </OutlineButton>
              </>
            )}
          </Formik>
        </PanelBoxLayout>
      </MiniPopUp>
    </S.Content>
  );
}
