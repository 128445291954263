// -- == //
import { Formik } from 'formik';
import React from 'react';

import { ValidationCreateFloor } from 'common/validations';
import PrimaryButton from 'components/form/buttons/primaryButton';
import TextIput from 'components/form/textInput';
import Gap from 'components/gap';
import { useActions } from 'common/hooks/useActions';

import * as T from 'common/types';
interface IProps {
  data?: T.Pages.FloorListItem;
  closeModal?: () => void;
  fetchData?: () => void;
}

const CafeteriaCreateForm: React.FC<IProps> = ({ data, closeModal, fetchData }) => {
  const { floorCreateRequestAction, floorEditRequestAction } = useActions();

  return (
    <Formik
      initialValues={{
        name: data?.name || '',
        id: data?.id || undefined
      }}
      enableReinitialize
      validationSchema={!data?.id ? ValidationCreateFloor : ''}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (data?.id)
          floorEditRequestAction(
            {
              name: JSON.stringify(values.name),
              id: data?.id
            },
            () => {
              closeModal && closeModal();
              resetForm();
              fetchData && fetchData();
            }
          );
        else
          floorCreateRequestAction(
            {
              name: JSON.stringify(values.name)
            },
            () => {
              closeModal && closeModal();
              resetForm();
              fetchData && fetchData();
            }
          );
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit
        // isSubmitting,
        // setValues,
      }) => (
        <>
          <TextIput
            type="number"
            name="name"
            onChange={handleChange}
            placeholder="Title"
            value={values.name}
            message={touched.name && errors.name}
            error={touched.name && (errors.name as unknown as boolean)}
            inputStyle={{ height: 40, borderRadius: 7 }}
          />

          <Gap size="10px" />
          <PrimaryButton
            style={{ width: '100%', height: 42, borderRadius: 8 }}
            onClick={handleSubmit}
            // disabled={isSubmitting}
          >
            Save
          </PrimaryButton>
        </>
      )}
    </Formik>
  );
};

export default CafeteriaCreateForm;
