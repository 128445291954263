import styled from 'styled-components';

export const AuthContainer = styled.div`
  width: 370px;
`;

export const FormLogin = styled.div``;

export const FormTitle = styled.h3`
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  text-align: left;
  margin-bottom: 10px;
`;

export const LoginLink = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--dark-3);
  margin-bottom: 15px;

  a {
    text-decoration: none;
    color: var(--primary);
    font-weight: 500;
  }
`;

export const Div = styled.div``;
export const Links = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--primary-green);
  }
`;

export const SubTitle = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;
