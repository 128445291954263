import styled from 'styled-components';
// import { device } from "common/constants/mediaQueries";

export const Content = styled.div``;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 520px;
  margin: 0 auto;
`;

export const Title = styled.h4`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
`;

export const Buttons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
  gap: 20px;
  justify-content: center;
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.delete {
    background: #ff6464;
  }
  &.success {
    background: var(--primary-green);
  }
`;
