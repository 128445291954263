// -- == //
import React from 'react';
import { ReactSVG } from 'react-svg';
import ImageUploading, { ImageListType } from 'react-images-uploading';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  label?: string;
  disabled?: boolean | undefined;
  onChange?: (e: T.Form.FileUpload) => void;
  style?: object;
  id?: string;
  placeholder?: string;
  error?: boolean;
  success?: boolean;
  message?: string | boolean;
  type?: string;
  name?: string;
  onBlur?: (e: object) => void;
  defaultImage?: string | null;
  icon?: string;
  inputStyle?: object;
  accept?: string[];
  value?: object | null;
  labelStyle?: object;
  // value?:
}

const UploadBox: React.FC<IProps> = ({
  label,
  value,
  // disabled,
  labelStyle,
  style,
  error,
  success,
  message,
  onChange,
  defaultImage,
  placeholder,
  accept = ['png', 'jpg', 'jpeg', 'webp']
}) => {
  // const [images, setImages] = React.useState([...value]);

  // useEffect(() => {
  //   setImages([])
  // }, [value])

  const onChangeFile = (
    imageList: ImageListType
    // addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    // setImages(imageList as never[]);
    if (onChange) onChange(imageList[0] as unknown as T.Form.FileUpload);
  };

  return (
    <S.inputContainer style={style} className={error ? 'error' : success ? 'success' : ''}>
      <ImageUploading value={[{ ...value }]} onChange={onChangeFile} maxNumber={1} acceptType={accept}>
        {({
          imageList,
          onImageUpload,
          // onImageRemoveAll,
          onImageUpdate,
          // onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <>
            {label ? (
              <S.label
                className={`input-label`}
                style={labelStyle}
                // onClick={() => setOpen(true)}
              >
                {label}
              </S.label>
            ) : (
              <></>
            )}
            <S.InputBox
              onClick={() => {
                imageList[0] ? onImageUpdate(0) : onImageUpload();
              }}
              style={{
                borderColor: isDragging ? 'var(--primary-green)' : undefined
              }}
              {...dragProps}
            >
              {defaultImage || imageList[0]?.dataURL ? (
                <S.img src={imageList[0] ? imageList[0]?.dataURL : (defaultImage as any)} className="icon" />
              ) : (
                <>
                  <S.Icon>
                    <ReactSVG
                      src="/icons/stroke/image-plus.svg"
                      beforeInjection={(svg) => {
                        svg.setAttribute('stroke', `#A4A6B3`);
                      }}
                    />
                  </S.Icon>
                  {placeholder && <S.Text>{placeholder}</S.Text>}
                </>
              )}
            </S.InputBox>
          </>
        )}
      </ImageUploading>

      {message ? (
        <S.message className={error ? 'error-message' : success ? 'success-message' : ''}>{message}</S.message>
      ) : (
        <></>
      )}
    </S.inputContainer>
  );
};

export default UploadBox;
