// -- == //
import PanelBoxLayout from 'components/layout/panelBox';
// import PaginationApp from "components/pagination";

// import * as T from "common/types";
import * as S from './styles';

import { ReactSVG } from 'react-svg';
import Gap from 'components/gap';
import DropDown from 'components/form/dropDown';
import { Formik } from 'formik';
import TextInput from 'components/form/textInput';
import { useState } from 'react';
import { SurveyQuestionTypeEnumArray } from 'common/enums';
import { useActions } from 'common/hooks/useActions';
import { toast } from 'common/helpers/toastConfig';
import { ValidationCreateQuestionSurvey } from 'common/validations';

interface IProps {
  data: [];
  questions: any[];
}

export default function SurveyCreateQuestions({ data, questions }: IProps) {
  console.log(data);

  const [showQuestionForm, setShowQuestionForm] = useState(false);
  const [showQuestionChoicesForm, setShowQuestionChoicesForm] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const {
    surveyQuestionAddAction,
    surveyQuestionDeleteAction,
    surveyQuestionEditAction
    // surveyQuestionChoicesEditAction,
    // surveyQuestionChoicesDeleteAction,
    // surveyQuestionChoicesAddAction,
  } = useActions();

  console.log(questions);

  return (
    <S.Content>
      <PanelBoxLayout style={{ padding: '20px 30px' }}>
        <S.Head>Questions</S.Head>
        <Gap size="30px" />
        <Formik
          initialValues={{
            question: '',
            type: '',
            typeTitle: '',
            questions: [],
            // options: [],
            text: '',
            choiceText: '',
            editId: null
          }}
          validationSchema={ValidationCreateQuestionSurvey}
          enableReinitialize
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            if (values.type === 'optional' && !options.length) {
              toast({ model: 'error', message: 'Please enter an option' });
              return;
            }

            if (values.editId) {
              surveyQuestionEditAction({
                type: values.type,
                title: values.question,
                typeTitle: values.typeTitle,
                text: values.text,
                options: options,
                id: values.editId
              });
            } else
              surveyQuestionAddAction({
                type: values.type,
                title: values.question,
                typeTitle: values.typeTitle,
                text: values.text,
                options: values.type === 'optional' ? options : [],
                id: JSON.stringify(Math.random() * 67)
              });
            resetForm();
            setOptions([]);
            setShowQuestionChoicesForm(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            // handleChange,
            handleSubmit,
            // isSubmitting,
            setValues
          }) => (
            <>
              <S.AddButton onClick={() => setShowQuestionForm(true)}>
                <S.Icon>
                  <ReactSVG
                    src="/icons/stroke/plus.svg"
                    beforeInjection={(svg) => {
                      svg.setAttribute('fill', `var(--white)`);
                      svg.setAttribute('width', `14px`);
                      svg.setAttribute('height', `14px`);
                    }}
                  />
                </S.Icon>
                Add Question
              </S.AddButton>
              <Gap size="20px" />
              {showQuestionForm ? (
                <>
                  <DropDown
                    label="Question Type"
                    list={SurveyQuestionTypeEnumArray}
                    placeholder="Question Type"
                    onChange={(e) => {
                      setValues({
                        ...values,
                        typeTitle: e.title,
                        type: e.id as any
                      });
                    }}
                    defaultValue={{ title: values.typeTitle, id: values.type }}
                    key={values.type}
                    message={touched.type && (errors.type as any)}
                    error={errors.type as unknown as boolean}
                  />
                  <Gap size="20px" />
                  <TextInput
                    label="Question "
                    name="question "
                    value={values.question}
                    onChange={(e) => {
                      setValues({ ...values, question: e.target.value });
                    }}
                    placeholder="Question "
                    message={touched.question && (errors.question as any)}
                    error={errors.question as unknown as boolean}
                    icon={
                      <S.Icon style={{ width: 20, height: 20 }} onClick={() => handleSubmit()}>
                        <ReactSVG
                          src={values.editId ? '/icons/check-white.svg' : '/icons/stroke/plus.svg'}
                          beforeInjection={(svg) => {
                            svg.setAttribute(`${values.editId ? 'strooke' : 'fill'}`, `var(--white)`);
                            svg.setAttribute('width', `10px`);
                            svg.setAttribute('height', `10px`);
                          }}
                        />
                      </S.Icon>
                    }
                  />
                  <Gap size="20px" />
                  {values.type === 'optional' ? (
                    <>
                      <S.AddButton onClick={() => setShowQuestionChoicesForm(true)}>
                        <S.Icon>
                          <ReactSVG
                            src="/icons/stroke/plus.svg"
                            beforeInjection={(svg) => {
                              svg.setAttribute('fill', `var(--white)`);
                              svg.setAttribute('width', `14px`);
                              svg.setAttribute('height', `14px`);
                            }}
                          />
                        </S.Icon>
                        Add Choices
                      </S.AddButton>
                      <Gap size="20px" />
                      {showQuestionChoicesForm ? (
                        <>
                          <TextInput
                            label="Choice "
                            name="choiceText "
                            value={values.choiceText}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                choiceText: e.target.value
                              });
                            }}
                            placeholder="Choice Title"
                            message={touched.choiceText && (errors.choiceText as any)}
                            error={errors.choiceText as unknown as boolean}
                            icon={
                              <S.Icon
                                style={{ width: 20, height: 20 }}
                                onClick={() => {
                                  setOptions([
                                    ...options,
                                    {
                                      id: JSON.stringify(Math.random() * 67),
                                      title: values.choiceText
                                    }
                                  ]);
                                  setValues({
                                    ...values,
                                    choiceText: ''
                                  });
                                }}
                              >
                                <ReactSVG
                                  src="/icons/stroke/plus.svg"
                                  beforeInjection={(svg) => {
                                    svg.setAttribute('fill', `var(--white)`);
                                    svg.setAttribute('width', `10px`);
                                    svg.setAttribute('height', `10px`);
                                  }}
                                />
                              </S.Icon>
                            }
                          />
                        </>
                      ) : (
                        <></>
                      )}
                      <Gap size="20px" />
                      {options.map((choice: any) => (
                        <TextInput
                          value={choice.title}
                          onChange={(e: any) => {
                            // if (values.editId) {
                            //   surveyQuestionChoicesEditAction(
                            //     e.target.value,
                            //     choice.id,
                            //     values.editId
                            //   );
                            // }
                            setOptions(
                              options.map((item: any) => {
                                return item.id === choice.id
                                  ? {
                                      ...item,
                                      title: e.target.value
                                    }
                                  : item;
                              })
                            );
                          }}
                          placeholder="Choice Title"
                          message={touched.choiceText && (errors.choiceText as any)}
                          error={errors.choiceText as unknown as boolean}
                          icon={
                            <S.DeleteButton
                              style={{
                                width: 20,
                                height: 20,
                                minWidth: 20
                              }}
                              onClick={() => {
                                setOptions(options.filter((item: any) => item.id !== choice.id));
                              }}
                            >
                              <ReactSVG
                                src="/icons/stroke/trash.svg"
                                beforeInjection={(svg) => {
                                  svg.setAttribute('stroke', `#FF6464`);
                                  svg.setAttribute('width', `10px`);
                                  svg.setAttribute('height', `10px`);
                                }}
                              />
                            </S.DeleteButton>
                          }
                        />
                      ))}
                    </>
                  ) : (
                    <></>
                  )}
                  <Gap size="20px" />
                </>
              ) : (
                <></>
              )}

              <S.List>
                {questions?.map((item: any) => (
                  <S.ListItem>
                    <S.Grid>
                      <S.Text>
                        <S.Type>{item.type}</S.Type>
                        {item.title}
                      </S.Text>
                      <S.EditButton
                        onClick={() => {
                          setShowQuestionForm(true);
                          if (item.options.length) setShowQuestionChoicesForm(true);
                          setValues({
                            ...values,
                            question: item.title,
                            type: item.type,
                            typeTitle: item.typeTitle,
                            editId: item.id
                          });
                          setOptions(item.options);
                        }}
                      >
                        <ReactSVG
                          src="/icons/stroke/pen.svg"
                          beforeInjection={(svg) => {
                            svg.setAttribute('stroke', `#8A93AC`);
                            svg.setAttribute('width', `18px`);
                            svg.setAttribute('height', `18px`);
                          }}
                        />
                      </S.EditButton>
                      <S.DeleteButton onClick={() => surveyQuestionDeleteAction(item)}>
                        <ReactSVG
                          src="/icons/stroke/trash.svg"
                          beforeInjection={(svg) => {
                            svg.setAttribute('stroke', `#FF6464`);
                            svg.setAttribute('width', `24px`);
                            svg.setAttribute('height', `24px`);
                          }}
                        />
                      </S.DeleteButton>
                    </S.Grid>
                    <Gap size="5px" />
                    {item?.options?.map((item: any) => <S.TextChoice>{item?.title}</S.TextChoice>)}
                  </S.ListItem>
                ))}
              </S.List>
            </>
          )}
        </Formik>
      </PanelBoxLayout>
    </S.Content>
  );
}
