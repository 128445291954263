// import TicketMenuManagementCreate from "./lib/create";
import CategorieyDetailForm from '../lib/form';
import Gap from 'components/gap';
// import { useTypedSelector } from "common/hooks/useTypedSelector";
import AppLoading from 'components/appLoading';
import CategorySupporterTable from './lib/table';
import { useTypedSelector } from 'common/hooks/useTypedSelector';

import * as S from './styles';

export default function CategoryDetailPage() {
  const { ticketCategory, supporterList, ticketCategoryDetail, ticketCategoryIcon, ticketCategoryEdit } =
    useTypedSelector((state) => state.Tickets);
  const { supporter } = useTypedSelector((state) => state.Admin);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Ticket Categories</S.Title>
      </S.Head>
      <Gap size="20px" />
      <S.Div className="row">
        <S.Div className="col-md-6">
          <CategorieyDetailForm
            categories={ticketCategory.data}
            supporterList={supporterList}
            supporter={supporter.data}
            data={ticketCategoryDetail.data}
          />
        </S.Div>
        <S.Div className="col-md-6">
          <CategorySupporterTable data={supporterList} />
        </S.Div>
      </S.Div>

      <AppLoading
        loading={
          ticketCategoryDetail.loading || ticketCategoryIcon.loading || ticketCategoryEdit.loading || supporter.loading
        }
      />
    </S.Container>
  );
}
