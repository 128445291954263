function convertMsToMin(milliseconds: number, label?: string) {
  if (milliseconds === 0 || !milliseconds) return 0;

  let seconds = typeof milliseconds === 'string' ? Number(milliseconds) / 1000 : milliseconds / 1000;
  let minutes = seconds / 60;
  //   let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  //   hours = hours % 24;
  if (minutes)
    return `${
      minutes.toString().indexOf('.') ? minutes.toString().substring(0, 4) : Math.floor(minutes)
    } ${label || ''}`;
  else return 0;
}

export default convertMsToMin;
