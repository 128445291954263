/* eslint-disable react-hooks/exhaustive-deps */
// import TicketMenuManagementCreate from "./lib/create";
import { useParams } from 'react-router';
import { useEffect } from 'react';

import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import TicketDetailInfo from './lib/info';
import { useActions } from 'common/hooks/useActions';
import AppLoading from 'components/appLoading';

import * as S from './styles';
import TicketDetailTypeInfo from './lib/typeInfo';

export default function TicketDetailPage() {
  const { ticketDetail, ticketClose } = useTypedSelector((state) => state.Tickets);
  const { ticketDetailRequestAction } = useActions();

  const params = useParams();

  useEffect(() => {
    ticketDetailRequestAction(params?.id);
  }, []);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Tickets</S.Title>
      </S.Head>
      <Gap size="20px" />
      <S.Div className="row">
        {/* <S.Div className="col-md-6">
          <TicketDetailChat data={ticketDetail.data} />
        </S.Div> */}
        <S.Div className="col-md-6">
          <TicketDetailInfo data={ticketDetail.data} />
        </S.Div>
        <S.Div className="col-md-6">
          <TicketDetailTypeInfo data={ticketDetail.data} />
        </S.Div>
      </S.Div>

      <AppLoading loading={ticketDetail.loading || ticketClose.loading} />
    </S.Container>
  );
}
