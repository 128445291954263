import { ApiCaller } from 'common/helpers/apiCaller';
import convertToQueryString from 'common/helpers/convertToQueryString';

// ------ Surveys ------ //
export const surveysService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/survey/list?${convertToQueryString(data)}`
  });
};

// ------ Survey Detail ------ //
export const surveyDetailService = (id?: string | number) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/survey/details/${id}`
  });
};

// ------ Survey Create ------ //
export const surveyCreateService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/survey/add-survey`,
    data
  });
};

// ------ Survey Result ------ //
export const surveyResultService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/survey/result?${convertToQueryString(data)}`,
    data
  });
};
