import { Link } from 'react-router-dom';
import * as S from './styles';
import * as T from 'common/types';

export const OutlineButton: React.FC<T.Form.Button> = ({ link, disabled, style, onClick, children, target }) => {
  return (
    <>
      {link ? (
        <S.outlineButtonLink style={style}>
          {target ? (
            <a href={link} target={target} style={style}>
              {children}
            </a>
          ) : (
            <Link to={link} style={style}>
              {children}
            </Link>
          )}
        </S.outlineButtonLink>
      ) : (
        <S.outlineButton onClick={onClick} disabled={disabled} style={style}>
          {children}
        </S.outlineButton>
      )}
    </>
  );
};

export default OutlineButton;
