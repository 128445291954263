/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import AppTable from 'components/appTable';
import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import { useActions } from 'common/hooks/useActions';

import * as S from './styles';
import * as T from 'common/types';
import PaginationApp from 'components/pagination';
import { useParams } from 'react-router';

interface IProps {
  data: { list: []; totalPages: number };
  loading: boolean;
}

export default function SurveyShowTable({ data, loading }: IProps) {
  const params = useParams();

  const { surveysResultRequestAction } = useActions();
  const [meta, setMeta] = useState({
    page: 1,
    page_size: 10,
    search: '',
    type: '',
    id: params.id
  });

  const fetchData = () => {
    surveysResultRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  const handleChangePage = (page: number) => {
    setMeta({ ...meta, page });
  };

  const handleChangePageSize = (page_size: number) => {
    setMeta({ ...meta, page_size });
  };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => setMeta({ ...meta, search: e })}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
          />
        </S.Head>
        <Gap size="20px" />
        <AppTable
          headers={[
            { id: 1, title: 'Employee ID', dir: 'left' },
            { id: 2, title: 'Username', dir: 'left' },
            { id: 3, title: 'Question', dir: 'left' },
            { id: 4, title: 'Question', dir: 'left' },
            { id: 5, title: 'Question', dir: 'left' },
            { id: 6, title: 'Question', dir: 'left' },
            { id: 7, title: 'Question', dir: 'left' }
          ]}
          loading={loading}
          data={data.list}
          tdFields={{
            id: (item: T.Pages.SurveyItem) => <S.Div className="text-left">{item?.id}</S.Div>,

            Username: (item: T.Pages.SurveyItem) => <S.Div className="text-left ">{item?.id}</S.Div>,

            Question: (item: T.Pages.SurveyItem) => <S.Div className="text-left ">{item?.id}</S.Div>,
            Question2: (item: T.Pages.SurveyItem) => <S.Div className="text-left ">{item?.id}</S.Div>,
            Question3: (item: T.Pages.SurveyItem) => <S.Div className="text-left ">{item?.id}</S.Div>,
            Question4: (item: T.Pages.SurveyItem) => <S.Div className="text-left ">{item?.id}</S.Div>,
            Question5: (item: T.Pages.SurveyItem) => <S.Div className="text-left ">{item?.id}</S.Div>
          }}
        />
      </PanelBoxLayout>
      {data.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.page_size * data.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={data.totalPages}
          pageSize={meta.page_size}
          handleChangeTake={handleChangePageSize}
        />
      ) : (
        <></>
      )}
    </S.Content>
  );
}
