import styled from 'styled-components';

export const loadingContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.6);
`;

export const animation = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #cee2fc;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    border-radius: 50%;
  }
`;

export const loadingText = styled.p`
  color: #fff;
  font-size: 19px;
  padding-top: 10px;
  margin: 0;
`;
