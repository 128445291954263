import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import * as S from './styles';
import * as T from 'common/types';

const RateStar: FC<T.RateStar> = ({ rate }: any) => {
  const newArray = Array.from({ length: rate }, (_value, index) => index);

  return (
    <S.RatesCon>
      {rate !== 0 ? (
        <>
          {newArray.map(() => (
            <S.Star>
              <ReactSVG src="/icons/star.svg" />
            </S.Star>
          ))}
          {rate?.toString().indexOf('.') !== -1 ? (
            <S.Star>
              <ReactSVG src="/icons/star-o.svg" />
            </S.Star>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </S.RatesCon>
  );
};

export default RateStar;
