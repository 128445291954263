import { ReactSVG } from 'react-svg';
import * as S from './styles';
import * as T from 'common/types';

const SecundButton: React.FC<T.Form.SecundButton> = ({
  disabled,
  style,
  iconStyle,
  onClick,
  className,
  type,
  children,
  icon,
  hasIcon,
  colorIcon
}) => {
  return (
    <S.PrimayButtonC className={className} type={type} onClick={onClick} disabled={disabled} style={style}>
      {hasIcon ? (
        <S.Icon style={iconStyle}>
          <ReactSVG
            src={icon ? icon : '/icons/stroke/plus.svg'}
            beforeInjection={(svg) => {
              svg.setAttribute('stroke', `${colorIcon ? colorIcon : 'var(--primary-green)'}`);
            }}
          />
        </S.Icon>
      ) : (
        <></>
      )}
      {children}
    </S.PrimayButtonC>
  );
};

export default SecundButton;
