import * as types from 'common/constants';

const initialState = {
  cafeteria: {
    data: {},
    loading: false,
    error: null
  },
  stationary: {
    data: {},
    loading: false,
    error: null
  },
  maintenance: {
    data: {},
    loading: false,
    error: null
  },
  hsse: {
    data: {},
    loading: false,
    error: null
  },
  events: {
    data: {},
    loading: false,
    error: null
  }
};

interface Reducer {
  type: string;
  payload: any;
}

const Dashboard = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Cafeteria List cases
     */
    case types.DASHBOARD_CAFETERIA.REQUEST:
      return {
        ...state,
        cafeteria: {
          data: {},
          loading: true,
          error: null
        }
      };
    case types.DASHBOARD_CAFETERIA.SUCCESS:
      return {
        ...state,
        cafeteria: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.DASHBOARD_CAFETERIA.FAILURE:
      return {
        ...state,
        cafeteria: {
          data: {},
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Cafeteria List cases
     */
    case types.DASHBOARD_STATIONARY.REQUEST:
      return {
        ...state,
        stationary: {
          data: {},
          loading: true,
          error: null
        }
      };
    case types.DASHBOARD_STATIONARY.SUCCESS:
      return {
        ...state,
        stationary: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.DASHBOARD_STATIONARY.FAILURE:
      return {
        ...state,
        stationary: {
          data: {},
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Maintenance cases
     */
    case types.DASHBOARD_MAINTENANCE.REQUEST:
      return {
        ...state,
        maintenance: {
          data: {},
          loading: true,
          error: null
        }
      };
    case types.DASHBOARD_MAINTENANCE.SUCCESS:
      return {
        ...state,
        maintenance: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.DASHBOARD_MAINTENANCE.FAILURE:
      return {
        ...state,
        maintenance: {
          data: {},
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Hsse cases
     */
    case types.DASHBOARD_HSSE.REQUEST:
      return {
        ...state,
        hsse: {
          data: {},
          loading: true,
          error: null
        }
      };
    case types.DASHBOARD_HSSE.SUCCESS:
      return {
        ...state,
        hsse: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.DASHBOARD_HSSE.FAILURE:
      return {
        ...state,
        hsse: {
          data: {},
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Events List cases
     */
    case types.DASHBOARD_EVENTS.REQUEST:
      return {
        ...state,
        events: {
          data: {},
          loading: true,
          error: null
        }
      };
    case types.DASHBOARD_EVENTS.SUCCESS:
      return {
        ...state,
        events: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.DASHBOARD_EVENTS.FAILURE:
      return {
        ...state,
        events: {
          data: {},
          loading: false,
          error: payload
        }
      };

    default:
      return state;
  }
};

export default Dashboard;
