// -- == //
// import TicketMenuManagementCreate from "./lib/create";
import EventsContributorsTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function EventsContributorsPage() {
  const { contributors, eventEnd, contributorAccept, contributorReject } = useTypedSelector((state) => state.Events);
  const { departments } = useTypedSelector((state) => state.Departments);

  return (
    <S.Container>
      <S.Head>
        <S.Title>
          Contributors List
          <S.Text>{contributors?.data?.eventInfo?.title}</S.Text>
        </S.Title>
      </S.Head>
      <Gap size="20px" />
      <EventsContributorsTable data={contributors.data as any} departments={departments.data} />

      <AppLoading
        loading={
          contributors.loading ||
          departments.loading ||
          eventEnd.loading ||
          contributorAccept.loading ||
          contributorReject.loading
        }
      />
    </S.Container>
  );
}
