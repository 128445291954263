import CafeteriaStatisticsSidebarFilterForm from './lib/filterForm';
import PanelBoxLayout from 'components/layout/panelBox';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  cafeteriaList: T.Pages.CafeteriaListItem[];
}

export default function CafeteriaStatisticsSidebar({ cafeteriaList }: IProps) {
  return (
    <S.Content>
      <PanelBoxLayout icon="/icons/stroke/filter.svg" title="Filter" style={{ padding: '20px 30px' }}>
        <CafeteriaStatisticsSidebarFilterForm cafeteriaList={cafeteriaList} />
      </PanelBoxLayout>
    </S.Content>
  );
}
