// import PaginationApp from "components/pagination";

// import * as T from "common/types";
import { useState } from 'react';
import * as S from '../../styles';
import Gap from 'components/gap';
import ModalApp from 'components/modalApp';

interface IProps {
  data: any;
}

export default function TicketDetailTypeNormalInfo({ data }: IProps) {
  const [imageUrl, setImageUrl] = useState('');
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (url: string) => {
    setImageUrl(url);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setImageUrl('');
    setShowModal(false);
  };

  return (
    <S.Detail>
      <S.Item>
        <S.Title>Description</S.Title>
        <S.Text className="text-wrap">{data?.description}</S.Text>
      </S.Item>

      <S.Item style={{ borderBottom: 'none' }}>
        <Gap size="15px" />
        <S.MainTitle>Attachments</S.MainTitle>
        <Gap size="10px" />
        <S.Grid>
          {data?.images?.map((item: any) => <S.ImageNormal src={item} onClick={() => handleShowModal(item)} />)}
        </S.Grid>
      </S.Item>

      <ModalApp show={showModal} handleClose={handleCloseModal} fixed>
        <S.Image src={imageUrl} />
      </ModalApp>
    </S.Detail>
  );
}
