// -- == //
import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'common/helpers/toastConfig';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';

import * as types from 'common/constants';
// import * as T from "common/types";
import * as services from 'common/services';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket list actions
 */
export const ticketsRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.TICKETS.REQUEST,
        payload: {}
      });

      const response: any = await services.ticketsService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(ticketsSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(ticketsFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(ticketsFailureAction(error));
      throw error;
    }
  };
};

export const ticketsSuccessAction = (data: object) => {
  return {
    type: types.TICKETS.SUCCESS,
    payload: data
  };
};

export const ticketsFailureAction = (error: object) => {
  return {
    type: types.TICKETS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket Detail actions
 */
export const ticketDetailRequestAction = (id?: string) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.TICKET_DETAIL.REQUEST,
        payload: {}
      });

      const response: any = await services.ticketDetailService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(ticketDetailSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(ticketDetailFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(ticketDetailFailureAction(error));
      throw error;
    }
  };
};

export const ticketDetailSuccessAction = (data: object) => {
  return {
    type: types.TICKET_DETAIL.SUCCESS,
    payload: data
  };
};

export const ticketDetailFailureAction = (error: object) => {
  return {
    type: types.TICKET_DETAIL.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket Categories list actions
 */
export const ticketCategoriesRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.TICKET_CATEGORIES.REQUEST,
        payload: {}
      });

      const response: any = await services.ticketCategoriesService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(ticketCategoriesSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(ticketCategoriesFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(ticketCategoriesFailureAction(error));
      throw error;
    }
  };
};

export const ticketCategoriesSuccessAction = (data: object) => {
  return {
    type: types.TICKET_CATEGORIES.SUCCESS,
    payload: data
  };
};

export const ticketCategoriesFailureAction = (error: object) => {
  return {
    type: types.TICKET_CATEGORIES.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket Category Create actions
 */
export const ticketCategoryCreateRequestAction = (data: object, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.TICKET_CATEROY_CREATE.REQUEST,
        payload: {}
      });

      const response: any = await services.ticketCategoryCreateService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(ticketCategoryCreateSuccessAction(response.data?.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(ticketCategoryCreateFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(ticketCategoryCreateFailureAction(error));
      throw error;
    }
  };
};

export const ticketCategoryCreateSuccessAction = (data: object) => {
  return {
    type: types.TICKET_CATEROY_CREATE.SUCCESS,
    payload: data
  };
};

export const ticketCategoryCreateFailureAction = (error: object) => {
  return {
    type: types.TICKET_CATEROY_CREATE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket Category Edit actions
 */
export const ticketCategoryEditRequestAction = (data: object, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.TICKET_CATEROY_EDIT.REQUEST,
        payload: {}
      });

      const response: any = await services.ticketCategoryEditService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(ticketCategoryEditSuccessAction(response.data?.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(ticketCategoryEditFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(ticketCategoryEditFailureAction(error));
      throw error;
    }
  };
};

export const ticketCategoryEditSuccessAction = (data: object) => {
  return {
    type: types.TICKET_CATEROY_EDIT.SUCCESS,
    payload: data
  };
};

export const ticketCategoryEditFailureAction = (error: object) => {
  return {
    type: types.TICKET_CATEROY_EDIT.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket Category Detail actions
 */
export const ticketCategoryDetailRequestAction = (id: number | string) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.TICKET_CATEROY_DETAIL.REQUEST,
        payload: {}
      });

      const response: any = await services.ticketCategoryDetailService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(ticketCategoryDetailSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(ticketCategoryDetailFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(ticketCategoryDetailFailureAction(error));
      throw error;
    }
  };
};

export const ticketCategoryDetailSuccessAction = (data: object) => {
  return {
    type: types.TICKET_CATEROY_DETAIL.SUCCESS,
    payload: data
  };
};

export const ticketCategoryDetailFailureAction = (error: object) => {
  return {
    type: types.TICKET_CATEROY_DETAIL.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket Category Disable actions
 */
export const ticketCategoryDeleteRequestAction = (data: object, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.TICKET_CATEROY_DELETE.REQUEST,
        payload: {}
      });

      const response: any = await services.ticketCategoryDisableService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(ticketCategoryDeleteSuccessAction(response.data?.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(ticketCategoryDeleteFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(ticketCategoryDeleteFailureAction(error));
      throw error;
    }
  };
};

export const ticketCategoryDeleteSuccessAction = (data: object) => {
  return {
    type: types.TICKET_CATEROY_DELETE.SUCCESS,
    payload: data
  };
};

export const ticketCategoryDeleteFailureAction = (error: object) => {
  return {
    type: types.TICKET_CATEROY_DELETE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket Category Enable actions
 */
export const ticketCategoryEnableRequestAction = (data: object, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.TICKET_CATEROY_DELETE.REQUEST,
        payload: {}
      });

      const response: any = await services.ticketCategoryEnableService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(ticketCategoryEnableSuccessAction(response.data?.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(ticketCategoryEnableFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(ticketCategoryEnableFailureAction(error));
      throw error;
    }
  };
};

export const ticketCategoryEnableSuccessAction = (data: object) => {
  return {
    type: types.TICKET_CATEROY_DELETE.SUCCESS,
    payload: data
  };
};

export const ticketCategoryEnableFailureAction = (error: object) => {
  return {
    type: types.TICKET_CATEROY_DELETE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * cafeteria Upload actions
 */
export const ticketCategoryIconRequestAction = (data: any, callBack: (fileName: string) => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.TICKET_CATEROY_ICON.REQUEST,
        payload: {}
      });

      const formData = new FormData();
      formData.append('image', data.image);

      const response: any = await services.ticketCategoryIconService(formData);

      if (response.status === 200 || response.status === 201) {
        dispatch(ticketCategoryIconSuccessAction(response.data));
        callBack && callBack(response.data?.data?.fileNameOnly);
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(ticketCategoryIconFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(ticketCategoryIconFailureAction(error));
      throw error;
    }
  };
};

export const ticketCategoryIconSuccessAction = (data: object) => {
  return {
    type: types.TICKET_CATEROY_ICON.SUCCESS,
    payload: data
  };
};

export const ticketCategoryIconFailureAction = (error: object) => {
  return {
    type: types.TICKET_CATEROY_ICON.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket Category Detail actions
 */
export const ticketStatisticsRequestAction = () => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.TICKET_STATISTICS.REQUEST,
        payload: {}
      });

      const response: any = await services.ticketStatisticsService();

      if (response.status === 200 || response.status === 201) {
        dispatch(ticketStatisticsSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(ticketStatisticsFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(ticketStatisticsFailureAction(error));
      throw error;
    }
  };
};

export const ticketStatisticsSuccessAction = (data: object) => {
  return {
    type: types.TICKET_STATISTICS.SUCCESS,
    payload: data
  };
};

export const ticketStatisticsFailureAction = (error: object) => {
  return {
    type: types.TICKET_STATISTICS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket Close actions
 */
export const ticketCloseRequestAction = (id: string | number, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.TICKET_CLOSE.REQUEST,
        payload: {}
      });

      const response: any = await services.ticketCloseService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(ticketCloseSuccessAction(response.data?.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(ticketCloseFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(ticketCloseFailureAction(error));
      throw error;
    }
  };
};

export const ticketCloseSuccessAction = (data: object) => {
  return {
    type: types.TICKET_CLOSE.SUCCESS,
    payload: data
  };
};

export const ticketCloseFailureAction = (error: object) => {
  return {
    type: types.TICKET_CLOSE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket Supporter List actions
 */
export const ticketAddSupporterListAction = (data: object) => {
  return {
    type: types.TICKET_SUPPORTER_LIST.ADD,
    payload: data
  };
};

export const ticketRemoveSupporterListAction = (data: object) => {
  return {
    type: types.TICKET_SUPPORTER_LIST.REMOVE,
    payload: data
  };
};

export const ticketResetSupporterListAction = () => {
  return {
    type: types.TICKET_SUPPORTER_LIST.RESET,
    payload: {}
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
