/* eslint-disable react-hooks/rules-of-hooks */
import AppLoading from 'components/appLoading';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

export default function index() {
  const history = useNavigate();
  const token = Cookies.get('token');

  useEffect(() => {
    if (token) history('/panel/dashboard');
    else history('/auth/login');
  }, [history, token]);

  return (
    <>
      <AppLoading loading={true} />
    </>
  );
}
