import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h2`
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: var(--primary-dark-two);
  margin: 0px 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Name = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0a214280;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Div = styled.div``;

export const Status = styled.span`
  padding: 0px 8px;
  font-size: 14px;
  font-weight: 400;
  height: 24px;
  line-height: 22px;
  border-radius: 50px;

  &.active {
    color: #1dbaaf;
    background: #e9f9f7;
  }

  &.complated {
    color: #20caff;
    background: #d2f4ff;
  }

  &.canceled {
    color: #ff6464;
    background: #fdf0ef;
  }
`;
