import { Formik } from 'formik';
import React from 'react';

import { ValidationForgetPassword } from 'common/validations';
import PrimaryButton from 'components/form/buttons/primaryButton';
import TextIput from 'components/form/textInput';
import Gap from 'components/gap';
import { useActions } from 'common/hooks/useActions';

// import * as S from "../../styles";

const ForgetasswordForm: React.FC = () => {
  const { forgetPasswordRequestAction } = useActions();

  return (
    <Formik
      initialValues={{ mail: '' }}
      validationSchema={ValidationForgetPassword}
      onSubmit={(values, { setSubmitting }) => {
        forgetPasswordRequestAction(values, () => {});
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
        <>
          <TextIput
            label="Email"
            type="text"
            name="mail"
            onChange={handleChange}
            placeholder="Please enter your mail"
            value={values.mail}
            message={touched.mail && errors.mail}
            error={errors.mail as unknown as boolean}
          />
          <Gap size="15px" />

          <PrimaryButton style={{ width: '100%' }} onClick={handleSubmit} disabled={isSubmitting}>
            Reset Password
          </PrimaryButton>
        </>
      )}
    </Formik>
  );
};

export default ForgetasswordForm;
