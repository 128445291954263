/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import AppTable from 'components/appTable';
import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import { useActions } from 'common/hooks/useActions';
import PrimaryButton from 'components/form/buttons/primaryButton';
import TicketFilterPopup from '../filterForm';
import PaginationApp from 'components/pagination';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  data: { list: []; totalPages: number };
  loading: boolean;
}

export default function ReservationTable({ data, loading }: IProps) {
  const { reservationsRequestAction } = useActions();
  const [meta, setMeta] = useState({
    page: 1,
    page_size: 10,
    search: '',
    type: ''
  });

  const fetchData = () => {
    reservationsRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  const handleChangePage = (page: number) => {
    setMeta({ ...meta, page });
  };

  const handleChangePageSize = (page_size: number) => {
    setMeta({ ...meta, page_size });
  };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => setMeta({ ...meta, search: e })}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
            value={meta.search}
          />
          <TicketFilterPopup setFilter={setMeta} filter={meta} />
        </S.Head>
        <Gap size="20px" />
        <AppTable
          headers={[
            { id: 1, title: 'ID', dir: 'left' },
            { id: 2, title: 'Subject', dir: 'left' },
            { id: 3, title: 'Start, End Date/Time', dir: 'left' },
            { id: 4, title: 'Room', dir: 'left' },
            { id: 5, title: 'Inviter', dir: 'left' },
            { id: 6, title: 'Status' },
            { id: 8, title: '' }
          ]}
          loading={loading}
          data={data.list}
          tdFields={{
            id: (item: T.Pages.ReservationItem) => <S.Div className="text-left">{item?.id}</S.Div>,
            subject: (item: T.Pages.ReservationItem) => <S.Div className="text-left">{item.subject}</S.Div>,
            date: (item: T.Pages.ReservationItem) => (
              <S.Div className="text-left">
                {new Date(`${item.month} ${item.day} ${item.year}`).toLocaleDateString()} (
                {item.start_time + '-' + item.end_time})
              </S.Div>
            ),
            room: (item: T.Pages.ReservationItem) => <S.Div className="text-left captal">{item.roomName}</S.Div>,
            inviter: (item: T.Pages.ReservationItem) => <S.Div className="text-left captal">{item.inviter}</S.Div>,
            status: (item: T.Pages.ReservationItem) => (
              <S.Div className="text-left">
                <S.Status className={item.status}>
                  <S.Span></S.Span>
                  {item.status}
                </S.Status>
              </S.Div>
            ),
            edit: (item: T.Pages.ReservationItem) => (
              <PrimaryButton
                style={{
                  height: 28,
                  fontSize: 12,
                  background: '#8A93AC',
                  color: 'var(--white)',
                  width: 94,
                  margin: 'auto',
                  borderRadius: 8
                }}
                link={`/panel/reservation/${item.id}`}
              >
                Show
              </PrimaryButton>
            )
          }}
        />
      </PanelBoxLayout>
      {data.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.page_size * data.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={data.totalPages}
          pageSize={meta.page_size}
          handleChangeTake={handleChangePageSize}
        />
      ) : (
        <></>
      )}
    </S.Content>
  );
}
