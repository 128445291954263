import { ApiCaller } from 'common/helpers/apiCaller';
import convertToQueryString from 'common/helpers/convertToQueryString';

// ------ Floor List ------ //
export const floorsService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/location/floor?${convertToQueryString(data)}`
  });
};

// ------ Floor Create ------ //
export const floorCreateService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/location/floor`,
    data
  });
};

// ------ Floor Edit ------ //
export const floorEditService = (data: object) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/location/floor`,
    data
  });
};

// ------ Floor Delete ------ //
export const floorDeleteService = (id: number) => {
  return ApiCaller({
    method: 'DELETE',
    url: `${process.env.REACT_APP_API_URL}api/admin/location/floor/${id}`
  });
};
