// import TicketMenuManagementCreate from "./lib/create";
import ReservationTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';
import ReservationCounts from './lib/reservationCounts';

import * as S from './styles';

export default function ReservationPage() {
  const { reservations, statistics }: any = useTypedSelector((state) => state.Reservation);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Reservation System</S.Title>
        <ReservationCounts data={statistics.data} />
      </S.Head>
      <Gap size="20px" />
      <ReservationTable data={reservations.data} loading={reservations.loading} />

      <AppLoading loading={statistics.loading || reservations.loading} />
    </S.Container>
  );
}
