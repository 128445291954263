/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import moment from 'moment';

import AppTable from 'components/appTable';
import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import { useActions } from 'common/hooks/useActions';
import PrimaryButton from 'components/form/buttons/primaryButton';
import TicketFilterPopup from '../filterForm';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  data: [];
  departments: [];
  ticketCategory: [];
}

export default function TicketTable({ data, departments, ticketCategory }: IProps) {
  const { ticketsRequestAction } = useActions();
  const [meta, setMeta] = useState({
    page: 1,
    page_size: 1000,
    search: '',
    type: ''
  });

  const fetchData = () => {
    ticketsRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  // const handleChangePage = (page: number) => {
  //   setMeta({ ...meta, page });
  // };

  // const handleChangePageSize = (page_size: number) => {
  //   setMeta({ ...meta, page_size });
  // };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => setMeta({ ...meta, search: e })}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
          />
          <TicketFilterPopup
            setFilter={setMeta}
            filter={meta}
            departments={departments}
            ticketCategory={ticketCategory}
          />
        </S.Head>
        <Gap size="20px" />
        <AppTable
          headers={[
            { id: 1, title: 'Employee ID', dir: 'left' },
            { id: 2, title: 'Name', dir: 'left' },
            { id: 3, title: 'Department', dir: 'left' },
            { id: 4, title: 'Job Title', dir: 'left' },
            { id: 5, title: 'Area', dir: 'left' },
            { id: 6, title: 'Date', dir: 'left' },
            { id: 7, title: 'Entry' },
            { id: 8, title: 'Exit' },
            { id: 9, title: '' }
          ]}
          data={data}
          tdFields={{
            id: (item: T.Pages.TicketItem) => <S.Div className="text-left">{item?.id}</S.Div>,
            icon: (item: T.Pages.TicketItem) => (
              <S.Div className="">
                <S.Icon>
                  <S.Image src={item.category?.icon} />
                </S.Icon>
              </S.Div>
            ),
            category: (item: T.Pages.TicketItem) => <S.Div className="text-left">{item.category?.title}</S.Div>,
            title: (item: T.Pages.TicketItem) => <S.Div className="text-left captal">{item.title}</S.Div>,
            requestLocation: (item: T.Pages.TicketItem) => (
              <S.Div className="text-left captal">{item.requestLocation}</S.Div>
            ),
            createdAt: (item: T.Pages.TicketItem) => (
              <S.Div className="text-left captal">{moment(item.createdAt).format('YYYY.MM.DD HH:MM')}</S.Div>
            ),
            entry: () => (
              <S.Div className="flex-center" style={{ flexDirection: 'column', gap: 3 }}>
                <S.Span>10:22</S.Span>
                <S.Floor className="flex-center">Floor 1</S.Floor>
              </S.Div>
            ),
            exit: () => (
              <S.Div className="flex-center" style={{ flexDirection: 'column', gap: 3 }}>
                <S.Span>10:22</S.Span>
                <S.Floor className="flex-center">Floor 1</S.Floor>
              </S.Div>
            ),
            edit: (item: T.Pages.TicketItem) => (
              <PrimaryButton
                style={{
                  height: 28,
                  fontSize: 12,
                  background: '#8A93AC',
                  color: 'var(--white)',
                  width: 94,
                  margin: 'auto'
                }}
                link={`/panel/access/activities/${item.id}`}
              >
                Activities
              </PrimaryButton>
            )
          }}
        />
      </PanelBoxLayout>
      {/* {data.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.page_size * data.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={data.totalPages}
          pageSize={meta.page_size}
          handleChangeTake={handleChangePageSize}
        />
      ) : (
        <></>
      )} */}
    </S.Content>
  );
}
