/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import * as S from './styles';
import { useActions } from 'common/hooks/useActions';
// import { ValidationLogin } from "common/validations";
import Gap from 'components/gap';
import PrimaryButton from 'components/form/buttons/primaryButton';
import MultiDropdown from 'components/form/MultiDropdown';
import * as T from 'common/types';
import DateInput from 'components/form/dateInput';
import { useEffect } from 'react';

interface IProps {
  cafeteriaList: T.Pages.CafeteriaListItem[];
}

export default function CafeteriaStatisticsSidebarFilterForm({ cafeteriaList }: IProps) {
  const { cafeteriaChartRequestAction, cafeteriaMenuManagementRequestAction } = useActions();

  // const [data, setData] = useState(second)

  useEffect(() => {
    cafeteriaMenuManagementRequestAction({ getAll: true, page: 1, take: 100 });
  }, []);

  return (
    <S.Content>
      <Formik
        initialValues={{
          items: [] as T.Form.ListDropdownItem[],
          date: ''
        }}
        // validationSchema={ValidationLogin}
        onSubmit={(values, { setSubmitting }) => {
          cafeteriaChartRequestAction({
            items: values.items.map((item) => item.title),
            date: values.date
          });
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          // handleChange,
          handleSubmit,
          // isSubmitting,
          setValues
        }) => (
          <>
            <MultiDropdown
              label="Items"
              list={cafeteriaList}
              onChange={(list: T.Form.ListDropdownItem[]) => {
                setValues({
                  ...values,
                  items: list
                });
              }}
              placeholder="Select or search Items"
              // hasSearch
              // defaultValue={values.items}
              message={touched.items && (errors.items as string)}
              error={errors.items as unknown as boolean}
              inputStyle={{
                height: 40,
                borderRadius: 7,
                fontSize: 12,
                padding: '0 15px'
              }}
              labelStyle={{ fontSize: 12 }}
            />

            <Gap size="10px" />

            <DateInput
              label="Date"
              onChange={(date) => setValues({ ...values, date })}
              inputStyle={{
                height: 40,
                borderRadius: 7,
                fontSize: 12,
                padding: '0 15px'
              }}
              labelStyle={{ fontSize: 12 }}
            />
            <Gap size="10px" />

            <PrimaryButton
              style={{ width: '100%', height: 42, borderRadius: 8 }}
              onClick={handleSubmit}
              // disabled={isSubmitting}
            >
              Filter
            </PrimaryButton>
          </>
        )}
      </Formik>
    </S.Content>
  );
}
