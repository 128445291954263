import reducer from './redux/reducers';
import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';

const composeEnhancers =
  // -- //
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer /* preloadedState, */, composeEnhancers(applyMiddleware(thunk)));

export default store;
