export enum NEWS {
  REQUEST = 'NEWS_REQUEST',
  SUCCESS = 'NEWS_SUCCESS',
  FAILURE = 'NEWS_FAILURE'
}

export enum NEWS_CREATE {
  REQUEST = 'NEWS_CREATE_REQUEST',
  SUCCESS = 'NEWS_CREATE_SUCCESS',
  FAILURE = 'NEWS_CREATE_FAILURE'
}

export enum NEWS_UPDATE {
  REQUEST = 'NEWS_UPDATE_REQUEST',
  SUCCESS = 'NEWS_UPDATE_SUCCESS',
  FAILURE = 'NEWS_UPDATE_FAILURE'
}

export enum NEWS_UPLOAD {
  REQUEST = 'NEWS_UPLOAD_REQUEST',
  SUCCESS = 'NEWS_UPLOAD_SUCCESS',
  FAILURE = 'NEWS_UPLOAD_FAILURE'
}

export enum NEWS_UPDATE_UPLOAD {
  REQUEST = 'NEWS_UPDATE_UPLOAD_REQUEST',
  SUCCESS = 'NEWS_UPDATE_UPLOAD_SUCCESS',
  FAILURE = 'NEWS_UPDATE_UPLOAD_FAILURE'
}

export enum NEWS_DETAIL {
  REQUEST = 'NEWS_DETAIL_REQUEST',
  SUCCESS = 'NEWS_DETAIL_SUCCESS',
  FAILURE = 'NEWS_DETAIL_FAILURE'
}

export enum NEWS_DELETE {
  REQUEST = 'NEWS_DELETE_REQUEST',
  SUCCESS = 'NEWS_DELETE_SUCCESS',
  FAILURE = 'NEWS_DELETE_FAILURE'
}
