import ForgetasswordForm from './lib/form';
import Gap from 'components/gap';

import * as S from './styles';
import { Link } from 'react-router-dom';

export default function ForgetasswordChangePage() {
  return (
    <S.AuthContainer>
      <S.FormLogin>
        <S.FormTitle>Change your password</S.FormTitle>
        <Gap size="35px" />
        <ForgetasswordForm />
        <Gap size="10px" />
        <S.SubTitle style={{ fontSize: 13 }}>
          Didn't receive a confirmation email? <Link to="/auth/login">Request a new one</Link>
        </S.SubTitle>
        <S.SubTitle style={{ fontSize: 13 }}>
          Already have login and password? <Link to="/auth/login">Sign in</Link>
        </S.SubTitle>
      </S.FormLogin>
    </S.AuthContainer>
  );
}
