import styled from 'styled-components';

// import { device } from "common/constants/mediaQueries";

export const container = styled.div`
  /* height: 100%; */
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  user-select: none;

  &.reverse {
    flex-direction: row-reverse;
    width: 100%;
  }

  &.bottomLine {
    border-bottom: 1px solid var(--gray);
  }
`;

export const box = styled.div``;

export const Div = styled.p`
  color: #717171;
  font-size: 13px;
  font-weight: 400;
  color: var(--primary);

  /* &.active {
    color: var(--primary-green);
  } */
`;

export const icon = styled.div`
  width: 24px;
  height: 24px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: var(--dark-light);

  &.two {
    width: 20px;
    height: 20px;
  }
`;
export const Input = styled.input`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.5em;
  -webkit-appearance: none;
  cursor: pointer;
  height: 24px;
  width: 24px;
  /* &:focus {
    outline-color: transparent;
  } */

  &.two {
    width: 20px;
    height: 20px;
    border: 1.5px solid #717171;
  }

  &.one.checked {
    background: url('/icons/check-black.svg');
    background-color: var(--primary-green);
    background-position: center;
    background-repeat: no-repeat;
  }

  &.two.checked {
    background: url('/icons/check-white.svg');
    background-color: var(--primary-green);
    /* opacity: 0.4; */
    background-position: center;
    background-repeat: no-repeat;
    border: none;
  }
`;
