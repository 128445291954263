// import TicketMenuManagementCreate from "./lib/create";
import { useNavigate } from 'react-router';
import EventsTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';
import SecundButton from 'components/form/buttons/secundButton';

import * as S from './styles';

export default function EventsPage() {
  const navigate = useNavigate();
  const { events } = useTypedSelector((state: any) => state.Events);
  const { departments } = useTypedSelector((state) => state.Departments);

  const handleRedirect = () => {
    navigate('/panel/events/create');
  };

  return (
    <S.Container>
      <S.Head>
        <S.Title>Events</S.Title>
        <SecundButton hasIcon={true} onClick={handleRedirect}>
          Create new Event
        </SecundButton>
      </S.Head>
      <Gap size="20px" />
      <EventsTable data={events.data} departments={departments.data} loading={events.loading} />

      <AppLoading loading={departments.loading} />
    </S.Container>
  );
}
