import styled from 'styled-components';

export const Cards = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Card = styled.div`
  width: 100%;
  height: 90px;
  border-radius: 8px;
  background: #000;

  position: relative;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    opacity: 0.4;
  }
`;
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  height: 100%;
`;

export const Text = styled.h4`
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 12px */
  margin: 0;
`;
export const Value = styled.span`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
`;
