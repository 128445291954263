export enum LOG_IN {
  REQUEST = 'LOG_IN_REQUEST',
  SUCCESS = 'LOG_IN_SUCCESS',
  FAILURE = 'LOG_IN_FAILURE'
}

export enum FORGET_PASSWORD {
  REQUEST = 'FORGET_PASSWORD_REQUEST',
  SUCCESS = 'FORGET_PASSWORD_SUCCESS',
  FAILURE = 'FORGET_PASSWORD_FAILURE'
}

export enum REFRESH_TOKEN {
  REQUEST = 'REFRESH_TOKEN_REQUEST',
  SUCCESS = 'REFRESH_TOKEN_SUCCESS',
  FAILURE = 'REFRESH_TOKEN_FAILURE'
}

export enum GET_FIREBASE_TOKEN {
  REQUEST = 'GET_FIREBASE_TOKEN_REQUEST',
  SUCCESS = 'GET_FIREBASE_TOKEN_SUCCESS',
  FAILURE = 'GET_FIREBASE_TOKEN_FAILURE'
}

export enum ADD_OR_UPDATE_FIREBASE_TOKEN {
  REQUEST = 'ADD_OR_UPDATE_FIREBASE_TOKEN_REQUEST',
  SUCCESS = 'ADD_OR_UPDATE_FIREBASE_TOKEN_SUCCESS',
  FAILURE = 'ADD_OR_UPDATE_FIREBASE_TOKEN_FAILURE'
}

export enum LOG_OUT {
  REQUEST = 'LOG_OUT_REQUEST',
  SUCCESS = 'LOG_OUT_SUCCESS',
  FAILURE = 'LOG_OUT_FAILURE'
}
