import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'common/helpers/toastConfig';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';

import * as types from 'common/constants';
// import * as T from "common/types";
import * as services from 'common/services';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Supporter list actions
 */
export const adminSupporterRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.ADMIN_SUPPORTER.REQUEST,
        payload: {}
      });

      // -- //
      const response: any = await services.adminSupporterService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(adminSupporterSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(adminSupporterFailureAction(response.data));
      }
      // -- //
    } catch (error: any) {
      console.log(error);
      dispatch(adminSupporterFailureAction(error));
      throw error;
    }
  };
};

export const adminSupporterSuccessAction = (data: object) => {
  return {
    type: types.ADMIN_SUPPORTER.SUCCESS,
    payload: data
  };
};

export const adminSupporterFailureAction = (error: object) => {
  return {
    type: types.ADMIN_SUPPORTER.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
