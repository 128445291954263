export enum TEABOYS {
  REQUEST = 'TEABOYS_REQUEST',
  SUCCESS = 'TEABOYS_SUCCESS',
  FAILURE = 'TEABOYS_FAILURE'
}

export enum TEABOY_ASSIGN_TO_FLOOR {
  REQUEST = 'TEABOY_ASSIGN_TO_FLOOR_REQUEST',
  SUCCESS = 'TEABOY_ASSIGN_TO_FLOOR_SUCCESS',
  FAILURE = 'TEABOY_ASSIGN_TO_FLOOR_FAILURE'
}

export enum TEABOY_EDIT {
  REQUEST = 'TEABOY_EDIT_REQUEST',
  SUCCESS = 'TEABOY_EDIT_SUCCESS',
  FAILURE = 'TEABOY_EDIT_FAILURE'
}
