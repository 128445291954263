import PanelBoxLayout from 'components/layout/panelBox';
import * as S from './styles';
import Gap from 'components/gap';
import TicketDetailTypeEventInfo from './lib/event';
import TicketDetailTypeNormalInfo from './lib/normal';
import TicketDetailTypeStationaryInfo from './lib/stationary';

interface IProps {
  data: any;
}

export default function TicketDetailTypeInfo({ data }: IProps) {
  return (
    <S.Content>
      <PanelBoxLayout style={{ padding: '20px 30px' }}>
        <S.Head>
          {data?.type === 'event'
            ? 'Event Request Details'
            : data?.type === 'normal'
              ? 'Request Details'
              : data?.type === 'stationary'
                ? 'Stationary Request Details'
                : '-'}
        </S.Head>
        <Gap size="10px" />
        {data?.type === 'event' ? (
          <TicketDetailTypeEventInfo data={data?.eventDetails} />
        ) : data?.type === 'normal' ? (
          <TicketDetailTypeNormalInfo data={data?.normalDetails} />
        ) : data?.type === 'stationary' ? (
          <TicketDetailTypeStationaryInfo data={data?.stationaryDetails} />
        ) : (
          '-'
        )}

        <Gap size="20px" />
      </PanelBoxLayout>
    </S.Content>
  );
}
