// -- == //
import React, { useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

import * as S from './styles';
import * as T from 'common/types';

interface Iprops {
  label?: string;
  disabled?: boolean | undefined;
  onChange?: (list: T.Form.ListDropdownItem[], item?: T.Form.ListDropdownItem) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  style?: object;
  inputStyle?: object;
  id?: string;
  error?: boolean;
  success?: boolean;
  message?: string;
  list: any[];
  iconColor?: string;
  defaultValues?: any[];
  className?: string;
  ref?: any;
  placeholder?: string;
  labelStyle?: object;
  mini?: boolean;
  oneItem?: boolean;
  showResults?: boolean;
  onChangeValue: (val?: string) => void;
  loading: boolean;
}

const NotificationUserMultiDropdown: React.FC<Iprops> = ({
  label,
  id,
  disabled,
  style,
  error,
  success,
  message,
  list,
  onChange,
  defaultValues = [],
  inputStyle,
  onFocus,
  onBlur,
  className,
  ref,
  placeholder,
  labelStyle,
  mini,
  onChangeValue,
  loading
}) => {
  // const [values, setValues] = useState<any[]>([...defaultValues]);

  const [show, setShow] = useState<boolean>(false);
  const wrapperRef: React.MutableRefObject<any> = useRef(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
      }
      if (onBlur) {
        onBlur();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onBlur]);

  const renderActiveItem = (selectDay: any) => {
    const checkDay = (day: any) => {
      if (day.id === selectDay.id) return true;
      else return false;
    };

    if (defaultValues.find(checkDay)) return true;
    else return false;
  };

  // useEffect(() =>{
  //   if(hasbeenReset) {
  //     reset(setValue);
  //   }
  //   console.log('hey')
  // }, [hasbeenReset]);

  const handleChange = (item: any) => {
    if (renderActiveItem(item)) {
      // setValues((newValues) =>
      //   newValues.filter((e: T.Form.ListDropdownItem) => e.id !== item.id)
      // );
      if (onChange) onChange(defaultValues.filter((e: T.Form.ListDropdownItem) => e.id !== item.id));

      return;
    }
    if (onBlur) {
      onBlur();
    }
    // setValues([...values, item]);
    if (onChange) onChange([...defaultValues, item], item);
  };

  const handleShow = () => {
    if (!show) setShow(true);
    else setShow(false);
    if (onFocus) {
      onFocus();
    }
  };
  return (
    <S.inputContainer
      style={style}
      className={error ? `error ${className}` : success ? `success ${className}` : `${className}`}
      ref={wrapperRef}
    >
      {label && (
        <S.label className="input-label" htmlFor={id} style={labelStyle} onClick={handleShow}>
          {label}
        </S.label>
      )}
      <S.InputBox>
        <S.input
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          onClick={handleShow}
          // value={
          //   values.length ? values.map((item) => item.displayName + ", ") : ""
          // }
          // readOnly
          onChange={(e) => onChangeValue(e.target.value)}
          style={inputStyle}
          className={`${mini ? 'mini' : ''} ${show ? 'dropdown-show' : ''}`}
          ref={ref}
        />
        <S.icon onClick={handleShow} className={mini ? 'mini' : ''}>
          <ReactSVG
            src={'/icons/stroke/arrow-bottom.svg'}
            beforeInjection={(svg) => {
              svg.setAttribute('stroke', `#A4A6B3`);
            }}
          />
        </S.icon>
        {/* {values?.icon ? (
        <S.iconSelect>
          <S.arrowImg src={values?.icon} />
        </S.iconSelect>
      ) : (
        <></>
      )} */}
        <S.dropDownItems className={`drop-down__items ${show ? 'show' : ''}`}>
          {!loading ? (
            list?.length ? (
              list.map((item: any, index: number) => {
                return (
                  <S.dropDownItem key={index}>
                    <S.CheckText>{item.displayName}</S.CheckText>
                    <S.AddButton
                      onClick={() => handleChange(item)}
                      className={renderActiveItem(item) ? 'active' : ''}
                      disabled={renderActiveItem(item)}
                    >
                      <ReactSVG
                        src="/icons/stroke/plus.svg"
                        beforeInjection={(svg) => {
                          svg.setAttribute('fill', `var(--white)`);
                        }}
                      />
                    </S.AddButton>
                  </S.dropDownItem>
                );
              })
            ) : (
              <S.Loader>User not found</S.Loader>
            )
          ) : (
            <S.Loader>
              <ReactSVG
                src="/loader.svg"
                beforeInjection={(svg) => {
                  svg.setAttribute('width', `60px`);
                  svg.setAttribute('height', `60px`);
                }}
              />
            </S.Loader>
          )}
        </S.dropDownItems>
      </S.InputBox>

      {message ? (
        <S.message className={error ? 'error-message' : success ? 'success-message' : ''}>{message}</S.message>
      ) : (
        <></>
      )}
    </S.inputContainer>
  );
};

export default NotificationUserMultiDropdown;
