import PanelBoxLayout from 'components/layout/panelBox';
// import PaginationApp from "components/pagination";

import * as T from 'common/types';
import * as S from './styles';
import DangerButton from 'components/form/buttons/danger';
import Gap from 'components/gap';
// import RateStar from "components/rateStar";
import { useActions } from 'common/hooks/useActions';
import QuestionModal from 'components/modal/question';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import PrimaryButton from 'components/form/buttons/primaryButton';
import ModalApp from 'components/modalApp';
import ChangeDateTimeForm from '../changeTimeForm';

interface IProps {
  data: T.Pages.ReservationDetail;
  fetchData: () => void;
}

export default function ReservationDetailInfo({ data, fetchData }: IProps) {
  const { reservationCancelRequestAction } = useActions();
  const [showCloseModal, setShowCloseModal] = useState<boolean>(false);
  const [showTimeModal, setShowTimeModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleShowCloseTicket = () => {
    setShowCloseModal(true);
  };

  const handleHideCancel = () => {
    setShowCloseModal(false);
  };

  const handleShowTimeModal = () => {
    setShowTimeModal(true);
  };

  const handleCloseTimeModal = () => {
    setShowTimeModal(false);
  };

  const handleCancelReservation = () => {
    reservationCancelRequestAction(data.id, () => {
      handleHideCancel();
      navigate('/panel/reservation');
    });
  };

  return (
    <S.Content>
      <PanelBoxLayout style={{ padding: '20px 30px' }}>
        <S.Head>Reservation Details</S.Head>
        <Gap size="10px" />
        <S.Div className="row">
          <S.Div className="col-12">
            <S.Item>
              <S.Title>Room</S.Title>
              <S.Text>{data?.room?.name}</S.Text>
            </S.Item>
          </S.Div>
          <S.Div className="col-md-6">
            <S.Item>
              <S.Title>Start Date</S.Title>
              <S.Text>{data?.startDate ? new Date(data?.startDate).toLocaleString() : ''}</S.Text>
            </S.Item>
          </S.Div>
          <S.Div className="col-md-6">
            <S.Item>
              <S.Title>End Date</S.Title>
              <S.Text>{data?.endDate ? new Date(data?.endDate).toLocaleString() : ''}</S.Text>
            </S.Item>
          </S.Div>
        </S.Div>
        <Gap size="20px" />
        <S.Head>People</S.Head>
        <Gap size="30px" />
        <S.Div className="row">
          {data?.people?.map((item) => (
            <S.Div className="col-lg-4">
              <S.UserCard>
                <S.Grid>
                  <S.Icon src={item?.profilePicture || '/images/default-avatar.jpg'} />
                  <S.Div className="full">
                    <S.MainTitle>
                      {item.name}
                      <S.Span>{item.jobTitle}</S.Span>
                    </S.MainTitle>
                    <S.Text style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                      {item.mail}
                      <span>{item.label}</span>
                    </S.Text>
                  </S.Div>
                </S.Grid>
              </S.UserCard>
            </S.Div>
          ))}
        </S.Div>
        <Gap size="20px" />

        <S.GridButton>
          <PrimaryButton
            style={{
              background: '#DFE0EB66',
              color: '#0A2142',
              fontSize: 14,
              fontWeight: 400,
              borderRadius: 8,
              height: 36
              // width: 208,
            }}
            onClick={handleShowTimeModal}
            disabled={data?.status === 'canceled' ? true : false}
          >
            Change Date and Time
          </PrimaryButton>
          <DangerButton
            style={{
              background: '#FDF0EF',
              // color: "var(--primary-green)",
              fontSize: 14,
              fontWeight: 400,
              borderRadius: 8,
              height: 36,
              width: 208
            }}
            onClick={handleShowCloseTicket}
            disabled={data?.status === 'canceled' ? true : false}
          >
            Cancel Reservation
          </DangerButton>
        </S.GridButton>
      </PanelBoxLayout>

      <QuestionModal
        show={showCloseModal}
        handleClose={handleHideCancel}
        model="delete"
        title="Cancel Reservation"
        text="Do you want to Cancel this Reservation?"
        handleSuccess={handleCancelReservation}
        successButtonText="Yes"
      />

      <ModalApp show={showTimeModal} handleClose={handleCloseTimeModal} fixed style={{ width: 520 }}>
        <ChangeDateTimeForm data={data} handleClose={handleCloseTimeModal} fetchData={() => fetchData()} />
      </ModalApp>
    </S.Content>
  );
}
