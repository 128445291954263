import React, { ReactNode } from 'react';

import * as S from './styles';

interface IProps {
  text?: ReactNode;
  onChange?: (data: boolean) => void;
  style?: object;
  reverse?: boolean;
  bottomLine?: boolean;
  checked?: boolean;
  disabled?: boolean;
  theme?: 'one' | 'two';
  name?: string;
  inputStyle?: object;
}

const CheckBox: React.FC<IProps> = ({
  text,
  onChange,
  style,
  reverse,
  bottomLine,
  checked = false,
  // disabled,
  theme = 'two',
  name,
  inputStyle
}) => {
  // const [value, setValue] = useState(checked);
  const renderCheckBox = () => {
    return (
      <S.Input
        className={`${checked ? 'checked' : 'not_checked'} ${theme}`}
        id="input"
        type="checkbox"
        name={name}
        onChange={() => handleChange(!checked)}
        checked={checked}
        style={inputStyle}
      />
    );
  };

  const handleChange = (data: boolean) => {
    // setValue(data);
    if (onChange) onChange(data);
  };

  return (
    <S.container
      style={style}
      onClick={() => handleChange(!checked)}
      className={`${bottomLine ? 'bottomLine' : ''} ${reverse ? 'reverse' : ''} ${theme}`}
    >
      <S.box>
        <S.icon className={theme}>{renderCheckBox()}</S.icon>
      </S.box>
      <S.Div className={theme === 'two' && checked ? 'active' : ''}>{text}</S.Div>
    </S.container>
  );
};

export default CheckBox;
