import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  :root {
    --primary: #3A4A65;
    --primary-dark: #0A2142;
    --primary-dark-two: #2B3674;
    --primary-green: #1DBAAF;

    --white: #ffffff;
    --black: #000;
    --light: #ced8e0;
    --gray: #A4A6B3;
    --gray1: #f6f8f9;
    --gray2: #f1f2f4;
    --gray3: #e1e7ec;
    --gray4: #e1e7ec40;
    --back-color: #eff2f7;
    --primary-two: #204d74;
    --danger: #FF6464;

    /* largecreen */
    --rsbs-ml: auto;
    --rsbs-mr: auto;
  }

  body {
    font-family: Poppins;
    font-display: swap;
    padding: 0;
  }

  *,
  p,
  ul {
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  .tooltip-custom {
    margin-top: 10px;
    right: -50px !important;
  }

  .tooltip-custom .MuiTooltip-tooltip {
    max-width: max-content;
  }

  .tooltip-custom .MuiTooltip-arrow {
    width: 4em !important;
    height: 1.5em !important;
    margin-top: -1.5em !important;
    left: -25px !important;
  }

  .paper {
    background: #cee2fc !important;
  }
  reach-portal {
    z-index: 1301;
    position: fixed;
  }

  .text-left {
    text-align: left;
  }

  .text-right {
    text-align: right;
  }

  .text-center {
    text-align: center;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.disabled {
    cursor: not-allowed;
  }

  .text-wrap {
    word-wrap: break-word;
    display: inline-block;
    width: 100%;
  }
`;
