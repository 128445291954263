/* eslint-disable react-hooks/exhaustive-deps */
// -- == //
import { useParams } from 'react-router';
import { useEffect } from 'react';
import SurveyCreateQuestions from '../lib/questions';
import Gap from 'components/gap';
// import { useTypedSelector } from "common/hooks/useTypedSelector";
// import AppLoading from "components/appLoading";
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import SurveyCreateFormInfo from '../lib/info';
import { useActions } from 'common/hooks/useActions';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function SurveyDetailPage() {
  const { surveyDetail, questions } = useTypedSelector((state) => state.Survey);
  const { surveyDetailRequestAction, surveyQuestionListAction } = useActions();

  const params: any = useParams();

  useEffect(() => {
    if (params?.id) surveyDetailRequestAction(params?.id);
  }, []);

  useEffect(() => {
    if (surveyDetail?.data?.questions?.length) {
      surveyQuestionListAction(surveyDetail?.data?.questions);
    }
  }, [surveyDetail?.data?.questions?.length]);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Survey</S.Title>
      </S.Head>
      <Gap size="20px" />
      <S.Div className="row">
        <S.Div className="col-md-6">
          <SurveyCreateFormInfo data={surveyDetail.data} questions={questions} />
        </S.Div>
        <S.Div className="col-md-6">
          <SurveyCreateQuestions data={surveyDetail.data} questions={questions} />
        </S.Div>
      </S.Div>

      <AppLoading loading={surveyDetail.loading} />
    </S.Container>
  );
}
