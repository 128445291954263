import { ApiCaller } from 'common/helpers/apiCaller';
import convertToQueryString from 'common/helpers/convertToQueryString';

// ------ Menu Management ------ //
export const cafeteriaMenuManagementService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/cafeteria?${convertToQueryString(data)}`
  });
};

// ------ Create Menu Management ------ //
export const cafeteriaCreateMenuManagementService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/cafeteria`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// ------ Create Menu Management ------ //
export const cafeteriaEditMenuManagementService = (data: object) => {
  return ApiCaller({
    method: 'PUT',
    url: `${process.env.REACT_APP_API_URL}api/admin/cafeteria`,
    data
  });
};

// ------ Chart ------ //
export const cafeteriaChartService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/cafeteria/chart?${convertToQueryString(data)}`
  });
};

// ------ Status ------ //
export const cafeteriaStatusService = (data: object) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/cafeteria-status`,
    data
  });
};

// ------ Update Image ------ //
export const cafeteriaUploadService = (data: object) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/cafeteria/upload`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
