import styled from 'styled-components';

export const SideContainer = styled.aside`
  width: 288px;
  min-width: 288px;
  background-color: var(--primary-dark);
  height: 100%;
  padding: 10px 0;
  overflow-y: auto;

  .ant-menu-item,
  .ant-menu-submenu-title {
    height: 56px !important;
  }

  .ant-menu-item,
  .ant-menu-item-active,
  .ant-menu-title-content,
  .ant-menu-submenu-title a {
    border-radius: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.20000000298023224px;
    color: var(--gray);

    /* &:hover {
      color: var(--gray) !important;
    } */
  }

  /* .ant-menu-submenu-selected .ant-menu-title-content, */
  .ant-menu-item-selected {
    color: var(--white);

    a {
      color: var(--white);
    }
  }

  .ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title,
  .ant-menu-item.ant-menu-item-selected,
  .ant-menu-submenu.ant-menu-submenu-selected .ant-menu-submenu-title a {
    background-color: #1dbaaf;
    color: var(--white);
    border-radius: 0;

    &:hover {
      color: var(--white) !important;
    }

    svg {
      fill: var(--white);

      &.stroke {
        fill: none;
        stroke: var(--white);
      }
    }

    .ant-menu-title-content {
      color: var(--white);
    }
  }

  .ant-menu-item-selected.ant-menu-item-only-child {
    background: none;
    font-weight: 600;
  }

  .ant-menu-item-only-child {
    height: 35px !important;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  padding: 30px;
  gap: 10px;
`;

export const Image = styled.img``;
export const LogoTitle = styled.h1`
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  color: var(--white);
`;
