export const enum menuCategory {
  food = 'Food',
  cold_drink = 'Cold Drink',
  hot_drink = 'Hot Drink'
}

export const menuCategoryArray = [
  { title: 'Food', id: 'food' },
  { title: 'Cold Drink', id: 'cold_drink' },
  { title: 'Hot Drink', id: 'hot_drink' }
];

export enum SurveyStatusEnum {
  WAITING = 'waiting',
  IN_PROGRESS = 'in-progress',
  CANCELLED = 'cancelled',
  EXPIRED = 'expired',
  DONE = 'done'
}

export const SurveyStatusEnumArray = [
  { title: 'Waiting', id: 'waiting' },
  { title: 'In Progress', id: 'in-progress' },
  { title: 'Cancelled', id: 'cancelled' },
  { title: 'Expired', id: 'expired' },
  { title: 'Done', id: 'done' }
];

export enum SurveyQuestionTypeEnum {
  descriptive = 'Descriptive',
  optional = 'optional',
  rate = 'rate'
}

export const SurveyQuestionTypeEnumArray = [
  { title: 'Open end', id: 'descriptive' },
  { title: 'Multiple', id: 'optional' },
  { title: 'Rate', id: 'rate' }
];

export enum CategoryTypeEnum {
  descriptive = 'Descriptive',
  optional = 'optional',
  rate = 'rate'
}

export const CategoryTypeEnumArray = [
  { title: 'normal', id: 'normal' },
  { title: 'stationary', id: 'stationary' },
  { title: 'event', id: 'event' }
];

export enum EventTypeEnum {
  Seminar = 'Seminar',
  Webinar = 'Webinar',
  Entertainment = 'Entertainment',
  Workshop = 'Workshop',
  Conferences = 'Conferences'
}

export const EventTypeEnumArray = [
  { title: 'Seminar', id: 'Seminar' },
  { title: 'Webinar', id: 'Webinar' },
  { title: 'Entertainment', id: 'Entertainment' },
  { title: 'Workshop', id: 'Workshop' },
  { title: 'Conferences', id: 'Conferences' }
];

export enum EventUserStageStatus {
  WatingForAdmin = 'Pending',
  Reject = 'Rejected',
  Accept = 'Attending',
  Cancel = 'Cancelled'
}
