export enum SURVEYS {
  REQUEST = 'SURVEYS_REQUEST',
  SUCCESS = 'SURVEYS_SUCCESS',
  FAILURE = 'SURVEYS_FAILURE'
}

export enum SURVEY_DETAIL {
  REQUEST = 'SURVEY_DETAIL_REQUEST',
  SUCCESS = 'SURVEY_DETAIL_SUCCESS',
  FAILURE = 'SURVEY_DETAIL_FAILURE'
}

export enum SURVEY_CREATE {
  REQUEST = 'SURVEY_CREATE_REQUEST',
  SUCCESS = 'SURVEY_CREATE_SUCCESS',
  FAILURE = 'SURVEY_CREATE_FAILURE'
}

export enum SURVEY_QUESTION {
  LIST = 'SURVEY_QUESTION_LIST',
  ADD = 'SURVEY_QUESTION_ADD',
  DELETE = 'SURVEY_QUESTION_DELETE',
  EDIT = 'SURVEY_QUESTION_EDIT'
}

export enum SURVEY_QUESTION_CHOICES {
  ADD = 'SURVEY_QUESTION_CHOICES_ADD',
  DELETE = 'SURVEY_QUESTION_CHOICES_DELETE',
  EDIT = 'SURVEY_QUESTION_CHOICES_EDIT'
}

export enum SURVEYS_RESULT {
  REQUEST = 'SURVEYS_RESULT_REQUEST',
  SUCCESS = 'SURVEYS_RESULT_SUCCESS',
  FAILURE = 'SURVEYS_RESULT_FAILURE'
}
