export enum CAFETERIA_MENU {
  REQUEST = 'CAFETERIA_MENU_REQUEST',
  SUCCESS = 'CAFETERIA_MENU_SUCCESS',
  FAILURE = 'CAFETERIA_MENU_FAILURE'
}

export enum CAFETERIA_CREATE_MENU {
  REQUEST = 'CAFETERIA_CREATE_MENU_REQUEST',
  SUCCESS = 'CAFETERIA_CREATE_MENU_SUCCESS',
  FAILURE = 'CAFETERIA_CREATE_MENU_FAILURE'
}

export enum CAFETERIA_EDIT_MENU {
  REQUEST = 'CAFETERIA_EDIT_MENU_REQUEST',
  SUCCESS = 'CAFETERIA_EDIT_MENU_SUCCESS',
  FAILURE = 'CAFETERIA_EDIT_MENU_FAILURE'
}

export enum CAFETERIA_UPLOAD {
  REQUEST = 'CAFETERIA_UPLOAD_REQUEST',
  SUCCESS = 'CAFETERIA_UPLOAD_SUCCESS',
  FAILURE = 'CAFETERIA_UPLOAD_FAILURE'
}

export enum CAFETERIA_CHART {
  REQUEST = 'CAFETERIA_CHART_REQUEST',
  SUCCESS = 'CAFETERIA_CHART_SUCCESS',
  FAILURE = 'CAFETERIA_CHART_FAILURE'
}

export enum CAFETERIA_STATUS {
  REQUEST = 'CAFETERIA_STATUS_REQUEST',
  SUCCESS = 'CAFETERIA_STATUS_SUCCESS',
  FAILURE = 'CAFETERIA_STATUS_FAILURE'
}
