import styled from 'styled-components';

export const Container = styled.div`
  .g2-tooltip {
    padding: 0 !important;
    border-radius: 8px !important;
  }
`;

export const Cards = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Card = styled.div`
  width: 100%;
  height: 90px;
  border-radius: 8px;
  background: #000;

  position: relative;

  svg {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    opacity: 0.4;
  }
`;
export const Div = styled.div``;

export const Span = styled.span`
  color: var(--primary-green);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
`;

export const Text = styled.h4`
  color: #2b3674;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 12px */
  margin: 0;
`;
export const Value = styled.span`
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
`;

export const Info = styled.div`
  border-radius: 8px;
  background: #f8f8f8;
  display: flex;
  gap: 8px;
  align-items: center;
  height: 32px;
  padding: 0 8px;
`;

export const Tooltip = styled.div`
  min-width: 223px;
  border-radius: 8px;
  padding: 8px;
  background: transparent;
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);

  * {
    background: transparent;
  }
`;

export const TooltipGrid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 12px;
`;
