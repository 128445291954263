import styled from 'styled-components';

export const TableCon = styled.div`
  overflow-x: auto;
  /* max-width: 100%; */
  width: 100%;
  position: relative;
  min-height: 200px;
`;

export const Table = styled.table`
  width: 100%;
  min-width: max-content;
  transition: all.3s;
`;

export const Thead = styled.thead`
  padding-bottom: 5px;
  position: relative;

  &.one {
    border-bottom: 1px solid #f0f0f0;
  }

  &.two {
    background: #fafafa;
    border-radius: 8px 8px 0px 0px;

    th {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 100%;
      color: var(--primary-two);
    }
  }

  /* &::before {
    content: "";
    width: 100%;
    height: 1px;
    background: #e2e8f0;
    position: absolute;
    bottom: 0;
  }

  &:last-child {
    border-bottom: none;

    &::before {
      opacity: 0;
    }
  } */
`;

export const Tbody = styled.tbody``;

export const Td = styled.td`
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #2d3748;
  padding: 14px 20px;
`;

export const Tr = styled.tr`
  border-bottom: 1px solid #f0f0f0;
  position: relative;
  overflow: hidden;
  transition: all.3s;

  &.hide {
    visibility: collapse;

    /* td {
      height: 0;
      padding: 0 20px;
      overflow: hidden;

    } */
  }

  &.show {
    visibility: visible;

    /* .child-arrow-icon {
      background: #1DBAAF;
      
      svg {
        stroke: #1DBAAF !important;
      }
    } */
  }

  /* &::before {
    content: "";
    width: 100%;
    height: 1px;
    background: #e2e8f0;
    position: absolute;
    bottom: 0;
  } */

  &:last-child {
    border-bottom: none;

    /* &::before {
      opacity: 0;
    } */
  }
  &.active {
    td {
      color: var(--primary);
      font-weight: 700;
    }
  }
`;

export const HeaderTh = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: Poppins;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;

  svg {
    margin-right: 5px;
  }
`;

export const Th = styled.th`
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #4d4d4d;
  padding: 14px 20px;
  /* font-family: DMSans; */
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
`;

export const Status = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 100px;
  height: 24px;
  margin: 0 auto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;

  &.Success {
    background: rgba(51, 183, 80, 0.1);
    color: var(--primary-green);
  }

  &.Rejected {
    background: rgba(183, 51, 51, 0.1);
    color: var(--danger);
  }
`;

export const TableLoading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 150px;
    height: 150px;
  }
`;
export const Div = styled.div``;
