import { ReactSVG } from 'react-svg';
import * as S from './styles';

interface IProps {
  onChange: (e: string) => void;
  style?: object;
  theme?: string;
  value?: string;
  data?: [];
  id?: string;
  placeholder?: string;
  inputStyle?: object;
  disabled?: boolean;
}

const SearchBox: React.FC<IProps> = ({
  onChange,
  style,
  theme,
  value,
  data,
  id,
  placeholder,
  inputStyle,
  disabled
}) => {
  // -- //
  const handleChange = (e: any) => {
    onChange(e.target.value);
  };

  return (
    <S.Container style={style}>
      <S.InputBox style={inputStyle} className={theme}>
        <S.SearchButton>
          <ReactSVG
            src="/icons/stroke/search.svg"
            beforeInjection={(svg) => {
              svg.setAttribute('stroke', `#C5C7CD`);
            }}
          />
        </S.SearchButton>
        <S.Input onChange={handleChange} value={value} id={id} placeholder={placeholder} disabled={disabled} />
        <S.ClearButton onClick={() => onChange('')} disabled={disabled}>
          <ReactSVG
            src="/icons/fill/close.svg"
            beforeInjection={(svg) => {
              svg.setAttribute('fill', `#C5C7CD`);
              svg.setAttribute('width', `12px`);
              svg.setAttribute('height', `12px`);
            }}
          />
        </S.ClearButton>
      </S.InputBox>
      {value && data?.length ? (
        <S.Result>
          <S.ResultItems>
            {// -- //
            data?.map((item: any) => <S.ResultItem>{item.title}</S.ResultItem>)}
          </S.ResultItems>
        </S.Result>
      ) : (
        <></>
      )}
    </S.Container>
  );
};

export default SearchBox;
