// -- == //
import Gap from 'components/gap';
import EventForm from '../lib/eventForm';
import AppLoading from 'components/appLoading';
import { useTypedSelector } from 'common/hooks/useTypedSelector';

import * as S from './styles';

export default function EventCreatePage() {
  const { eventCreate, eventUpload, contributorUploadQuest } = useTypedSelector((state: any) => state.Events);
  const { departments } = useTypedSelector((state) => state.Departments);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Create New Event</S.Title>
      </S.Head>
      <Gap size="20px" />
      <EventForm data={{} as any} departments={departments.data} loading={eventCreate.loading} />

      <AppLoading
        loading={eventCreate.loading || eventUpload.loading || departments.loading || contributorUploadQuest.loading}
      />
    </S.Container>
  );
}
