export enum RESERVATIONS {
  REQUEST = 'RESERVATIONS_REQUEST',
  SUCCESS = 'RESERVATIONS_SUCCESS',
  FAILURE = 'RESERVATIONS_FAILURE'
}

export enum RESERVATION_DETAIL {
  REQUEST = 'RESERVATION_DETAIL_REQUEST',
  SUCCESS = 'RESERVATION_DETAIL_SUCCESS',
  FAILURE = 'RESERVATION_DETAIL_FAILURE'
}

export enum RESERVATION_UPDATE_TIME {
  REQUEST = 'RESERVATION_UPDATE_TIME_REQUEST',
  SUCCESS = 'RESERVATION_UPDATE_TIME_SUCCESS',
  FAILURE = 'RESERVATION_UPDATE_TIME_FAILURE'
}

export enum RESERVATION_CANCEL {
  REQUEST = 'RESERVATION_CANCEL_REQUEST',
  SUCCESS = 'RESERVATION_CANCEL_SUCCESS',
  FAILURE = 'RESERVATION_CANCEL_FAILURE'
}

export enum RESERVATION_CONFIRM {
  REQUEST = 'RESERVATION_CONFIRM_REQUEST',
  SUCCESS = 'RESERVATION_CONFIRM_SUCCESS',
  FAILURE = 'RESERVATION_CONFIRM_FAILURE'
}

export enum RESERVATION_STATISTICS {
  REQUEST = 'RESERVATION_STATISTICS_REQUEST',
  SUCCESS = 'RESERVATION_STATISTICS_SUCCESS',
  FAILURE = 'RESERVATION_STATISTICS_FAILURE'
}
