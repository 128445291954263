// -- == //
/* eslint-disable react-hooks/exhaustive-deps */
import Gap from 'components/gap';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import EventForm from '../lib/eventForm';
import { useActions } from 'common/hooks/useActions';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';
import DangerButton from 'components/form/buttons/danger';
import QuestionModal from 'components/modal/question';

import * as S from './styles';

export default function EventDetailPage() {
  const params = useParams();
  const nav = useNavigate();

  const [ShowEndEventModal, setShowEndEventModal] = useState(false);

  const { eventDetailRequestAction, eventEndRequestAction } = useActions();
  const { departments } = useTypedSelector((state: any) => state.Departments);

  const { eventDetail, eventUpdate, eventUpload, eventCancel, eventChangeVisible, contributorUploadQuest } =
    useTypedSelector((state: any) => state.Events);

  useEffect(() => {
    eventDetailRequestAction(params?.id);
  }, []);

  const handleShowEndModal = () => {
    setShowEndEventModal(true);
  };

  const handleHideEndModal = () => {
    setShowEndEventModal(false);
  };

  const handleEndEvent = () => {
    eventEndRequestAction(eventDetail.data?.id, () => {
      setShowEndEventModal(false);
      nav('/panel/events');
    });
  };

  return (
    <S.Container>
      <S.Head>
        <S.Title>Edit Event</S.Title>

        <DangerButton
          onClick={handleShowEndModal}
          disabled={eventDetail.data?.status === 'End'}
          style={{
            height: 28,
            fontSize: 12,
            width: 120,
            borderRadius: 8,
            background: '#FDF0EF',
            padding: 10
          }}
        >
          {eventDetail.data?.status === 'End' ? 'Finished' : 'Finish Event'}
        </DangerButton>
      </S.Head>
      <Gap size="20px" />
      <EventForm data={eventDetail.data} departments={departments.data} loading={eventUpdate.loading} />

      <AppLoading
        loading={
          eventDetail.loading ||
          eventUpdate.loading ||
          eventUpload.loading ||
          departments.loading ||
          eventCancel.loading ||
          eventChangeVisible.loading ||
          contributorUploadQuest.loading
        }
      />

      <QuestionModal
        handleClose={handleHideEndModal}
        show={ShowEndEventModal}
        model="delete"
        handleSuccess={handleEndEvent}
        title="Are you sure End to this event?"
        successButtonText="Finish Event"
      />
    </S.Container>
  );
}
