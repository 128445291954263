import * as types from 'common/constants';

const initialState = {
  login: {
    data: {},
    loading: false,
    error: null
  },
  forgetPassword: {
    data: {},
    loading: false,
    error: null
  },
  refreshToken: {
    data: {},
    loading: false,
    error: null
  },
  logOut: {
    data: {},
    loading: false,
    error: null
  }
};

interface Reducer {
  type: string;
  // -- //
  payload: any;
}

const Authenticate = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Login cases
     */
    case types.LOG_IN.REQUEST:
      return {
        ...state,
        login: { data: null, loading: true, error: null }
      };
    case types.LOG_IN.SUCCESS:
      return {
        ...state,
        login: {
          data: { ...payload },
          loading: false,
          error: null
        }
      };
    case types.LOG_IN.FAILURE:
      return {
        ...state,
        login: { data: null, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Forget Password cases
     */
    case types.FORGET_PASSWORD.REQUEST:
      return {
        ...state,
        forgetPassword: { data: {}, loading: true, error: null }
      };
    case types.FORGET_PASSWORD.SUCCESS:
      return {
        ...state,
        forgetPassword: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.FORGET_PASSWORD.FAILURE:
      return {
        ...state,
        forgetPassword: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Refresh Token cases
     */
    case types.REFRESH_TOKEN.REQUEST:
      return {
        ...state,
        refreshToken: { data: {}, loading: true, error: null }
      };
    case types.REFRESH_TOKEN.SUCCESS:
      return {
        ...state,
        refreshToken: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.REFRESH_TOKEN.FAILURE:
      return {
        ...state,
        refreshToken: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * LOg Out cases
     */
    case types.LOG_OUT.REQUEST:
      return {
        ...state,
        logOut: { data: {}, loading: true, error: null }
      };
    case types.LOG_OUT.SUCCESS:
      return {
        ...state,
        logOut: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.LOG_OUT.FAILURE:
      return {
        ...state,
        logOut: { data: {}, loading: false, error: payload }
      };

    default:
      return state;
  }
};

export default Authenticate;
