import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'common/helpers/toastConfig';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';

import * as types from 'common/constants';
// import * as T from "common/types";
import * as services from 'common/services';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Department list actions
 */
export const departmentsRequestAction = () => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.DEPARTMENTS.REQUEST,
        payload: {}
      });

      // -- //
      const response: any = await services.departmentsService();

      if (response.status === 200 || response.status === 201) {
        dispatch(departmentsSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(departmentsFailureAction(response.data));
      }
      // -- //
    } catch (error: any) {
      console.log(error);
      dispatch(departmentsFailureAction(error));
      throw error;
    }
  };
};

export const departmentsSuccessAction = (data: object) => {
  return {
    type: types.DEPARTMENTS.SUCCESS,
    payload: data
  };
};

export const departmentsFailureAction = (error: object) => {
  return {
    type: types.DEPARTMENTS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
