import React, { useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import CheckBox from '../checkbox';

import * as S from './styles';
import * as T from 'common/types';

const MultiDropdown: React.FC<T.Form.MultipleDropDown> = ({
  label,
  id,
  disabled,
  style,
  error,
  success,
  message,
  list,
  onChange,
  defaultValues = [],
  inputStyle,
  onFocus,
  onBlur,
  className,
  ref,
  placeholder,
  labelStyle,
  mini,
  showResults = true
}) => {
  const [values, setValues] = useState<T.Form.ListDropdownItem[]>([...defaultValues]);

  const [show, setShow] = useState<boolean>(false);
  // -- //
  const wrapperRef: React.MutableRefObject<any> = useRef(null);

  useEffect(() => {
    // -- //
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
      }
      if (onBlur) {
        onBlur();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onBlur]);

  // -- //
  const renderActiveItem = (selectDay: any) => {
    // -- //
    const checkDay = (day: any) => {
      if (day.id === selectDay.id) return true;
      else return false;
    };

    if (values.find(checkDay)) return true;
    else return false;
  };

  // useEffect(() =>{
  //   if(hasbeenReset) {
  //     reset(setValue);
  //   }
  //   console.log('hey')
  // }, [hasbeenReset]);

  // -- //
  const handleChange = (item: any) => {
    if (renderActiveItem(item)) {
      setValues((newValues) => newValues.filter((e: T.Form.ListDropdownItem) => e.id !== item.id));
      if (onChange) onChange(values.filter((e: T.Form.ListDropdownItem) => e.id !== item.id));

      return;
    }
    if (onBlur) {
      onBlur();
    }
    setValues([...values, item]);
    if (onChange) onChange([...values, item], item);
  };

  const handleShow = () => {
    if (!show) setShow(true);
    else setShow(false);
    if (onFocus) {
      onFocus();
    }
  };
  return (
    <S.inputContainer
      style={style}
      className={error ? `error ${className}` : success ? `success ${className}` : `${className}`}
      ref={wrapperRef}
    >
      {label && (
        <S.label className="input-label" htmlFor={id} style={labelStyle} onClick={handleShow}>
          {label}
        </S.label>
      )}
      <S.InputBox>
        <S.input
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          onClick={handleShow}
          value={values.length ? values.map((item) => item.title + ' ') : ''}
          readOnly
          style={inputStyle}
          className={`${mini ? 'mini' : ''} ${show ? 'dropdown-show' : ''}`}
          ref={ref}
        />
        <S.icon onClick={handleShow} className={mini ? 'mini' : ''}>
          <ReactSVG
            src={'/icons/stroke/arrow-bottom.svg'}
            beforeInjection={(svg) => {
              svg.setAttribute('stroke', `#A4A6B3`);
            }}
          />
        </S.icon>
        {/* {values?.icon ? (
        <S.iconSelect>
          <S.arrowImg src={values?.icon} />
        </S.iconSelect>
      ) : (
        <></>
      )} */}
        <S.dropDownItems className={`drop-down__items ${show ? 'show' : ''}`}>
          {list?.length ? (
            // -- //
            list.map((item: any, index: number) => {
              return (
                <S.dropDownItem
                  key={index}
                  onClick={() => handleChange(item)}
                  className={renderActiveItem(item) ? 'active' : ''}
                >
                  <S.CheckText>{item.title}</S.CheckText>
                  <CheckBox
                    theme="two"
                    checked={renderActiveItem(item) ? true : false}
                    inputStyle={{ borderRadius: 30, border: 'none' }}
                  />
                </S.dropDownItem>
              );
            })
          ) : (
            <></>
          )}
        </S.dropDownItems>
      </S.InputBox>

      {message ? (
        <S.message className={error ? 'error-message' : success ? 'success-message' : ''}>{message}</S.message>
      ) : (
        <></>
      )}
      {showResults ? (
        <S.ActiveItems>
          {
            // -- //
            values.map((item: any) => (
              <S.ActiveItem key={item.id}>
                {item.title}
                {/* <S.text></S.text> */}
                <S.ActiveItemButton onClick={() => handleChange(item)}>
                  <ReactSVG
                    src="/icons/fill/close.svg"
                    beforeInjection={(svg) => {
                      svg.setAttribute('fill', `var(--primary-green)`);
                    }}
                  />
                </S.ActiveItemButton>
              </S.ActiveItem>
            ))
          }
        </S.ActiveItems>
      ) : (
        <></>
      )}
    </S.inputContainer>
  );
};

export default MultiDropdown;
