export enum FLOORS {
  REQUEST = 'FLOORS_REQUEST',
  SUCCESS = 'FLOORS_SUCCESS',
  FAILURE = 'FLOORS_FAILURE'
}

export enum FLOOR_CREATE {
  REQUEST = 'FLOOR_CREATE_REQUEST',
  SUCCESS = 'FLOOR_CREATE_SUCCESS',
  FAILURE = 'FLOOR_CREATE_FAILURE'
}

export enum FLOOR_EDIT {
  REQUEST = 'FLOOR_EDIT_REQUEST',
  SUCCESS = 'FLOOR_EDIT_SUCCESS',
  FAILURE = 'FLOOR_EDIT_FAILURE'
}

export enum FLOOR_DELETE {
  REQUEST = 'FLOOR_DELETE_REQUEST',
  SUCCESS = 'FLOOR_DELETE_SUCCESS',
  FAILURE = 'FLOOR_DELETE_FAILURE'
}
