import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 40px;
  background: #f7f8fc;
  padding-bottom: 30px;
  overflow-y: auto;
`;
