// import TicketMenuManagementCreate from "./lib/create";
import TicketsTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';

import * as S from './styles';
import TicketCounts from './lib/ticketCounts';

export default function TicketsPage() {
  const { tickets, ticketCategory, ticketStatistics } = useTypedSelector((state) => state.Tickets);
  const { departments } = useTypedSelector((state) => state.Departments);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Ticket System</S.Title>
        <TicketCounts data={ticketStatistics.data} />
      </S.Head>
      <Gap size="20px" />
      <TicketsTable
        data={tickets.data}
        loading={tickets.loading}
        departments={departments.data}
        ticketCategory={ticketCategory.data}
      />

      <AppLoading loading={departments.loading || ticketCategory.loading || ticketStatistics.loading} />
    </S.Container>
  );
}
