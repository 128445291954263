import styled from 'styled-components';
import { device } from 'common/constants/mediaQueries';

export const Content = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--dark);
  transition: 0.2s ease all;
  z-index: 9;

  @media ${device.tabletL} {
    font-size: 12px;
    line-height: 20px;
  }
`;
