// -- == //
import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'common/helpers/toastConfig';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';

import * as types from 'common/constants';
// import * as T from "common/types";
import * as services from 'common/services';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Notifications Custom list actions
 */
export const notificationsRequestAction = () => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.NOTIFICATIONS.REQUEST,
        payload: {}
      });

      const response: any = await services.notificationsService();

      if (response.status === 200 || response.status === 201) {
        dispatch(notificationsSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(notificationsFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(notificationsFailureAction(error));
      throw error;
    }
  };
};

export const notificationsSuccessAction = (data: object) => {
  return {
    type: types.NOTIFICATIONS.SUCCESS,
    payload: data
  };
};

export const notificationsFailureAction = (error: object) => {
  return {
    type: types.NOTIFICATIONS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Notifications Custom list actions
 */
export const notificationsCustomRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.NOTIFICATIONS_CUSTOM.REQUEST,
        payload: {}
      });

      const response: any = await services.notificationsCustomService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(notificationsCustomSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(notificationsCustomFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(notificationsCustomFailureAction(error));
      throw error;
    }
  };
};

export const notificationsCustomSuccessAction = (data: object) => {
  return {
    type: types.NOTIFICATIONS_CUSTOM.SUCCESS,
    payload: data
  };
};

export const notificationsCustomFailureAction = (error: object) => {
  return {
    type: types.NOTIFICATIONS_CUSTOM.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Notifications Create Custom actions
 */
export const notficationCreateCustomRequestAction = (data: object, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.NOTIFICATION_CREATE_CUSTOM.REQUEST,
        payload: {}
      });

      const response: any = await services.notficationCreateCustomService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(notficationCreateCustomSuccessAction(response.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(notficationCreateCustomFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(notficationCreateCustomFailureAction(error));
      throw error;
    }
  };
};

export const notficationCreateCustomSuccessAction = (data: object) => {
  return {
    type: types.NOTIFICATION_CREATE_CUSTOM.SUCCESS,
    payload: data
  };
};

export const notficationCreateCustomFailureAction = (error: object) => {
  return {
    type: types.NOTIFICATION_CREATE_CUSTOM.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Notifications Update Custom actions
 */
export const notficationUpdateCustomRequestAction = (data: object, callBack: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.NOTIFICATION_UPDATE_CUSTOM.REQUEST,
        payload: {}
      });

      const response: any = await services.notficationUpdateCustomService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(notficationUpdateCustomSuccessAction(response.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(notficationUpdateCustomFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(notficationUpdateCustomFailureAction(error));
      throw error;
    }
  };
};

export const notficationUpdateCustomSuccessAction = (data: object) => {
  return {
    type: types.NOTIFICATION_UPDATE_CUSTOM.SUCCESS,
    payload: data
  };
};

export const notficationUpdateCustomFailureAction = (error: object) => {
  return {
    type: types.NOTIFICATION_UPDATE_CUSTOM.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Notifications Update Read actions
 */
export const notficationUpdateReadRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.NOTIFICATION_UPDATE_READ.REQUEST,
        payload: {}
      });

      const response: any = await services.notficationUpdateReadService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(notficationUpdateReadSuccessAction(response.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(notficationUpdateReadFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(notficationUpdateReadFailureAction(error));
      throw error;
    }
  };
};

export const notficationUpdateReadSuccessAction = (data: object) => {
  return {
    type: types.NOTIFICATION_UPDATE_READ.SUCCESS,
    payload: data
  };
};

export const notficationUpdateReadFailureAction = (error: object) => {
  return {
    type: types.NOTIFICATION_UPDATE_READ.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
