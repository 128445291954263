import * as types from 'common/constants';

const initialState = {
  teaboyList: {
    data: [],
    loading: false,
    error: null
  },
  assignToFloor: {
    data: {},
    loading: false,
    error: null
  }
};

interface Reducer {
  type: string;
  // -- //
  payload: any;
}

const Authenticate = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Floor List cases
     */
    case types.TEABOYS.REQUEST:
      return {
        ...state,
        teaboyList: {
          data: [],
          loading: true,
          error: null
        }
      };
    case types.TEABOYS.SUCCESS:
      return {
        ...state,
        teaboyList: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.TEABOYS.FAILURE:
      return {
        ...state,
        teaboyList: {
          data: [],
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Create Floor cases
     */
    case types.TEABOY_ASSIGN_TO_FLOOR.REQUEST:
      return {
        ...state,
        assignToFloor: { data: {}, loading: true, error: null }
      };
    case types.TEABOY_ASSIGN_TO_FLOOR.SUCCESS:
      return {
        ...state,
        assignToFloor: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.TEABOY_ASSIGN_TO_FLOOR.FAILURE:
      return {
        ...state,
        assignToFloor: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Edit Floor cases
     */
    case types.FLOOR_EDIT.REQUEST:
      return {
        ...state,
        editFloor: { data: {}, loading: true, error: null }
      };
    case types.FLOOR_EDIT.SUCCESS:
      return {
        ...state,
        editFloor: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.FLOOR_EDIT.FAILURE:
      return {
        ...state,
        editFloor: { data: {}, loading: false, error: payload }
      };

    default:
      return state;
  }
};

export default Authenticate;
