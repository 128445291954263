import { Crypto } from 'common/helpers/crypto';
import { toast } from 'common/helpers/toastConfig';
import Cookies from 'js-cookie';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';
import { jwtDecode } from 'jwt-decode';

import * as types from 'common/constants';
import * as services from 'common/services';
// import jwt_decode from "jwt-decode";

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * LogIn actions
 */
export const logInRequestAction = (data: object, callBack?: (path: string) => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.LOG_IN.REQUEST,
        payload: {}
      });

      const response: any = await services.loginService(data);

      if (response.status === 200 || response.status === 201) {
        const decoded: any = jwtDecode(response.data?.data?.jwtToken);
        Cookies.set('token', Crypto.encrypt(response.data.data.jwtToken));
        Cookies.set('userInfo', Crypto.encrypt(JSON.stringify(decoded)));
        console.log(decoded);

        dispatch(logInSuccessAction(response.data));

        callBack &&
          callBack(
            decoded?.role === 'cafeTeriaManager'
              ? '/panel/cafeteria/menu-management'
              : decoded?.role === 'marketingManager'
                ? '/panel/events'
                : decoded?.role === 'supporter'
                  ? '/panel/ticket/tickets'
                  : decoded.role === 'reservationManager'
                    ? '/panel/reservation'
                    : '/panel/dashboard'
          );
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(logInFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);

      // toast({ model: "error", message: error });
      dispatch(logInFailureAction(error));
      throw error;
    }
  };
};

export const logInSuccessAction = (data: object) => {
  return {
    type: types.LOG_IN.SUCCESS,
    payload: data
  };
};

export const logInFailureAction = (error: object) => {
  return {
    type: types.LOG_IN.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Forget Password actions
 */

export const forgetPasswordRequestAction = (data: object, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.FORGET_PASSWORD.REQUEST,
        payload: {}
      });

      const response: any = await services.forgetPassword(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(forgetPasswordSuccessAction(response.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data?.clientMessage });
        dispatch(forgetPasswordFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error.response);

      dispatch(forgetPasswordFailureAction(error));
      throw error;
    }
  };
};

export const forgetPasswordSuccessAction = (data: object) => {
  return {
    type: types.FORGET_PASSWORD.SUCCESS,
    payload: data
  };
};

export const forgetPasswordFailureAction = (error: object) => {
  return {
    type: types.FORGET_PASSWORD.FAILURE,
    payload: error
  };
};

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Refresh Token actions
 */
export const refreshTokenRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.REFRESH_TOKEN.REQUEST,
        payload: {}
      });

      const response: any = await services.refreshTokenResend(data);

      if (response.status === 200 || response.status === 201) {
        // var decoded: any = jwt_decode(response.data?.accessToken);
        // localStorage.setItem("userRole", decoded.role);
        Cookies.set('token', Crypto.encrypt(response.data.data.jwtToken));

        dispatch(refreshTokenSuccessAction(response.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(refreshTokenFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);

      // toast({ model: "error", message: error });
      dispatch(refreshTokenFailureAction(error));
      throw error;
    }
  };
};

export const refreshTokenSuccessAction = (data: object) => {
  return {
    type: types.REFRESH_TOKEN.SUCCESS,
    payload: data
  };
};

export const refreshTokenFailureAction = (error: object) => {
  return {
    type: types.REFRESH_TOKEN.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Get Token actions
 */
export const getFirebaseTokenRequestAction = (callBack?: (token: string) => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.GET_FIREBASE_TOKEN.REQUEST,
        payload: {}
      });

      const response: any = await services.getFirebaseTokenService();

      if (response.status === 200 || response.status === 201) {
        Cookies.set('customFirebaseToken', Crypto.encrypt(response.data.data.token));
        dispatch(getFirebaseTokenSuccessAction(response.data));
        callBack && callBack(response.data.data.token);
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(getFirebaseTokenFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);

      // toast({ model: "error", message: error });
      dispatch(getFirebaseTokenFailureAction(error));
      throw error;
    }
  };
};

export const getFirebaseTokenSuccessAction = (data: object) => {
  return {
    type: types.GET_FIREBASE_TOKEN.SUCCESS,
    payload: data
  };
};

export const getFirebaseTokenFailureAction = (error: object) => {
  return {
    type: types.GET_FIREBASE_TOKEN.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Add Or Update Token actions
 */
export const addOrUpdateFirebaseTokenRequestAction = (token: string, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.ADD_OR_UPDATE_FIREBASE_TOKEN.REQUEST,
        payload: {}
      });

      const response: any = await services.addOrUpdateFirebaseTokenService(token);

      if (response.status === 200 || response.status === 201) {
        Cookies.set('customFirebaseToken', Crypto.encrypt(response.data.data.jwtToken));
        dispatch(addOrUpdateFirebaseTokenSuccessAction(response.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(addOrUpdateFirebaseTokenFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(addOrUpdateFirebaseTokenFailureAction(error));
      throw error;
    }
  };
};

export const addOrUpdateFirebaseTokenSuccessAction = (data: object) => {
  return {
    type: types.ADD_OR_UPDATE_FIREBASE_TOKEN.SUCCESS,
    payload: data
  };
};

export const addOrUpdateFirebaseTokenFailureAction = (error: object) => {
  return {
    type: types.ADD_OR_UPDATE_FIREBASE_TOKEN.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Get Token actions
 */
export const logOutRequestAction = (callBack?: (token: string) => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.LOG_OUT.REQUEST,
        payload: {}
      });

      const response: any = await services.logOutService();

      if (response.status === 200 || response.status === 201) {
        dispatch(logOutSuccessAction(response.data));
        callBack && callBack(response.data.data.token);
        Cookies.remove('userInfo');
        Cookies.remove('token');
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(logOutFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);

      // toast({ model: "error", message: error });
      dispatch(logOutFailureAction(error));
      throw error;
    }
  };
};

export const logOutSuccessAction = (data: object) => {
  return {
    type: types.LOG_OUT.SUCCESS,
    payload: data
  };
};

export const logOutFailureAction = (error: object) => {
  return {
    type: types.LOG_OUT.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
