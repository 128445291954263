/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// -- == //
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Formik } from 'formik';
import dayjs from 'dayjs';

import { ValidationCreateEvent, ValidationEditEvent } from 'common/validations';
import PrimaryButton from 'components/form/buttons/primaryButton';
import TextIput from 'components/form/textInput';
import Gap from 'components/gap';
import { useActions } from 'common/hooks/useActions';
import DateInput from 'components/form/dateInput';
import UploadBox from 'components/form/uploadBox';
import { EventTypeEnumArray } from 'common/enums';
import Dropdown from 'components/form/dropDown';
import TextArea from 'components/form/texArea';
import EventFormGuestsList from './lib/guestsList';
import AppSwitch from 'components/form/switch';
import DangerButton from 'components/form/buttons/danger';
import QuestionModal from 'components/modal/question';
import { toast } from 'common/helpers/toastConfig';

import * as S from '../../styles';
import * as T from 'common/types';

interface IProps {
  data?: T.Pages.EventItem;
  departments: string[];
  closeModal?: () => void;
  fetchData?: () => void;
  loading: boolean;
}

const EventForm: React.FC<IProps> = ({ data, departments, loading }) => {
  const nav = useNavigate();
  const params = useParams();

  const [ShowCanceleModal, setShowCanceleModal] = useState(false);

  const {
    eventCreateRequestAction,
    eventUpdateRequestAction,
    eventUploadRequestAction,
    departmentsRequestAction,
    eventCancelRequestAction,
    eventDetailRequestAction,
    eventChangeVisibleRequestAction
  } = useActions();

  useEffect(() => {
    departmentsRequestAction();
  }, []);

  const handleShowCancelModal = () => {
    setShowCanceleModal(true);
  };

  const handleHideCancelModal = () => {
    setShowCanceleModal(false);
  };

  const handleCancelEvent = () => {
    eventCancelRequestAction(data?.id, () => {
      setShowCanceleModal(false);
      nav('/panel/events');
    });
  };

  const handleChangeVisible = () => {
    eventChangeVisibleRequestAction(data?.id, () => {
      eventDetailRequestAction(params?.id);
    });
  };

  return (
    <>
      <Formik
        initialValues={{
          title: data?.title || '',
          type: data?.type || '',
          department: data?.department || '',
          cover: '',
          startsAt: data?.startsAt || '',
          startSign: data?.startSign || '',
          endSign: data?.endSign || '',
          id: data?.id || undefined,
          description: data?.description || '',
          image: undefined,
          fileUrl: data?.image || '',
          capacity: data?.capacity || '',
          duration: data?.duration || '',
          eventPlace: data?.eventPlace || '',
          guestsList: data?.guests || [],
          // isDisable: false,
          isPublic: data?.isPublic || false
        }}
        enableReinitialize
        validationSchema={data?.id ? ValidationEditEvent : ValidationCreateEvent}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (new Date(values.startSign) > new Date(values.startsAt)) {
            toast({
              model: 'error',
              message: 'The registration date cannot be older than the start date'
            });
            setSubmitting(false);
            return;
          }
          let flag = 0;
          let flagLinkdin = 0;
          let flagWeblink = 0;

          const expression =
            // eslint-disable-next-line no-useless-escape
            /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
          const regex = new RegExp(expression);

          values.guestsList.map((item: any) => {
            if (!item.fullName || !item.jobTitle || !item.description) {
              flag += 1;
            }
            if (!item.weblink.match(regex) && item.weblink) {
              flagWeblink += 1;
            }
            if (!item.linkedin.match(regex) && item.linkedin) {
              flagLinkdin += 1;
            }
          });
          if (flagWeblink > 0) {
            toast({
              message: `The website link format is wrong`,
              model: 'error'
            });
            setSubmitting(false);
            return;
          }
          if (flagLinkdin > 0) {
            toast({
              message: `The linkedin link format is wrong`,
              model: 'error'
            });
            setSubmitting(false);
            return;
          }
          if (flag !== 0) {
            toast({
              model: 'error',
              message: 'Full name, job title, Description fields are required'
            });
            setSubmitting(false);
            return;
          }
          const newData = {
            title: values.title,
            type: values.type,
            description: values.description,
            cover: values.cover || undefined,
            startsAt: values.startsAt,
            duration: values.duration,
            capacity: values.capacity,
            eventPlace: values.eventPlace,
            department: values.department,
            startSign: values.startSign,
            endSign: values.endSign,
            id: data?.id || undefined,
            isPublic: values.isPublic,
            guestsList: values.guestsList.map((item) => {
              return {
                avatar: item.avatar,
                description: item.description,
                fullName: item.fullName,
                jobTitle: item.jobTitle,
                linkedin: item.linkedin,
                weblink: item.weblink,
                id: item.id
              };
            })
          };
          if (data?.id)
            eventUpdateRequestAction(newData, () => {
              resetForm();
              nav('/panel/events');
            });
          else
            eventCreateRequestAction(newData, () => {
              resetForm();
              nav('/panel/events');
            });
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setValues }) => (
          <>
            <S.Div className="row">
              <S.Div className="col-md-6">
                <Dropdown
                  list={departments?.map((item: string) => {
                    return { id: item, title: item };
                  })}
                  onChange={(e: T.Form.ListDropdownItem) => {
                    setValues({
                      ...values,
                      department: e.id as string
                    });
                  }}
                  label="Department"
                  placeholder="Department"
                  defaultValue={{
                    title: values.department,
                    id: values.department
                  }}
                  message={touched.department && (errors.department as any)}
                  error={touched.department && (errors.department as unknown as boolean)}
                  inputStyle={{ borderRadius: 7 }}
                />
                <Gap size="10px" />
                <TextIput
                  type="text"
                  name="title"
                  onChange={handleChange}
                  placeholder="Title"
                  label="Title"
                  value={values.title}
                  message={touched.title && (errors.title as any)}
                  error={touched.title && (errors.title as unknown as boolean)}
                  inputStyle={{ borderRadius: 7 }}
                />
                <Gap size="10px" />
                <DateInput
                  onChange={(e) => {
                    setValues({
                      ...values,
                      startsAt: e
                    });
                  }}
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY MMMM D HH:mm"
                  placeholder="Event Start Date"
                  label="Event Start Date"
                  defaultDate={values.startsAt && dayjs(values.startsAt)}
                  // key={values.startsAt}
                  message={touched.startsAt && (errors.startsAt as any)}
                  error={touched.startsAt && (errors.startsAt as unknown as boolean)}
                  inputStyle={{ height: 50, borderRadius: 7 }}
                />
                <Gap size="10px" />
                <DateInput
                  onChange={(e) => {
                    setValues({
                      ...values,
                      startSign: e
                    });
                  }}
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY MMMM D HH:mm"
                  placeholder="Registration start date"
                  label="Registration start date"
                  defaultDate={values.startSign && dayjs(values.startSign)}
                  message={touched.startSign && (errors.startSign as any)}
                  // key={values.startSign}
                  error={touched.startSign && (errors.startSign as unknown as boolean)}
                  inputStyle={{ height: 50, borderRadius: 7 }}
                />

                <Gap size="10px" />

                <DateInput
                  onChange={(e) => {
                    setValues({
                      ...values,
                      endSign: e
                    });
                  }}
                  showTime={{ format: 'HH:mm' }}
                  format="YYYY MMMM D HH:mm"
                  placeholder="Registration end date"
                  label="Registration end date"
                  defaultDate={values.endSign && dayjs(values.endSign)}
                  key={values.endSign}
                  message={touched.endSign && (errors.endSign as any)}
                  error={touched.endSign && (errors.endSign as unknown as boolean)}
                  inputStyle={{ height: 50, borderRadius: 7 }}
                />
                <Gap size="10px" />

                <Dropdown
                  label="Type"
                  placeholder="Type"
                  list={EventTypeEnumArray}
                  onChange={(e: T.Form.ListDropdownItem) => {
                    setValues({
                      ...values,
                      type: e.id as string
                    });
                  }}
                  defaultValue={{ title: values.type, id: values.type }}
                  message={touched.type && (errors.type as any)}
                  error={touched.type && (errors.type as unknown as boolean)}
                  inputStyle={{ borderRadius: 7 }}
                />
                <Gap size="10px" />
                <TextIput
                  type="number"
                  arrow
                  label="Capacity"
                  placeholder="Capacity"
                  name="capacity"
                  onChange={handleChange}
                  value={values.capacity as string}
                  message={touched.capacity && (errors.capacity as any)}
                  error={touched.capacity && (errors.capacity as unknown as boolean)}
                  inputStyle={{ borderRadius: 7 }}
                />
                <Gap size="10px" />
                <TextIput
                  label="Duration"
                  placeholder="Duration"
                  name="duration"
                  onChange={handleChange}
                  value={values.duration}
                  message={touched.duration && (errors.duration as any)}
                  error={touched.duration && (errors.duration as unknown as boolean)}
                  inputStyle={{ borderRadius: 7 }}
                />
                <Gap size="10px" />
                <TextIput
                  type="text"
                  label="Event Place"
                  placeholder="Event Place"
                  name="eventPlace"
                  onChange={handleChange}
                  value={values.eventPlace}
                  message={touched.eventPlace && (errors.eventPlace as any)}
                  error={touched.eventPlace && (errors.eventPlace as unknown as boolean)}
                  inputStyle={{ borderRadius: 7 }}
                />
                <Gap size="10px" />
                <TextArea
                  label="Description"
                  name="description"
                  onChange={handleChange}
                  placeholder="Description"
                  value={values.description}
                  message={touched.description && (errors.description as any)}
                  error={touched.description && (errors.description as unknown as boolean)}
                  inputStyle={{ borderRadius: 7 }}
                />
                <Gap size="10px" />
                <UploadBox
                  placeholder="Please Upload Image with 1.7 : 1 dimension"
                  label="Event Cover Photo"
                  inputStyle={{ height: 56, borderRadius: 7 }}
                  onChange={(e: T.Form.FileUpload) => {
                    eventUploadRequestAction({ image: e?.file }, (fileNameOnly: string) => {
                      setValues({
                        ...values,
                        cover: fileNameOnly,
                        fileUrl: e.dataURL
                      });
                    });
                  }}
                  message={touched.image && (errors.image as any)}
                  error={touched.image && (errors.image as any)}
                  key={data?.id}
                  defaultImage={values.fileUrl}
                  value={{ file: values.image, dataURL: values.fileUrl }}
                />
              </S.Div>
              <S.Div className="col-md-6">
                <EventFormGuestsList setValues={setValues} values={values} />
                <Gap size="20px" />
                <S.Grid>
                  <AppSwitch
                    checked={values.isPublic}
                    onChange={(e) => setValues({ ...values, isPublic: e })}
                    text="Public Event"
                  />
                  {data?.id ? (
                    <AppSwitch checked={data?.isDisable} onChange={() => handleChangeVisible()} text="Hide the event" />
                  ) : (
                    <></>
                  )}
                </S.Grid>
                <Gap size="20px" />
                <S.GridButton>
                  {data?.id ? (
                    <DangerButton
                      style={{
                        width: '216px',
                        height: 42,
                        borderRadius: 8
                      }}
                      onClick={handleShowCancelModal}
                      // disabled={isSubmitting}
                    >
                      Cancel
                    </DangerButton>
                  ) : (
                    <></>
                  )}
                  <PrimaryButton
                    style={{
                      width: '216px',
                      height: 42,
                      borderRadius: 8
                    }}
                    onClick={handleSubmit}
                    disabled={loading || isSubmitting}
                  >
                    {data?.id ? 'Edit Event' : 'Create Event'}
                  </PrimaryButton>
                </S.GridButton>
              </S.Div>
            </S.Div>
          </>
        )}
      </Formik>
      <QuestionModal
        handleClose={handleHideCancelModal}
        show={ShowCanceleModal}
        model="delete"
        handleSuccess={handleCancelEvent}
        title="Are you sure cancel to this event?"
        successButtonText="Yes"
      />
    </>
  );
};

export default EventForm;
