// -- == //
import React from 'react';
import { Formik } from 'formik';

import PrimaryButton from 'components/form/buttons/primaryButton';
import TextIput from 'components/form/textInput';
import Gap from 'components/gap';
import { useActions } from 'common/hooks/useActions';
import TextArea from 'components/form/texArea';

import * as S from '../../../../styles';
import { useNavigate } from 'react-router-dom';
import { ValidationCreateSendToAllNotif } from 'common/validations';
// import * as T from "common/types";

interface IProps {
  data?: any;
}

const NotificationCreateSendToAllForm: React.FC<IProps> = ({ data }) => {
  const { notficationCreateCustomRequestAction } = useActions();

  const nav = useNavigate();
  // const handle = () => {

  // };

  return (
    <>
      <Formik
        initialValues={{
          title: data?.title || '',
          body: data?.body || '',
          sendToAll: true
        }}
        enableReinitialize
        validationSchema={ValidationCreateSendToAllNotif}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          notficationCreateCustomRequestAction(values, () => {
            nav('/panel/notifications');
          });
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit
          // isSubmitting,
          // setValues,
        }) => (
          <S.Div className="row">
            <S.Div className="col-md-6">
              <TextIput
                type="text"
                name="title"
                onChange={handleChange}
                placeholder="Title"
                label="Notification Title"
                value={values.title}
                message={touched.title && (errors.title as any)}
                error={touched.title && (errors.title as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
              />

              <Gap size="10px" />

              <TextArea
                label="Notification Body"
                name="body"
                onChange={handleChange}
                placeholder="Body"
                value={values.body}
                message={touched.body && (errors.body as any)}
                error={touched.body && (errors.body as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
              />
              <Gap size="20px" />

              <PrimaryButton
                style={{
                  width: '216px',
                  height: 42,
                  borderRadius: 8
                }}
                onClick={handleSubmit}
                // disabled={isSubmitting}
              >
                Send
              </PrimaryButton>
            </S.Div>
          </S.Div>
        )}
      </Formik>
    </>
  );
};

export default NotificationCreateSendToAllForm;
