import styled from 'styled-components';

export const Content = styled.div``;

export const Name = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0a214280;
`;

export const Div = styled.div`
  &.active {
    color: var(--primary-green);
  }
  &.inactive {
    color: #ff646480;
  }
  &.captal {
    text-transform: capitalize;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eef1f6;
  padding: 7px 10px;
  border-radius: 12px;
  font-size: 14px;
  color: #8a93ac;
  font-weight: 500;
`;

export const Detail = styled.div`
  min-height: 400px;
  flex-direction: column;
  justify-content: center;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
`;
