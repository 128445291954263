import React from 'react';
import { ReactSVG } from 'react-svg';
import ImageUploading, { ImageListType } from 'react-images-uploading';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  label?: string;
  disabled?: boolean | undefined;
  onChange?: (e: T.Form.FileUpload) => void;
  style?: object;
  id?: string;
  placeholder?: string;
  error?: boolean;
  success?: boolean;
  message?: string | boolean;
  type?: string;
  name?: string;
  onBlur?: (e: object) => void;
  defaultImage?: string | null;
  icon?: string;
  inputStyle?: object;
  accept?: [string];
  value?: object | null;
  // value?:
}

const UploadInput: React.FC<IProps> = ({
  // label,
  value,
  id,
  disabled,
  style,
  error,
  success,
  message,
  onChange,
  name,
  onBlur,
  defaultImage,
  icon,
  inputStyle,
  placeholder,
  accept = ['png', 'jpg', 'jpeg', 'webp']
}) => {
  const randomId = Math.random() * 200;

  // const [images, setImages] = React.useState([...value]);

  // useEffect(() => {
  //   setImages([])
  // }, [value])

  const onChangeFile = (
    imageList: ImageListType
    // addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    // setImages(imageList as never[]);
    if (onChange) onChange(imageList[0] as unknown as T.Form.FileUpload);
  };

  return (
    <S.inputContainer style={style} className={error ? 'error' : success ? 'success' : ''}>
      <ImageUploading value={[{ ...value }]} onChange={onChangeFile} maxNumber={1} acceptType={accept}>
        {({
          imageList,
          onImageUpload,
          // onImageRemoveAll,
          onImageUpdate,
          // onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <>
            <S.InputBox
              onClick={() => {
                imageList[0] ? onImageUpdate(0) : onImageUpload();
              }}
            >
              <S.input
                name={name}
                type="text"
                placeholder={!defaultImage && !imageList[0]?.dataURL ? placeholder : ''}
                id={id ? id : `${randomId}`}
                disabled={disabled}
                style={{
                  ...inputStyle,
                  paddingLeft: icon ? '35px' : '15px',
                  borderColor: isDragging ? 'red' : undefined
                }}
                readOnly
                onBlur={onBlur}
                // value={imageList[0] ? imageList[0]?.file?.name : label}
                {...dragProps}
              />
              {defaultImage || imageList[0]?.dataURL ? (
                <S.img
                  src={
                    // -- //
                    imageList[0] ? imageList[0]?.dataURL : (defaultImage as any)
                  }
                  className="icon"
                />
              ) : (
                <></>
              )}
              <S.Icon>
                <ReactSVG
                  src="/icons/stroke/image-plus.svg"
                  beforeInjection={(svg) => {
                    svg.setAttribute('stroke', `#A4A6B3`);
                  }}
                />
              </S.Icon>
            </S.InputBox>
          </>
        )}
      </ImageUploading>

      {message ? (
        <S.message className={error ? 'error-message' : success ? 'success-message' : ''}>{message}</S.message>
      ) : (
        <></>
      )}
    </S.inputContainer>
  );
};

export default UploadInput;
