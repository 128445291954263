export enum USERS {
  REQUEST = 'USERS_REQUEST',
  SUCCESS = 'USERS_SUCCESS',
  FAILURE = 'USERS_FAILURE'
}

export enum USER_DETAIL {
  REQUEST = 'USER_DETAIL_REQUEST',
  SUCCESS = 'USER_DETAIL_SUCCESS',
  FAILURE = 'USER_DETAIL_FAILURE'
}

export enum CHANGE_ROLE {
  REQUEST = 'CHANGE_ROLE_REQUEST',
  SUCCESS = 'CHANGE_ROLE_SUCCESS',
  FAILURE = 'CHANGE_ROLE_FAILURE'
}

export enum ROLES {
  REQUEST = 'ROLES_REQUEST',
  SUCCESS = 'ROLES_SUCCESS',
  FAILURE = 'ROLES_FAILURE'
}

export enum ADMIN_ROLES {
  REQUEST = 'ADMIN_ROLES_REQUEST',
  SUCCESS = 'ADMIN_ROLES_SUCCESS',
  FAILURE = 'ADMIN_ROLES_FAILURE'
}

export enum ATTENDANCES {
  REQUEST = 'ATTENDANCES_REQUEST',
  SUCCESS = 'ATTENDANCES_SUCCESS',
  FAILURE = 'ATTENDANCES_FAILURE'
}

export enum AREA_LIST {
  REQUEST = 'AREA_LIST_REQUEST',
  SUCCESS = 'AREA_LIST_SUCCESS',
  FAILURE = 'AREA_LIST_FAILURE'
}
