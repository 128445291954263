/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import AppTable from 'components/appTable';
import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import { useActions } from 'common/hooks/useActions';
import PrimaryButton from 'components/form/buttons/primaryButton';
import PaginationApp from 'components/pagination';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  data: { list: []; totalPages?: number };
  departments: [];
  loading: boolean;
}

export default function UserListTable({ data, loading }: IProps) {
  const { usersRequestAction } = useActions();
  const [meta, setMeta] = useState({
    page: 1,
    page_size: 10,
    search: '',
    type: ''
  });

  const fetchData = () => {
    usersRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  const handleChangePage = (page: number) => {
    setMeta({ ...meta, page });
  };

  const handleChangePageSize = (page_size: number) => {
    setMeta({ ...meta, page_size });
  };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => setMeta({ ...meta, search: e })}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
            value={meta.search}
          />
          {/* <EventFilterPopup
            setFilter={setMeta}
            filter={meta}
            departments={departments}
          /> */}
        </S.Head>
        <Gap size="20px" />
        {!loading && !data.list.length ? (
          <S.P>No result was found for this search query </S.P>
        ) : (
          <AppTable
            headers={[
              { id: 1, title: 'User ID', dir: 'left' },
              { id: 4, title: 'User Name', dir: 'left' },
              { id: 5, title: 'E-mail', dir: 'left' },
              { id: 6, title: 'Phone' },
              { id: 7, title: 'Department', dir: 'left' },
              { id: 7, title: 'Job Title', dir: 'left' },
              { id: 8, title: 'Role', dir: 'left' },
              { id: 10, title: '' }
            ]}
            data={data.list}
            loading={loading}
            tdFields={{
              id: (item: T.Pages.UserItem) => <S.Div className="text-left">{item?.id}</S.Div>,

              name: (item: T.Pages.UserItem) => (
                <S.Div className="text-left captal">
                  {item.displayName?.length > 50 ? item.displayName.substring(0, 50) + ' ... ' : item.displayName}
                </S.Div>
              ),
              email: (item: T.Pages.UserItem) => <S.Div className="text-left">{item.mail}</S.Div>,
              phone: (item: T.Pages.UserItem) => <S.Div className="text-center">{item?.mobilePhone}</S.Div>,
              department: (item: T.Pages.UserItem) => <S.Div className="text-left">{item.department}</S.Div>,
              jobTitle: (item: T.Pages.UserItem) => <S.Div className="text-left">{item.jobTitle}</S.Div>,
              role: (item: T.Pages.UserItem) => <S.Div className="text-left">{item.role}</S.Div>,
              edit: (item: T.Pages.UserItem) => (
                <S.Div className="text-left captal">
                  <PrimaryButton
                    style={{
                      height: 28,
                      fontSize: 12,
                      background: '#8A93AC',
                      color: 'var(--white)',
                      width: 110,
                      margin: 'auto',
                      borderRadius: 8,
                      padding: '0 5px'
                    }}
                    link={`/panel/users/change-role/${item.id}?backPage=/panel/users/user-list`}
                  >
                    Change Role
                  </PrimaryButton>
                </S.Div>
              )
            }}
          />
        )}
      </PanelBoxLayout>
      {data.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.page_size * data.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={data.totalPages}
          pageSize={meta.page_size}
          handleChangeTake={handleChangePageSize}
        />
      ) : (
        <></>
      )}
    </S.Content>
  );
}
