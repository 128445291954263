import * as types from 'common/constants';

const initialState = {
  news: {
    data: {
      list: [],
      totalPages: null
    },
    loading: false,
    error: null
  },
  newsCreate: {
    data: {},
    loading: false,
    error: null
  },
  newsUpdate: {
    data: {},
    loading: false,
    error: null
  },
  newsDelete: {
    data: {},
    loading: false,
    error: null
  },
  newsUpload: {
    data: {},
    loading: false,
    error: null
  },
  newsUpdateUpload: {
    data: {},
    loading: false,
    error: null
  },
  newsDetail: {
    data: {},
    loading: false,
    error: null
  }
};

interface Reducer {
  type: string;
  // -- //
  payload: any;
}

const News = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * News cases
     */
    case types.NEWS.REQUEST:
      return {
        ...state,
        news: {
          data: {
            list: state.news.data.list,
            totalPages: null
          },
          loading: true,
          error: null
        }
      };
    case types.NEWS.SUCCESS:
      return {
        ...state,
        news: {
          data: {
            list: payload.list ? payload.list : payload,
            totalPages: payload?.totalPages
          },
          loading: false,
          error: null
        }
      };
    case types.NEWS.FAILURE:
      return {
        ...state,
        news: {
          data: {
            list: [],
            totalPages: null
          },
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * News Create cases
     */
    case types.NEWS_CREATE.REQUEST:
      return {
        ...state,
        newsCreate: { data: {}, loading: true, error: null }
      };
    case types.NEWS_CREATE.SUCCESS:
      return {
        ...state,
        newsCreate: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.NEWS_CREATE.FAILURE:
      return {
        ...state,
        newsCreate: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * News Update cases
     */
    case types.NEWS_UPDATE.REQUEST:
      return {
        ...state,
        newsUpdate: { data: {}, loading: true, error: null }
      };
    case types.NEWS_UPDATE.SUCCESS:
      return {
        ...state,
        newsUpdate: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.NEWS_UPDATE.FAILURE:
      return {
        ...state,
        newsUpdate: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * News Upload cases
     */
    case types.NEWS_UPLOAD.REQUEST:
      return {
        ...state,
        newsUpload: { data: {}, loading: true, error: null }
      };
    case types.NEWS_UPLOAD.SUCCESS:
      return {
        ...state,
        newsUpload: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.NEWS_UPLOAD.FAILURE:
      return {
        ...state,
        newsUpload: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * News Upload cases
     */
    case types.NEWS_UPDATE_UPLOAD.REQUEST:
      return {
        ...state,
        newsUpdateUpload: { data: {}, loading: true, error: null }
      };
    case types.NEWS_UPDATE_UPLOAD.SUCCESS:
      return {
        ...state,
        newsUpdateUpload: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.NEWS_UPDATE_UPLOAD.FAILURE:
      return {
        ...state,
        newsUpdateUpload: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * News Detail cases
     */
    case types.NEWS_DETAIL.REQUEST:
      return {
        ...state,
        newsDetail: { data: {}, loading: true, error: null }
      };
    case types.NEWS_DETAIL.SUCCESS:
      return {
        ...state,
        newsDetail: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.NEWS_DETAIL.FAILURE:
      return {
        ...state,
        newsDetail: { data: {}, loading: false, error: payload }
      };

    default:
      return state;
  }
};

export default News;
