import * as types from 'common/constants';

const initialState = {
  menuList: {
    data: {
      list: []
    },
    loading: false,
    error: null
  },
  createMenu: {
    data: {},
    loading: false,
    error: null
  },
  editMenu: {
    data: {},
    loading: false,
    error: null
  },
  chart: {
    data: {},
    loading: false,
    error: null
  },
  cafeTeriaStatus: {
    data: {},
    loading: false,
    error: null
  },
  upload: {
    data: {},
    loading: false,
    error: null
  }
};

interface Reducer {
  type: string;
  // -- //
  payload: any;
}

const Authenticate = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Menu List cases
     */
    case types.CAFETERIA_MENU.REQUEST:
      return {
        ...state,
        menuList: {
          data: {
            list: state.menuList.data.list
          },
          loading: true,
          error: null
        }
      };
    case types.CAFETERIA_MENU.SUCCESS:
      return {
        ...state,
        menuList: {
          data: {
            list: payload.list ? payload.list : payload,
            totalPages: payload?.totalPages
          },
          loading: false,
          error: null
        }
      };
    case types.CAFETERIA_MENU.FAILURE:
      return {
        ...state,
        menuList: {
          data: {
            list: []
          },
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Create Menu cases
     */
    case types.CAFETERIA_CREATE_MENU.REQUEST:
      return {
        ...state,
        createMenu: { data: {}, loading: true, error: null }
      };
    case types.CAFETERIA_CREATE_MENU.SUCCESS:
      return {
        ...state,
        createMenu: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.CAFETERIA_CREATE_MENU.FAILURE:
      return {
        ...state,
        createMenu: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Edit Menu cases
     */
    case types.CAFETERIA_EDIT_MENU.REQUEST:
      return {
        ...state,
        editMenu: { data: {}, loading: true, error: null }
      };
    case types.CAFETERIA_EDIT_MENU.SUCCESS:
      return {
        ...state,
        editMenu: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.CAFETERIA_EDIT_MENU.FAILURE:
      return {
        ...state,
        editMenu: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Edit Menu cases
     */
    case types.CAFETERIA_CHART.REQUEST:
      return {
        ...state,
        chart: { data: {}, loading: true, error: null }
      };
    case types.CAFETERIA_CHART.SUCCESS:
      return {
        ...state,
        chart: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.CAFETERIA_CHART.FAILURE:
      return {
        ...state,
        chart: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Status cases
     */
    case types.CAFETERIA_STATUS.REQUEST:
      return {
        ...state,
        cafeTeriaStatus: { data: {}, loading: true, error: null }
      };
    case types.CAFETERIA_STATUS.SUCCESS:
      return {
        ...state,
        cafeTeriaStatus: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.CAFETERIA_STATUS.FAILURE:
      return {
        ...state,
        cafeTeriaStatus: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Upload cases
     */
    case types.CAFETERIA_UPLOAD.REQUEST:
      return {
        ...state,
        upload: { data: {}, loading: true, error: null }
      };
    case types.CAFETERIA_UPLOAD.SUCCESS:
      return {
        ...state,
        upload: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.CAFETERIA_UPLOAD.FAILURE:
      return {
        ...state,
        upload: { data: {}, loading: false, error: payload }
      };

    default:
      return state;
  }
};

export default Authenticate;
