import { ApiCaller } from 'common/helpers/apiCaller';
import convertToQueryString from 'common/helpers/convertToQueryString';

// ------ News ------ //
export const newsService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/news?${convertToQueryString(data)}`
  });
};

// ------ Create News ------ //
export const newsCreateService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/news`,
    data
  });
};

// ------ Update News ------ //
export const newsUpdateService = (data: object) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/news`,
    data
  });
};

// ------ News Detail ------ //
export const newsDetailService = (id?: string) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/news/${id}`
  });
};

// ------ News Upload Image ------ //
export const newsUploadService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/news/upload`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// ------ News Update Upload Image ------ //
export const newsUpdateUploadService = (data: object) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/news/upload`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
