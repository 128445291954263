// import TicketMenuManagementCreate from "./lib/create";
import { useNavigate } from 'react-router';
import NotificationsTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import SecundButton from 'components/form/buttons/secundButton';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function NotificationsPage() {
  const navigate = useNavigate();
  // const { notification } = useTypedSelector(
  //   (state: any) => state.Notification
  // );
  const { notificationsCustom }: any = useTypedSelector((state) => state.Notifications);
  const { departments } = useTypedSelector((state) => state.Departments);

  const handleRedirect = () => {
    navigate('/panel/notifications/create');
  };

  return (
    <S.Container>
      <S.Head>
        <S.Title>Notifications</S.Title>
        <SecundButton hasIcon={true} onClick={handleRedirect}>
          Create new Notification
        </SecundButton>
      </S.Head>
      <Gap size="20px" />
      <NotificationsTable data={notificationsCustom.data} departments={departments.data} loading={false} />

      <AppLoading loading={departments.loading || notificationsCustom.loading} />
    </S.Container>
  );
}
