// -- == //
import Gap from 'components/gap';
import NewsForm from '../lib/newsForm';
import AppLoading from 'components/appLoading';
import { useTypedSelector } from 'common/hooks/useTypedSelector';

import * as S from './styles';

export default function NewsCreatePage() {
  const { newsCreate, newsUpload } = useTypedSelector((state: any) => state.News);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Create News</S.Title>
      </S.Head>
      <Gap size="20px" />
      <NewsForm data={{} as any} loading={newsCreate.loading} />

      <AppLoading loading={newsCreate.loading || newsUpload.loading} />
    </S.Container>
  );
}
