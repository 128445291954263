import styled from 'styled-components';

import { device } from 'common/constants/mediaQueries';

export const inputContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  /* overflow: hidden; */
  border-radius: 12px;

  @media ${device.mobileL} {
    width: 100%;
  }

  @media ${device.mobileM} {
    width: 100%;
  }

  @media ${device.mobileS} {
    width: 100%;
  }

  &.error {
    input {
      border: 1px solid #eb2b2b !important;
    }
  }

  &.success {
    input {
      border: solid 1px var(--green-dark);
    }
  }
`;

export const Input = styled('input')``;

export const Label = styled('label')<{ labelColor?: string | undefined }>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  /* position: absolute;
  top: 15px;
  left: 15px; */
  color: var(--dark);
  transition: 0.2s ease all;
  cursor: pointer;

  &.mini {
    font-weight: 400;
    font-size: 12px;
  }

  @media ${device.tabletL} {
    font-size: 12px !important;
    line-height: 14px;
  }
`;

export const message = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: var(--dark-light);
  padding-left: 10px;
  padding-top: 3px;

  &.error-message {
    color: var(--danger);
  }

  &.success-message {
    color: var(--green-dark);
  }
`;

export const icon = styled.div`
  position: absolute;
  right: 15px;
  bottom: 0;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;

  &.mini {
    right: 8px;
    bottom: 0px;
  }
  /* @media ${device.mobileM} {
    display: none;
  } */
`;

export const iconSelect = styled.div`
  position: absolute;
  left: 20px;
  bottom: 13px;
  cursor: pointer;
  @media ${device.mobileM} {
    display: none;
  }
`;

export const dropDownItems = styled.ul`
  width: 100%;
  overflow-y: auto;
  background: var(--white);
  border-radius: 12px;
  box-shadow: 0px 1px 4px rgb(0 0 0 / 20%);
  list-style: none;
  position: absolute;
  top: 100%;
  max-height: 0;
  transition: 0.3s;
  padding: 0;
  z-index: 999;

  &.show {
    /* padding: 6px 0; */
    max-height: 300px;
    visibility: visible;
  }
`;

export const dropDownItem = styled.li`
  padding: 0 15px;
  height: 44px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px var(--gray3);
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 125%;
  gap: 10px;

  &.active-check {
    padding-left: 36px;
  }

  :last-child {
    border-bottom: none;
  }

  img {
    margin-right: 10px;
  }

  &.active {
    background: #eaeaea !important;
    padding-left: 15px;
  }

  :hover {
    background: var(--gray1);
  }
`;

export const text = styled.span`
  font-size: 14px;
  font-weight: 400;
`;
export const img = styled.img``;
export const arrowImg = styled.img`
  /* @media ${device.mobileL} {
    display: none;
  } */
`;

export const InputBox = styled.div`
  position: relative;

  .ant-select-selector {
    height: 50px !important;
    width: 100%;
    width: -webkit-fill-available;
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 18px !important;
    line-height: 150%;
    border: 1px solid rgba(182, 182, 182, 0.2) !important;
    box-shadow: none !important;
    border-radius: 10px;
    padding: 0px 20px !important;

    input {
      height: 50px !important;
      line-height: 100%;
      padding: 0px 10px !important;
    }

    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      display: flex;
      align-items: center;
      padding: 0px !important;
      font-size: 14px;
    }

    color: var(--dark);
    cursor: pointer;

    &:focus,
    &:hover,
    &:active {
      border: 1px solid var(--primary-green) !important;
      border-color: var(--primary-green) !important;
    }

    &:not(:placeholder-shown) ~ .input-label {
      color: #367af7;
    }

    &:focus {
      outline: none;
    }

    @media ${device.tabletL} {
      font-size: 13px;
      height: 40px !important;
      padding: 10px 12px;
    }

    &.mini {
      height: 28px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 0px;
      padding: 10px;
      border-radius: 4px;
    }
  }
`;
