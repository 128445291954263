import styled from 'styled-components';

export const Content = styled.div`
  position: relative;
`;

export const Button = styled.button`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 5px;

  &.mobile-theme {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #f0f0f0;
    border: none;
    border-radius: 29px;
    gap: 0;

    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

export const ContentShow = styled.div`
  position: absolute;
  width: 180px;
  right: 0;
  top: 0;
  background: var(--white);
  box-shadow: 0px 4px 8px rgba(182, 182, 182, 0.3);
  border-radius: 16px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #9e9e9e;
  padding: 10px 20px;
  z-index: 10;

  &.show {
    opacity: 1;
    visibility: visible;
    top: 120%;
  }
`;

export const Items = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
export const Item = styled.li`
  padding: 5px 0;
  user-select: none;
`;

export const ButtonText = styled.span`
  &.active {
    color: var(--primary-green);
  }
`;
