import * as types from 'common/constants';

const initialState = {
  show: false,
  title: '',
  text: '',
  model: ''
};

interface Reducer {
  type: string;
  // -- //
  payload: any;
}

const SweetAlert = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    case types.SWEET_ALERT.SHOW:
      return {
        show: true,
        title: payload.title,
        text: payload.text,
        model: payload.model
      };

    case types.SWEET_ALERT.CLOSE:
      return {
        show: false,
        title: '',
        text: '',
        model: ''
      };

    default:
      return state;
  }
};

export default SweetAlert;
