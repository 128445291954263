import styled from 'styled-components';

export const Content = styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--primary-dark);
  margin: 10px 0;
`;

export const Name = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0a214280;
`;

export const Div = styled.div``;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-green);
  line-height: 0;
  border-radius: 40px;
`;
