/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import AppTable from 'components/appTable';
import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import { useActions } from 'common/hooks/useActions';
// import NotificationFilterPopup from "../filterForm";
import PaginationApp from 'components/pagination';
import CheckBox from 'components/form/checkbox';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  data: { list: []; totalPages: number };
  departments: [];
  loading: boolean;
}

export default function EventsTable({ data, departments, loading }: IProps) {
  const { notificationsCustomRequestAction } = useActions();
  const [meta, setMeta] = useState({
    page: 1,
    page_size: 10,
    search: '',
    type: ''
  });

  const fetchData = () => {
    notificationsCustomRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  const handleChangePage = (page: number) => {
    setMeta({ ...meta, page });
  };

  const handleChangePageSize = (page_size: number) => {
    setMeta({ ...meta, page_size });
  };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => setMeta({ ...meta, search: e })}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
            value={meta.search}
          />
          {/* <NotificationFilterPopup
            setFilter={setMeta}
            filter={meta}
            departments={departments}
          /> */}
        </S.Head>
        <Gap size="20px" />
        <AppTable
          headers={[
            { id: 1, title: 'ID', dir: 'left' },
            { id: 2, title: 'Title', dir: 'left' },
            { id: 3, title: 'Department', dir: 'left' },
            { id: 4, title: 'Floor', dir: 'left' },
            { id: 5, title: 'Send to all' }
          ]}
          data={data?.list}
          loading={loading}
          tdFields={{
            id: (item: T.Pages.NotificationListItem) => <S.Div className="text-left">{item?.id}</S.Div>,
            title: (item: T.Pages.NotificationListItem) => (
              <S.Div className="text-left captal">
                {item?.title?.length > 50 ? item?.title.substring(0, 50) + ' ... ' : item?.title}
              </S.Div>
            ),
            department: (item: T.Pages.NotificationListItem) => <S.Div className="text-left">{item?.department}</S.Div>,
            floor: (item: T.Pages.NotificationListItem) => <S.Div className="text-left">{item?.floor?.name}</S.Div>,
            sendToAll: (item: T.Pages.NotificationListItem) => (
              <S.Div className="flex-center">
                <CheckBox checked={item?.sendToAll} />
              </S.Div>
            )
          }}
        />
      </PanelBoxLayout>
      {data.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.page_size * data.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={data.totalPages}
          pageSize={meta.page_size}
          handleChangeTake={handleChangePageSize}
        />
      ) : (
        <></>
      )}
    </S.Content>
  );
}
