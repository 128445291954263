import { useEffect, useState } from 'react';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';

import * as S from './styles';
import * as T from 'common/types';

const DateInput: React.FC<T.Form.DateInput> = ({
  label,
  id,
  // disabled,
  style,
  error,
  success,
  message,
  onChange,
  defaultDate,
  placeholder,
  labelStyle,
  inputStyle,
  format = 'YYYY MMMM D',
  picker,
  showTime
}) => {
  const randomId = Math.random() * 200;
  const [value, setValue] = useState(defaultDate);

  // const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setValue(defaultDate);
  }, [defaultDate]);

  const handleChange: DatePickerProps['onChange'] = (date) => {
    onChange(date);
    setValue(date);
  };

  return (
    <S.inputContainer style={style} className={error ? 'error' : success ? 'success' : ''}>
      <S.label
        className={`input-label`}
        style={labelStyle}
        htmlFor={id ? id : `${randomId}`}
        // onClick={() => setOpen(true)}
      >
        {label}
      </S.label>
      <DatePicker
        onChange={handleChange}
        format={format}
        value={value}
        // popupStyle={{top: 0}}
        // open={open}
        showTime={showTime}
        picker={picker}
        placeholder={placeholder}
        inputRender={(props) => (
          <>
            <S.InputBox className="input-box">
              <S.input placeholder={placeholder} {...props} style={inputStyle} />
            </S.InputBox>
          </>
        )}
      />

      {/* <S.icon src={"/images/icons/calendar.svg"} /> */}
      {message ? (
        <S.message className={error ? 'error-message' : success ? 'success-message' : ''}>{message}</S.message>
      ) : (
        <></>
      )}
    </S.inputContainer>
  );
};

export default DateInput;
