/* eslint-disable react-hooks/exhaustive-deps */
// -- == //
import React, { useEffect } from 'react';
import { Formik } from 'formik';

import PrimaryButton from 'components/form/buttons/primaryButton';
import TextIput from 'components/form/textInput';
import Gap from 'components/gap';
import { useActions } from 'common/hooks/useActions';
import Dropdown from 'components/form/dropDown';
import TextArea from 'components/form/texArea';

import * as S from '../../../../styles';
import * as T from 'common/types';
import { useNavigate } from 'react-router-dom';
import { ValidationCreateDepFloorNotif } from 'common/validations';

interface IProps {
  data?: any;
  departments: string[];
  closeModal?: () => void;
  fetchData?: () => void;
  floors: { list: any[] };
}

const DepartmentAndFloorForm: React.FC<IProps> = ({ data, departments, floors }) => {
  const nav = useNavigate();
  const { departmentsRequestAction, floorsRequestAction, notficationCreateCustomRequestAction } = useActions();

  useEffect(() => {
    departmentsRequestAction();
    floorsRequestAction({ getAll: true });
  }, []);

  // const handleChangeVisible = () => {

  // };

  return (
    <>
      <Formik
        initialValues={{
          title: data?.title || '',
          department: data?.department || '',
          body: data?.body || '',
          floor: data?.floor || '',
          floorName: data?.floor || '',
          sendToAll: false
        }}
        enableReinitialize
        validationSchema={ValidationCreateDepFloorNotif}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          notficationCreateCustomRequestAction(values, () => {
            nav('/panel/notifications');
          });
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          // isSubmitting,
          setValues
        }) => (
          <S.Div className="row">
            <S.Div className="col-md-6">
              <Dropdown
                list={departments?.map((item: string) => {
                  return { id: item, title: item };
                })}
                onChange={(e: T.Form.ListDropdownItem) => {
                  setValues({
                    ...values,
                    department: e.id as string
                  });
                }}
                label="Department"
                placeholder="Department"
                defaultValue={{
                  title: values.department,
                  id: values.department
                }}
                message={touched.department && (errors.department as any)}
                error={touched.department && (errors.department as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
              />
              <Gap size="10px" />

              <Dropdown
                list={floors?.list
                  ?.filter((item: T.Pages.FloorListItem) => !item.isDeleted)
                  ?.map((item: T.Pages.FloorListItem) => {
                    return { id: item.id, title: item.name };
                  })}
                onChange={(e: T.Form.ListDropdownItem) => {
                  setValues({
                    ...values,
                    floor: e.id as string,
                    floorName: e.title
                  });
                }}
                label="Floor"
                placeholder="Floor"
                defaultValue={{
                  title: values.floorName,
                  id: values.floor
                }}
                message={touched.floor && (errors.floor as any)}
                error={touched.floor && (errors.floor as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
              />
              <Gap size="10px" />

              <TextIput
                type="text"
                name="title"
                onChange={handleChange}
                placeholder="Notification Title"
                label="Notification Title"
                value={values.title}
                message={touched.title && (errors.title as any)}
                error={touched.title && (errors.title as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
              />

              <Gap size="10px" />

              <TextArea
                label="Notification Body"
                name="body"
                onChange={handleChange}
                placeholder="Body"
                value={values.body}
                message={touched.body && (errors.body as any)}
                error={touched.body && (errors.body as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
              />
              <Gap size="20px" />

              <PrimaryButton
                style={{
                  width: '216px',
                  height: 42,
                  borderRadius: 8
                }}
                onClick={handleSubmit}
                // disabled={isSubmitting}
              >
                Send
              </PrimaryButton>
            </S.Div>
          </S.Div>
        )}
      </Formik>
    </>
  );
};

export default DepartmentAndFloorForm;
