import styled from 'styled-components';

import { device } from 'common/constants/mediaQueries';

interface buttonColor {
  buttonColor?: string;
  buttonFont?: string;
}
export const dangerButton = styled.button`
  height: 50px;
  background: #f7ccc9;
  border-radius: 16px;
  padding: 0 30px;
  border: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  color: #ff6464;
  /* margin: 0 3px; */

  span {
    font-weight: 700;
    color: #f0857d;
  }

  &:active {
    /* background: var(--green); */
    outline: double 3px #f7ccc9 !important;
  }

  &:focus {
    outline: double 3px #f7ccc9 !important;
  }

  &:disabled {
    background: #b5bfe2;
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media ${device.tablet} {
    font-size: 13px;
    font-weight: 600;
    padding: 0 10px;
  }
`;

export const dangerButtonLink = styled.div`
  a {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    background: #f7ccc9;
    height: 50px;
    border-radius: 16px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    text-decoration: none;
    /* margin: 0 3px; */
    justify-content: center;
    color: #ff6464;

    span {
      font-weight: 700;
    }

    &:active {
      outline: double 3px #f7ccc9;
    }

    &:focus {
      outline: double 3px #f7ccc9;
    }

    &:disabled {
      opacity: 0.5;
      background: #b5bfe2;
      cursor: not-allowed;
    }

    @media ${device.tablet} {
      font-size: 13px;
      font-weight: 600;
      padding: 0 10px;
    }
  }
`;
export const text = styled.span<buttonColor>`
  font-style: normal;
  line-height: 19px;
  color: ${({ buttonColor }) => (buttonColor ? buttonColor : 'white')};
  font-size: ${({ buttonFont }) => (buttonFont ? buttonFont : '14px')};
`;
