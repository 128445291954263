/* eslint-disable react-hooks/exhaustive-deps */
// import TicketMenuManagementCreate from "./lib/create";
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import ReservationDetailInfo from './lib/info';
import { useActions } from 'common/hooks/useActions';
import AppLoading from 'components/appLoading';

import PrimaryButton from 'components/form/buttons/primaryButton';
import QuestionModal from 'components/modal/question';

import * as S from './styles';

export default function ReservationDetailPage() {
  const navigate = useNavigate();
  const params = useParams();

  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);

  const { reservationDetail, reservationCancel, reservationUpdateTime, reservationConfirm }: any = useTypedSelector(
    (state) => state.Reservation
  );
  const { reservationDetailRequestAction, reservationConfirmRequestAction } = useActions();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    reservationDetailRequestAction(params?.id);
  };

  const handleShowConfirmModal = () => {
    setShowConfirmModal(true);
  };

  const handleCloseConfirmModal = () => {
    setShowConfirmModal(false);
  };

  const handleConfirmReservation = () => {
    reservationConfirmRequestAction(reservationDetail?.data?.id, () => {
      handleCloseConfirmModal();
      navigate('/panel/reservation');
    });
  };

  return (
    <S.Container>
      <S.Head>
        <S.Title>
          {reservationDetail?.data?.subject}
          <S.Status className={reservationDetail?.data?.status}>{reservationDetail?.data?.status}</S.Status>
        </S.Title>
        {reservationDetail?.data?.status === 'active' && !reservationDetail?.data?.confirmed ? (
          <PrimaryButton onClick={handleShowConfirmModal} style={{ height: 38, borderRadius: 8 }}>
            Confirm Reservation
          </PrimaryButton>
        ) : reservationDetail?.data?.status === 'completed' ||
          (reservationDetail?.data?.status === 'active' && reservationDetail?.data?.confirmed) ? (
          <S.Status
            className={reservationDetail?.data?.status}
            style={{ padding: 10, height: 'auto', borderRadius: 8 }}
          >
            Confirmed
          </S.Status>
        ) : reservationDetail?.data?.status === 'canceled' ? (
          <S.Status
            className={reservationDetail?.data?.status}
            style={{ padding: 10, height: 'auto', borderRadius: 8 }}
          >
            Not Confirmed
          </S.Status>
        ) : (
          <></>
        )}
      </S.Head>
      <Gap size="20px" />

      <S.Div>
        <ReservationDetailInfo data={reservationDetail.data} fetchData={() => fetchData()} />
      </S.Div>

      <QuestionModal
        show={showConfirmModal}
        handleClose={handleCloseConfirmModal}
        model="success"
        title="Confirm Reservation"
        text="Do you want to Confirm this Reservation?"
        handleSuccess={handleConfirmReservation}
        successButtonText="Confirm"
      />

      <AppLoading
        loading={
          reservationDetail.loading ||
          reservationCancel.loading ||
          reservationUpdateTime.loading ||
          reservationConfirm.loading
        }
      />
    </S.Container>
  );
}
