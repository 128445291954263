import CafeteriaTeaboyManagementTable from './lib/table';
import Gap from 'components/gap';

import * as S from './styles';
import AppLoading from 'components/appLoading';
import { useTypedSelector } from 'common/hooks/useTypedSelector';

export default function CafeteriaTeaboyManagementPage() {
  const { floorList } = useTypedSelector((state) => state.Floors);
  const { teaboyList, assignToFloor } = useTypedSelector((state) => state.Teaboy);
  const { cafeTeriaStatus } = useTypedSelector((state) => state.Cafeteria);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Teaboy Management</S.Title>
      </S.Head>
      <Gap size="20px" />
      <CafeteriaTeaboyManagementTable data={floorList.data} teaboyList={teaboyList.data} loading={floorList.loading} />

      <AppLoading loading={assignToFloor.loading || teaboyList.loading || cafeTeriaStatus.loading} />
    </S.Container>
  );
}
