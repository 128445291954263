import { ApiCaller } from 'common/helpers/apiCaller';
import convertToQueryString from 'common/helpers/convertToQueryString';

// ------ Teaboy List ------ //
export const teaboysService = (data?: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/tea-boy-list?${convertToQueryString(data)}`
  });
};

// ------ Assign Teaboy To Floor  ------ //
export const teaboyAssignToFloorService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/add-tea-boy`,
    data
  });
};
