import styled from 'styled-components';

export const Content = styled.div``;

export const Title = styled.h2`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: var(--primary-dark);
  margin: 20px 0;
`;

export const Name = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0a214280;
`;

export const Div = styled.div`
  &.active {
    color: var(--primary-green);
  }
  &.inactive {
    color: #ff646480;
  }
  &.captal {
    text-transform: capitalize;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f7f8fc;
  padding: 7px 10px;
  border-radius: 12px;
  margin: 0 15px;
`;

export const FilterButoons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Button = styled.button`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--white);
  background: #8a93ac;
  min-width: 68px;
  border: none;
  height: 28px;
  border-radius: 8px;
  padding: 0 8px;

  &.active {
    background: var(--primary-dark-two);
  }
`;

export const Icon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #616e84;
  line-height: 0;
  border-radius: 40px;
  margin: auto;
  /* padding: 10px; */
`;

export const Image = styled.img`
  max-width: 100%;
  padding: 10px;
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const P = styled.p`
  padding: 100px 20px;
  text-align: center;
`;

export const Status = styled.span`
  position: relative;
  padding-left: 16px;
  &::before {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 20px;
  }
  &.Pending {
    &::before {
      border: 3px solid #d2f4ff;
      background: linear-gradient(0deg, #20caff, #20caff), linear-gradient(0deg, #d2f4ff, #d2f4ff);
    }
  }

  &.Rejected {
    &::before {
      background: #ff6464;
      border: 3px solid #fdf0ef;
    }
  }

  &.Attending {
    &::before {
      background: linear-gradient(0deg, #1dbaaf, #1dbaaf), linear-gradient(0deg, #e9f9f7, #e9f9f7);
      border: 3px solid #e9f9f7;
    }
  }

  &.Cancelled {
    &::before {
      background: linear-gradient(0deg, #ff9c72, #ff9c72), linear-gradient(0deg, #ffece4, #ffece4);
      border: 3px solid #ffece4;
    }
  }
`;
