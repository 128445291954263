import * as types from 'common/constants';

const initialState = {
  notificationsCustom: {
    data: {
      list: [],
      totalPages: 0
    },
    loading: false,
    error: null
  },
  notifications: {
    data: {},
    loading: false,
    error: null
  },
  notificationsCreateCustom: {
    data: {},
    loading: false,
    error: null
  },
  notificationsUpdateRead: {
    data: {},
    loading: false,
    error: null
  }
};

interface Reducer {
  type: string;
  // -- //
  payload: any;
}

const Notifications = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Notifications Custom List cases
     */
    case types.NOTIFICATIONS_CUSTOM.REQUEST:
      return {
        ...state,
        notificationsCustom: {
          data: {
            list: [],
            totalPages: 0
          },
          loading: true,
          error: null
        }
      };
    case types.NOTIFICATIONS_CUSTOM.SUCCESS:
      return {
        ...state,
        notificationsCustom: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.NOTIFICATIONS_CUSTOM.FAILURE:
      return {
        ...state,
        notificationsCustom: {
          data: {
            list: [],
            totalPages: 0
          },
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Notifications  List cases
     */
    case types.NOTIFICATIONS.REQUEST:
      return {
        ...state,
        notifications: {
          data: {},
          loading: true,
          error: null
        }
      };
    case types.NOTIFICATIONS.SUCCESS:
      return {
        ...state,
        notifications: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.NOTIFICATIONS.FAILURE:
      return {
        ...state,
        notifications: {
          data: {},
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Notifications Create Custom cases
     */
    case types.NOTIFICATION_CREATE_CUSTOM.REQUEST:
      return {
        ...state,
        notificationsCreateCustom: {
          data: {},
          loading: true,
          error: null
        }
      };
    case types.NOTIFICATION_CREATE_CUSTOM.SUCCESS:
      return {
        ...state,
        notificationsCreateCustom: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.NOTIFICATION_CREATE_CUSTOM.FAILURE:
      return {
        ...state,
        notificationsCreateCustom: {
          data: {},
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Notifications Update Read cases
     */
    case types.NOTIFICATION_UPDATE_READ.REQUEST:
      return {
        ...state,
        notificationsUpdateRead: {
          data: {},
          loading: true,
          error: null
        }
      };
    case types.NOTIFICATION_UPDATE_READ.SUCCESS:
      return {
        ...state,
        notificationsUpdateRead: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.NOTIFICATION_UPDATE_READ.FAILURE:
      return {
        ...state,
        notificationsUpdateRead: {
          data: {},
          loading: false,
          error: payload
        }
      };

    default:
      return state;
  }
};

export default Notifications;
