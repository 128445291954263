import CafeteriaStatistics from './lib/statistics';
import CafeteriaStatisticsSidebar from './lib/sidebar';
import Gap from 'components/gap';

import * as S from './styles';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';

export default function ActivitiesStatisticsPage() {
  // -- //
  const { chart, menuList }: any = useTypedSelector((state) => state.Cafeteria);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Statistics</S.Title>
      </S.Head>
      <Gap size="20px" />
      <S.Div className="row">
        <S.Div className="col-md-9">
          <CafeteriaStatistics />
        </S.Div>
        <S.Div className="col-md-3">
          <CafeteriaStatisticsSidebar cafeteriaList={menuList.data} />
        </S.Div>
      </S.Div>

      <AppLoading loading={chart.loading} />
    </S.Container>
  );
}
