import UserListTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function UsersPage() {
  const { userList } = useTypedSelector((state: any) => state.Users);
  const { departments } = useTypedSelector((state) => state.Departments);

  return (
    <S.Container>
      <S.Head>
        <S.Title>User List</S.Title>
      </S.Head>
      <Gap size="20px" />
      <UserListTable data={userList.data} departments={departments.data} loading={userList.loading} />

      <AppLoading loading={departments.loading} />
    </S.Container>
  );
}
