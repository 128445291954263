/* eslint-disable react-hooks/exhaustive-deps */
// -- == //
import { Formik } from 'formik';
import { SetStateAction, useEffect } from 'react';

import SecundButton from 'components/form/buttons/secundButton';
import MiniPopUp from 'components/modal/miniPopUp';
import PanelBoxLayout from 'components/layout/panelBox';
import Gap from 'components/gap';
import PrimaryButton from 'components/form/buttons/primaryButton';
import { useActions } from 'common/hooks/useActions';
import DropDown from 'components/form/dropDown';
import { SurveyStatusEnumArray } from 'common/enums';
import DateInput from 'components/form/dateInput';

import * as S from './styles';
import * as T from 'common/types';
import OutlineButton from 'components/form/buttons/outLineButton';

interface IProps {
  setFilter: SetStateAction<any>;
  filter: any;
}

export default function TicketFilterPopup({ setFilter, filter }: IProps) {
  const { departmentsRequestAction } = useActions();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    departmentsRequestAction();
  };

  return (
    <S.Content>
      <MiniPopUp
        popUpStyle={{ width: 245, padding: '0' }}
        buttonNode={
          <SecundButton
            hasIcon={true}
            icon="/icons/stroke/filter.svg"
            iconStyle={{ background: 'none' }}
            style={{ background: '#8A93AC' }}
            colorIcon="var(--white)"
          >
            Filters
          </SecundButton>
        }
      >
        <PanelBoxLayout
          style={{ padding: 15 }}
          titleStyle={{ color: '#2D3748', fontSize: 14 }}
          title="Filters"
          icon="/icons/stroke/filter.svg"
        >
          <Formik
            initialValues={{
              dateFrom: '',
              dateTo: '',
              status: ''
            }}
            // validationSchema={ValidationLogin}
            onSubmit={(values, { setSubmitting }) => {
              setFilter({ ...filter, ...values, page: 1 });
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              // handleChange,
              handleSubmit,
              // isSubmitting,
              resetForm,
              setValues
            }) => (
              <>
                <DropDown
                  label="Status"
                  list={SurveyStatusEnumArray}
                  onChange={(e: T.Form.ListDropdownItem) => {
                    setValues({
                      ...values,
                      status: e.id as any
                    });
                  }}
                  placeholder="Select Status"
                  message={touched.status && (errors.status as any)}
                  error={errors.status as unknown as boolean}
                  inputStyle={{
                    height: 40,
                    borderRadius: 7,
                    fontSize: 12,
                    padding: '0 15px'
                  }}
                  labelStyle={{ fontSize: 12 }}
                />
                <Gap size="20px" />
                <S.Label
                // onClick={() => setOpen(true)}
                >
                  Date
                </S.Label>
                <S.Grid>
                  <DateInput
                    onChange={(date) =>
                      setValues({
                        ...values,
                        dateFrom: date || ''
                        // moment(date).format( "YYYY-MM")
                      })
                    }
                    defaultDate={values.dateFrom}
                    format="YYYY/MM/DD"
                    inputStyle={{
                      height: 40,
                      borderRadius: 7,
                      fontSize: 12,
                      padding: '0 15px'
                    }}
                    placeholder="From"
                    labelStyle={{ fontSize: 12 }}
                    message={touched.dateFrom && (errors.dateFrom as any)}
                    error={touched.dateFrom && (errors.dateFrom as any)}
                  />

                  <Gap size="20px" />

                  <DateInput
                    onChange={(date) =>
                      setValues({
                        ...values,
                        dateTo: date || ''
                        // moment(date).format( "YYYY-MM")
                      })
                    }
                    placeholder="To"
                    defaultDate={values.dateTo}
                    format="YYYY/MM/DD"
                    inputStyle={{
                      height: 40,
                      borderRadius: 7,
                      fontSize: 12,
                      padding: '0 15px'
                    }}
                    labelStyle={{ fontSize: 12 }}
                    message={touched.dateTo && (errors.dateTo as any)}
                    error={touched.dateTo && (errors.dateTo as any)}
                  />
                </S.Grid>

                <Gap size="20px" />

                <PrimaryButton
                  style={{ width: '100%', height: 42, borderRadius: 8 }}
                  onClick={handleSubmit}
                  // disabled={isSubmitting}
                >
                  Filter
                </PrimaryButton>
                <OutlineButton
                  style={{ width: '100%', height: 42, borderRadius: 8 }}
                  onClick={() => {
                    resetForm();
                    handleSubmit();
                  }}
                  // disabled={isSubmitting}
                >
                  Reset
                </OutlineButton>
              </>
            )}
          </Formik>
        </PanelBoxLayout>
      </MiniPopUp>
    </S.Content>
  );
}
