export enum TICKETS {
  REQUEST = 'TICKETS_REQUEST',
  SUCCESS = 'TICKETS_SUCCESS',
  FAILURE = 'TICKETS_FAILURE'
}

export enum TICKET_DETAIL {
  REQUEST = 'TICKET_DETAIL_REQUEST',
  SUCCESS = 'TICKET_DETAIL_SUCCESS',
  FAILURE = 'TICKET_DETAIL_FAILURE'
}

export enum TICKET_CLOSE {
  REQUEST = 'TICKET_CLOSE_REQUEST',
  SUCCESS = 'TICKET_CLOSE_SUCCESS',
  FAILURE = 'TICKET_CLOSE_FAILURE'
}

export enum TICKET_CATEGORIES {
  REQUEST = 'TICKET_CATEGORIES_REQUEST',
  SUCCESS = 'TICKET_CATEGORIES_SUCCESS',
  FAILURE = 'TICKET_CATEGORIES_FAILURE'
}

export enum TICKET_CATEROY_CREATE {
  REQUEST = 'TICKET_CATEROY_CREATE_REQUEST',
  SUCCESS = 'TICKET_CATEROY_CREATE_SUCCESS',
  FAILURE = 'TICKET_CATEROY_CREATE_FAILURE'
}

export enum TICKET_CATEROY_EDIT {
  REQUEST = 'TICKET_CATEROY_EDIT_REQUEST',
  SUCCESS = 'TICKET_CATEROY_EDIT_SUCCESS',
  FAILURE = 'TICKET_CATEROY_EDIT_FAILURE'
}

export enum TICKET_CATEROY_DETAIL {
  REQUEST = 'TICKET_CATEROY_DETAIL_REQUEST',
  SUCCESS = 'TICKET_CATEROY_DETAIL_SUCCESS',
  FAILURE = 'TICKET_CATEROY_DETAIL_FAILURE'
}

export enum TICKET_STATISTICS {
  REQUEST = 'TICKET_STATISTICS_REQUEST',
  SUCCESS = 'TICKET_STATISTICS_SUCCESS',
  FAILURE = 'TICKET_STATISTICS_FAILURE'
}

export enum TICKET_CATEROY_DELETE {
  REQUEST = 'TICKET_CATEROY_DELETE_REQUEST',
  SUCCESS = 'TICKET_CATEROY_DELETE_SUCCESS',
  FAILURE = 'TICKET_CATEROY_DELETE_FAILURE'
}

export enum TICKET_CATEROY_ICON {
  REQUEST = 'TICKET_CATEROY_ICON_REQUEST',
  SUCCESS = 'TICKET_CATEROY_ICON_SUCCESS',
  FAILURE = 'TICKET_CATEROY_ICON_FAILURE'
}

export enum TICKET_SUPPORTER_LIST {
  ADD = 'TICKET_SUPPORTER_LIST_ADD',
  REMOVE = 'TICKET_SUPPORTER_LIST_REMOVE',
  RESET = 'TICKET_SUPPORTER_LIST_RESET'
}
