// -- == //
import { ReactSVG } from 'react-svg';

import AppTable from 'components/appTable';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import DangerButton from 'components/form/buttons/danger';

import * as S from './styles';
import { useActions } from 'common/hooks/useActions';
// import * as T from "common/types";

interface IProps {
  data: any[];
}

export default function CategorySupporterTable({ data }: IProps) {
  const { ticketRemoveSupporterListAction } = useActions();

  return (
    <PanelBoxLayout style={{ padding: 20 }}>
      <S.Head>Current Supporters</S.Head>
      <Gap size="20px" />
      <AppTable
        headers={[
          { id: 1, title: 'ID', dir: 'left' },
          { id: 2, title: 'Supporter Name', dir: 'left' },
          { id: 4, title: 'Job Title', dir: 'left' },
          { id: 6, title: '' }
        ]}
        data={data}
        tdFields={{
          id: (item: any) => <S.Div className="text-left">{item?.id}</S.Div>,

          supporter: (item: any) => <S.Div className="text-left captal">{item.displayName}</S.Div>,
          jobTitle: (item: any) => <S.Div className="text-left captal">{item.jobTitle}</S.Div>,
          management: (item: any) => (
            <S.Grid>
              <DangerButton
                onClick={() => ticketRemoveSupporterListAction(item)}
                style={{
                  height: 40,
                  width: 40,
                  fontSize: 12,
                  background: '#FDF0EF',
                  color: '#FF6464 !important',
                  borderRadius: 40,
                  padding: 0
                }}
              >
                <ReactSVG
                  src="/icons/stroke/trash.svg"
                  beforeInjection={(svg) => {
                    svg.setAttribute('stroke', `#FF6464`);
                  }}
                />
              </DangerButton>
            </S.Grid>
          )
        }}
      />
    </PanelBoxLayout>
  );
}
