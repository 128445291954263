import { ReactSVG } from 'react-svg';
import * as S from './styles';

interface IProps {
  loading: boolean;
  text?: string;
}

const AppLoading: React.FC<IProps> = ({ loading, text }) => {
  if (loading) {
    return (
      <S.loadingContainer className="loading">
        <S.animation>
          <ReactSVG src="/loader.svg" />
        </S.animation>
        {text && <S.loadingText>{text}</S.loadingText>}
      </S.loadingContainer>
    );
  } else return <></>;
};

export default AppLoading;
