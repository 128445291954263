import { ReactSVG } from 'react-svg';
import * as S from './styles';

export default function TicketCounts() {
  return (
    <S.Content>
      <S.Item style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <S.Title>Most issue category</S.Title>
        <S.Value style={{ color: '#8A93AC' }}>It</S.Value>
      </S.Item>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-green.svg" />
          </S.Icon>
          <S.Title>Open</S.Title>
        </S.Div>
        <S.Value style={{ color: '#1DBAAF' }}>14</S.Value>
      </S.Item>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-blue.svg" />
          </S.Icon>
          <S.Title>New</S.Title>
        </S.Div>
        <S.Value style={{ color: '#20CAFF' }}>14</S.Value>
      </S.Item>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-red.svg" />
          </S.Icon>
          <S.Title>Resolve</S.Title>
        </S.Div>
        <S.Value style={{ color: '#FF6464' }}>14</S.Value>
      </S.Item>
    </S.Content>
  );
}
