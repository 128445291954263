import Gap from 'components/gap';
import LoginForm from './lib/form';
import * as S from './styles';
import AppLoading from 'components/appLoading';
import { useTypedSelector } from 'common/hooks/useTypedSelector';

export default function LoginPage() {
  // -- //
  const { login }: any = useTypedSelector((state) => state.Authenticate);

  return (
    <S.AuthContainer>
      <S.FormLogin>
        <S.FormTitle>Login</S.FormTitle>
        <S.SubTitle>Enter your email and password to log in.</S.SubTitle>
        <Gap size="35px" />
        <LoginForm />
      </S.FormLogin>

      <AppLoading loading={login.loading} />
    </S.AuthContainer>
  );
}
