import styled from 'styled-components';

export const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
`;

export const ProfileHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: right;
  cursor: pointer;
`;

export const Image = styled.img`
  border: 1.5px solid #dfe0eb;
  border-radius: 50px;
  width: 40px;
  height: 40px;
`;

export const ProfileTitle = styled.h1`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.20000000298023224px;
  color: #252733;
  margin: 0;
`;

export const Line = styled.span`
  height: 32px;
  width: 1px;
  background: #dfe0eb;
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const Icon = styled.div`
  cursor: pointer;
  position: relative;

  &.newNotif {
    &::before {
      content: '';
      width: 5px;
      height: 5px;
      background: #ff6363;
      border-radius: 5px;
      top: 2px;
      right: 0;
      position: absolute;
    }
  }
`;

export const Ul = styled.ul`
  list-style: none;
`;

export const Li = styled.li`
  a {
    display: block;
    padding: 6px 0;
    color: var(--primary-dark);
  }
`;

export const Notifications = styled.div``;
export const Grid = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;
export const NotifTitle = styled.h4`
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.02em;
  text-align: left;
  color: var(--primary);
  margin: 0;
`;
export const NotifSpan = styled.span`
  width: 58px;
  height: 19px;
  background: var(--primary-green);
  border-radius: 29px;
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
  display: block;
  text-align: center;
  color: var(--white);
`;
export const NotifList = styled.ul`
  list-style: none;
  list-style: none;
  max-height: 400px;
  overflow-y: auto;
  padding: 0 10px;
`;

export const NotifItem = styled.li`
  border-bottom: 1px solid #0000001c;
  padding: 10px 10px;
  display: block;

  &:last-child {
    border-bottom: none;
  }

  &.unRead {
    background: #f7f8fc;
  }
`;

export const NotifItemTitle = styled.h5`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.02em;
`;
export const NotifItemText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.02em;
`;
export const NotifDate = styled.span`
  margin-top: 10px;
  display: block;
`;
