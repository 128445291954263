import styled from 'styled-components';

export const inputContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  /* overflow: hidden; */
  border-radius: 12px;

  &.error {
    input {
      border: 1px solid #eb2b2b !important;
    }
  }

  &.success {
    input {
      border: solid 1px var(--green-dark);
    }
  }
`;

export const input = styled.input`
  padding: 0 40px;
  height: 54px;
  width: -webkit-fill-available;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  background: #fdfdfd;
  border: 1px solid rgba(182, 182, 182, 0.2);
  border-radius: 10px;
  color: var(--dark);
  padding-right: 50px;
  cursor: pointer;

  &:focus ~ .input-label,
  &:not(:placeholder-shown) ~ .input-label {
    top: 0px;
    font-size: 11px;
  }

  &:focus {
    border: 1px solid #367af7;
  }

  &:not(:placeholder-shown) ~ .input-label {
    color: #367af7;
  }

  &:focus {
    outline: none;
  }
`;

export const label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: var(--dark);
  transition: 0.2s ease all;
`;

export const message = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: var(--dark-light);
  padding-left: 10px;

  &.error-message {
    color: var(--danger);
  }

  &.success-message {
    color: var(--green-dark);
  }
`;

export const img = styled.img`
  &.icon {
    /* position: absolute;
    left: 15px;
    top: 0;
    bottom: 0; */
    margin: auto;
    /* width: 100%; */
    height: 100%;
    /* border-radius: 8px; */
  }
`;
export const Icon = styled.div`
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 68px;
  border-radius: 8px;
  border: 1px solid #e1dfec;
  background: #e2e8f0;
`;

export const InputBox = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 220px;
  background: var(--white);
  border: 1px dashed #e1dfec;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  color: #8a93ac;
`;
