import { ReactNode, useEffect, useRef, useState } from 'react';
// import { ReactSVG } from "react-svg";
import * as S from './styles';

interface IProps {
  children: ReactNode;
  icon?: string;
  popUpStyle?: object;
  mobileTheme?: boolean;
  buttonStyle?: object;
  buttonNode?: ReactNode;
}

export default function MiniPopUp({
  // buttonText,
  children,
  // icon,
  popUpStyle,
  mobileTheme,
  buttonStyle,
  buttonNode
}: IProps) {
  const [show, setShow] = useState<boolean>(false);
  // -- //
  const wrapperRef: React.MutableRefObject<any> = useRef(null);

  useEffect(() => {
    // -- //
    function handleClickOutside(event: any) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        !document.getElementsByClassName('ant-picker-panel-container')[0]?.contains(event.target) &&
        !document.getElementsByClassName('ant-picker-panel-container')[1]?.contains(event.target)
      ) {
        setShow(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleShow = () => {
    setShow(true);
  };

  return (
    <S.Content ref={wrapperRef}>
      <S.Button onClick={handleShow} className={mobileTheme ? 'mobile-theme' : ''} style={buttonStyle}>
        {buttonNode}
        {/* {icon && (
          <ReactSVG
            src={icon}
            beforeInjection={(svg) => {
              svg.setAttribute(
                "stroke",
                `${!show ? "#717171" : "var(--primary-green)"}`
              );
            }}
          />
        )}
        <S.ButtonText className={show ? "active" : ""}>
          {buttonText}
        </S.ButtonText> */}
      </S.Button>

      <S.ContentShow className={show ? 'show' : ''} style={popUpStyle}>
        {children}
      </S.ContentShow>
    </S.Content>
  );
}
