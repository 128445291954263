/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import AppTable from 'components/appTable';
import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import { useActions } from 'common/hooks/useActions';
import UserFilterPopup from '../filterForm';
import PaginationApp from 'components/pagination';
import timezoneToDate from 'common/helpers/timezoneToDate';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  data: { list: []; totalPages?: number };
  departments: [];
  loading: boolean;
  areas: any[];
}

export default function EntranceExitTimesTable({ data, departments, loading, areas }: IProps) {
  const { attendancesRequestAction } = useActions();
  const [meta, setMeta] = useState({
    page: 1,
    page_size: 10,
    search: ''
  });

  const fetchData = () => {
    attendancesRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  const handleChangePage = (page: number) => {
    setMeta({ ...meta, page });
  };

  const handleChangePageSize = (page_size: number) => {
    setMeta({ ...meta, page_size });
  };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => setMeta({ ...meta, search: e })}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
            value={meta.search}
          />
          <UserFilterPopup setFilter={setMeta} filter={meta} departments={departments} areas={areas} />
        </S.Head>
        <Gap size="20px" />
        {!loading && !data.list.length ? (
          <S.P>No result was found for this search query </S.P>
        ) : (
          <AppTable
            headers={[
              { id: 1, title: 'ID', dir: 'left' },
              { id: 4, title: 'Name', dir: 'left' },
              { id: 7, title: 'Department', dir: 'left' },
              // { id: 6, title: 'Area' },
              { id: 8, title: 'Date', dir: 'left' },
              { id: 7, title: 'Enter Time', dir: 'left' },
              { id: 9, title: 'Exit Time', dir: 'left' }
            ]}
            data={data.list}
            loading={loading}
            tdFields={{
              id: (item: T.Pages.Attendances) => <S.Div className="text-left">{item?.id}</S.Div>,

              name: (item: T.Pages.Attendances) => (
                <S.Div className="text-left captal">
                  {item.name?.length > 50 ? item.name.substring(0, 50) + ' ... ' : item.name}
                </S.Div>
              ),
              department: (item: T.Pages.Attendances) => <S.Div className="text-left">{item.department}</S.Div>,

              // area: (item: T.Pages.Attendances) => <S.Div className="text-center">{item?.area}</S.Div>,
              date: (item: T.Pages.Attendances) => (
                <S.Div className="text-left">{timezoneToDate(item.createdAt) || ''}</S.Div>
              ),
              enter: (item: T.Pages.Attendances) => {
                return <S.Div className="text-left">{item.firstCheckin || '-'}</S.Div>;
              },
              exit: (item: T.Pages.Attendances) => {
                return <S.Div className="text-left">{item.lastCheckout || '-'}</S.Div>;
              }
            }}
          />
        )}
      </PanelBoxLayout>
      {data.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.page_size * data.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={data.totalPages}
          pageSize={meta.page_size}
          handleChangeTake={handleChangePageSize}
        />
      ) : (
        <></>
      )}
    </S.Content>
  );
}
