// -- == //
import { Pagination } from 'antd';
import { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import * as S from './styles';

interface IProps {
  tdFields: any;
  headers: IPropsHeader[];
  data: any[];
  style?: object;
  totlaItems?: number;
  pagination?: boolean;
  headerStyle?: 'two' | 'one';
  headerColor?: string;
  loading?: boolean;
}

interface IPropsHeader {
  id: string | number;
  title: string;
  icon?: string;
  dir?: 'left' | 'right';
  width?: string | number;
  padding?: string;
}

const AppTable: FC<IProps> = ({
  tdFields,
  data,
  headers,
  style,
  pagination,
  totlaItems,
  headerStyle = 'one',
  headerColor = '#8A93AC',
  loading
}) => {
  const [page, setPage] = useState<number>(1);

  const handleChnage = (newPage: number) => {
    setPage(newPage);
  };

  return (
    <>
      <S.TableCon>
        {loading ? (
          <S.TableLoading>
            <ReactSVG src="/loader.svg" />
          </S.TableLoading>
        ) : (
          <></>
        )}
        <S.Table style={style}>
          <S.Thead className={headerStyle}>
            <S.Tr>
              {headers?.map((item: IPropsHeader, index) => (
                <S.Th key={index} style={{ width: item.width, padding: item.padding }}>
                  <S.HeaderTh
                    key={item.id}
                    style={{
                      color: headerColor,
                      justifyContent: item.dir || 'center'
                    }}
                  >
                    {item.icon ? (
                      <ReactSVG
                        src={item.icon}
                        beforeInjection={(svg) => {
                          svg.setAttribute('stroke', `${headerColor}`);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {item.title}
                  </S.HeaderTh>
                </S.Th>
              ))}
            </S.Tr>
          </S.Thead>
          <S.Tbody>
            {data?.map((item: any, index) => (
              <S.Tr key={index}>
                {Object.values(tdFields)?.map((field: any, index2) => <S.Td key={`td-${index2}`}>{field(item)}</S.Td>)}
              </S.Tr>
            ))}
          </S.Tbody>
        </S.Table>
      </S.TableCon>
      {pagination ? (
        <S.Pagination>
          <Pagination current={page} onChange={handleChnage} total={totlaItems} showSizeChanger={false} />
        </S.Pagination>
      ) : (
        <></>
      )}
    </>
  );
};

export default AppTable;
