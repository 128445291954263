import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import * as S from './styles';
import Gap from 'components/gap';

export default function AuthLayout() {
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/auth') history('/auth/login');
  }, [history, location.pathname]);

  return (
    <S.Container>
      <S.PagesContent>
        <Outlet />
      </S.PagesContent>
      <S.LayoutContent>
        <S.ColorBack />
        <S.Vector1 src="/images/vector-light1.png" />
        <S.Vector2 src="/images/vector-light2.png" />
        <S.LogoContent>
          <S.Image src="/images/full-logo.svg" />
          <Gap size="40px" />
          <S.Image src="/images/lock-icon.svg" />
          <Gap size="20px" />
          <S.Title>WELCOME TO TDF</S.Title>
        </S.LogoContent>
      </S.LayoutContent>
    </S.Container>
  );
}
