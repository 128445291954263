// -- == //
import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'common/helpers/toastConfig';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';

import * as types from 'common/constants';
// import * as T from "common/types";
import * as services from 'common/services';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Event list actions
 */
export const newsRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.NEWS.REQUEST,
        payload: {}
      });

      const response: any = await services.newsService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(newsSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(newsFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(newsFailureAction(error));
      throw error;
    }
  };
};

export const newsSuccessAction = (data: object) => {
  return {
    type: types.NEWS.SUCCESS,
    payload: data
  };
};

export const newsFailureAction = (error: object) => {
  return {
    type: types.NEWS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * News Create actions
 */
export const newsCreateRequestAction = (data: any, callBack: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.NEWS_CREATE.REQUEST,
        payload: {}
      });

      const response: any = await services.newsCreateService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(newsCreateSuccessAction(response.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(newsCreateFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(newsCreateFailureAction(error));
      throw error;
    }
  };
};

export const newsCreateSuccessAction = (data: object) => {
  return {
    type: types.NEWS_CREATE.SUCCESS,
    payload: data
  };
};

export const newsCreateFailureAction = (error: object) => {
  return {
    type: types.NEWS_CREATE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * News Update actions
 */
export const newsUpdateRequestAction = (data: any, callBack: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.NEWS_UPDATE.REQUEST,
        payload: {}
      });

      const response: any = await services.newsUpdateService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(newsUpdateSuccessAction(response.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(newsUpdateFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(newsUpdateFailureAction(error));
      throw error;
    }
  };
};

export const newsUpdateSuccessAction = (data: object) => {
  return {
    type: types.NEWS_UPDATE.SUCCESS,
    payload: data
  };
};

export const newsUpdateFailureAction = (error: object) => {
  return {
    type: types.NEWS_UPDATE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * News Upload actions
 */
export const newsUploadRequestAction = (data: any, callBack: (fileName: string) => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.NEWS_UPLOAD.REQUEST,
        payload: {}
      });

      const formData = new FormData();
      formData.append('image', data.image);

      const response: any = await services.newsUploadService(formData);

      if (response.status === 200 || response.status === 201) {
        dispatch(newsUploadSuccessAction(response.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack(response.data?.data?.fileNameOnly);
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(newsUploadFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(newsUploadFailureAction(error));
      throw error;
    }
  };
};

export const newsUploadSuccessAction = (data: object) => {
  return {
    type: types.NEWS_UPLOAD.SUCCESS,
    payload: data
  };
};

export const newsUploadFailureAction = (error: object) => {
  return {
    type: types.NEWS_UPLOAD.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * News Update Upload actions
 */
export const newsUpdateUploadRequestAction = (data: any, callBack: (fileName: string) => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.NEWS_UPDATE_UPLOAD.REQUEST,
        payload: {}
      });

      const formData = new FormData();
      formData.append('image', data.image);
      formData.append('id', data.id);

      const response: any = await services.newsUpdateUploadService(formData);

      if (response.status === 200 || response.status === 201) {
        dispatch(newsUpdateUploadSuccessAction(response.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack(response.data?.data?.fileNameOnly);
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(newsUpdateUploadFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(newsUpdateUploadFailureAction(error));
      throw error;
    }
  };
};

export const newsUpdateUploadSuccessAction = (data: object) => {
  return {
    type: types.NEWS_UPDATE_UPLOAD.SUCCESS,
    payload: data
  };
};

export const newsUpdateUploadFailureAction = (error: object) => {
  return {
    type: types.NEWS_UPDATE_UPLOAD.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * News Detail actions
 */
export const newsDetailRequestAction = (id?: string) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.NEWS_DETAIL.REQUEST,
        payload: {}
      });

      const response: any = await services.newsDetailService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(newsDetailSuccessAction(response.data.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(newsDetailFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(newsDetailFailureAction(error));
      throw error;
    }
  };
};

export const newsDetailSuccessAction = (data: object) => {
  return {
    type: types.NEWS_DETAIL.SUCCESS,
    payload: data
  };
};

export const newsDetailFailureAction = (error: object) => {
  return {
    type: types.NEWS_DETAIL.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
