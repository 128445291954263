import { FC } from 'react';

import * as S from './styles';
import * as T from 'common/types';
import { ReactSVG } from 'react-svg';

const PanelBoxLayout: FC<T.Layout.PanelBox> = ({
  children,
  title,
  icon,
  info,
  contentStyle,
  titleStyle,
  id,
  style,
  afterTitle,
  headStyle
}) => {
  return (
    <S.BoxContainer style={style} id={id}>
      {icon || title || info ? (
        <S.Head style={headStyle}>
          <S.TitleCon>
            {icon && (
              <S.Icon className="flex-center">
                <ReactSVG
                  src={icon}
                  beforeInjection={(svg) => {
                    svg.setAttribute('stroke', `var(--primary-dark)`);
                    svg.setAttribute('height', `20px`);
                    svg.setAttribute('width', `20px`);
                  }}
                />
              </S.Icon>
            )}
            {title && <S.Title style={titleStyle}>{title}</S.Title>}
            {afterTitle && afterTitle}
          </S.TitleCon>
          <S.Div>{info ? info : <></>}</S.Div>
        </S.Head>
      ) : (
        <></>
      )}
      <S.Content style={contentStyle}>{children}</S.Content>
    </S.BoxContainer>
  );
};

export default PanelBoxLayout;
