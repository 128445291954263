import PanelBoxLayout from 'components/layout/panelBox';
// import PaginationApp from "components/pagination";

// import * as T from "common/types";
import * as S from './styles';
import DangerButton from 'components/form/buttons/danger';
import Gap from 'components/gap';
// import RateStar from "components/rateStar";
import { useActions } from 'common/hooks/useActions';
import QuestionModal from 'components/modal/question';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import dateToString from 'common/helpers/dateToString';
import RateStar from 'components/rateStar';

interface IProps {
  data: any;
}

export default function TicketDetailInfo({ data }: IProps) {
  const { ticketCloseRequestAction } = useActions();
  const [showCloseModal, setShowCloseModal] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleShowCloseTicket = () => {
    setShowCloseModal(true);
  };

  const handleHideCloseTicket = () => {
    setShowCloseModal(false);
  };

  const handleCloseTicket = () => {
    ticketCloseRequestAction(data.id, () => {
      handleHideCloseTicket();
      navigate('/panel/ticket/tickets');
    });
  };

  return (
    <S.Content>
      <PanelBoxLayout style={{ padding: '20px 30px' }}>
        <S.Head>Tickets General Details</S.Head>
        <Gap size="10px" />
        <S.Detail>
          <S.Item>
            <S.MainTitle>Requester</S.MainTitle>
            <S.Grid>
              <S.Icon src={data?.user?.profilePicture || '/images/default-avatar.jpg'} />
              <S.Text>{data?.user?.displayName}</S.Text>
            </S.Grid>
          </S.Item>
          {/* <S.Item>
            <S.Title>Department</S.Title>
            <S.Text>Department</S.Text>
          </S.Item> */}
          <S.Item>
            <S.Title>Employee ID</S.Title>
            <S.Text>{data?.user?.employeeId}</S.Text>
          </S.Item>
          <S.Item>
            <S.Title>Office Location</S.Title>
            <S.Text>{data?.user?.officeLocation || '-'}</S.Text>
          </S.Item>
          <S.Item>
            <S.Title>Job Title</S.Title>
            <S.Text>{data?.user?.jobTitle || '-'}</S.Text>
          </S.Item>
          <S.Item>
            <S.Title>Department</S.Title>
            <S.Text>{data?.user?.department || '-'}</S.Text>
          </S.Item>
          <S.Item>
            <S.Title>Email</S.Title>
            <S.Text>{data?.user?.mail || '-'}</S.Text>
          </S.Item>
          <S.Item style={{ borderBottom: 'none' }}>
            <S.Title>Phone Number</S.Title>
            <S.Text>{data?.mobilePhone || '-'}</S.Text>
          </S.Item>
          <Gap size="15px" />
          <S.MainTitle>Ticket Details</S.MainTitle>
          <S.Item>
            <S.Title>Title</S.Title>
            <S.Text>{data?.title || '-'}</S.Text>
          </S.Item>
          <S.Item>
            <S.Title>Type</S.Title>
            <S.Text>{data?.type || '-'}</S.Text>
          </S.Item>
          <S.Item>
            <S.Title>Creation Date</S.Title>
            <S.Text>{dateToString(data?.createdAt)}</S.Text>
          </S.Item>
          <S.Item>
            <S.Title>Ticket Id</S.Title>
            <S.Text>{data?.id}</S.Text>
          </S.Item>
          <S.Item>
            <S.Title>Ticket Location</S.Title>
            <S.Text>{data?.requestLocation || '-'}</S.Text>
          </S.Item>
          {data?.rate?.length ? (
            <S.Item style={{ borderBottom: 'none' }}>
              <S.Title>Rate</S.Title>
              <S.Text>
                <RateStar rate={data?.rate[0]?.rate} />
              </S.Text>
            </S.Item>
          ) : (
            <></>
          )}
        </S.Detail>
        <Gap size="20px" />
        <S.Div style={{ textAlign: 'right' }}>
          <DangerButton
            style={{
              // background: "#DCF6F0",
              // color: "var(--primary-green)",
              fontSize: 14,
              fontWeight: 400,
              borderRadius: 8,
              height: 36,
              width: 208,
              marginLeft: 'auto'
            }}
            onClick={handleShowCloseTicket}
            disabled={data?.status === 'active' ? false : true}
          >
            {data?.status === 'active' ? 'Close Ticket' : 'Closed'}
          </DangerButton>
        </S.Div>
      </PanelBoxLayout>

      <QuestionModal
        show={showCloseModal}
        handleClose={handleHideCloseTicket}
        model="delete"
        title="Close Ticket"
        text="Do you want to Close this Ticket?"
        handleSuccess={handleCloseTicket}
        successButtonText="Close"
      />
    </S.Content>
  );
}
