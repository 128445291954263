// -- == //
import { Formik } from 'formik';
import React from 'react';

import { ValidationCreateMenu, ValidationEditMenu } from 'common/validations';
import PrimaryButton from 'components/form/buttons/primaryButton';
import TextIput from 'components/form/textInput';
import Gap from 'components/gap';
import { useActions } from 'common/hooks/useActions';
import DropDown from 'components/form/dropDown';
import UploadInput from 'components/form/uploadInput';

import { menuCategoryArray } from 'common/enums';
import CheckBox from 'components/form/checkbox';

import * as S from '../../styles';
import * as T from 'common/types';
interface IProps {
  data?: T.Pages.CafeteriaListItem;
  closeModal?: () => void;
  fetchData?: () => void;
}

const CafeteriaCreateForm: React.FC<IProps> = ({ data, closeModal, fetchData }) => {
  const {
    cafeteriaCreateMenuManagementRequestAction,
    cafeteriaEditMenuManagementRequestAction,
    cafeteriaUploadRequestAction
  } = useActions();

  const renderCategoryName = (cat: string) => {
    let newText: string = '';
    // eslint-disable-next-line array-callback-return
    menuCategoryArray.map((item: any) => {
      if (item.id === cat) newText = item.title;
    });
    return newText;
  };

  return (
    <Formik
      initialValues={{
        title: data?.title || '',
        status: data?.status || false,
        category: data?.category || '',
        id: data?.id || undefined,
        image: undefined,
        fileUrl: data?.image || '',
        imageName: ''
      }}
      enableReinitialize
      validationSchema={data?.id ? ValidationEditMenu : ValidationCreateMenu}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (data?.id)
          cafeteriaEditMenuManagementRequestAction(
            {
              title: values.title,
              status: values?.status,
              id: data?.id,
              imageName: values.imageName
            },
            () => {
              closeModal && closeModal();
              resetForm();
              fetchData && fetchData();
            }
          );
        else
          cafeteriaCreateMenuManagementRequestAction(
            {
              category: values?.category,
              title: values.title,
              status: values?.status,
              image: values.image
            },
            () => {
              closeModal && closeModal();
              resetForm();
              fetchData && fetchData();
            }
          );
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        // isSubmitting,
        setValues
      }) => (
        <>
          {!data?.id ? (
            <>
              <DropDown
                list={menuCategoryArray}
                onChange={(e: T.Form.ListDropdownItem) => setValues({ ...values, category: e?.id as any })}
                key={values.category}
                placeholder="Category"
                defaultValue={{
                  id: values.category,
                  title: renderCategoryName(values.category)
                }}
                message={touched.category && errors.category}
                error={touched.category && (errors.category as unknown as boolean)}
                inputStyle={{ height: 40, borderRadius: 7, fontSize: 14 }}
              />

              <Gap size="10px" />
            </>
          ) : (
            <></>
          )}

          <TextIput
            type="text"
            name="title"
            onChange={handleChange}
            placeholder="Title"
            value={values.title}
            message={touched.title && errors.title}
            error={touched.title && (errors.title as unknown as boolean)}
            inputStyle={{ height: 40, borderRadius: 7 }}
          />

          <Gap size="10px" />

          <UploadInput
            placeholder="Choose icon"
            inputStyle={{ height: 56, borderRadius: 7 }}
            onChange={(e: T.Form.FileUpload) => {
              if (data?.id) {
                cafeteriaUploadRequestAction({ image: e?.file }, (fileNameOnly: string) => {
                  setValues({
                    ...values,
                    imageName: fileNameOnly,
                    fileUrl: e.dataURL
                  });
                });
              } else {
                setValues({
                  ...values,
                  fileUrl: e.dataURL,
                  image: e?.file as any
                });
              }
            }}
            message={touched.image && (errors.image as any)}
            error={touched.image && (errors.image as any)}
            key={data?.id}
            defaultImage={values.fileUrl}
            value={{ file: values.image, dataURL: values.fileUrl }}
          />

          <Gap size="10px" />
          <S.Div style={{ width: '100%' }}>
            <CheckBox
              text="Status"
              checked={values.status}
              onChange={(status: boolean) => setValues({ ...values, status })}
              theme="two"
            />
          </S.Div>
          <Gap size="10px" />
          <PrimaryButton
            style={{ width: '100%', height: 42, borderRadius: 8 }}
            onClick={handleSubmit}
            // disabled={isSubmitting}
          >
            Save
          </PrimaryButton>
        </>
      )}
    </Formik>
  );
};

export default CafeteriaCreateForm;
