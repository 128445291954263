/* eslint-disable react-hooks/exhaustive-deps */
import PanelBoxLayout from 'components/layout/panelBox';

import { ReactSVG } from 'react-svg';
import { Divider } from 'antd';

import * as S from './styles';
import { Column } from '@ant-design/charts';
import PrimaryButton from 'components/form/buttons/primaryButton';
import Gap from 'components/gap';
import { useActions } from 'common/hooks/useActions';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import convertMsToMin from 'common/helpers/convertMsToMin';

export default function DashboardCafeteria() {
  const { dashboardCafeteriaRequestAction } = useActions();
  const { cafeteria } = useTypedSelector((state: any) => state.Dashboard);

  useEffect(() => {
    dashboardCafeteriaRequestAction({});
  }, []);

  const config = {
    data: cafeteria?.data?.tea_room_order_chart
      ? cafeteria?.data?.tea_room_order_chart?.map((item: any) => ({
          ...item,
          totalcount: Number(item.totalcount),
          name: `${item.name}`
        }))
      : [],
    xField: 'name',
    yField: 'totalcount',
    color: '#1DBAAF',
    tooltip: {
      customContent: (title: string, data: any) => {
        return (
          <S.Tooltip>
            <S.TooltipGrid>
              <span>Number of Orders: </span>
              <span style={{ color: '#1DBAAF' }}>{data[0]?.data?.totalcount}</span>
            </S.TooltipGrid>
            <Gap size="8px" />
            <S.TooltipGrid>
              <span>Average Delivery Time: </span>
              <span style={{ color: '#1DBAAF' }}>
                {convertMsToMin(Number(data[0]?.data?.avgresponsetime), 'Min') || 'N/A'}
              </span>
            </S.TooltipGrid>
          </S.Tooltip>
        );
      }
    },
    meta: {
      name: {
        alias: 'name'
      },
      totalcount: {
        alias: 'Total count'
      }
    },
    minColumnWidth: 20,
    maxColumnWidth: 20
  };

  return (
    <S.Container>
      <PanelBoxLayout
        title="Cafeteria"
        afterTitle={
          <S.Div style={{ display: 'flex', gap: 8, marginLeft: 6 }}>
            <ReactSVG src="/icons/star-o-2.svg" />
            <S.Span>{cafeteria?.data?.service_rate_average}</S.Span>
          </S.Div>
        }
        headStyle={{ marginBottom: 0 }}
        info={
          <S.Info>
            <ReactSVG
              src="/icons/fill/timer.svg"
              beforeInjection={(svg) => {
                svg.setAttribute('fill', `#2B3674`);
              }}
            />
            <S.Text>Average Delivery Time:</S.Text>
            <S.Span>{convertMsToMin(cafeteria.data?.delivered_response_average, 'Min') || 'N/A'}</S.Span>
          </S.Info>
        }
        style={{ padding: 16 }}
        titleStyle={{
          fontSize: 24,
          fontWeight: 700,
          color: `var(--primary-dark-two)`
        }}
      >
        <Divider style={{ margin: '20px 0' }} />
        <Column {...config} height={380} key={cafeteria?.data?.tea_room_order_chart?.length} />
        <Gap size="20px" />
        <Link to={'/panel/cafeteria/statistics'}>
          <PrimaryButton style={{ margin: 'auto', borderRadius: 8 }}>
            Number of Order Per Items
            <ReactSVG
              src="/icons/fill/arrow-right.svg"
              beforeInjection={(svg) => {
                svg.setAttribute('fill', `#fff`);
              }}
            />
          </PrimaryButton>
        </Link>
      </PanelBoxLayout>
    </S.Container>
  );
}
