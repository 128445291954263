import React from 'react';
import * as S from './styles';

interface IProps {
  label?: string;
  disabled?: boolean | undefined;
  // -- //
  onChange?: (val: any) => void;
  style?: object;
  id?: string;
  error?: boolean;
  success?: boolean;
  message?: string;
  height?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  labelStyle?: object;
  inputStyle?: object;
}

const TextArea: React.FC<IProps> = ({
  label,
  id,
  disabled,
  style,
  error,
  success,
  message,
  height,
  onChange,
  name,
  placeholder,
  labelStyle,
  value,
  inputStyle
}) => {
  const randomId = Math.random() * 200;

  return (
    <S.inputContainer style={style} className={error ? 'error' : success ? 'success' : ''}>
      <S.label style={labelStyle} className="input-label" htmlFor={id ? id : `${randomId}`}>
        {label}
      </S.label>
      <S.textarea
        name={name}
        onChange={onChange}
        id={id ? id : `${randomId}`}
        disabled={disabled}
        placeholder={placeholder}
        style={{ ...inputStyle, height: height ? height : 160 }}
        value={value}
      />
      {message ? (
        <S.message className={error ? 'error-message' : success ? 'success-message' : ''}>{message}</S.message>
      ) : (
        <></>
      )}
    </S.inputContainer>
  );
};

export default TextArea;
