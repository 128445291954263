import { Formik } from 'formik';
import * as S from './styles';
import DateInput from 'components/form/dateInput';
import Gap from 'components/gap';
import PrimaryButton from 'components/form/buttons/primaryButton';
import DangerButton from 'components/form/buttons/danger';
import { useActions } from 'common/hooks/useActions';
import dayjs from 'dayjs';
import * as T from 'common/types';
import { ValidationReservationUpdateTime } from 'common/validations';

interface IProps {
  data: T.Pages.ReservationDetail;
  handleClose: () => void;
  fetchData: () => void;
}

export default function ChangeDateTimeForm({ data, handleClose, fetchData }: IProps) {
  const { reservationUpdateTimeRequestAction } = useActions();

  return (
    <div>
      <S.Head>Change Date and Time</S.Head>
      <Gap size="20px" />
      <Formik
        initialValues={{
          startDate: data?.startDate || '',
          endDate: data?.endDate || ''
        }}
        enableReinitialize
        validationSchema={ValidationReservationUpdateTime}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          if (new Date(values.startDate) > new Date(values.endDate)) {
            setErrors({ endDate: 'End date cannot be smaller than the start date' });
            setSubmitting(false);
            return;
          }
          reservationUpdateTimeRequestAction(data.id, values, () => {
            handleClose();
            fetchData();
          });
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleSubmit, isSubmitting, setValues }) => (
          <S.Div className="row">
            <S.Div className="col-lg-6">
              <DateInput
                label="Start Time"
                onChange={(e) => {
                  setValues({
                    ...values,
                    startDate: e
                  });
                }}
                inputStyle={{ height: 50 }}
                showTime={{ format: 'HH:mm' }}
                defaultDate={values.startDate && dayjs(values.startDate)}
                // key={values.startDate}
                format="YYYY MMMM D HH:mm"
                message={touched.startDate && (errors.startDate as any)}
                error={touched.startDate && (errors.startDate as unknown as boolean)}
              />
            </S.Div>
            <S.Div className="col-lg-6">
              <DateInput
                label="End Time"
                onChange={(e) => {
                  setValues({
                    ...values,
                    endDate: e
                  });
                }}
                inputStyle={{ height: 50 }}
                showTime={{ format: 'HH:mm' }}
                defaultDate={values.endDate && dayjs(values.endDate)}
                // key={values.endDate}
                format="YYYY MMMM D HH:mm"
                message={touched.endDate && (errors.endDate as any)}
                error={touched.endDate && (errors.endDate as unknown as boolean)}
              />
            </S.Div>

            <S.GridButton>
              <PrimaryButton
                style={{
                  background: '#E9F9F7',
                  color: '#1DBAAF',
                  fontSize: 14,
                  fontWeight: 400,
                  borderRadius: 8,
                  height: 36,
                  width: '100%'
                }}
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                Change Date and Time
              </PrimaryButton>
              <DangerButton
                style={{
                  background: '#FDF0EF',
                  // color: "var(--primary-green)",
                  fontSize: 14,
                  fontWeight: 400,
                  borderRadius: 8,
                  height: 36,
                  width: '100%'
                }}
                onClick={handleClose}
              >
                Cancel
              </DangerButton>
            </S.GridButton>
          </S.Div>
        )}
      </Formik>
    </div>
  );
}
