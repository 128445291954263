import FloorCreate from './lib/create';
import FloorsTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function FloorsPage() {
  const { floorList, editFloor, createFloor, deleteFloor }: any = useTypedSelector((state) => state.Floors);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Floors</S.Title>
        <FloorCreate />
      </S.Head>
      <Gap size="20px" />
      <FloorsTable menuList={floorList.data} loading={floorList.loading} />

      <AppLoading loading={editFloor.loading || createFloor.loading || deleteFloor.loading} />
    </S.Container>
  );
}
