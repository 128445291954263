import React, { ReactNode } from 'react';
import * as S from './styles';

interface IProps {
  label?: string;
  disabled?: boolean | undefined;
  // -- //
  onChange?: (val: any) => void;
  style?: object;
  id?: string;
  error?: boolean;
  success?: boolean;
  message?: string | boolean;
  height?: string;
  type?: string;
  name?: string;
  onBlur?: (val: object) => void;
  onFocus?: (val: object) => void;
  value?: string;
  icon?: ReactNode;
  inputStyle?: object;
  labelStyle?: object;
  readOnly?: boolean;
  inputSize?: number;
  maxLength?: number;
  // -- //
  refrence?: any;
  className?: string;
  placeholder?: string;
  defaultValue?: string;
  arrow?: boolean;
}

const TextInput: React.FC<IProps> = ({
  label,
  id,
  disabled,
  style,
  error,
  success,
  message,
  height,
  onChange,
  type,
  name,
  onBlur,
  value,
  icon,
  inputStyle,
  readOnly,
  inputSize,
  maxLength,
  className,
  refrence,
  onFocus,
  placeholder,
  labelStyle,
  defaultValue,
  arrow
}) => {
  const randomId = Math.random() * 200;

  return (
    <S.inputContainer
      style={style}
      className={error ? `error ${className}` : success ? `success ${className}` : `${className}`}
    >
      {label ? (
        <S.label
          className="input-label"
          style={{ ...labelStyle, left: icon ? '35px' : '15px' }}
          htmlFor={id ? id : `${randomId}`}
        >
          {label}
        </S.label>
      ) : (
        <></>
      )}
      <S.InputBox>
        <S.input
          ref={refrence}
          maxLength={maxLength}
          size={inputSize}
          readOnly={readOnly}
          name={name}
          type={type}
          onChange={onChange}
          id={id ? id : `${randomId}`}
          disabled={disabled}
          placeholder={placeholder}
          style={{
            height: height ? height : '50px',
            lineHeight: height ? height : 'auto',
            paddingRight: icon ? '35px' : '10px',
            ...inputStyle
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          defaultValue={defaultValue}
          arrow={arrow}
        />
        {icon ? <S.Icon>{icon}</S.Icon> : <></>}
      </S.InputBox>
      {message ? (
        <S.message className={error ? 'error-message' : success ? 'success-message' : ''}>{message}</S.message>
      ) : (
        <></>
      )}
    </S.inputContainer>
  );
};

export default TextInput;
