// -- == //
import { Column, Line } from '@ant-design/plots';

import * as S from './styles';
import PanelBoxLayout from 'components/layout/panelBox';
// import * as T from "common/types";

interface IProps {
  data: any[];
  totalOrders?: any[];
  // loading?: boolean;
}

export default function CafeteriaStatistics({ data }: IProps) {
  function convertData() {
    const convertedData: any[] = [];
    data?.forEach((dayData: any) => {
      // console.log(checkAllFields(dayData), "========", dayData.day);

      if (checkAllFields(dayData))
        Object.keys(dayData).forEach((key) => {
          if (key !== 'day') {
            const item = key;
            const day = dayData.day.toString();
            const value = dayData[key];
            // console.log(value);
            // if (value)
            const existingRecordIndex = convertedData.findIndex((record) => {
              return record.item === item && record.day === day;
            });

            if (existingRecordIndex !== -1) {
              convertedData[existingRecordIndex].value += value;
            } else {
              convertedData.push({
                item,
                day,
                value,
                index: convertedData.length + 1,
                datum_index: convertedData.length + 1
              });
            }
          }
        });
      // else {
      //   convertedData.push({
      //     // item: "",
      //     day: dayData.day,
      //     value: 0,
      //   });
      // }
    });

    return convertedData.sort((a, b) => a.day - b.day);
  }

  const checkAllFields = (obj: any) => {
    let flag = 0;

    Object.keys(obj).forEach((key) => {
      // console.log(obj[key]);

      // console.log(obj[key]);

      if (obj[key] !== 0 && key !== 'day') {
        flag += 1;
      }
    });
    return flag;
  };

  const newData = convertData();

  const configColumn = {
    data: newData,
    xField: 'day',
    yField: 'value',
    seriesField: 'item',
    isGroup: true,
    // xAxis: {
    //   type: 'time',
    // },
    // columnStyle: {
    //   radius: [20, 20, 0, 0],
    // },
    // columnBackground: {
    //   style: {
    //     fill: "#000",
    //     fillOpacity: 0,
    //   },
    // },
    legend: {
      position: 'bottom' as any,
      flipPage: true,
      maxRow: 2,
      pageNavigator: {
        marker: {
          style: {
            fill: 'rgba(0,0,0,0.65)'
          }
        }
      },
      customElements: () => ''
    },
    // interactions: [
    //   {
    //     type: "element-highlight-by-color",
    //   },
    //   {
    //     type: "element-link",
    //   },
    // ],

    tooltip: {
      customContent: (title: string, items: any[]) => {
        return (
          <>
            <h5 style={{ marginTop: 16, fontSize: 14 }}>Day {title}</h5>
            <ul style={{ paddingLeft: 0, width: 'max-content' }}>
              {items
                ?.sort((a, b) => b.value - a.value)
                .map((itemOne, index) => {
                  const { name, value, color } = itemOne;
                  return (
                    <li
                      key={itemOne.day}
                      className="g2-tooltip-list-item"
                      data-index={index}
                      style={{
                        marginBottom: 4,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                      <span
                        style={{
                          display: 'inline-flex',
                          flex: 1,
                          justifyContent: 'space-between'
                        }}
                      >
                        <span style={{ marginRight: 16 }}>{name}:</span>
                        <span className="g2-tooltip-list-item-value">{value}</span>
                      </span>
                    </li>
                  );
                })}
            </ul>
          </>
        );
      }
    }
  };
  // const configLine = {
  //   data: newData,
  //   xField: "day",
  //   yField: "value",
  //   seriesField: "item",
  //   legend: {
  //     position: "bottom" as any,
  //   },
  // };

  const configLine = {
    data: newData,
    xField: 'day',
    yField: 'value',
    seriesField: 'item',
    // yAxis: {
    //   label: {
    //     formatter: (v: any) =>
    //       `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
    //   },
    // },
    // xAxis: {
    //   type: 'day',

    // },
    legend: {
      position: 'bottom' as any,
      flipPage: true,
      maxRow: 2,
      pageNavigator: {
        marker: {
          style: {
            fill: 'rgba(0,0,0,0.65)'
          }
        }
      }
    },
    interactions: [
      {
        type: 'element-highlight-by-color'
      },
      {
        type: 'element-link'
      }
    ],
    // tooltip: false,
    tooltip: {
      customContent: (title: string, items: any[]) => {
        return (
          <>
            <h5 style={{ marginTop: 16, fontSize: 14 }}>Day {title}</h5>
            <ul style={{ paddingLeft: 0, width: 'max-content' }}>
              {items
                ?.sort((a, b) => b.value - a.value)
                .map((itemOne, index) => {
                  const { name, value, color } = itemOne;
                  return (
                    <li
                      key={itemOne.day}
                      className="g2-tooltip-list-item"
                      data-index={index}
                      style={{
                        marginBottom: 4,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                    >
                      <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                      <span
                        style={{
                          display: 'inline-flex',
                          flex: 1,
                          justifyContent: 'space-between'
                        }}
                      >
                        <span style={{ marginRight: 16 }}>{name}:</span>
                        <span className="g2-tooltip-list-item-value">{value}</span>
                      </span>
                    </li>
                  );
                })}
            </ul>
          </>
        );
      }
    }
  };

  return (
    <S.Content>
      <PanelBoxLayout title="Order Amounts Line" style={{ padding: '20px 30px', position: 'relative' }}>
        {/* <S.Text>no data</S.Text> */}
        <Line {...configLine} />
      </PanelBoxLayout>
      <PanelBoxLayout title="Order Amounts Column" style={{ padding: '20px 30px' }}>
        <Column {...configColumn} />
      </PanelBoxLayout>
    </S.Content>
  );
}
