/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import AppTable from 'components/appTable';
import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import { useActions } from 'common/hooks/useActions';
import EventFilterPopup from '../filterForm';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import PaginationApp from 'components/pagination';

import * as S from './styles';
import * as T from 'common/types';
import dateToString from 'common/helpers/dateToString';

interface IProps {
  data: { list: []; totalPages?: number };
  loading: boolean;
}

export default function NewsTable({ data, loading }: IProps) {
  const { newsRequestAction } = useActions();
  const [meta, setMeta] = useState({
    page: 1,
    page_size: 10,
    search: '',
    type: ''
  });

  const fetchData = () => {
    newsRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  const handleChangePage = (page: number) => {
    setMeta({ ...meta, page });
  };

  const handleChangePageSize = (page_size: number) => {
    setMeta({ ...meta, page_size });
  };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => setMeta({ ...meta, search: e })}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
            value={meta.search}
          />
          <EventFilterPopup setFilter={setMeta} filter={meta} />
        </S.Head>
        <Gap size="20px" />
        {meta.search && !data.list.length ? (
          <S.P>No result was found for this search query </S.P>
        ) : (
          <AppTable
            headers={[
              { id: 1, title: 'ID', dir: 'left' },
              { id: 4, title: 'Title', dir: 'left' },
              { id: 5, title: 'Reference', dir: 'left' },
              { id: 8, title: 'Created At', dir: 'left' },
              { id: 10, title: '' }
            ]}
            data={data.list}
            loading={loading}
            tdFields={{
              id: (item: T.Pages.NewsListItem) => <S.Div className="text-left">{item?.id}</S.Div>,

              title: (item: T.Pages.NewsListItem) => (
                <S.Div className="text-left captal">
                  {item.title?.length > 50 ? item.title.substring(0, 50) + ' ... ' : item.title}
                </S.Div>
              ),
              reference: (item: T.Pages.NewsListItem) => <S.Div className="text-left">{item.reference}</S.Div>,
              createdAt: (item: T.Pages.NewsListItem) => (
                <S.Div className="text-left">{dateToString(item?.createdAt)}</S.Div>
              ),
              edit: (item: T.Pages.NewsListItem) => (
                <Link to={`/panel/news/${item.id}`}>
                  <ReactSVG
                    src="/icons/stroke/pen-card.svg"
                    beforeInjection={(svg) => {
                      svg.setAttribute('stroke', `#8A93AC`);
                      // svg.setAttribute("width", `12px`);
                      // svg.setAttribute("heigth", `12px`);
                    }}
                    wrapper="span"
                  />
                </Link>
              )
            }}
          />
        )}
      </PanelBoxLayout>
      {data.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.page_size * data.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={data.totalPages}
          pageSize={meta.page_size}
          handleChangeTake={handleChangePageSize}
        />
      ) : (
        <></>
      )}
    </S.Content>
  );
}
