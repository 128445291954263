import styled from 'styled-components';

import { device } from 'common/constants/';

export const BoxContainer = styled.div`
  background: var(--white);
  box-shadow: 0px 3.5px 5.5px 0px #00000005;
  border-radius: 15px;
  padding: 10px 0;
  margin-bottom: 20px;

  @media ${device.tabletL} {
    padding: 16px;
    border-radius: 8px;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Icon = styled.div`
  /* width: 36px;
  height: 36px; */
  /* background: var(--primary); */
  border-radius: 40px;
  line-height: 0;
`;
export const Title = styled.h4`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;

  @media ${device.tabletL} {
    font-size: 18px;
  }

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const TitleCon = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Content = styled.div`
  @media ${device.tabletL} {
    padding: 0;
    width: 100%;
    margin-top: 10px;
  }
`;

export const Div = styled.div``;
