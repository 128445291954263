import CryptoJS from 'crypto-js';

export class Crypto {
  static encrypt(value?: string): string | any {
    try {
      return CryptoJS.AES.encrypt(value || '', 'Secret Passphrase').toString();
    } catch (error) {
      return '';
    }
  }

  static decrypt(value?: string): string | any {
    try {
      return CryptoJS.AES.decrypt(value || '', 'Secret Passphrase').toString(CryptoJS.enc.Utf8);
    } catch (error) {
      return '';
    }
  }
}
