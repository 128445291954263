import styled from 'styled-components';

export const Content = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 186px;
  min-height: 56px;
  background: var(--white);
  border-radius: 7px;
  padding: 10px;

  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
`;

export const Value = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
`;

export const Div = styled.div``;
export const Icon = styled.div``;
