import { ApiCaller } from 'common/helpers/apiCaller';
import convertToQueryString from 'common/helpers/convertToQueryString';

// ------ Access Management List ------ //
export const accessManagementService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/access-management/activities?${convertToQueryString(data)}`
  });
};

// ------ Area List ------ //
export const areaListService = () => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/access-management/areas`
  });
};
