import * as types from 'common/constants';

const initialState = {
  supporter: {
    data: [],
    loading: false,
    error: null
  }
};

interface Reducer {
  type: string;
  // -- //
  payload: any;
}

const Admin = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Floor List cases
     */
    case types.ADMIN_SUPPORTER.REQUEST:
      return {
        ...state,
        supporter: {
          data: [],
          loading: true,
          error: null
        }
      };
    case types.ADMIN_SUPPORTER.SUCCESS:
      return {
        ...state,
        supporter: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.ADMIN_SUPPORTER.FAILURE:
      return {
        ...state,
        supporter: {
          data: [],
          loading: false,
          error: payload
        }
      };

    default:
      return state;
  }
};

export default Admin;
