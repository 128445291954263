/* eslint-disable react-hooks/exhaustive-deps */
import { ReactSVG } from 'react-svg';
import * as S from './styles';
import { useEffect } from 'react';
import { useActions } from 'common/hooks/useActions';

interface IProps {
  data?: any;
}

export default function ReservationCounts({ data }: IProps) {
  const { reservationStatisticsRequestAction } = useActions();
  useEffect(() => {
    reservationStatisticsRequestAction();
  }, []);

  return (
    <S.Content>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-black.svg" />
          </S.Icon>
          <S.Title>All</S.Title>
        </S.Div>
        <S.Value style={{ color: '#2B3674' }}>{data?.all}</S.Value>
      </S.Item>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-green.svg" />
          </S.Icon>
          <S.Title>Active</S.Title>
        </S.Div>
        <S.Value style={{ color: '#1DBAAF' }}>{data?.total_active}</S.Value>
      </S.Item>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-blue.svg" />
          </S.Icon>
          <S.Title>Completed</S.Title>
        </S.Div>
        <S.Value style={{ color: '#20CAFF' }}>{data?.total_complete}</S.Value>
      </S.Item>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-red.svg" />
          </S.Icon>
          <S.Title>Canceled</S.Title>
        </S.Div>
        <S.Value style={{ color: '#FF6464' }}>{data?.total_canceled}</S.Value>
      </S.Item>
    </S.Content>
  );
}
