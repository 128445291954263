import { ApiCaller } from 'common/helpers/apiCaller';

// ------ User List ------ //
export const usersService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/users`,
    data
  });
};

// ------ User List ------ //
export const userDetailService = (id?: string) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/user/${id}`
  });
};

// ------ Change Role ------ //
export const changeRoleService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/change-role`,
    data
  });
};

// ------ Roles ------ //
export const rolesService = () => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/roles`
  });
};

// ------ Admin Roles ------ //
export const adminRolesService = () => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/roles-admin`
  });
};
