import { ApiCaller } from 'common/helpers/apiCaller';
import convertToQueryString from 'common/helpers/convertToQueryString';

// ------ Tickets ------ //
export const ticketsService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/list?${convertToQueryString(data)}`
  });
};

// ------ Ticket Detail ------ //
export const ticketDetailService = (id?: string) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/details/${id}`
  });
};

// ------ Ticket Categories ------ //
export const ticketCategoriesService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/category?${convertToQueryString(data)}`
  });
};

// ------ Ticket Category Create ------ //
export const ticketCategoryCreateService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/category`,
    data
  });
};

// ------ Ticket Category Edit ------ //
export const ticketCategoryEditService = (data: object) => {
  return ApiCaller({
    method: 'PUT',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/category`,
    data
  });
};

// ------ Category Detail ------ //
export const ticketCategoryDetailService = (id: number | string) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/category/details/${id}`
  });
};

// ------ Category Close ------ //
export const ticketCloseService = (id: string | number) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/close`,
    data: { id }
  });
};

// ------ Category Disable ------ //
export const ticketCategoryDisableService = (data: object) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/category/disable`,
    data
  });
};

// ------ Category Enable ------ //
export const ticketCategoryEnableService = (data: object) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/category/enable`,
    data
  });
};

// ------ Category Icon ------ //
export const ticketCategoryIconService = (data: object) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/category/icon`,
    data,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

// ------ Ticket Statistics ------ //
export const ticketStatisticsService = () => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/statistics`
  });
};
