import * as types from 'common/constants';

const initialState = {
  floorList: {
    data: {
      list: []
    },
    loading: false,
    error: null
  },
  createFloor: {
    data: {},
    loading: false,
    error: null
  },
  editFloor: {
    data: {},
    loading: false,
    error: null
  },
  deleteFloor: {
    data: {},
    loading: false,
    error: null
  }
};

interface Reducer {
  type: string;
  // -- //
  payload: any;
}

const Authenticate = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Floor List cases
     */
    case types.FLOORS.REQUEST:
      return {
        ...state,
        floorList: {
          data: {
            list: []
          },
          loading: true,
          error: null
        }
      };
    case types.FLOORS.SUCCESS:
      return {
        ...state,
        floorList: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.FLOORS.FAILURE:
      return {
        ...state,
        floorList: {
          data: {
            list: []
          },
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Create Floor cases
     */
    case types.FLOOR_CREATE.REQUEST:
      return {
        ...state,
        createFloor: { data: {}, loading: true, error: null }
      };
    case types.FLOOR_CREATE.SUCCESS:
      return {
        ...state,
        createFloor: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.FLOOR_CREATE.FAILURE:
      return {
        ...state,
        createFloor: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Edit Floor cases
     */
    case types.FLOOR_EDIT.REQUEST:
      return {
        ...state,
        editFloor: { data: {}, loading: true, error: null }
      };
    case types.FLOOR_EDIT.SUCCESS:
      return {
        ...state,
        editFloor: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.FLOOR_EDIT.FAILURE:
      return {
        ...state,
        editFloor: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Delete Floor cases
     */
    case types.FLOOR_DELETE.REQUEST:
      return {
        ...state,
        deleteFloor: { data: {}, loading: true, error: null }
      };
    case types.FLOOR_DELETE.SUCCESS:
      return {
        ...state,
        deleteFloor: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.FLOOR_DELETE.FAILURE:
      return {
        ...state,
        deleteFloor: { data: {}, loading: false, error: payload }
      };

    default:
      return state;
  }
};

export default Authenticate;
