import * as types from 'common/constants';

const initialState = {
  userList: {
    data: {
      list: [],
      totalPages: null
    },
    loading: false,
    error: null
  },
  changeRole: {
    data: {},
    loading: false,
    error: null
  },
  userDetail: {
    data: {},
    loading: false,
    error: null
  },
  roles: {
    data: [],
    loading: false,
    error: null
  },
  adminRoles: {
    data: [],
    loading: false,
    error: null
  },
  areas: {
    data: [],
    loading: false,
    error: null
  },
  attendances: {
    data: {
      list: [],
      totalPages: null
    },
    loading: false,
    error: null
  }
};

interface Reducer {
  type: string;
  // -- //
  payload: any;
}

const Users = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Users List cases
     */
    case types.USERS.REQUEST:
      return {
        ...state,
        userList: {
          data: {
            list: state.userList?.data?.list,
            totalPages: state.userList.data?.totalPages
          },
          loading: true,
          error: null
        }
      };
    case types.USERS.SUCCESS:
      return {
        ...state,
        userList: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.USERS.FAILURE:
      return {
        ...state,
        userList: {
          data: {
            list: [],
            totalPages: null
          },
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * User Detail cases
     */
    case types.USER_DETAIL.REQUEST:
      return {
        ...state,
        userDetail: {
          data: {},
          loading: true,
          error: null
        }
      };
    case types.USER_DETAIL.SUCCESS:
      return {
        ...state,
        userDetail: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.USER_DETAIL.FAILURE:
      return {
        ...state,
        userDetail: {
          data: {},
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Change Role cases
     */
    case types.CHANGE_ROLE.REQUEST:
      return {
        ...state,
        changeRole: {
          data: {},
          loading: true,
          error: null
        }
      };
    case types.CHANGE_ROLE.SUCCESS:
      return {
        ...state,
        changeRole: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.CHANGE_ROLE.FAILURE:
      return {
        ...state,
        changeRole: {
          data: {},
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Roles cases
     */
    case types.ROLES.REQUEST:
      return {
        ...state,
        roles: {
          data: [],
          loading: true,
          error: null
        }
      };
    case types.ROLES.SUCCESS:
      return {
        ...state,
        roles: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.ROLES.FAILURE:
      return {
        ...state,
        roles: {
          data: [],
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Admin Roles cases
     */
    case types.ADMIN_ROLES.REQUEST:
      return {
        ...state,
        adminRoles: {
          data: [],
          loading: true,
          error: null
        }
      };
    case types.ADMIN_ROLES.SUCCESS:
      return {
        ...state,
        adminRoles: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.ADMIN_ROLES.FAILURE:
      return {
        ...state,
        adminRoles: {
          data: [],
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Attendances cases
     */
    case types.ATTENDANCES.REQUEST:
      return {
        ...state,
        attendances: {
          data: {
            list: state.attendances?.data?.list,
            totalPages: state.attendances?.data?.totalPages
          },
          loading: true,
          error: null
        }
      };
    case types.ATTENDANCES.SUCCESS:
      return {
        ...state,
        attendances: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.ATTENDANCES.FAILURE:
      return {
        ...state,
        attendances: {
          data: {
            list: [],
            totalPages: null
          },
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Areas cases
     */
    case types.AREA_LIST.REQUEST:
      return {
        ...state,
        areas: {
          data: [],
          loading: true,
          error: null
        }
      };
    case types.AREA_LIST.SUCCESS:
      return {
        ...state,
        areas: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.AREA_LIST.FAILURE:
      return {
        ...state,
        areas: {
          data: [],
          loading: false,
          error: payload
        }
      };

    default:
      return state;
  }
};

export default Users;
