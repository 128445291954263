import React, { ReactNode, useState } from 'react';

import * as S from './styles';
// import { ReactSVG } from "react-svg";

interface IProps {
  label?: string | ReactNode;
  disabled?: boolean | undefined;
  onChange: (e: object) => void;
  style?: object;
  id?: string;
  error?: boolean;
  success?: boolean;
  message?: string | boolean;
  height?: string;
  name?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  value?: string;
  inputStyle?: object;
  readOnly?: boolean;
  inputSize?: number;
  maxLength?: number;
  // -- //
  refrence?: any;
  className?: string;
  placeholder?: string;
  showHide?: boolean;
  labelStyle?: object;
}

const PasswordInput: React.FC<IProps> = ({
  label,
  id,
  disabled,
  style,
  error,
  success,
  message,
  height,
  onChange,
  name,
  onBlur,
  value,
  inputStyle,
  readOnly,
  inputSize,
  maxLength,
  className,
  refrence,
  onFocus,
  placeholder,
  showHide,
  labelStyle
}) => {
  const randomId = Math.random() * 200;
  const [show, setShow] = useState<boolean>(false);

  return (
    <S.inputContainer
      style={style}
      className={error ? `error ${className}` : success ? `success ${className}` : `${className}`}
    >
      <S.Grid>
        <S.label className="input-label" htmlFor={id ? id : `${randomId}`} style={labelStyle}>
          {label}
        </S.label>
        {/* <S.CopyBtn>
          <S.Img
            src="/icons/stroke/copy.svg"
            // beforeInjection={(svg) => {
            //   svg.setAttribute("stroke", "var(--dark)");
            // }}
          />
        </S.CopyBtn> */}
      </S.Grid>
      <S.InputBox>
        <S.Input
          ref={refrence}
          maxLength={maxLength}
          size={inputSize}
          readOnly={readOnly}
          name={name}
          type={show ? 'text' : 'password'}
          onChange={onChange}
          id={id ? id : `${randomId}`}
          disabled={disabled}
          placeholder={placeholder}
          style={{
            ...inputStyle,
            height: height ? height : '50px',
            lineHeight: height ? height : 'auto',
            paddingRight: showHide ? '35px' : '15px'
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
        />
        {showHide ? (
          <S.ShowHideButton type="button" onClick={() => setShow(!show)}>
            {!show ? <S.Img src="/icons/eye.svg" className="icon" /> : <S.Img src="/icons/eye-hidden.svg" />}
          </S.ShowHideButton>
        ) : (
          <></>
        )}
      </S.InputBox>

      {message ? (
        <S.message className={error ? 'error-message' : success ? 'success-message' : ''}>{message}</S.message>
      ) : (
        <></>
      )}
    </S.inputContainer>
  );
};

export default PasswordInput;
