import styled from 'styled-components';

export const inputContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  /* overflow: hidden; */
  border-radius: 12px;

  &.error {
    input {
      border: 1px solid #eb2b2b !important;
    }
  }

  &.success {
    input {
      border: solid 1px var(--green-dark);
    }
  }
`;

export const textarea = styled.textarea`
  padding: 15px;
  /* height: 54px; */
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  color: var(--dark);

  &:focus ~ .input-label,
  &:not(:placeholder-shown) ~ .input-label {
    top: 0px;
    font-size: 11px;
  }

  &:focus {
    border: 1px solid var(--primary-green);
  }

  &:not(:placeholder-shown) ~ .input-label {
    color: #367af7;
  }

  &:focus {
    outline: none;
  }
`;

export const label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--dark);
  padding-bottom: 5px;
  transition: 0.2s ease all;
`;

export const message = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: var(--dark-light);
  padding-left: 10px;
  margin-top: 5px;

  &.error-message {
    color: var(--danger);
  }

  &.success-message {
    color: var(--green-dark);
  }
`;
