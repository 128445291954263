import { Pagination } from 'antd';
import * as S from './styles';

interface IProps {
  page: number;
  handleChangePage: (page: number) => void;
  size?: 'small' | 'large';
  count: number;
  fontWeight?: number;
  pageSize: number;
  handleChangeTake: (take: number) => void;
}

const PaginationApp: React.FC<IProps> = ({
  page,
  handleChangePage,
  handleChangeTake,
  // size,
  count,
  pageSize
  // fontWeight,
}) => {
  console.log(count);

  return (
    <>
      {count > 1 ? (
        <S.PaginationContainer>
          <Pagination
            total={count}
            defaultCurrent={page}
            onChange={handleChangePage}
            pageSize={pageSize}
            onShowSizeChange={handleChangeTake}
            showSizeChanger={false}
          />
        </S.PaginationContainer>
      ) : (
        <></>
      )}
    </>
  );
};

export default PaginationApp;
