import React from 'react';
import { ReactSVG } from 'react-svg';
import ImageUploading, { ImageListType } from 'react-images-uploading';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  label?: string;
  disabled?: boolean | undefined;
  onChange?: (e: T.Form.FileUpload) => void;
  style?: object;
  iconStyle?: object;
  error?: boolean;
  success?: boolean;
  message?: string | boolean;
  defaultImage?: string | null;
  icon?: string;
  accept?: [string];
  value?: T.Form.FileUpload;
}

const UploadIcon: React.FC<IProps> = ({
  value,
  style,
  error,
  success,
  message,
  onChange,
  defaultImage,
  accept = ['png', 'jpg', 'jpeg', 'webp'],
  label,
  iconStyle
}) => {
  const onChangeFile = (
    imageList: ImageListType
    // addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    // setImages(imageList as never[]);
    if (onChange) onChange(imageList[0] as unknown as T.Form.FileUpload);
  };

  return (
    <S.inputContainer style={style} className={error ? 'error' : success ? 'success' : ''}>
      {label ? <S.label>{label}</S.label> : ''}
      <ImageUploading value={[{ ...value }]} onChange={onChangeFile} maxNumber={1} acceptType={accept}>
        {({
          imageList,
          onImageUpload,
          // onImageRemoveAll,
          onImageUpdate,
          // onImageRemove,
          isDragging,
          dragProps
        }) => (
          // write your building UI
          <>
            <S.InputBox
              onClick={() => {
                imageList[0] ? onImageUpdate(0) : onImageUpload();
              }}
              {...dragProps}
              style={{ borderColor: isDragging ? 'red' : undefined }}
            >
              {defaultImage || imageList[0]?.dataURL ? (
                <S.img
                  src={
                    // -- //
                    imageList[0] ? imageList[0]?.dataURL : (defaultImage as any)
                  }
                  className="icon"
                />
              ) : (
                <ReactSVG
                  src="/icons/stroke/plus.svg"
                  beforeInjection={(svg) => {
                    svg.setAttribute('fill', `var(--white)`);
                    svg.setAttribute('width', `10px`);
                    svg.setAttribute('height', `10px`);
                  }}
                />
              )}
              {defaultImage || imageList[0]?.dataURL ? (
                <S.Icon style={iconStyle}>
                  <ReactSVG
                    src="/icons/stroke/pen.svg"
                    beforeInjection={(svg) => {
                      svg.setAttribute('stroke', `#A4A6B3`);
                    }}
                  />
                </S.Icon>
              ) : (
                <></>
              )}
            </S.InputBox>
          </>
        )}
      </ImageUploading>

      {message ? (
        <S.message className={error ? 'error-message' : success ? 'success-message' : ''}>{message}</S.message>
      ) : (
        <></>
      )}
    </S.inputContainer>
  );
};

export default UploadIcon;
