import EntranceExitTimesTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function EntranceExitTimesPage() {
  const { attendances, areas } = useTypedSelector((state: any) => state.Users);
  const { departments } = useTypedSelector((state) => state.Departments);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Employee Entrance and Exit Times</S.Title>
      </S.Head>
      <Gap size="20px" />
      <EntranceExitTimesTable
        data={attendances.data}
        departments={departments.data}
        loading={attendances.loading}
        areas={areas.data}
      />

      <AppLoading loading={departments.loading || areas.loading} />
    </S.Container>
  );
}
