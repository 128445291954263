import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const InputBox = styled.div`
  background: var(--white);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 48px;
  border: 1px solid #e5e5e5;
  border-radius: 16px;

  &.white {
    background: var(--white);
    border: 1px solid var(--gray);

    input {
      color: var(--dark);
    }
  }
`;

export const Img = styled.img``;
export const Input = styled.input`
  background: none;
  border: none;
  color: var(--primary-dark);
  padding: 0 10px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

export const SearchButton = styled.button`
  border: none;
  background: none;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ClearButton = styled.button`
  border: none;
  background: none;

  svg {
    width: 20px;
  }
`;

export const Result = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;
  z-index: 1000;
  background: var(--white);
  min-height: 100%;
`;

export const ResultItems = styled.ul`
  padding: 10px 15px;
  margin: 0;
  list-style: none;
  width: 100%;
`;

export const ResultItem = styled.li`
  padding: 13px 0;
  width: 100%;
`;
