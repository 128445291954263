import styled from 'styled-components';

import { device } from 'common/constants/mediaQueries';

interface buttonColor {
  buttonColor?: string;
  buttonFont?: string;
}

export const outlineButton = styled.button`
  height: 48px;
  background: none;
  border-radius: 8px;
  padding: 0 30px;
  border: none;
  border: 1px solid var(--primary);
  color: var(--primary);

  span {
    color: var(--primary);
  }
  /* &:active {
    background: var(--primary-two);
  } */

  &:focus {
    outline: double 3px var(--primary) !important;
  }

  &:disabled {
    opacity: 0.3;
    background: var(--light);
  }

  @media ${device.tablet} {
    font-size: 13px;
    font-weight: 500;
    padding: 0 10px;
  }
`;

export const outlineButtonLink = styled.div`
  a {
    background: none;
    height: 48px;
    border-radius: 8px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    border: 1px solid var(--primary);
    color: var(--primary);

    span {
      color: var(--primary);
      font-weight: 500;
    }

    /* &:active {
      background: var(--primary-two);
    } */

    &:focus {
      outline: double 3px var(--primary);
    }

    &:disabled {
      opacity: 0.3;
      background: var(--light);
    }

    @media ${device.tablet} {
      font-size: 13px;
      font-weight: 500;
      padding: 0 10px;
    }
  }
`;

export const text = styled.span<buttonColor>`
  font-style: normal;
  line-height: 19px;
  color: ${({ buttonColor }) => (buttonColor ? buttonColor : 'white')};
  font-size: ${({ buttonFont }) => (buttonFont ? buttonFont : '14px')};
`;
