/* eslint-disable react-hooks/exhaustive-deps */
import { ReactSVG } from 'react-svg';
import { useEffect, useState } from 'react';

import AppTable from 'components/appTable';
import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import PaginationApp from 'components/pagination';
import ModalApp from 'components/modalApp';
import AssignTeaboyForm from '../assignTeaboyForm';
import { useActions } from 'common/hooks/useActions';

import * as S from './styles';
import * as T from 'common/types';
import { Switch } from 'antd';

interface IProps {
  data: { list: T.Pages.FloorListItem[]; totalPages: number };
  teaboyList: T.Pages.TeaboyListItem[];
  loading: boolean;
}

export default function CafeteriaTeaboyManagementTable({ data, teaboyList, loading }: IProps) {
  const { floorsRequestAction, cafeteriaStatusRequestAction } = useActions();
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState<T.Pages.FloorListItem | null>(null);
  const [meta, setMeta] = useState({
    page: 1,
    take: 10,
    search: '',
    getTeaBoys: true
  });

  const fetchData = () => {
    floorsRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  const handleShowEdit = (item: T.Pages.FloorListItem) => {
    setShowEditModal(true);
    setEditData(item);
  };

  const handleCloseEdit = () => {
    setShowEditModal(false);
    setEditData(null);
  };

  const handleChangePage = (page: number) => {
    setMeta({ ...meta, page });
  };

  const handleChangeTake = (take: number) => {
    setMeta({ ...meta, take });
  };

  const onChangeStatus = (status: boolean, id: number) => {
    cafeteriaStatusRequestAction(
      {
        id,
        status
      },
      () => {
        fetchData();
      }
    );
  };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => setMeta({ ...meta, search: e })}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
            value={meta.search}
          />
        </S.Head>
        <Gap size="20px" />
        <AppTable
          headers={[
            { id: 1, title: 'Floor', dir: 'left' },
            { id: 2, title: 'User', dir: 'left' },
            { id: 3, title: 'Status', dir: 'left' },
            { id: 4, title: 'Management' }
          ]}
          loading={loading}
          data={data.list}
          tdFields={{
            id: (item: T.Pages.FloorListItem) => <S.Div className="text-left">{item?.name}</S.Div>,
            user: (item: T.Pages.FloorListItem) => (
              <S.Div className="text-left"> {item?.teaBoy?.user?.displayName}</S.Div>
            ),
            status: (item: T.Pages.FloorListItem) => (
              <S.Div className={`text-left`}>
                <S.Span className={item.cafeteriaStatus ? 'active' : 'inactive'}>
                  <Switch
                    checked={item.cafeteriaStatus}
                    onChange={(val: boolean) => onChangeStatus(val, item.id)}
                    disabled={!item?.teaBoy?.id ? true : false}
                  />
                </S.Span>
              </S.Div>
            ),
            management: (item: T.Pages.FloorListItem) => (
              <S.Div className={`text-center`} style={{ margin: 'auto', width: 'max-content' }}>
                {item?.id ? (
                  <S.Button onClick={() => handleShowEdit(item)}>
                    <ReactSVG
                      src="/icons/stroke/pen-card.svg"
                      beforeInjection={(svg) => {
                        svg.setAttribute('stroke', `#fff`);
                        svg.setAttribute('width', `12px`);
                        svg.setAttribute('heigth', `12px`);
                      }}
                      wrapper="span"
                    />
                    Edit
                  </S.Button>
                ) : (
                  <S.Button onClick={() => handleShowEdit(item)}>
                    <ReactSVG
                      src="/icons/plus-card.svg"
                      beforeInjection={(svg) => {
                        svg.setAttribute('width', `18px`);
                        svg.setAttribute('heigth', `18px`);
                      }}
                      wrapper="span"
                    />
                    Add
                  </S.Button>
                )}
              </S.Div>
            )
          }}
        />
      </PanelBoxLayout>
      {data.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.take * data.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={data.totalPages}
          pageSize={meta.take}
          handleChangeTake={handleChangeTake}
        />
      ) : (
        <></>
      )}

      <ModalApp
        show={showEditModal}
        fixed
        handleClose={handleCloseEdit}
        style={{ maxWidth: 350, padding: '20px 40px' }}
      >
        <AssignTeaboyForm
          data={editData as T.Pages.FloorListItem}
          teaboyList={teaboyList}
          closeModal={handleCloseEdit}
          fetchData={fetchData}
        />
      </ModalApp>
    </S.Content>
  );
}
