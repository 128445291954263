// -- == //
// Import the functions you need from the SDKs you need
// import firebaseConfig from "firebase-config";
import { initializeApp } from 'firebase/app';
// import { onBackgroundMessage } from "firebase/messaging/sw";
import { deleteToken, getMessaging, getToken, onMessage } from 'firebase/messaging';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const db = app.firestore();

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
const messaging = getMessaging(app);
const getCurrentToken = (callBack: (token: any) => void) =>
  getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_VAP_ID_KEY
  })
    .then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        // ...
        callBack(currentToken);
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // ...
    });

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

const handleDeleteToken = () => {
  deleteToken(messaging);
  // auth().signOut().then(function() {
  //   // Sign-out successful.
  // }).catch(function(error) {
  //   // An error happened.
  // });
};
// onBackgroundMessage(messaging, (payload) => {
//   console.log(
//     "[firebase-messaging-sw.js] Received background message ",
//     payload
//   );
//   // Customize notification here
//   // const notificationTitle = "Background Message Title";
//   // const notificationOptions = {
//   //   body: "Background Message body.",
//   //   icon: "/firebase-logo.png",
//   // };

//   // self?.registration?.showNotification(notificationTitle, notificationOptions);
// });

export { app, messaging, getCurrentToken, onMessageListener, handleDeleteToken };
