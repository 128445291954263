import { ApiCaller } from 'common/helpers/apiCaller';
import convertToQueryString from 'common/helpers/convertToQueryString';

// ------ Notifications List ------ //
export const notificationsService = () => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/notifications`
  });
};

// ------ Notifications Custom List ------ //
export const notificationsCustomService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/notifications/custom?${convertToQueryString(data)}`
  });
};

// ------ Notifications Create Custom ------ //
export const notficationCreateCustomService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/notifications/custom`,
    data
  });
};

// ------ Notifications Edit Custom ------ //
export const notficationUpdateCustomService = (data: object, id?: string) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/notifications/custom?id=${id}`,
    data
  });
};

// ------ Notifications Update Read ------ //
export const notficationUpdateReadService = (data: object, id?: string) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/notifications/read`,
    data
  });
};

// ------ Notifications Delete ------ //
export const notficationDeleteService = (id: string | number) => {
  return ApiCaller({
    method: 'DELETE',
    url: `${process.env.REACT_APP_API_URL}api/admin/notifications?id=${id}`
  });
};
