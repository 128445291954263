import CafeteriaStatisticsSidebarFilterForm from './lib/filterForm';
import PanelBoxLayout from 'components/layout/panelBox';

import * as S from './styles';
import CafeteriaStatisticsSidebarExportDataForm from './lib/exportDataForm';
import * as T from 'common/types';
import dayjs from 'dayjs';

interface IProps {
  cafeteriaList: { list: T.Pages.CafeteriaListItem[] };
}

export default function CafeteriaStatisticsSidebar({ cafeteriaList }: IProps) {
  const now = dayjs().date(1);

  console.log(now);
  console.log(dayjs());

  return (
    <S.Content>
      <PanelBoxLayout icon="/icons/stroke/filter.svg" title="Filter" style={{ padding: '20px 30px', maxWidth: 350 }}>
        <CafeteriaStatisticsSidebarFilterForm cafeteriaList={cafeteriaList} now={now} />
      </PanelBoxLayout>
      {/* <Gap size="20px" /> */}
      <PanelBoxLayout title="Export  Data" style={{ padding: '20px 30px' }}>
        <CafeteriaStatisticsSidebarExportDataForm />
      </PanelBoxLayout>
    </S.Content>
  );
}
