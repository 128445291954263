/* eslint-disable react-hooks/exhaustive-deps */
// -- == //
import SecundButton from 'components/form/buttons/secundButton';

// import { useActions } from "common/hooks/useActions";
import MiniPopUp from 'components/modal/miniPopUp';
import PanelBoxLayout from 'components/layout/panelBox';

import { Formik } from 'formik';
import Gap from 'components/gap';
import PrimaryButton from 'components/form/buttons/primaryButton';
import { SetStateAction, useEffect } from 'react';

import * as S from './styles';
import * as T from 'common/types';
import { useActions } from 'common/hooks/useActions';
import DropDown from 'components/form/dropDown';

interface IProps {
  setFilter: SetStateAction<any>;
  departments: string[];
  ticketCategory: [];
  filter: any;
}

export default function TicketFilterPopup({ setFilter, ticketCategory, filter }: IProps) {
  const { departmentsRequestAction, ticketCategoriesRequestAction } = useActions();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    departmentsRequestAction();
    ticketCategoriesRequestAction({ page: 1, page_size: 1000 });
  };

  return (
    <S.Content>
      <MiniPopUp
        popUpStyle={{ width: 245, padding: '0' }}
        buttonNode={
          <SecundButton
            hasIcon={true}
            icon="/icons/stroke/filter.svg"
            iconStyle={{ background: 'none' }}
            style={{ background: '#8A93AC' }}
            colorIcon="var(--white)"
          >
            Filters
          </SecundButton>
        }
      >
        <PanelBoxLayout
          style={{ padding: 15 }}
          titleStyle={{ color: '#2D3748', fontSize: 14 }}
          title="Filters"
          icon="/icons/stroke/filter.svg"
        >
          <Formik
            initialValues={{
              categoryId: '',
              status: ''
            }}
            // validationSchema={ValidationLogin}
            onSubmit={(values, { setSubmitting }) => {
              setFilter({ ...filter, ...values });
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              // handleChange,
              handleSubmit,
              // isSubmitting,
              setValues
            }) => (
              <>
                <DropDown
                  label="Status"
                  list={[
                    { id: '', title: 'All' },
                    { id: 'active', title: 'Active' },
                    { id: 'closed', title: 'Closed' }
                  ]}
                  onChange={(e: T.Form.ListDropdownItem) => {
                    setValues({
                      ...values,
                      status: e.id as any
                    });
                  }}
                  placeholder="Select Status"
                  message={touched.status && (errors.status as any)}
                  error={errors.status as unknown as boolean}
                  inputStyle={{
                    height: 40,
                    borderRadius: 7,
                    fontSize: 12,
                    padding: '0 15px'
                  }}
                  labelStyle={{ fontSize: 12 }}
                />
                <Gap size="20px" />

                <DropDown
                  label="Categories"
                  list={ticketCategory}
                  onChange={(e: T.Form.ListDropdownItem) => {
                    setValues({
                      ...values,
                      categoryId: e.id as any
                    });
                  }}
                  placeholder="Select Categories"
                  message={touched.categoryId && (errors.categoryId as any)}
                  error={errors.categoryId as unknown as boolean}
                  inputStyle={{
                    height: 40,
                    borderRadius: 7,
                    fontSize: 12,
                    padding: '0 15px'
                  }}
                  labelStyle={{ fontSize: 12 }}
                />

                <Gap size="20px" />

                <PrimaryButton
                  style={{ width: '100%', height: 42, borderRadius: 8 }}
                  onClick={handleSubmit}
                  // disabled={isSubmitting}
                >
                  Filter
                </PrimaryButton>
              </>
            )}
          </Formik>
        </PanelBoxLayout>
      </MiniPopUp>
    </S.Content>
  );
}
