/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

import AppTable from 'components/appTable';
import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import PaginationApp from 'components/pagination';
import { useActions } from 'common/hooks/useActions';
import ModalApp from 'components/modalApp';
import CafeteriaCreateForm from '../create/lib/form';
import { menuCategoryArray } from 'common/enums';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  menuList: { list: T.Pages.CafeteriaListItem[]; totalPages: number };
  loading: boolean;
}

export default function CafeteriaMenuManagementTable({ menuList, loading }: IProps) {
  const { cafeteriaMenuManagementRequestAction } = useActions();
  const [showEditModal, setShowEditModal] = useState(false);
  const [editData, setEditData] = useState<T.Pages.CafeteriaListItem | null>();
  const [meta, setMeta] = useState({
    page: 1,
    take: 10,
    search: '',
    type: ''
  });

  const fetchData = () => {
    cafeteriaMenuManagementRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  const handleShowEdit = (item: T.Pages.CafeteriaListItem) => {
    setShowEditModal(true);
    setEditData(item);
  };

  const handleCloseEdit = () => {
    setShowEditModal(false);
    setEditData(null);
  };

  const handleChangePage = (page: number) => {
    setMeta({ ...meta, page });
  };

  const handleChangeTake = (take: number) => {
    setMeta({ ...meta, take });
  };

  const renderCategoryName = (cat: string) => {
    let newText: string = '';
    // -- //
    // eslint-disable-next-line array-callback-return
    menuCategoryArray.map((item: any) => {
      if (item.id === cat) newText = item.title;
    });
    return newText;
  };

  const handleFilter = (field: string, val: string | number) => {
    setMeta({ ...meta, page: 1, [field]: val });
  };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => handleFilter('search', e)}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
            value={meta.search}
          />
          <S.FilterButoons>
            <S.Button onClick={() => handleFilter('type', '')} className={meta.type === '' ? 'active' : ''}>
              aLL
            </S.Button>
            {menuCategoryArray.map((item) => (
              <S.Button onClick={() => handleFilter('type', item.id)} className={meta.type === item.id ? 'active' : ''}>
                {item.title}
              </S.Button>
            ))}
          </S.FilterButoons>
        </S.Head>
        <Gap size="20px" />
        <AppTable
          headers={[
            { id: 1, title: 'ID', dir: 'left', width: '5%' },
            { id: 2, title: 'Icon', width: '15%' },
            { id: 3, title: 'Title', dir: 'left', width: '30%' },
            { id: 4, title: 'Category', dir: 'left', width: '20%' },
            { id: 5, title: 'Status', dir: 'left', width: '10%' },
            { id: 6, title: 'Orders Count', width: '10%' },
            { id: 7, title: 'Edit', width: '10%' }
          ]}
          style={{ minWidth: 'auto' }}
          data={menuList?.list}
          loading={loading}
          tdFields={{
            id: (item: T.Pages.CafeteriaListItem) => <S.Div className="text-left">{item?.id}</S.Div>,
            icon: (item: T.Pages.CafeteriaListItem) => (
              <S.Div className="">
                <S.Image src={item.image} />
              </S.Div>
            ),
            title: (item: T.Pages.CafeteriaListItem) => <S.Div className="text-left captal">{item.title}</S.Div>,
            category: (item: T.Pages.CafeteriaListItem) => (
              <S.Div className="text-left">{renderCategoryName(item.category)}</S.Div>
            ),
            status: (item: T.Pages.CafeteriaListItem) => (
              <S.Div className={`text-left ${item.status ? 'active' : 'inactive'}`}>
                {item.status ? 'Active' : 'Inactive'}
              </S.Div>
            ),
            count: (item: T.Pages.CafeteriaListItem) => item.ordersCount,
            edit: (item: T.Pages.CafeteriaListItem) => (
              <S.Div style={{ cursor: 'pointer' }} onClick={() => handleShowEdit(item)}>
                {/* <Link to={`/panel/cafeteria/menu-management/${item.id}`}> */}
                <ReactSVG
                  src="/icons/stroke/pen-card.svg"
                  beforeInjection={(svg) => {
                    svg.setAttribute('stroke', `#8A93AC`);
                  }}
                />
                {/* </Link> */}
              </S.Div>
            )
          }}
        />
      </PanelBoxLayout>
      {menuList?.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.take * menuList.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={menuList.totalPages}
          pageSize={meta.take}
          handleChangeTake={handleChangeTake}
        />
      ) : (
        <></>
      )}

      <ModalApp show={showEditModal} fixed handleClose={handleCloseEdit} style={{ width: 400, padding: '20px 40px' }}>
        <S.Div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <S.Icon>
            <ReactSVG
              src={'/icons/stroke/pen.svg'}
              beforeInjection={(svg) => {
                svg.setAttribute('stroke', `var(--white)`);
                svg.setAttribute('width', `14px`);
                svg.setAttribute('height', `14px`);
              }}
            />
          </S.Icon>
          <S.Title>Edit Menu {editData?.title}</S.Title>
          <CafeteriaCreateForm
            data={editData as T.Pages.CafeteriaListItem}
            closeModal={handleCloseEdit}
            fetchData={fetchData}
            // key={editData?.id}
          />
        </S.Div>
      </ModalApp>
    </S.Content>
  );
}
