/* eslint-disable react-hooks/exhaustive-deps */
import { SetStateAction, useEffect, useState } from 'react';
import { Formik } from 'formik';

import SecundButton from 'components/form/buttons/secundButton';
import MiniPopUp from 'components/modal/miniPopUp';
import PanelBoxLayout from 'components/layout/panelBox';
// import { useActions } from "common/hooks/useActions";

import Gap from 'components/gap';
import PrimaryButton from 'components/form/buttons/primaryButton';
import { useActions } from 'common/hooks/useActions';
import DropDown from 'components/form/dropDown';
import DateInput from 'components/form/dateInput';
import OutlineButton from 'components/form/buttons/outLineButton';
// import { ValidationFilterDate } from "common/validations";

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  setFilter: SetStateAction<any>;
  departments: string[];
  filter: any;
  areas: any[];
}

export default function UserFilterPopup({ setFilter, filter, departments, areas }: IProps) {
  const [flag, setFlag] = useState<number>(0);
  const { departmentsRequestAction, areaListRequestAction } = useActions();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    departmentsRequestAction();
    areaListRequestAction();
  };

  return (
    <S.Content>
      <MiniPopUp
        popUpStyle={{ width: 245, padding: '0' }}
        buttonNode={
          <SecundButton
            hasIcon={true}
            icon="/icons/stroke/filter.svg"
            iconStyle={{ background: 'none' }}
            style={{ background: '#8A93AC' }}
            colorIcon="var(--white)"
          >
            Filters
          </SecundButton>
        }
        key={flag}
      >
        <PanelBoxLayout
          style={{ padding: 15 }}
          titleStyle={{ color: '#2D3748', fontSize: 14 }}
          title="Filters"
          icon="/icons/stroke/filter.svg"
        >
          <Formik
            initialValues={{
              type: '',
              area: '',
              department: '',
              startDate: '',
              endDate: ''
            }}
            // validationSchema={ValidationFilterDate}
            onSubmit={(values, { setSubmitting }) => {
              setFilter({ ...filter, ...values, page: 1 });
              setSubmitting(false);
              setFlag((val) => (val += 1));
            }}
          >
            {({ values, errors, touched, handleSubmit, resetForm, setValues }) => (
              <>
                <DropDown
                  label="Area"
                  list={areas?.map((item: any) => {
                    return { id: item.area_name, title: item.area_name };
                  })}
                  onChange={(e: T.Form.ListDropdownItem) => {
                    setValues({
                      ...values,
                      area: e.title as any
                    });
                  }}
                  defaultValue={{
                    title: values.area,
                    id: values.area
                  }}
                  placeholder="Select Area"
                  message={touched.area && (errors.area as any)}
                  error={errors.area as unknown as boolean}
                  inputStyle={{
                    height: 40,
                    borderRadius: 7,
                    fontSize: 12,
                    padding: '0 15px'
                  }}
                  labelStyle={{ fontSize: 12 }}
                />

                <Gap size="10px" />

                <DropDown
                  label="Department"
                  list={departments.map((item: any) => {
                    return { title: item, id: item };
                  })}
                  onChange={(e: T.Form.ListDropdownItem) => {
                    setValues({
                      ...values,
                      department: e.id as any
                    });
                  }}
                  defaultValue={{
                    title: values.department,
                    id: values.department
                  }}
                  placeholder="Select Type"
                  message={touched.type && (errors.type as any)}
                  error={errors.type as unknown as boolean}
                  inputStyle={{
                    height: 40,
                    borderRadius: 7,
                    fontSize: 12,
                    padding: '0 15px'
                  }}
                  labelStyle={{ fontSize: 12 }}
                />
                <Gap size="10px" />

                <DateInput
                  onChange={(e) => {
                    setValues({
                      ...values,
                      startDate: e
                    });
                  }}
                  placeholder="Start Date"
                  label="Start Date"
                  defaultDate={values.startDate}
                  message={touched.startDate && (errors.startDate as any)}
                  error={touched.startDate && (errors.startDate as unknown as boolean)}
                  inputStyle={{
                    height: 40,
                    borderRadius: 7,
                    fontSize: 12,
                    padding: '0 15px'
                  }}
                  labelStyle={{ fontSize: 12 }}
                />
                <Gap size="10px" />

                <DateInput
                  onChange={(e) => {
                    setValues({
                      ...values,
                      endDate: e
                    });
                  }}
                  placeholder="End Date"
                  label="End Date"
                  defaultDate={values.endDate}
                  message={touched.endDate && (errors.endDate as any)}
                  error={touched.endDate && (errors.endDate as unknown as boolean)}
                  inputStyle={{
                    height: 40,
                    borderRadius: 7,
                    fontSize: 12,
                    padding: '0 15px'
                  }}
                  labelStyle={{ fontSize: 12 }}
                />
                <Gap size="20px" />

                <PrimaryButton
                  style={{ width: '100%', height: 42, borderRadius: 8 }}
                  onClick={handleSubmit}
                  // disabled={isSubmitting}
                >
                  Filter
                </PrimaryButton>
                <Gap size="10px" />

                <OutlineButton
                  style={{ width: '100%', height: 42, borderRadius: 8 }}
                  onClick={() => {
                    resetForm();
                    handleSubmit();
                  }}
                  // disabled={isSubmitting}
                >
                  Reset
                </OutlineButton>
              </>
            )}
          </Formik>
        </PanelBoxLayout>
      </MiniPopUp>
    </S.Content>
  );
}
