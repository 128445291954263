import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'common/helpers/toastConfig';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';

import * as types from 'common/constants';
// import * as T from "common/types";
import * as services from 'common/services';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Cafeteria actions
 */
export const dashboardCafeteriaRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.DASHBOARD_CAFETERIA.REQUEST,
        payload: {}
      });

      // -- //
      const response: any = await services.dashboardCafeteriaService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(dashboardCafeteriaSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(dashboardCafeteriaFailureAction(response.data));
      }
      // -- //
    } catch (error: any) {
      console.log(error);
      dispatch(dashboardCafeteriaFailureAction(error));
      throw error;
    }
  };
};

export const dashboardCafeteriaSuccessAction = (data: object) => {
  return {
    type: types.DASHBOARD_CAFETERIA.SUCCESS,
    payload: data
  };
};

export const dashboardCafeteriaFailureAction = (error: object) => {
  return {
    type: types.DASHBOARD_CAFETERIA.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Stationary actions
 */
export const dashboardStationaryRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.DASHBOARD_STATIONARY.REQUEST,
        payload: {}
      });

      const response: any = await services.dashboardStationaryService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(dashboardStationarySuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(dashboardStationaryFailureAction(response.data));
      }
      // -- //
    } catch (error: any) {
      console.log(error);
      dispatch(dashboardStationaryFailureAction(error));
      throw error;
    }
  };
};

export const dashboardStationarySuccessAction = (data: object) => {
  return {
    type: types.DASHBOARD_STATIONARY.SUCCESS,
    payload: data
  };
};

export const dashboardStationaryFailureAction = (error: object) => {
  return {
    type: types.DASHBOARD_STATIONARY.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Events actions
 */
export const dashboardEventsRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.DASHBOARD_EVENTS.REQUEST,
        payload: {}
      });

      // -- //
      const response: any = await services.dashboardEventsService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(dashboardEventsSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(dashboardEventsFailureAction(response.data));
      }
      // -- //
    } catch (error: any) {
      console.log(error);
      dispatch(dashboardEventsFailureAction(error));
      throw error;
    }
  };
};

export const dashboardEventsSuccessAction = (data: object) => {
  return {
    type: types.DASHBOARD_EVENTS.SUCCESS,
    payload: data
  };
};

export const dashboardEventsFailureAction = (error: object) => {
  return {
    type: types.DASHBOARD_EVENTS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Maintenance actions
 */
export const dashboardMaintenanceRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.DASHBOARD_MAINTENANCE.REQUEST,
        payload: {}
      });

      const response: any = await services.dashboardMaintenanceService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(dashboardMaintenanceSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(dashboardMaintenanceFailureAction(response.data));
      }
      // -- //
    } catch (error: any) {
      console.log(error);
      dispatch(dashboardMaintenanceFailureAction(error));
      throw error;
    }
  };
};

export const dashboardMaintenanceSuccessAction = (data: object) => {
  return {
    type: types.DASHBOARD_MAINTENANCE.SUCCESS,
    payload: data
  };
};

export const dashboardMaintenanceFailureAction = (error: object) => {
  return {
    type: types.DASHBOARD_MAINTENANCE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Hsse actions
 */
export const dashboardHsseRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.DASHBOARD_HSSE.REQUEST,
        payload: {}
      });

      const response: any = await services.dashboardHsseService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(dashboardHsseSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(dashboardHsseFailureAction(response.data));
      }
      // -- //
    } catch (error: any) {
      console.log(error);
      dispatch(dashboardHsseFailureAction(error));
      throw error;
    }
  };
};

export const dashboardHsseSuccessAction = (data: object) => {
  return {
    type: types.DASHBOARD_HSSE.SUCCESS,
    payload: data
  };
};

export const dashboardHsseFailureAction = (error: object) => {
  return {
    type: types.DASHBOARD_HSSE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
