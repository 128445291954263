import styled from 'styled-components';

import { device } from 'common/constants/mediaQueries';

export const inputContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  /* overflow: hidden; */
  border-radius: 12px;

  @media ${device.mobileL} {
    width: 100%;
  }

  @media ${device.mobileM} {
    width: 100%;
  }

  @media ${device.mobileS} {
    width: 100%;
  }

  &.error {
    input {
      border: 1px solid #eb2b2b !important;
    }
  }

  &.success {
    input {
      border: solid 1px var(--green-dark);
    }
  }
`;

export const input = styled('input')`
  padding: 10px 20px;
  height: 48px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-right: 6px;
  background: var(--white);
  border: 1px solid #eeeeee;
  border-radius: 16px;
  color: var(--dark);
  cursor: pointer;

  &.dropdown-show {
    border-bottom: none;
    border-radius: 16px 16px 0 0;
  }

  &:not(:placeholder-shown) ~ .input-label {
    color: #367af7;
  }

  &:focus {
    outline: none;
  }

  &.mini {
    height: 28px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 0px;
  }
`;

export const label = styled('label')<{ labelColor?: string | undefined }>`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  /* position: absolute;
  top: 15px;
  left: 15px; */
  color: var(--dark);
  transition: 0.2s ease all;
  cursor: pointer;
  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 20px;
  }
  @media ${device.mobileM} {
    font-size: 12px;
    line-height: 16px;
  }
  @media ${device.mobileS} {
    font-size: 10px;
    line-height: 14px;
  }
`;

export const message = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: var(--dark-light);
  padding-left: 10px;

  &.error-message {
    color: var(--danger);
  }

  &.success-message {
    color: var(--green-dark);
  }
`;

export const icon = styled.div`
  position: absolute;
  right: 15px;
  bottom: 13px;
  cursor: pointer;

  &.mini {
    right: 8px;
    bottom: 0px;
  }
  @media ${device.mobileM} {
    display: none;
  }
`;

export const iconSelect = styled.div`
  position: absolute;
  left: 10px;
  bottom: 13px;
  cursor: pointer;
  @media ${device.mobileM} {
    display: none;
  }
`;

export const dropDownItems = styled.ul`
  width: 100%;
  overflow-y: auto;
  border-radius: 0 0 16px 16px;
  background: #fdfdfd;
  box-shadow: 0px 2px 12px rgba(182, 182, 182, 0.2);
  list-style: none;
  position: absolute;
  top: 100%;
  max-height: 0;
  transition: 0.3s;
  padding: 0;
  z-index: 999;

  &.show {
    border-top: solid 1px #e2e8f0;
    padding: 6px 0;
    max-height: 300px;
    visibility: visible;
    border: 1px solid #eeeeee;
    border-top: 0;
  }
`;

export const dropDownItem = styled.li`
  padding: 4px 15px;
  /* border-bottom: solid 1px var(--gray3); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  border-radius: 4px;

  /* :last-child {
    border-bottom: none;
  } */

  img {
    margin-right: 10px;
  }

  &.active {
    background: #eef1f6;
  }
`;

export const text = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 0;
`;
export const img = styled.img``;
export const arrowImg = styled.img`
  @media ${device.mobileL} {
    display: none;
  }
`;

export const CheckText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  color: #4d4d4d;
`;
export const InputBox = styled.div`
  position: relative;
`;

export const ActiveItems = styled.ul`
  list-style: none;
  list-style: none;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  margin-top: 10px;
`;

export const ActiveItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  height: 32px;
  background: #cfeeec;
  color: var(--primary-green);
  border-radius: 90px;
  gap: 10px;
`;

export const ActiveItemButton = styled.button`
  border: none;
  background: none;
`;

export const AddButton = styled.button`
  width: 20px;
  height: 20px;
  border: none;
  background: var(--primary-green);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;
