import styled from 'styled-components';

export const Content = styled.div`
  padding: 0;
`;

export const Title = styled.h3`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin: 0;
`;

export const List = styled.div`
  border: 1px solid #e2e8f0;
  background: var(--white);
  border-radius: 15px;
`;

export const EmptyList = styled.div`
  padding: 30px;
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const Button = styled.button`
  width: 28px;
  height: 28px;
  background: #34bea526;
  border: none;
  border-radius: 30px;
`;

export const Span = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
`;

export const Div = styled.div``;
export const Item = styled.div`
  padding: 25px;
  border-bottom: 1px solid #00000021;

  &:last-child {
    border-bottom: none;
  }
`;
export const GridItem = styled.div`
  display: flex;
  gap: 10px;
`;

export const Inputs = styled.div`
  flex-grow: 1;
`;

export const ButtonDelete = styled.button`
  width: 28px;
  height: 28px;
  background: #ff5a5a21;
  border: none;
  border-radius: 30px;
`;
