// -- == //
import * as Yup from 'yup';

export const ValidationLogin = Yup.object().shape({
  mail: Yup.string().email('Email format').required('Email is Required'),
  password: Yup.string().required('Required')
});

export const ValidationForgetPassword = Yup.object().shape({
  mail: Yup.string().email('Email format').required('Email is Required')
});

export const ValidationCreateMenu = Yup.object().shape({
  title: Yup.string()
    .test('len', 'Must be less than 150 characters', (val: any) => (val ? val?.length < 150 : true))
    .required('Title is required'),
  category: Yup.string().required('Category is required'),
  image: Yup.mixed().test('required', 'You need to provide a file', (file) => {
    // return file && file.size <-- u can use this if you don't want to allow empty files to be uploaded;
    if (file) return true;
    return false;
  })
  // mixed().required("The image cannot be empty")
});

export const ValidationEditMenu = Yup.object().shape({
  title: Yup.string().test('len', 'Must be less than 150 characters', (val: any) => (val ? val?.length < 150 : true))
});

export const ValidationCreateEvent = Yup.object().shape({
  title: Yup.string()
    .test('len', 'Must be less than 150 characters', (val: any) => (val ? val?.length < 150 : true))
    .required('Title is required'),
  startSign: Yup.string().required('Registration start date is required'),
  endSign: Yup.string().required('Registration end date is required'),
  duration: Yup.string()
    .test('len', 'Must be less than 50 characters', (val: any) => (val ? val?.length < 50 : true))
    .required('Duration is required'),
  startsAt: Yup.string().required('Event start date is required'),
  type: Yup.string().required('Type is required'),
  description: Yup.string().test('len', 'Must be less than 1000 characters', (val: any) =>
    val ? val?.length < 1000 : true
  ),
  capacity: Yup.string().required('Capacity is required'),
  department: Yup.string().required('Department is required')
});

export const ValidationEditEvent = Yup.object().shape({
  title: Yup.string().test('len', 'Must be less than 150 characters', (val: any) => (val ? val?.length < 150 : true)),
  startSign: Yup.string(),
  endSign: Yup.string(),
  duration: Yup.string().test('len', 'Must be less than 50 characters', (val: any) => (val ? val?.length < 50 : true)),
  startsAt: Yup.string(),
  type: Yup.string(),
  description: Yup.string().test('len', 'Must be less than 1000 characters', (val: any) =>
    val ? val?.length < 1000 : true
  ),
  capacity: Yup.string()
});

export const ValidationFilterStatistics = Yup.object().shape({
  items: Yup.array().min(1, 'You must select at least one option')
  // date: Yup.string().required("Date is required"),
});

export const ValidationTicketCategory = Yup.object().shape({
  adminIds: Yup.array().min(1, 'You must select at least one supporter'),
  titleEn: Yup.string().required('Title is required'),
  titleAr: Yup.string().required('Title is required'),
  imageName: Yup.string().required('Icon is required')
});

export const ValidationCreateSurvey = Yup.object().shape({
  startsAt: Yup.string().required('Start date is required'),
  endsAt: Yup.string().required('End date is required'),
  title: Yup.string().required('Title is required')
});

export const ValidationCreateQuestionSurvey = Yup.object().shape({
  question: Yup.string().required('Question is required'),
  type: Yup.string().required('Type is required')
});

export const ValidationCreateSendToAllNotif = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  body: Yup.string().required('Body is required')
});

export const ValidationCreateDepFloorNotif = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  body: Yup.string().required('Body is required'),
  floor: Yup.string().required('Floor is required'),
  department: Yup.string().required('Department is required')
});

export const ValidationCreateSendUserNotif = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  body: Yup.string().required('Body is required'),
  users: Yup.array().min(1, 'Users is required')
});

export const ValidationCreateNews = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  body: Yup.string().required('Body is required')
});

export const ValidationCreateFloor = Yup.object().shape({
  name: Yup.string().required('Name is required')
});

export const ValidationChnageRole = Yup.object().shape({
  password: Yup.string()
    .required('The password cannot be empty')
    .min(8, 'The password must be at least 8 characters long')
    .matches(/^(?=.*[a-z])/, ' Your password must have at least 1 lowercase letter')
    .matches(/^(?=.*[A-Z])/, '  Your password must have at least 1 capital letter')
    .matches(/^(?=.*[0-9])/, '  Your password must have at least 1 number')
    .matches(/[@$!%*#?&]+/, 'Need one special character'),
  repeat_password: Yup.string()
    .required('The confirm password cannot be empty')
    .oneOf([Yup.ref('password'), ''], 'The password does not match')
});

export const ValidationFilterDate = Yup.object().shape({
  startDate: Yup.string(),
  endDate: Yup.string().test('date', 'The end date cannot be less than the start date', (val: any) =>
    val ? Yup.ref('startDate') < val : true
  )
});

export const ValidationReservationUpdateTime = Yup.object().shape({
  startDate: Yup.string().required('Start date is required'),
  endDate: Yup.string().required('End date is required')
});
