import * as S from './styles';
import DashboardEvents from './lib/events';
import DashboardCafeteria from './lib/cafeteria';
import DashboardStationary from './lib/stationary';
import DashboardMaintenance from './lib/maintenance';
import DashboardHsse from './lib/hsse';

export default function DashboardPage() {
  return (
    <S.Container>
      <S.Title>Dashboard</S.Title>
      <DashboardEvents />
      <DashboardCafeteria />
      <DashboardMaintenance />
      <DashboardHsse />
      <DashboardStationary />
    </S.Container>
  );
}
