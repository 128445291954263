// import PaginationApp from "components/pagination";

// import * as T from "common/types";
import * as S from '../../styles';
import Gap from 'components/gap';
import timezoneToDate from 'common/helpers/timezoneToDate';

interface IProps {
  data: any;
}

export default function TicketDetailTypeEventInfo({ data }: IProps) {
  return (
    <S.Detail>
      <S.Item>
        <S.Title>Title</S.Title>
        <S.Text>{data?.name}</S.Text>
      </S.Item>
      <S.Item>
        <S.Title>Description</S.Title>
        <S.Text className="text-wrap">{data?.description}</S.Text>
      </S.Item>
      <S.Item>
        <S.Title>Start Date</S.Title>
        <S.Text>{timezoneToDate(data?.startDate)}</S.Text>
      </S.Item>
      <S.Item>
        <S.Title>End Date</S.Title>
        <S.Text>{timezoneToDate(data?.endDate)}</S.Text>
      </S.Item>
      <S.Item>
        <S.Title>Attendance Numbers</S.Title>
        <S.Text>{data?.attendanceNumbers}</S.Text>
      </S.Item>
      <S.Item style={{ borderBottom: 'none' }}>
        <S.Title>Duration</S.Title>
        <S.Text>{data?.duration}</S.Text>
      </S.Item>
      <S.Item style={{ borderBottom: 'none' }}>
        <Gap size="15px" />
        <S.MainTitle>Requirements</S.MainTitle>
        <Gap size="10px" />
        {data?.requirements.map((item: any) => (
          <>
            <S.Grid>
              <S.IconCon>
                <S.Icon src={item.icon || '/images/default-avatar.jpg'} />
              </S.IconCon>
              <S.Text>{item.title}</S.Text>
            </S.Grid>
            <Gap size="7px" />
          </>
        ))}
      </S.Item>
    </S.Detail>
  );
}
