import styled from 'styled-components';

import { device } from 'common/constants/mediaQueries';

interface buttonColor {
  buttonColor?: string;
  buttonFont?: string;
}

export const PrimayButtonC = styled.button`
  height: 50px;
  background: var(--primary-green);
  border-radius: 16px;
  padding: 0 30px;
  border: none;
  font-style: normal;
  font-weight: 600;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0em;

  span {
    font-weight: 700;

    @media ${device.tabletL} {
      font-weight: 600;
    }
  }

  &:focus {
    outline: double 3px var(--primary-green) !important;
  }

  &:disabled {
    background: #b6b6b6;
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const PrimayButtonLinkC = styled.div`
  a {
    color: var(--white) !important;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    background: var(--primary-green);
    height: 50px;
    border-radius: 16px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    text-decoration: none;
    /* margin: 0 3px; */
    justify-content: center;
    gap: 10px;

    span {
      font-weight: 700;

      @media ${device.tabletL} {
        font-weight: 600;
      }
    }

    /* &:active {
      background: var(-);
    } */

    &:focus {
      outline: double 3px var(--primary-green);
    }

    &.disabled {
      opacity: 0.5;
      background: #b6b6b6;
      cursor: not-allowed;
    }
  }
`;

export const text = styled.span<buttonColor>`
  font-style: normal;
  line-height: 19px;
  color: ${({ buttonColor }) => (buttonColor ? buttonColor : 'white')};
  font-size: ${({ buttonFont }) => (buttonFont ? buttonFont : '14px')};
`;
