import { useState } from 'react';
import { ReactSVG } from 'react-svg';

import SecundButton from 'components/form/buttons/secundButton';
import ModalApp from 'components/modalApp';
import CafeteriaCreateForm from './lib/form';
import { useActions } from 'common/hooks/useActions';

import * as S from './styles';

export default function CafeteriaMenuManagementCreate() {
  const [show, setShow] = useState<boolean>(false);

  const { cafeteriaMenuManagementRequestAction } = useActions();

  const fetchData = () => {
    cafeteriaMenuManagementRequestAction({ page: 1, take: 10 });
  };

  const handleShowModal = () => {
    setShow(true);
  };

  const handleCloseModal = () => {
    setShow(false);
  };

  return (
    <S.Content>
      <SecundButton hasIcon={true} onClick={handleShowModal}>
        Create new item
      </SecundButton>

      <ModalApp show={show} fixed handleClose={handleCloseModal} style={{ width: 400, padding: '20px 40px' }}>
        <S.Div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <S.Icon>
            <ReactSVG
              src={'/icons/stroke/plus.svg'}
              beforeInjection={(svg) => {
                svg.setAttribute('fill', `var(--white)`);
                svg.setAttribute('width', `14px`);
                svg.setAttribute('height', `14px`);
              }}
            />
          </S.Icon>
          <S.Title>Create New Menu Item</S.Title>
          <CafeteriaCreateForm closeModal={handleCloseModal} fetchData={fetchData} key={Math.random()} />
        </S.Div>
      </ModalApp>
    </S.Content>
  );
}
