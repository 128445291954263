// import TicketMenuManagementCreate from "./lib/create";
import EventsTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function AccessManagementLeaveRequestsPage() {
  const { tickets, ticketCategory } = useTypedSelector((state) => state.Tickets);
  const { departments } = useTypedSelector((state) => state.Departments);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Leave Requests</S.Title>
      </S.Head>
      <Gap size="20px" />
      <EventsTable data={tickets.data} departments={departments.data} ticketCategory={ticketCategory.data} />

      <AppLoading loading={tickets.loading || departments.loading || ticketCategory.loading} />
    </S.Container>
  );
}
