/* eslint-disable array-callback-return */
// -- == //
import { FC } from 'react';
import { ReactSVG } from 'react-svg';
import * as S from './styles';

interface IProps {
  tdFields: any;
  headers: IPropsHeader[];
  data: any[];
  style?: object;
  totlaItems?: number;
  pagination?: boolean;
  headerStyle?: 'two' | 'one';
  headerColor?: string;
  loading?: boolean;
}

interface IPropsHeader {
  id: string | number;
  title: string;
  icon?: string;
  dir?: 'left' | 'right';
  width?: string | number;
}

const AppTreeTable: FC<IProps> = ({
  tdFields,
  data,
  headers,
  style,
  headerStyle = 'one',
  headerColor = '#8A93AC',
  loading
}) => {
  let childLevel = 1;
  const renderChild = (item: any) => {
    if (item.child.length) {
      childLevel += 1;

      return item.child.map((itemChild: any, index: number) => (
        <>
          <S.Tr key={index} className={`${itemChild.level} parent-${item.id} hide`}>
            {Object.values(tdFields)?.map((field: any, index2) => (
              <S.Td key={`td-${index2}`}>
                {field(
                  itemChild,
                  true,
                  itemChild.child.length ? true : false,
                  () => {
                    [...(document.querySelectorAll(`.parent-${itemChild.id}`) as any)].map((el) => {
                      if (el.classList.contains('show')) {
                        el.classList.add('hide');
                        el.classList.remove('show');
                      } else if (el.classList.contains('hide')) {
                        el.classList.add('show');
                        el.classList.remove('hide');
                      }
                    });
                  },
                  `level-${childLevel}`
                )}
              </S.Td>
            ))}
          </S.Tr>
          {renderChild(itemChild)}
        </>
      ));
    }
  };

  const renderList = (list: any) => {
    return list.map((item: any) => {
      return {
        ...item,
        level: `level-1`,
        child: item.child.map((item: any) => {
          return {
            ...item,
            level: `level-2`,
            child: item.child.map((item: any) => {
              return {
                ...item,
                level: `level-3`,
                child: item.child.map((item: any) => {
                  return {
                    ...item,
                    level: `level-4`,
                    child: item.child.map((item: any) => {
                      return {
                        ...item,
                        level: `level-5`,
                        child: item.child.map((item: any) => {
                          return {
                            ...item,
                            level: `level-6`,
                            child: item.child
                          };
                        })
                      };
                    })
                  };
                })
              };
            })
          };
        })
      };
    });
  };

  return (
    <>
      <S.TableCon>
        {loading ? (
          <S.TableLoading>
            <ReactSVG src="/loader.svg" />
          </S.TableLoading>
        ) : (
          <></>
        )}
        <S.Table style={style}>
          <S.Thead className={headerStyle}>
            <S.Tr>
              {headers?.map((item: IPropsHeader, index) => (
                <S.Th key={index} style={{ width: item.width }}>
                  <S.HeaderTh
                    key={item.id}
                    style={{
                      color: headerColor,
                      justifyContent: item.dir || 'center'
                    }}
                  >
                    {item.icon ? (
                      <ReactSVG
                        src={item.icon}
                        beforeInjection={(svg) => {
                          svg.setAttribute('stroke', `${headerColor}`);
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {item.title}
                  </S.HeaderTh>
                </S.Th>
              ))}
            </S.Tr>
          </S.Thead>
          <S.Tbody>
            {renderList(data)?.map((item: any, index: number) => {
              return (
                <>
                  <S.Tr key={index} className={item.level}>
                    {Object.values(tdFields)?.map((field: any, index2) => (
                      <S.Td key={`td-${index2}`}>
                        {field(item, false, item.child.length ? true : false, (item: { id: any; child: any[] }) => {
                          [...(document.querySelectorAll(`.parent-${item.id}`) as any)].map((el) => {
                            if (el.classList.contains('show')) {
                              el.classList.add('hide');
                              el.classList.remove('show');
                              if (item?.child?.length)
                                item?.child?.map((item2) => {
                                  [...(document.querySelectorAll(`.parent-${item2.id}`) as any)].map((el) => {
                                    el.classList.add('hide');
                                    el.classList.remove('show');
                                    if (item?.child?.length)
                                      item?.child?.map((item2) => {
                                        [...(document.querySelectorAll(`.parent-${item2.id}`) as any)].map((el) => {
                                          el.classList.add('hide');
                                          el.classList.remove('show');
                                        });
                                      });
                                  });
                                });
                            } else if (el.classList.contains('hide')) {
                              el.classList.add('show');
                              el.classList.remove('hide');
                            }
                          });
                        })}
                      </S.Td>
                    ))}
                  </S.Tr>

                  {renderChild(item)}
                </>
              );
            })}
          </S.Tbody>
        </S.Table>
      </S.TableCon>
    </>
  );
};

export default AppTreeTable;
