import { Formik } from 'formik';
import * as S from './styles';
import { useActions } from 'common/hooks/useActions';
import { ValidationLogin } from 'common/validations';
import DropDown from 'components/form/dropDown';
import Gap from 'components/gap';
import PrimaryButton from 'components/form/buttons/primaryButton';
// import * as T from "common/types";

export default function CafeteriaStatisticsSidebarExportDataForm() {
  const { logInRequestAction } = useActions();

  return (
    <S.Content>
      <Formik
        initialValues={{ mail: '', password: '', remember_me: false }}
        validationSchema={ValidationLogin}
        onSubmit={(values, { setSubmitting }) => {
          logInRequestAction({ ...values, remember_me: JSON.stringify(values.remember_me) }, () => {});
          setSubmitting(false);
        }}
      >
        {({
          // values,
          errors,
          touched,
          handleChange,
          handleSubmit
          // isSubmitting,
          // setValues,
        }) => (
          <>
            <DropDown
              list={[]}
              disabled
              onChange={handleChange}
              placeholder="Select or search user"
              hasSearch
              // defaultValue={values.mail}
              message={touched.mail && errors.mail}
              error={errors.mail as unknown as boolean}
              inputStyle={{
                height: 40,
                borderRadius: 7,
                fontSize: 12,
                padding: '0 15px'
              }}
            />

            <Gap size="10px" />

            <PrimaryButton style={{ width: '100%', height: 42, borderRadius: 8 }} onClick={handleSubmit} disabled>
              Export
            </PrimaryButton>
          </>
        )}
      </Formik>
    </S.Content>
  );
}
