import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'common/helpers/toastConfig';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';

import * as types from 'common/constants';
// import * as T from "common/types";
import * as services from 'common/services';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * User list actions
 */
export const usersRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.USERS.REQUEST,
        payload: {}
      });

      const response: any = await services.usersService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(usersSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(usersFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(usersFailureAction(error));
      throw error;
    }
  };
};

export const usersSuccessAction = (data: object) => {
  return {
    type: types.USERS.SUCCESS,
    payload: data
  };
};

export const usersFailureAction = (error: object) => {
  return {
    type: types.USERS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * User Detail actions
 */
export const userDetailRequestAction = (id?: string, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.USER_DETAIL.REQUEST,
        payload: {}
      });

      const response: any = await services.userDetailService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(userDetailSuccessAction(response.data.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(userDetailFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(userDetailFailureAction(error));
      throw error;
    }
  };
};

export const userDetailSuccessAction = (data: object) => {
  return {
    type: types.USER_DETAIL.SUCCESS,
    payload: data
  };
};

export const userDetailFailureAction = (error: object) => {
  return {
    type: types.USER_DETAIL.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Change Role actions
 */
export const changeRoleRequestAction = (data: any, callBack: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.CHANGE_ROLE.REQUEST,
        payload: {}
      });

      const response: any = await services.changeRoleService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(changeRoleSuccessAction(response.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(changeRoleFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(changeRoleFailureAction(error));
      throw error;
    }
  };
};

export const changeRoleSuccessAction = (data: object) => {
  return {
    type: types.CHANGE_ROLE.SUCCESS,
    payload: data
  };
};

export const changeRoleFailureAction = (error: object) => {
  return {
    type: types.CHANGE_ROLE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Roles actions
 */
export const rolesRequestAction = () => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.ROLES.REQUEST,
        payload: {}
      });

      const response: any = await services.rolesService();

      if (response.status === 200 || response.status === 201) {
        dispatch(rolesSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(rolesFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(rolesFailureAction(error));
      throw error;
    }
  };
};

export const rolesSuccessAction = (data: object) => {
  return {
    type: types.ROLES.SUCCESS,
    payload: data
  };
};

export const rolesFailureAction = (error: object) => {
  return {
    type: types.ROLES.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Admin Roles actions
 */
export const adminRolesRequestAction = () => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.ADMIN_ROLES.REQUEST,
        payload: {}
      });

      const response: any = await services.adminRolesService();

      if (response.status === 200 || response.status === 201) {
        dispatch(adminRolesSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(adminRolesFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(adminRolesFailureAction(error));
      throw error;
    }
  };
};

export const adminRolesSuccessAction = (data: object) => {
  return {
    type: types.ADMIN_ROLES.SUCCESS,
    payload: data
  };
};

export const adminRolesFailureAction = (error: object) => {
  return {
    type: types.ADMIN_ROLES.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Attendances actions
 */
export const attendancesRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.ATTENDANCES.REQUEST,
        payload: {}
      });

      const response: any = await services.accessManagementService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(attendancesSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(attendancesFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(attendancesFailureAction(error));
      throw error;
    }
  };
};

export const attendancesSuccessAction = (data: object) => {
  return {
    type: types.ATTENDANCES.SUCCESS,
    payload: data
  };
};

export const attendancesFailureAction = (error: object) => {
  return {
    type: types.ATTENDANCES.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Area List actions
 */
export const areaListRequestAction = () => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.AREA_LIST.REQUEST,
        payload: {}
      });

      const response: any = await services.areaListService();

      if (response.status === 200 || response.status === 201) {
        dispatch(areaListSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(areaListFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(areaListFailureAction(error));
      throw error;
    }
  };
};

export const areaListSuccessAction = (data: object) => {
  return {
    type: types.AREA_LIST.SUCCESS,
    payload: data
  };
};

export const areaListFailureAction = (error: object) => {
  return {
    type: types.AREA_LIST.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
