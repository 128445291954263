import { ApiCaller } from 'common/helpers/apiCaller';
// import convertToQueryString from "common/helpers/convertToQueryString";

// ------ Floor List ------ //
export const departmentsService = () => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/departments`
  });
};
