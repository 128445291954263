import { ReactSVG } from 'react-svg';
import * as S from './styles';
import { useEffect } from 'react';
import { useActions } from 'common/hooks/useActions';

interface IProps {
  // -- //
  data?: any;
}

export default function TicketCounts({ data }: IProps) {
  const { ticketStatisticsRequestAction } = useActions();
  useEffect(() => {
    ticketStatisticsRequestAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(data?.statusCounts?.length);

  return (
    <S.Content>
      <S.Item style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        <S.Title>Most issue category</S.Title>
        <S.Value style={{ color: '#8A93AC' }}></S.Value>
      </S.Item>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-green.svg" />
          </S.Icon>
          <S.Title>Open</S.Title>
        </S.Div>
        <S.Value style={{ color: '#1DBAAF' }}>{data?.statusCounts?.length ? data?.statusCounts[0]?.count : ''}</S.Value>
      </S.Item>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-blue.svg" />
          </S.Icon>
          <S.Title>New</S.Title>
        </S.Div>
        <S.Value style={{ color: '#20CAFF' }}>{data?.categoryCounts?.count}</S.Value>
      </S.Item>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-red.svg" />
          </S.Icon>
          <S.Title>Resolve</S.Title>
        </S.Div>
        <S.Value style={{ color: '#FF6464' }}>{data?.statusCounts?.length ? data?.statusCounts[1]?.count : ''}</S.Value>
      </S.Item>
    </S.Content>
  );
}
