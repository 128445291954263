import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

import { Crypto } from './crypto';

export const ApiCaller = (apiCallerConfig: AxiosRequestConfig) => {
  const token = Cookies.get('token') ? Crypto.decrypt(Cookies.get('token')) : '';
  let flagLogout = false;
  const axiosInstance: AxiosInstance = Axios.create({
    // mode: 'no-cors',
    headers: {
      accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
      ...apiCallerConfig.headers
    },
    responseType: 'json'
  });
  axiosInstance.interceptors.request.use(
    async (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (!error.response) {
        console.log(error);
        // toast({ message: Lang("Internet access error"), model: "error" });
        return false;
      }
      if (error.response.status >= 500) {
        // toast({ message: Lang("Server internal error"), model: "error" });
        return error.response;
      }
      if (error.response.status === 401 && window.location.pathname !== '/auth/login') {
        flagLogout = true;
        Cookies.remove('userInfo');
        Cookies.remove('token');

        window.location.assign('/auth/login');
        return error.response;
      }
      return error.response;
    }
  );
  if (!flagLogout) {
    return axiosInstance.request(apiCallerConfig);
  } else return {};
};
