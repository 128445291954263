import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'common/helpers/toastConfig';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';

import * as types from 'common/constants';
// import * as T from "common/types";
import * as services from 'common/services';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Floor list actions
 */
export const floorsRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.FLOORS.REQUEST,
        payload: {}
      });

      // -- //
      const response: any = await services.floorsService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(floorsSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(floorsFailureAction(response.data));
      }
      // -- //
    } catch (error: any) {
      console.log(error);
      dispatch(floorsFailureAction(error));
      throw error;
    }
  };
};

export const floorsSuccessAction = (data: object) => {
  return {
    type: types.FLOORS.SUCCESS,
    payload: data
  };
};

export const floorsFailureAction = (error: object) => {
  return {
    type: types.FLOORS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Create Floor actions
 */
export const floorCreateRequestAction = (data: object, callBack: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.FLOOR_CREATE.REQUEST,
        payload: {}
      });

      // -- //
      const response: any = await services.floorCreateService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(floorCreateSuccessAction(response.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(floorCreateFailureAction(response.data));
      }
      // -- //
    } catch (error: any) {
      console.log(error);
      dispatch(floorCreateFailureAction(error));
      throw error;
    }
  };
};

export const floorCreateSuccessAction = (data: object) => {
  return {
    type: types.FLOOR_CREATE.SUCCESS,
    payload: data
  };
};

export const floorCreateFailureAction = (error: object) => {
  return {
    type: types.FLOOR_CREATE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Edit Floor actions
 */
export const floorEditRequestAction = (data: object, callBack: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.FLOOR_EDIT.REQUEST,
        payload: {}
      });

      // -- //
      const response: any = await services.floorEditService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(floorEditSuccessAction(response.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(floorEditFailureAction(response.data));
      }
      // -- //
    } catch (error: any) {
      console.log(error);
      dispatch(floorEditFailureAction(error));
      throw error;
    }
  };
};

export const floorEditSuccessAction = (data: object) => {
  return {
    type: types.FLOOR_EDIT.SUCCESS,
    payload: data
  };
};

export const floorEditFailureAction = (error: object) => {
  return {
    type: types.FLOOR_EDIT.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Edit Floor actions
 */
export const floorDeleteRequestAction = (id: number, callBack: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.FLOOR_DELETE.REQUEST,
        payload: {}
      });

      // -- //
      const response: any = await services.floorDeleteService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(floorDeleteSuccessAction(response.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(floorDeleteFailureAction(response.data));
      }
      // -- //
    } catch (error: any) {
      console.log(error);
      dispatch(floorDeleteFailureAction(error));
      throw error;
    }
  };
};

export const floorDeleteSuccessAction = (data: object) => {
  return {
    type: types.FLOOR_DELETE.SUCCESS,
    payload: data
  };
};

export const floorDeleteFailureAction = (error: object) => {
  return {
    type: types.FLOOR_DELETE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
