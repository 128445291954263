import React from 'react';
// import firebase from "firebase/app";

import { ReactSVG } from 'react-svg';
import MiniPopUp from 'components/modal/miniPopUp';
import { Link, useNavigate } from 'react-router-dom';
import Gap from 'components/gap';
import dateToString from 'common/helpers/dateToString';
import { useActions } from 'common/hooks/useActions';
import { handleDeleteToken } from '../../../../../firebase';

import * as S from './styles';

interface IProps {
  notifications: any;
  userInfo?: any;
}

const Header: React.FC<IProps> = ({ notifications, userInfo }) => {
  const nav = useNavigate();
  const { notficationUpdateReadRequestAction, logOutRequestAction } = useActions();

  const handleLogout = () => {
    handleDeleteToken();
    logOutRequestAction(() => {});
    nav('/auth/login');
  };

  return (
    <S.HeaderContainer>
      <S.Icons>
        <S.Icon>
          <ReactSVG
            src="/icons/stroke/search.svg"
            beforeInjection={(svg) => {
              svg.setAttribute('stroke', `#C5C7CD`);
            }}
          />
        </S.Icon>

        <MiniPopUp
          buttonNode={
            <S.Icon
              className={notifications?.countUnread ? 'newNotif' : ''}
              onClick={() => notficationUpdateReadRequestAction({ readAll: true })}
            >
              <ReactSVG
                src="/icons/fill/notif.svg"
                beforeInjection={(svg) => {
                  svg.setAttribute('fill', `#C5C7CD`);
                }}
              />
            </S.Icon>
          }
          popUpStyle={{ width: 315, padding: 0 }}
        >
          <S.Notifications>
            <Gap size="20px" />

            <S.Grid style={{ padding: '0 20px' }}>
              <S.NotifTitle>YOUR NOTIFICATIONS</S.NotifTitle>
              {notifications?.countUnread ? (
                <S.NotifSpan className="flex-center">{notifications?.countUnread} New</S.NotifSpan>
              ) : (
                <></>
              )}
            </S.Grid>
            <Gap size="10px" />
            <S.NotifList>
              {notifications?.list?.map((item: any) => {
                return (
                  <S.NotifItem className={!item?.isRead ? 'unRead' : ''}>
                    <S.NotifItemTitle>{item?.title}</S.NotifItemTitle>
                    <S.NotifItemText>{item?.body}</S.NotifItemText>
                    <S.NotifDate>{dateToString(item?.createdAt)}</S.NotifDate>
                  </S.NotifItem>
                );
              })}
            </S.NotifList>
          </S.Notifications>
        </MiniPopUp>
      </S.Icons>
      <S.Line />
      <MiniPopUp
        buttonNode={
          <S.ProfileHeader>
            <S.ProfileTitle>{userInfo?.mail}</S.ProfileTitle>
            <S.Image src="/images/default-avatar.jpg" />
          </S.ProfileHeader>
        }
        popUpStyle={{ width: 152 }}
      >
        <S.Ul>
          {/* <S.Li>
            <Link to="/panel/dashboard/change-password">Change Password</Link>
          </S.Li> */}
          <S.Li onClick={handleLogout}>
            <Link to="#">Sign out</Link>
          </S.Li>
        </S.Ul>
      </MiniPopUp>
    </S.HeaderContainer>
  );
};

export default Header;
