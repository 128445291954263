/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';

import AppTable from 'components/appTable';
import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import { useActions } from 'common/hooks/useActions';
import PrimaryButton from 'components/form/buttons/primaryButton';
import EventFilterPopup from '../filterForm';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import PaginationApp from 'components/pagination';

import * as S from './styles';
import * as T from 'common/types';
import dateToString from 'common/helpers/dateToString';

interface IProps {
  data: { list: []; totalPages?: number };
  departments: [];
  loading: boolean;
}

export default function EventsTable({ data, departments, loading }: IProps) {
  const { eventsRequestAction } = useActions();
  const [meta, setMeta] = useState({
    page: 1,
    page_size: 10,
    search: '',
    type: ''
  });

  const fetchData = () => {
    eventsRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  const handleChangePage = (page: number) => {
    setMeta({ ...meta, page });
  };

  const handleChangePageSize = (page_size: number) => {
    setMeta({ ...meta, page_size });
  };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => setMeta({ ...meta, search: e })}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
            value={meta.search}
          />
          <EventFilterPopup setFilter={setMeta} filter={meta} departments={departments} />
        </S.Head>
        <Gap size="20px" />
        {meta.search && !data.list.length ? (
          <S.P>No result was found for this search query </S.P>
        ) : (
          <AppTable
            headers={[
              { id: 1, title: 'ID', dir: 'left' },
              { id: 4, title: 'Title', dir: 'left' },
              { id: 5, title: 'Type', dir: 'left' },
              { id: 6, title: 'Capacity' },
              { id: 7, title: 'Department', dir: 'left' },
              { id: 7, title: 'Duration', dir: 'left' },
              { id: 8, title: 'Start Date', dir: 'left' },
              { id: 9, title: 'Edit' },
              { id: 10, title: '' }
            ]}
            data={data.list}
            loading={loading}
            tdFields={{
              id: (item: T.Pages.EventItem) => <S.Div className="text-left">{item?.id}</S.Div>,

              title: (item: T.Pages.EventItem) => (
                <S.Div className="text-left captal">
                  {item.title?.length > 50 ? item.title.substring(0, 50) + ' ... ' : item.title}
                </S.Div>
              ),
              type: (item: T.Pages.EventItem) => <S.Div className="text-left">{item.type}</S.Div>,
              capacity: (item: T.Pages.EventItem) => <S.Div className="text-center">{item?.capacity}</S.Div>,
              department: (item: T.Pages.EventItem) => <S.Div className="text-left">{item.department}</S.Div>,
              duration: (item: T.Pages.EventItem) => <S.Div className="text-left">{item.duration}</S.Div>,
              startsAt: (item: T.Pages.EventItem) => {
                return <S.Div className="text-left captal">{dateToString(item.startsAt)}</S.Div>;
              },
              edit: (item: T.Pages.EventItem) => (
                <Link to={`/panel/events/${item.id}`}>
                  <ReactSVG
                    src="/icons/stroke/pen-card.svg"
                    beforeInjection={(svg) => {
                      svg.setAttribute('stroke', `#8A93AC`);
                      // svg.setAttribute("width", `12px`);
                      // svg.setAttribute("heigth", `12px`);
                    }}
                    wrapper="span"
                  />
                </Link>
              ),
              requestLocation: (item: T.Pages.EventItem) => (
                <S.Div className="text-left captal">
                  <PrimaryButton
                    style={{
                      height: 28,
                      fontSize: 12,
                      background: '#8A93AC',
                      color: 'var(--white)',
                      width: 94,
                      margin: 'auto',
                      borderRadius: 8
                    }}
                    link={`/panel/events/contributors/${item.id}`}
                    disabled={item.isPublic}
                  >
                    {item.isPublic ? 'Public' : 'Contributors'}
                  </PrimaryButton>
                </S.Div>
              )
            }}
          />
        )}
      </PanelBoxLayout>
      {data.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.page_size * data.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={data.totalPages}
          pageSize={meta.page_size}
          handleChangeTake={handleChangePageSize}
        />
      ) : (
        <></>
      )}
    </S.Content>
  );
}
