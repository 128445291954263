// -- == //
import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'common/helpers/toastConfig';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';

import * as types from 'common/constants';
// import * as T from "common/types";
import * as services from 'common/services';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * cafeteria Menu Management list actions
 */
export const cafeteriaMenuManagementRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.CAFETERIA_MENU.REQUEST,
        payload: {}
      });

      const response: any = await services.cafeteriaMenuManagementService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(cafeteriaMenuManagementSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(cafeteriaMenuManagementFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(cafeteriaMenuManagementFailureAction(error));
      throw error;
    }
  };
};

export const cafeteriaMenuManagementSuccessAction = (data: object) => {
  return {
    type: types.CAFETERIA_MENU.SUCCESS,
    payload: data
  };
};

export const cafeteriaMenuManagementFailureAction = (error: object) => {
  return {
    type: types.CAFETERIA_MENU.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * cafeteria Create Menu Management actions
 */
export const cafeteriaCreateMenuManagementRequestAction = (data: any, callBack: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.CAFETERIA_CREATE_MENU.REQUEST,
        payload: {}
      });

      const formData = new FormData();

      formData.append('image', data.image);
      formData.append('title', data.title);
      formData.append('status', data.status.toString());
      formData.append('category', data.category);

      const response: any = await services.cafeteriaCreateMenuManagementService(formData);

      if (response.status === 200 || response.status === 201) {
        dispatch(cafeteriaCreateMenuManagementSuccessAction(response.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(cafeteriaCreateMenuManagementFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(cafeteriaCreateMenuManagementFailureAction(error));
      throw error;
    }
  };
};

export const cafeteriaCreateMenuManagementSuccessAction = (data: object) => {
  return {
    type: types.CAFETERIA_CREATE_MENU.SUCCESS,
    payload: data
  };
};

export const cafeteriaCreateMenuManagementFailureAction = (error: object) => {
  return {
    type: types.CAFETERIA_CREATE_MENU.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * cafeteria Edit Menu Management actions
 */
export const cafeteriaEditMenuManagementRequestAction = (data: any, callBack: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.CAFETERIA_EDIT_MENU.REQUEST,
        payload: {}
      });

      const response: any = await services.cafeteriaEditMenuManagementService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(cafeteriaEditMenuManagementSuccessAction(response.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(cafeteriaEditMenuManagementFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(cafeteriaEditMenuManagementFailureAction(error));
      throw error;
    }
  };
};

export const cafeteriaEditMenuManagementSuccessAction = (data: object) => {
  return {
    type: types.CAFETERIA_EDIT_MENU.SUCCESS,
    payload: data
  };
};

export const cafeteriaEditMenuManagementFailureAction = (error: object) => {
  return {
    type: types.CAFETERIA_EDIT_MENU.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * cafeteria Upload actions
 */
export const cafeteriaUploadRequestAction = (data: any, callBack: (fileName: string) => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.CAFETERIA_UPLOAD.REQUEST,
        payload: {}
      });

      const formData = new FormData();
      formData.append('image', data.image);

      const response: any = await services.cafeteriaUploadService(formData);

      if (response.status === 200 || response.status === 201) {
        dispatch(cafeteriaUploadSuccessAction(response.data));
        callBack && callBack(response.data?.data?.fileNameOnly);
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(cafeteriaUploadFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(cafeteriaUploadFailureAction(error));
      throw error;
    }
  };
};

export const cafeteriaUploadSuccessAction = (data: object) => {
  return {
    type: types.CAFETERIA_UPLOAD.SUCCESS,
    payload: data
  };
};

export const cafeteriaUploadFailureAction = (error: object) => {
  return {
    type: types.CAFETERIA_UPLOAD.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * cafeteria Chart actions
 */
export const cafeteriaChartRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.CAFETERIA_CHART.REQUEST,
        payload: {}
      });

      const response: any = await services.cafeteriaChartService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(cafeteriaChartSuccessAction(response.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(cafeteriaChartFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(cafeteriaChartFailureAction(error));
      throw error;
    }
  };
};

export const cafeteriaChartSuccessAction = (data: object) => {
  return {
    type: types.CAFETERIA_CHART.SUCCESS,
    payload: data
  };
};

export const cafeteriaChartFailureAction = (error: object) => {
  return {
    type: types.CAFETERIA_CHART.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * cafeteria Edit Menu Management actions
 */
export const cafeteriaStatusRequestAction = (data: object, callBack: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.CAFETERIA_STATUS.REQUEST,
        payload: {}
      });

      const response: any = await services.cafeteriaStatusService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(cafeteriaStatusSuccessAction(response.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(cafeteriaStatusFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(cafeteriaStatusFailureAction(error));
      throw error;
    }
  };
};

export const cafeteriaStatusSuccessAction = (data: object) => {
  return {
    type: types.CAFETERIA_STATUS.SUCCESS,
    payload: data
  };
};

export const cafeteriaStatusFailureAction = (error: object) => {
  return {
    type: types.CAFETERIA_STATUS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
