import AdminListTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function AdminsPage() {
  const { userList, roles } = useTypedSelector((state: any) => state.Users);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Admin List</S.Title>
      </S.Head>
      <Gap size="20px" />
      <AdminListTable data={userList.data} loading={userList.loading} roles={roles.data} />

      <AppLoading loading={roles.loading} />
    </S.Container>
  );
}
