/* eslint-disable react-hooks/exhaustive-deps */
import { ReactSVG } from 'react-svg';
import { Formik } from 'formik';

// import { ValidationLogin } from "common/validations";
import DropDown from 'components/form/dropDown';
import Gap from 'components/gap';
import PrimaryButton from 'components/form/buttons/primaryButton';
import { useActions } from 'common/hooks/useActions';

import * as S from './styles';
import * as T from 'common/types';
import { useEffect } from 'react';

interface IProps {
  data: T.Pages.FloorListItem;
  closeModal?: () => void;
  fetchData?: () => void;
  teaboyList: T.Pages.TeaboyListItem[];
}

export default function AssignTeaboyForm({ data, teaboyList, closeModal, fetchData }: IProps) {
  const { teaboysRequestAction, teaboyAssignToFloorRequestAction } = useActions();

  useEffect(() => {
    teaboysRequestAction({ isAdding: true });
  }, []);

  return (
    <S.Content>
      <S.Icon>
        <ReactSVG
          src={'/icons/stroke/plus.svg'}
          beforeInjection={(svg) => {
            svg.setAttribute('fill', `var(--white)`);
            svg.setAttribute('width', `14px`);
            svg.setAttribute('height', `14px`);
          }}
        />
      </S.Icon>
      <S.Title>Assign Teaboy to floor {data?.name}</S.Title>
      <Formik
        initialValues={{
          id: data?.teaBoy?.userId,
          floor: data?.id || '',
          displayName: data?.teaBoy?.user?.displayName
        }}
        enableReinitialize
        // validationSchema={ValidationLogin}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          teaboyAssignToFloorRequestAction(values, () => {
            resetForm();
            closeModal && closeModal();
            fetchData && fetchData();
          });
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          // handleChange,
          handleSubmit,
          // isSubmitting,
          setValues
        }) => (
          <>
            <DropDown
              list={teaboyList.map((item: T.Pages.TeaboyListItem) => {
                return { id: item.id, title: item?.displayName };
              })}
              onChange={(e) => {
                // -- //
                setValues({ ...values, id: e.id as any, displayName: e.title });
              }}
              placeholder="Select or search user"
              key={values.id}
              defaultValue={{
                id: values.id,
                title: values.displayName
              }}
              message={touched.id && errors.id}
              error={errors.id as unknown as boolean}
              inputStyle={{
                height: 40,
                borderRadius: 7,
                fontSize: 12,
                padding: '0 15px'
              }}
            />

            <Gap size="10px" />

            <PrimaryButton
              style={{ width: '100%', height: 42, borderRadius: 8 }}
              onClick={handleSubmit}
              // disabled={isSubmitting}
            >
              Assign
            </PrimaryButton>
          </>
        )}
      </Formik>
    </S.Content>
  );
}
