import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h2`
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: var(--primary-dark-two);
  margin: 20px 0;
`;

export const Name = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0a214280;
`;

export const Div = styled.div``;

export const BoxTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
`;
