import * as types from 'common/constants';

const initialState = {
  events: {
    data: {
      list: [],
      totalPages: null
    },
    loading: false,
    error: null
  },
  eventCreate: {
    data: {},
    loading: false,
    error: null
  },
  eventUpdate: {
    data: {},
    loading: false,
    error: null
  },
  eventDelete: {
    data: {},
    loading: false,
    error: null
  },
  eventUpload: {
    data: {},
    loading: false,
    error: null
  },
  eventDetail: {
    data: {},
    loading: false,
    error: null
  },
  eventCancel: {
    data: {},
    loading: false,
    error: null
  },
  eventChangeVisible: {
    data: {},
    loading: false,
    error: null
  },
  contributors: {
    data: {
      list: [],
      totalPages: null,
      eventInfo: {}
    },
    loading: false,
    error: null
  },
  eventEnd: {
    data: {},
    loading: false,
    error: null
  },
  contributorAccept: {
    data: {},
    loading: false,
    error: null
  },
  contributorReject: {
    data: {},
    loading: false,
    error: null
  },
  contributorUploadQuest: {
    data: {},
    loading: false,
    error: null
  }
};

interface Reducer {
  type: string;
  // -- //
  payload: any;
}

const Authenticate = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Events cases
     */
    case types.EVENTS.REQUEST:
      return {
        ...state,
        events: {
          data: {
            list: state.events.data.list,
            totalPages: null
          },
          loading: true,
          error: null
        }
      };
    case types.EVENTS.SUCCESS:
      return {
        ...state,
        events: {
          data: {
            list: payload.list ? payload.list : payload,
            totalPages: payload?.totalPages
          },
          loading: false,
          error: null
        }
      };
    case types.EVENTS.FAILURE:
      return {
        ...state,
        events: {
          data: {
            list: [],
            totalPages: null
          },
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Event Create cases
     */
    case types.EVENT_CREATE.REQUEST:
      return {
        ...state,
        eventCreate: { data: {}, loading: true, error: null }
      };
    case types.EVENT_CREATE.SUCCESS:
      return {
        ...state,
        eventCreate: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.EVENT_CREATE.FAILURE:
      return {
        ...state,
        eventCreate: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Event Update cases
     */
    case types.EVENT_UPDATE.REQUEST:
      return {
        ...state,
        eventUpdate: { data: {}, loading: true, error: null }
      };
    case types.EVENT_UPDATE.SUCCESS:
      return {
        ...state,
        eventUpdate: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.EVENT_UPDATE.FAILURE:
      return {
        ...state,
        eventUpdate: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Event Upload cases
     */
    case types.EVENT_UPLOAD.REQUEST:
      return {
        ...state,
        eventUpload: { data: {}, loading: true, error: null }
      };
    case types.EVENT_UPLOAD.SUCCESS:
      return {
        ...state,
        eventUpload: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.EVENT_UPLOAD.FAILURE:
      return {
        ...state,
        eventUpload: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Event Detail cases
     */
    case types.EVENT_DETAIL.REQUEST:
      return {
        ...state,
        eventDetail: { data: {}, loading: true, error: null }
      };
    case types.EVENT_DETAIL.SUCCESS:
      return {
        ...state,
        eventDetail: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.EVENT_DETAIL.FAILURE:
      return {
        ...state,
        eventDetail: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Event Cancel cases
     */
    case types.EVENT_CANCEL.REQUEST:
      return {
        ...state,
        eventCancel: { data: {}, loading: true, error: null }
      };
    case types.EVENT_CANCEL.SUCCESS:
      return {
        ...state,
        eventCancel: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.EVENT_CANCEL.FAILURE:
      return {
        ...state,
        eventCancel: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Event Change Visible cases
     */
    case types.EVENT_CHANGE_VISIBLE.REQUEST:
      return {
        ...state,
        eventChangeVisible: { data: {}, loading: true, error: null }
      };
    case types.EVENT_CHANGE_VISIBLE.SUCCESS:
      return {
        ...state,
        eventChangeVisible: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.EVENT_CHANGE_VISIBLE.FAILURE:
      return {
        ...state,
        eventChangeVisible: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Events cases
     */
    case types.EVENT_CONTRIBUTORS.REQUEST:
      return {
        ...state,
        contributors: {
          data: {
            list: state.contributors.data.list,
            totalPages: null,
            eventInfo: {}
          },
          loading: true,
          error: null
        }
      };
    case types.EVENT_CONTRIBUTORS.SUCCESS:
      return {
        ...state,
        contributors: {
          data: {
            list: payload.list ? payload.list : payload,
            totalPages: payload?.totalPages,
            eventInfo: payload.eventInfo
          },
          loading: false,
          error: null
        }
      };
    case types.EVENT_CONTRIBUTORS.FAILURE:
      return {
        ...state,
        contributors: {
          data: {
            list: [],
            totalPages: null,
            eventInfo: {}
          },
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Event End cases
     */
    case types.EVENT_END.REQUEST:
      return {
        ...state,
        eventEnd: { data: {}, loading: true, error: null }
      };
    case types.EVENT_END.SUCCESS:
      return {
        ...state,
        eventEnd: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.EVENT_END.FAILURE:
      return {
        ...state,
        eventEnd: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Event Contributor Accept cases
     */
    case types.EVENT_CONTRIBUTOR_ACCEPT.REQUEST:
      return {
        ...state,
        contributorAccept: { data: {}, loading: true, error: null }
      };
    case types.EVENT_CONTRIBUTOR_ACCEPT.SUCCESS:
      return {
        ...state,
        contributorAccept: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.EVENT_CONTRIBUTOR_ACCEPT.FAILURE:
      return {
        ...state,
        contributorAccept: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Event Contributor Reject cases
     */
    case types.EVENT_CONTRIBUTOR_REJECT.REQUEST:
      return {
        ...state,
        contributorReject: { data: {}, loading: true, error: null }
      };
    case types.EVENT_CONTRIBUTOR_REJECT.SUCCESS:
      return {
        ...state,
        contributorReject: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.EVENT_CONTRIBUTOR_REJECT.FAILURE:
      return {
        ...state,
        contributorReject: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Event Contributor Reject cases
     */
    case types.EVENT_UPLOAD_GUEST.REQUEST:
      return {
        ...state,
        contributorUploadQuest: { data: {}, loading: true, error: null }
      };
    case types.EVENT_UPLOAD_GUEST.SUCCESS:
      return {
        ...state,
        contributorUploadQuest: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.EVENT_UPLOAD_GUEST.FAILURE:
      return {
        ...state,
        contributorUploadQuest: { data: {}, loading: false, error: payload }
      };

    default:
      return state;
  }
};

export default Authenticate;
