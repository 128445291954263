import { ApiCaller } from 'common/helpers/apiCaller';
import convertToQueryString from 'common/helpers/convertToQueryString';

// ------ Cafeteria ------ //
export const dashboardCafeteriaService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/cafeteria/statistics?${convertToQueryString(data)}`
  });
};

// ------ Stationary ------ //
export const dashboardStationaryService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/stationary/statistics?${convertToQueryString(data)}`
  });
};

// ------ Maintenance ------ //
export const dashboardMaintenanceService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/maintenance/statistics?${convertToQueryString(data)}`
  });
};

// ------ Hsse ------ //
export const dashboardHsseService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/hsse/statistics?${convertToQueryString(data)}`
  });
};

// ------ Events ------ //
export const dashboardEventsService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/ticket/event/statistics?${convertToQueryString(data)}`
  });
};
