/* eslint-disable react-hooks/exhaustive-deps */
// -- == //
import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';

import PrimaryButton from 'components/form/buttons/primaryButton';
import TextIput from 'components/form/textInput';
import Gap from 'components/gap';
import { useActions } from 'common/hooks/useActions';
import TextArea from 'components/form/texArea';

import NotificationUserMultiDropdown from '../multiDropdown';
import NoticationUserTable from '../../../table';
import { useNavigate } from 'react-router-dom';
// import * as T from "common/types";

import * as S from '../../styles';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import { ValidationCreateSendUserNotif } from 'common/validations';

interface IProps {
  data?: any;
  userList: any[];
}

const NotificationCreateSendToUserForm: React.FC<IProps> = ({ data, userList }) => {
  const nav = useNavigate();
  const [userSearchValue, setUserSearchValue] = useState<string | undefined>('');

  const { userList: users } = useTypedSelector((state) => state.Users);
  const { notficationCreateCustomRequestAction, usersRequestAction } = useActions();

  useEffect(() => {
    usersRequestAction({});
  }, []);

  useEffect(() => {
    usersRequestAction({ search: userSearchValue });
  }, [userSearchValue]);

  return (
    <>
      <Formik
        initialValues={{
          title: data?.title || '',
          body: data?.body || '',
          sendToAll: false,
          users: []
        }}
        enableReinitialize
        validationSchema={ValidationCreateSendUserNotif}
        onSubmit={(values, { setSubmitting }) => {
          const newData = {
            ...values,
            users: values.users.map((item: any) => item.id)
          };
          notficationCreateCustomRequestAction(newData, () => {
            nav('/panel/notifications');
          });
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          // isSubmitting,
          setValues
        }) => (
          <S.Div className="row">
            <S.Div className="col-md-6">
              <TextIput
                type="text"
                name="title"
                onChange={handleChange}
                placeholder="Title"
                label="Notification Title"
                value={values.title}
                message={touched.title && (errors.title as any)}
                error={touched.title && (errors.title as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
              />

              <Gap size="10px" />

              <TextArea
                label="Notification Body"
                name="body"
                onChange={handleChange}
                placeholder="Body"
                value={values.body}
                message={touched.body && (errors.body as any)}
                error={touched.body && (errors.body as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
              />
              <Gap size="20px" />

              <PrimaryButton
                style={{
                  width: '216px',
                  height: 42,
                  borderRadius: 8
                }}
                onClick={handleSubmit}
                // disabled={isSubmitting}
              >
                Send
              </PrimaryButton>
            </S.Div>

            <S.Div className="col-md-6">
              <NotificationUserMultiDropdown
                label="Users"
                list={userList}
                placeholder="Search or Select For user"
                defaultValues={values.users}
                onChange={(list: any) => {
                  setValues({ ...values, users: list as any });
                }}
                onChangeValue={(val?: string) => setUserSearchValue(val)}
                loading={users.loading}
                message={touched.users && (errors.users as any)}
                error={touched.users && (errors.users as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
              />
              <Gap size="20px" />

              <NoticationUserTable data={values.users} setValues={setValues} values={values} />
            </S.Div>
          </S.Div>
        )}
      </Formik>
    </>
  );
};

export default NotificationCreateSendToUserForm;
