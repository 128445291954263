import styled from 'styled-components';

export const Container = styled.div``;

export const Div = styled.div``;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
export const Tab = styled.div`
  padding: 6px 1px;
  color: var(--primary);

  &.active {
    border-bottom: 2px solid var(--primary-green);
    color: var(--primary-green);
  }
`;

export const TabPanel = styled.div``;
