import { Formik } from 'formik';
import React from 'react';

import { ValidationForgetPassword } from 'common/validations';
import PrimaryButton from 'components/form/buttons/primaryButton';
import TextIput from 'components/form/textInput';
import Gap from 'components/gap';
import { useActions } from 'common/hooks/useActions';

// import * as S from "../../styles";

const ForgetasswordChangeForm: React.FC = () => {
  const { forgetPasswordRequestAction } = useActions();

  return (
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      validationSchema={ValidationForgetPassword}
      onSubmit={(values, { setSubmitting }) => {
        forgetPasswordRequestAction(values, () => {});
        setSubmitting(false);
      }}
    >
      {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
        <>
          <TextIput
            label="New password"
            type="text"
            name="password"
            onChange={handleChange}
            placeholder="Please type a new password"
            value={values.password}
            message={touched.password && errors.password}
            error={errors.password as unknown as boolean}
          />
          <Gap size="15px" />
          <TextIput
            label="Confirm new password"
            type="text"
            name="confirmPassword"
            onChange={handleChange}
            placeholder="Please Retype your new password"
            value={values.confirmPassword}
            message={errors.confirmPassword}
            error={errors.confirmPassword as unknown as boolean}
          />
          <Gap size="15px" />
          <PrimaryButton style={{ width: '100%' }} onClick={handleSubmit} disabled={isSubmitting}>
            Change Password
          </PrimaryButton>
        </>
      )}
    </Formik>
  );
};

export default ForgetasswordChangeForm;
