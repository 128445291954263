import styled from 'styled-components';

// import { device } from "common/constants/mediaQueries";

export const PrimayButtonC = styled.button`
  height: 32px;
  background: var(--primary-green);
  border-radius: 8px;
  padding: 0 10px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: var(--white);
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0em;
  cursor: pointer;

  &:focus {
    outline: double 3px var(--primary-green) !important;
  }

  &:disabled {
    background: #b6b6b6;
  }
`;

export const Icon = styled.div`
  width: 16px;
  height: 16px;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  line-height: 0;
  border-radius: 3px;
`;
