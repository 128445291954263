// -- == //
import * as types from 'common/constants';

const initialState = {
  reservations: {
    data: {
      list: [],
      totalPages: 0
    },
    loading: false,
    error: null
  },
  reservationDetail: {
    data: {},
    loading: false,
    error: null
  },
  reservationUpdateTime: {
    data: {},
    loading: false,
    error: null
  },
  reservationCancel: {
    data: [],
    loading: false,
    error: null
  },
  statistics: {
    data: {},
    loading: false,
    error: null
  },
  reservationConfirm: {
    data: {},
    loading: false,
    error: null
  }
};

interface Reducer {
  type: string;
  payload: any;
}

const Reservations = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Reservation List cases
     */
    case types.RESERVATIONS.REQUEST:
      return {
        ...state,
        reservations: {
          data: state.reservations.data,
          loading: true,
          error: null
        }
      };
    case types.RESERVATIONS.SUCCESS:
      return {
        ...state,
        reservations: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.RESERVATIONS.FAILURE:
      return {
        ...state,
        reservations: {
          data: {
            list: [],
            totalPages: 0
          },
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Reservation Detail cases
     */
    case types.RESERVATION_DETAIL.REQUEST:
      return {
        ...state,
        reservationDetail: { data: {}, loading: true, error: null }
      };
    case types.RESERVATION_DETAIL.SUCCESS:
      return {
        ...state,
        reservationDetail: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.RESERVATION_DETAIL.FAILURE:
      return {
        ...state,
        reservationDetail: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Reservation Update Time cases
     */
    case types.RESERVATION_UPDATE_TIME.REQUEST:
      return {
        ...state,
        reservationUpdateTime: { data: {}, loading: true, error: null }
      };
    case types.RESERVATION_UPDATE_TIME.SUCCESS:
      return {
        ...state,
        reservationUpdateTime: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.RESERVATION_UPDATE_TIME.FAILURE:
      return {
        ...state,
        reservationUpdateTime: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Reservation Cancel cases
     */
    case types.RESERVATION_CANCEL.REQUEST:
      return {
        ...state,
        reservationCancel: { data: {}, loading: true, error: null }
      };
    case types.RESERVATION_CANCEL.SUCCESS:
      return {
        ...state,
        reservationCancel: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.RESERVATION_CANCEL.FAILURE:
      return {
        ...state,
        reservationCancel: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Reservation Cancel cases
     */
    case types.RESERVATION_CONFIRM.REQUEST:
      return {
        ...state,
        reservationConfirm: { data: {}, loading: true, error: null }
      };
    case types.RESERVATION_CONFIRM.SUCCESS:
      return {
        ...state,
        reservationConfirm: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.RESERVATION_CONFIRM.FAILURE:
      return {
        ...state,
        reservationConfirm: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Reservation Statistics cases
     */
    case types.RESERVATION_STATISTICS.REQUEST:
      return {
        ...state,
        statistics: { data: {}, loading: true, error: null }
      };
    case types.RESERVATION_STATISTICS.SUCCESS:
      return {
        ...state,
        statistics: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.RESERVATION_STATISTICS.FAILURE:
      return {
        ...state,
        statistics: { data: {}, loading: false, error: payload }
      };

    default:
      return state;
  }
};

export default Reservations;
