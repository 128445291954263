import { useNavigate } from 'react-router';
import CategoriesTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
// import AppLoading from "components/appLoading";
import SecundButton from 'components/form/buttons/secundButton';

import * as S from './styles';

export default function CategoriesPage() {
  const { ticketCategory } = useTypedSelector((state) => state.Tickets);
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/panel/ticket/categories/create');
  };

  return (
    <S.Container>
      <S.Head>
        <S.Title>Ticket Categories</S.Title>
        <SecundButton hasIcon={true} onClick={handleRedirect}>
          Add Category
        </SecundButton>
      </S.Head>
      <Gap size="20px" />
      <CategoriesTable data={ticketCategory.data} loading={ticketCategory.loading} />

      {/* <AppLoading loading={departments.loading} /> */}
    </S.Container>
  );
}
