/* eslint-disable array-callback-return */
const convertToQueryString = (json: any) => {
  let queryString = '';
  const stringCount = Object.keys(json).length - 1;
  Object.keys(json).forEach((item, index) => {
    if (Array.isArray(json[item])) {
      json[item].map((item2: unknown) => {
        if (item2) queryString += `${item}=${item2}${index < stringCount ? '&' : ''}`;
      });
    } else if (json[item] !== '') {
      queryString += `${item}=${json[item]}${index < stringCount ? '&' : ''}`;
    }
  });
  return queryString;
};

export const convertReverseToQueryString = (json: any) => {
  let queryString = '';
  const stringCount = Object.keys(json).length - 1;
  Object.keys(json).forEach((item, index) => {
    if (json[item] !== '') {
      queryString += `${json[item]}=${item}${index < stringCount ? '&' : ''}`;
    }
  });
  return queryString;
};

export default convertToQueryString;
