// -- == //
import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'common/helpers/toastConfig';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';

import * as types from 'common/constants';
// import * as T from "common/types";
import * as services from 'common/services';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket list actions
 */
export const surveysRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.SURVEYS.REQUEST,
        payload: {}
      });

      const response: any = await services.surveysService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(surveysSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(surveysFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(surveysFailureAction(error));
      throw error;
    }
  };
};

export const surveysSuccessAction = (data: object) => {
  return {
    type: types.SURVEYS.SUCCESS,
    payload: data
  };
};

export const surveysFailureAction = (error: object) => {
  return {
    type: types.SURVEYS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket Detail actions
 */
export const surveyDetailRequestAction = (id: string | number) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.SURVEY_DETAIL.REQUEST,
        payload: {}
      });

      const response: any = await services.surveyDetailService(id);

      if (response.status === 200 || response.status === 201) {
        dispatch(surveyDetailSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(surveyDetailFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(surveyDetailFailureAction(error));
      throw error;
    }
  };
};

export const surveyDetailSuccessAction = (data: object) => {
  return {
    type: types.SURVEY_DETAIL.SUCCESS,
    payload: data
  };
};

export const surveyDetailFailureAction = (error: object) => {
  return {
    type: types.SURVEY_DETAIL.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket Detail actions
 */
export const surveyCreateRequestAction = (data: object, callBack?: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.SURVEY_CREATE.REQUEST,
        payload: {}
      });

      const response: any = await services.surveyCreateService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(surveyCreateSuccessAction(response.data?.data));
        toast({ model: 'success', message: response.data.clientMessage });
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(surveyCreateFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(surveyCreateFailureAction(error));
      throw error;
    }
  };
};

export const surveyCreateSuccessAction = (data: object) => {
  return {
    type: types.SURVEY_CREATE.SUCCESS,
    payload: data
  };
};

export const surveyCreateFailureAction = (error: object) => {
  return {
    type: types.SURVEY_CREATE.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Ticket list Result actions
 */
export const surveysResultRequestAction = (data: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.SURVEYS_RESULT.REQUEST,
        payload: {}
      });

      const response: any = await services.surveyResultService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(surveysResultSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(surveysResultFailureAction(response.data));
      }
    } catch (error: any) {
      console.log(error);
      dispatch(surveysResultFailureAction(error));
      throw error;
    }
  };
};

export const surveysResultSuccessAction = (data: object) => {
  return {
    type: types.SURVEYS_RESULT.SUCCESS,
    payload: data
  };
};

export const surveysResultFailureAction = (error: object) => {
  return {
    type: types.SURVEYS_RESULT.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Survey Question List actions
 */
export const surveyQuestionListAction = (data: any[]) => {
  return {
    type: types.SURVEY_QUESTION.LIST,
    payload: data
  };
};
export const surveyQuestionAddAction = (data: object) => {
  return {
    type: types.SURVEY_QUESTION.ADD,
    payload: data
  };
};
export const surveyQuestionDeleteAction = (data: object) => {
  return {
    type: types.SURVEY_QUESTION.DELETE,
    payload: data
  };
};
export const surveyQuestionEditAction = (data: object) => {
  return {
    type: types.SURVEY_QUESTION.EDIT,
    payload: data
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Survey Question Choices List actions
 */
export const surveyQuestionChoicesAddAction = (data: object) => {
  return {
    type: types.SURVEY_QUESTION_CHOICES.ADD,
    payload: data
  };
};
export const surveyQuestionChoicesDeleteAction = (id: number, parentId: number) => {
  return {
    type: types.SURVEY_QUESTION_CHOICES.DELETE,
    payload: { id, parentId }
  };
};
export const surveyQuestionChoicesEditAction = (value: string, id: number, parentId: number) => {
  return {
    type: types.SURVEY_QUESTION_CHOICES.EDIT,
    payload: { value, id, parentId }
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
