import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

const initialStateSidebar: any = (getItem: any) => {
  return [
    getItem(
      ['sAdmin', 'admin'],
      <Link to="/panel/dashboard">Dashboard</Link>,
      '/panel/dashboard',
      <ReactSVG
        src="/icons/fill/dashboard.svg"
        beforeInjection={(svg) => {
          svg.setAttribute('fill', `#979797`);
        }}
      />
    ),
    getItem(
      ['sAdmin', 'admin', 'cafeTeriaManager'],
      'Cafeteria System',
      '/panel/cafeteria',
      <ReactSVG
        src="/icons/fill/cafe.svg"
        beforeInjection={(svg) => {
          svg.setAttribute('fill', `#979797`);
        }}
      />,
      [
        getItem(
          ['sAdmin', 'admin', 'cafeTeriaManager'],
          <Link to="/panel/cafeteria/menu-management">Menu Management</Link>,
          '/panel/cafeteria/menu-management'
        ),
        getItem(
          ['sAdmin', 'admin', 'cafeTeriaManager'],
          <Link to="/panel/cafeteria/teaboy-management">Teaboy Management</Link>,
          '/panel/cafeteria/teaboy-management'
        ),
        getItem(
          ['sAdmin', 'admin', 'cafeTeriaManager'],
          <Link to="/panel/cafeteria/statistics">Statistics</Link>,
          '/panel/cafeteria/statistics'
        )
      ]
    ),
    getItem(
      ['sAdmin', 'admin', 'supporter'],
      'Ticket System',
      '/panel/ticket',
      <ReactSVG
        src="/icons/fill/ticket.svg"
        beforeInjection={(svg) => {
          svg.setAttribute('fill', `#979797`);
        }}
      />,
      [
        getItem(
          ['sAdmin', 'admin', 'supporter'],
          <Link to="/panel/ticket/tickets">Tickets</Link>,
          '/panel/ticket/tickets'
        ),
        getItem(['sAdmin', 'admin'], <Link to="/panel/ticket/categories">Categories</Link>, '/panel/ticket/categories')
      ]
    ),
    getItem(
      ['sAdmin', 'admin'],
      'User Management',
      '/panel/users',
      <ReactSVG
        src="/icons/fill/users.svg"
        beforeInjection={(svg) => {
          svg.setAttribute('fill', `#979797`);
        }}
      />,
      [
        getItem(['sAdmin', 'admin'], <Link to="/panel/users/user-list">User List</Link>, '/panel/users/user-list'),
        getItem(['sAdmin', 'admin'], <Link to="/panel/users/admin-list">Admin List</Link>, '/panel/users/admin-list'),
        getItem(
          ['sAdmin', 'admin'],
          <Link to="/panel/users/attendance-list">Attendance List</Link>,
          '/panel/users/attendance-list'
        )
      ]
    ),
    getItem(
      ['sAdmin', 'admin'],
      'Location Management',
      '/panel/location',
      <ReactSVG
        className="stroke"
        src="/icons/fill/location.svg"
        beforeInjection={(svg) => {
          svg.setAttribute('fill', `#979797`);
          svg.setAttribute('fill', `#979797`);
        }}
      />,
      [getItem(['sAdmin', 'admin'], <Link to="/panel/location/floors">Floors</Link>, '/panel/location/floors')]
    ),
    getItem(
      ['sAdmin', 'admin', 'marketingManager'],
      <Link to="/panel/events">Event</Link>,
      '/panel/events',
      <ReactSVG
        src="/icons/fill/event.svg"
        beforeInjection={(svg) => {
          svg.setAttribute('fill', `#979797`);
        }}
      />
    ),
    // getItem(
    //   <Link to="#">Access Management</Link>,
    //   "/panel/access",
    //   <ReactSVG
    //     src="/icons/fill/users.svg"
    //     beforeInjection={(svg) => {
    //       svg.setAttribute("fill", `#979797`);
    //     }}
    //   />,
    //   [
    //     getItem(<Link to="/panel/access/activities">Activities</Link>, "/panel/access/activities"),
    //     getItem(<Link to="/panel/access/leave-requests">Leave Requests</Link>, "/panel/access/leave-requests"),
    //     getItem(<Link to="/panel/access/area-management">Area Management</Link>, "/panel/access/area-management"),
    //   ]
    // ),

    getItem(
      ['sAdmin', 'admin', 'reservationManager'],
      <Link to="/panel/reservation">Reservation System</Link>,
      '/panel/reservation',
      <ReactSVG
        src="/icons/fill/agent.svg"
        beforeInjection={(svg) => {
          svg.setAttribute('fill', `#979797`);
        }}
      />
    ),
    getItem(
      ['sAdmin', 'admin', 'marketingManager'],
      <Link to="/panel/notifications">Notifications</Link>,
      '/panel/notifications',
      <ReactSVG
        src="/icons/fill/notif.svg"
        beforeInjection={(svg) => {
          svg.setAttribute('fill', `#979797`);
        }}
      />
    ),
    getItem(
      ['sAdmin', 'admin', 'marketingManager'],
      <Link to="/panel/news">News</Link>,
      '/panel/news',
      <ReactSVG
        src="/icons/fill/news.svg"
        beforeInjection={(svg) => {
          svg.setAttribute('fill', `#979797`);
        }}
      />
    )
  ];
};

export default initialStateSidebar;
