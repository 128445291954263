import styled from 'styled-components';
import { device } from 'common/constants/mediaQueries';
export const modalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;

  /* transition: all.3s; */

  &.show {
    visibility: visible;
    opacity: 1;
  }

  &.absolute {
    position: absolute;
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
  }

  &.center {
    align-items: center;
  }
`;

export const modalPaper = styled.div`
  width: 100%;
  max-width: 600px;
  /* height: 459px; */
  background: var(--white);
  border-radius: 12px;
  visibility: hidden;
  opacity: 0;
  z-index: 11;
  /* top: 0; */
  position: absolute;
  transition: all 0.3s;
  padding: 40px 30px;
  transform: scale(0.5);

  &.show {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }

  &.center {
    position: static;
  }

  /* top: 20%;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto; */

  @media ${device.tablet} {
    width: 90%;
  }
`;

export const back = styled.div`
  background: #4b576c;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  position: absolute;
`;

export const title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: var(--dark);
  margin-bottom: 20px;
`;

export const closeBtn = styled.div`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

export const img = styled.img``;
