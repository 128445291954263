import { combineReducers } from 'redux';

import Authenticate from './auth';
import Cafeteria from './cafeteria';
import SweetAlert from './sweetAlert';
import Floors from './floors';
import Teaboy from './teaboy';
import Tickets from './ticket';
import Departments from './department';
import Admin from './admin';
import Survey from './survey';
import Events from './events';
import Notifications from './notifications';
import Users from './users';
import News from './news';
import Dashboard from './dashboard';
import Reservation from './reservation';

const reducers = combineReducers({
  Authenticate,
  Cafeteria,
  Floors,
  Teaboy,
  Tickets,
  Departments,
  Admin,
  Survey,
  Events,
  Notifications,
  Users,
  News,
  Dashboard,
  Reservation,
  SweetAlert
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
