/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import SideBarMenu from './lib/side';
import Header from './lib/header';
import { getCurrentToken, onMessageListener } from '../../../firebase';
import { useActions } from 'common/hooks/useActions';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { notification } from 'antd';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import Cookies from 'js-cookie';
import { Crypto } from 'common/helpers/crypto';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function PanelLayout() {
  const history = useNavigate();
  const location = useLocation();
  const auth = getAuth();
  const userInfo = Cookies.get('userInfo') ? JSON.parse(Crypto.decrypt(Cookies.get('userInfo'))) : '';

  const { notifications }: any = useTypedSelector((state: any) => state.Notifications);
  const { logOut }: any = useTypedSelector((state: any) => state.Authenticate);

  const { getFirebaseTokenRequestAction, addOrUpdateFirebaseTokenRequestAction, notificationsRequestAction } =
    useActions();

  useEffect(() => {
    if (location.pathname === '/panel') history('/panel/dashboard');
  }, [history, location.pathname]);

  useEffect(() => {
    onMessageListener()
      .then((payload: any) => {
        console.log('New Notification', payload);
        notificationsRequestAction();
        // setShow(true);
        // setNotification({ title: payload.notification.title, body: payload.notification.body })
        // toast({ message: payload.notification.title + " - " + payload.notification.body, position: "top-right" })
        const args = {
          message: payload.notification.title,
          description: payload.notification.body,
          duration: 0
        };
        notification.open(args);
      })
      .catch((err: any) => console.log('failed: ', err));
    notificationsRequestAction();
  }, []);

  useEffect(() => {
    getCurrentToken((fcm_token: string) => {
      addOrUpdateFirebaseTokenRequestAction(fcm_token, () => {
        console.log(fcm_token);

        if (localStorage.getItem('customFirebaseToken')) {
          handleSigninFirebaseCustom(localStorage.getItem('customFirebaseToken') as string);
        } else {
          getFirebaseTokenRequestAction((custom_token: string) => {
            handleSigninFirebaseCustom(custom_token);
          });
        }
      });
    });
  }, []);

  const handleSigninFirebaseCustom = (token: string) => {
    signInWithCustomToken(auth, token)
      .then((_userCredential) => {})
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('firebase error: ', errorCode, errorMessage);
        if (errorCode === 'auth/invalid-custom-token') {
          console.log('The token you provided is not valid.');
        } else {
          console.error(error);
        }
      });
  };

  return (
    <S.Container>
      <SideBarMenu userInfo={userInfo} />
      <S.Main>
        <Header notifications={notifications.data} userInfo={userInfo} />
        <Outlet />
      </S.Main>
      <AppLoading loading={logOut.loading} />
    </S.Container>
  );
}
