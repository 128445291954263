import ForgetasswordForm from './lib/form';
import Gap from 'components/gap';

import * as S from './styles';
import { Link } from 'react-router-dom';

export default function ForgePasswordPage() {
  return (
    <S.AuthContainer>
      <S.FormLogin>
        <S.FormTitle>Forget Password</S.FormTitle>
        <S.SubTitle>Enter your email and password to log in.</S.SubTitle>
        <Gap size="35px" />
        <ForgetasswordForm />
        <Gap size="10px" />
        <S.SubTitle style={{ fontSize: 13 }}>
          Already have login and password? <Link to="/auth/login">Sign in</Link>
        </S.SubTitle>
      </S.FormLogin>
    </S.AuthContainer>
  );
}
