// import TicketMenuManagementCreate from "./lib/create";
import SurveyTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
// import AppLoading from "components/appLoading";

import * as S from './styles';
import SurveyCounts from './lib/surveyCounts';

export default function SurveyPage() {
  const { surveys } = useTypedSelector((state) => state.Survey);
  // const { ticketStatistics } = useTypedSelector((state) => state.Tickets);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Survey System</S.Title>
        <SurveyCounts data={{}} />
      </S.Head>
      <Gap size="20px" />
      <SurveyTable data={surveys.data} loading={surveys.loading} />

      {/* <AppLoading
        loading={
          ticketStatistics.loading
        }
      /> */}
    </S.Container>
  );
}
