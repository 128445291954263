import { ReactSVG } from 'react-svg';
import { FC } from 'react';

import DangerButton from 'components/form/buttons/danger';
import OutlineButton from 'components/form/buttons/outLineButton';
import ModalApp from 'components/modalApp';
import Gap from 'components/gap';

import * as S from './styles';
import PrimaryButton from 'components/form/buttons/primaryButton';

interface Question {
  show: boolean;
  handleClose: () => void;
  title?: string;
  text?: string;
  handleSuccess?: () => void;
  successButtonText?: string;
  model: 'delete' | 'success';
  icon?: string;
}

const QuestionModal: FC<Question> = ({
  show,
  handleClose,
  title,
  text,
  handleSuccess,
  successButtonText,
  model,
  icon
}) => {
  return (
    <S.Content>
      <ModalApp show={show} handleClose={handleClose} fixed={true} style={{ maxWidth: 400 }} center={true}>
        <S.ModalContent>
          {icon ? (
            <ReactSVG src={icon} />
          ) : model === 'delete' ? (
            <S.Icon className={model}>
              <ReactSVG
                src="/icons/stroke/trash.svg"
                beforeInjection={(svg) => {
                  svg.setAttribute('stroke', `var(--white)`);
                }}
              />
            </S.Icon>
          ) : model === 'success' ? (
            <S.Icon className={model}>
              <ReactSVG
                src="/icons/check-white.svg"
                beforeInjection={(svg) => {
                  svg.setAttribute('stroke', `var(--white)`);
                }}
              />
            </S.Icon>
          ) : (
            <></>
          )}
          <Gap size="10px" />
          <S.Title>{title}</S.Title>
          <Gap size="10px" />
          <S.Text>{text}</S.Text>
          <S.Buttons>
            {model === 'delete' ? (
              <>
                <OutlineButton
                  style={{
                    width: '100%',
                    maxWidth: 220,
                    height: 48,
                    fontWeight: 400,
                    fontSize: 14,
                    borderRadius: 8,
                    border: 'none',
                    color: 'var(--primary-dark)',
                    background: '#DFE0EB'
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </OutlineButton>

                <DangerButton
                  style={{
                    width: '100%',
                    maxWidth: 220,
                    height: 48,
                    fontWeight: 400,
                    fontSize: 14,
                    borderRadius: 8
                  }}
                  onClick={handleSuccess}
                >
                  {successButtonText ? successButtonText : 'Remove'}
                </DangerButton>
              </>
            ) : model === 'success' ? (
              <>
                <DangerButton
                  style={{
                    width: '100%',
                    maxWidth: 220,
                    height: 48,
                    fontWeight: 400,
                    fontSize: 14,
                    borderRadius: 8
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </DangerButton>
                <PrimaryButton
                  style={{
                    width: '100%',
                    maxWidth: 220,
                    height: 48,
                    fontWeight: 400,
                    fontSize: 14,
                    borderRadius: 8
                  }}
                  onClick={handleSuccess}
                >
                  {successButtonText ? successButtonText : 'Remove'}
                </PrimaryButton>
              </>
            ) : (
              <></>
            )}
          </S.Buttons>
        </S.ModalContent>
      </ModalApp>
    </S.Content>
  );
};

export default QuestionModal;
