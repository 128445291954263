// import { ReactNode } from "react";
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Crypto } from 'common/helpers/crypto';

// interface IProps {
//   access?: any[];
//   children?: ReactNode;
// }

const ProtectedRoute = ({ access, children }: any) => {
  const userInfo = Cookies.get('userInfo') ? JSON.parse(Crypto.decrypt(Cookies.get('userInfo'))) : '';
  const token = Cookies.get('token');

  if (!token) return <Navigate to="/auth/login" replace />;
  if (access?.find((item: string) => item === userInfo?.role)) {
    return children;
  } else return <Navigate to="/panel/access" replace />;
};

export default ProtectedRoute;
