import styled from 'styled-components';

import { device } from 'common/constants/mediaQueries';

export const inputContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  /* overflow: hidden; */
  border-radius: 12px;

  /* &.error {
    input {
      border: 1px solid #eb2b2b !important;
    }
  } */

  &.success {
    input {
      border: solid 1px var(--green-dark);
    }
  }
`;

export const Input = styled.input`
  padding: 0px 20px;
  height: 54px;
  width: -webkit-fill-available;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  background: #fdfdfd;
  border: 1px solid rgba(182, 182, 182, 0.2);
  border-radius: 10px;
  margin-bottom: 0px;
  color: var(--dark);

  /* &:focus ~ .input-label,
  &:not(:placeholder-shown) ~ .input-label {
    top: 0px;
    font-size: 11px;
  } */

  &:focus {
    border: 1px solid var(--primary-green);
  }

  &:not(:placeholder-shown) ~ .input-label {
    color: #367af7;
  }

  &:focus {
    outline: none;
  }

  @media ${device.tablet} {
    font-size: 14px;
  }
  @media ${device.mobileS} {
    font-size: 12px;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 5px;
  color: var(--dark);
  transition: 0.2s ease all;
  cursor: pointer;

  @media ${device.mobileL} {
    font-size: 14px;
    line-height: 20px;
  }
  @media ${device.mobileM} {
    font-size: 12px;
    line-height: 16px;
  }
  @media ${device.mobileS} {
    font-size: 10px;
    line-height: 14px;
  }
`;

export const message = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: var(--dark-light);
  padding-left: 10px;

  &.error-message {
    color: var(--danger);
  }

  &.success-message {
    color: var(--green-dark);
  }
`;

export const Img = styled.img``;

export const ShowHideButton = styled.button`
  position: absolute;
  right: 10px;
  bottom: 0;
  top: 0;
  margin: auto 0;
  background: none;
  border: none;
`;

export const InputBox = styled.div`
  position: relative;
  margin-bottom: 5px;
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const CopyBtn = styled.button`
  /* border: none;
  background: none; */
`;
