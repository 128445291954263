import styled from 'styled-components';

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;

  .ant-pagination-item {
    border-radius: 4px;
    border: 1px solid #dfe3e8;
    background: var(--white);

    a {
    }

    &.ant-pagination-item-active {
      border: 1px solid var(--primary-green);

      a {
        color: var(--primary-green);
      }
    }
  }
`;
