import * as types from '../../common/constants';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * show sweet alert
 */
export const showSweetAlertAction = (data: object) => {
  return {
    type: types.SWEET_ALERT.SHOW,
    payload: data
  };
};

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * close sweet alert
 */
export const closeSweetAlertAction = () => {
  return {
    type: types.SWEET_ALERT.CLOSE,
    payload: {}
  };
};
