/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import type { MenuProps } from 'antd';
import {
  Menu
  // Tree
} from 'antd';
import { useLocation } from 'react-router-dom';
import initialStateSidebar from './initialState';

import * as S from './styles';

interface IProps {
  userInfo?: any;
}

type MenuItem = Required<MenuProps>['items'][number];

const SideBarMenu: React.FC<IProps> = ({ userInfo }) => {
  const location = useLocation();
  const [openKeys, setOpenKeys] = useState(['']);
  const onOpenChange: MenuProps['onOpenChange'] = (keys) => {
    setOpenKeys(keys);
  };

  useEffect(() => {
    const path = location.pathname.split('/');
    if (path[2] === 'cafeteria') {
      setOpenKeys(['/panel/cafeteria']);
    } else if (path[2] === 'ticket') {
      setOpenKeys(['/panel/ticket']);
    } else if (path[2] === 'users') {
      setOpenKeys(['/panel/users']);
    } else if (path[2] === 'location') {
      setOpenKeys(['/panel/location']);
    }
  }, []);

  function getItem(
    role: string[],
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem {
    if (role?.find((item: string) => item === userInfo?.role)) {
      return {
        key,
        icon,
        children,
        label,
        type
      } as MenuItem;
    } else return null as any;
  }

  return (
    <S.SideContainer>
      <S.Logo>
        <S.Image src="/logo-min.svg" />
        <S.LogoTitle>TDF OMS</S.LogoTitle>
      </S.Logo>
      <Menu
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        style={{ width: '100%', background: 'none', color: 'var(--gray)' }}
        items={initialStateSidebar(getItem)}
        // defaultOpenKeys={openKeys}
        defaultSelectedKeys={[location.pathname]}
      />
    </S.SideContainer>
  );
};

export default SideBarMenu;
