/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
// import TicketMenuManagementCreate from "./lib/create";
import CategorieyDetailForm from '../lib/form';
import Gap from 'components/gap';
import AppLoading from 'components/appLoading';
import CategorySupporterTable from './lib/table';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import { useActions } from 'common/hooks/useActions';

import * as S from './styles';

export default function CategoryCreatePage() {
  const { ticketResetSupporterListAction } = useActions();

  const { ticketCategory, supporterList, ticketCategoryIcon, ticketCategoryCreate } = useTypedSelector(
    (state) => state.Tickets
  );
  const { supporter } = useTypedSelector((state) => state.Admin);

  useEffect(() => {
    ticketResetSupporterListAction();
  }, []);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Ticket Categories</S.Title>
      </S.Head>
      <Gap size="20px" />
      <S.Div className="row">
        <S.Div className="col-md-6">
          <CategorieyDetailForm
            categories={ticketCategory.data}
            supporterList={supporterList}
            supporter={supporter.data}
          />
        </S.Div>
        <S.Div className="col-md-6">
          <CategorySupporterTable data={supporterList} />
        </S.Div>
      </S.Div>

      <AppLoading loading={ticketCategoryIcon.loading || ticketCategoryCreate.loading} />
    </S.Container>
  );
}
