import { ApiCaller } from 'common/helpers/apiCaller';

// ------ login ------ //
export const loginService = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/signin`,
    data
  });
};

// ------ forgot-password ------ //
export const forgetPassword = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/forget-password`,
    data
  });
};

// ------ refresh token ------ //
export const refreshTokenResend = (data: object) => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/refresh-token`,
    data
  });
};

// ------ login ------ //
export const getFirebaseTokenService = () => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/firebase`
  });
};

// ------ refresh token ------ //
export const addOrUpdateFirebaseTokenService = (token: string) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/firebase`,
    data: { token }
  });
};

// ------ Log Out ------ //
export const logOutService = () => {
  return ApiCaller({
    method: 'POST',
    url: `${process.env.REACT_APP_API_URL}api/admin/logout`
  });
};
