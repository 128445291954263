import * as types from 'common/constants';

const initialState = {
  departments: {
    data: [],
    loading: false,
    error: null
  }
};

interface Reducer {
  type: string;
  // -- //
  payload: any;
}

const Departments = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Department List cases
     */
    case types.DEPARTMENTS.REQUEST:
      return {
        ...state,
        departments: {
          data: [],
          loading: true,
          error: null
        }
      };
    case types.DEPARTMENTS.SUCCESS:
      return {
        ...state,
        departments: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.DEPARTMENTS.FAILURE:
      return {
        ...state,
        departments: {
          data: [],
          loading: false,
          error: payload
        }
      };

    default:
      return state;
  }
};

export default Departments;
