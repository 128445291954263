import styled from 'styled-components';

export const Content = styled.div``;

export const Name = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0a214280;
`;

export const Div = styled.div`
  &.active {
    color: var(--primary-green);
  }
  &.inactive {
    color: #ff646480;
  }
  &.captal {
    text-transform: capitalize;
  }
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eef1f6;
  padding: 7px 10px;
  border-radius: 12px;
  font-size: 14px;
  color: #8a93ac;
  font-weight: 500;
`;

export const AddButton = styled.button`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  background: none;
  color: var(--primary-green);
`;

export const Icon = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-green);
  line-height: 0;
  border-radius: 40px;
  margin: auto;
  cursor: pointer;
  border: none;
  /* padding: 10px; */
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const Title = styled.h4`
  font-size: 16px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
`;

export const Text = styled.p`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  flex-grow: 1;
  color: #2d3748;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const EditButton = styled.button`
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eef1f6;
  line-height: 0;
  border-radius: 40px;
  cursor: pointer;
  border: none;
`;

export const DeleteButton = styled.button`
  min-width: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fdf0ef;
  line-height: 0;
  border-radius: 40px;
  border: none;
  cursor: pointer;
`;

export const List = styled.ul`
  list-style: none;
`;

export const ListItem = styled.li`
  gap: 7px;
  background: #f7f8fc;
  padding: 15px 10px;
  border-radius: 12px;
  margin: 10px 0;
`;

export const Type = styled.span`
  font-family: Poppins;
  font-size: 9px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  height: 14px;
  background: rgba(233, 249, 247, 1);
  border-radius: 8px;
  color: var(--primary-green);
`;

export const TextChoice = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #2d3748;
  padding: 0;
`;
