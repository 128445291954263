// -- == //
import React, { useEffect, useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';

import * as S from './styles';
import * as T from 'common/types';

const DropDown: React.FC<T.Form.Dropdown> = ({
  label,
  id,
  disabled,
  style,
  error,
  success,
  message,
  list,
  onChange,
  defaultValue,
  inputStyle,
  onFocus,
  onBlur,
  className,
  placeholder,
  labelStyle,
  mini,
  name,
  activeCheck,
  hasSearch = false,
  hasSearchLocal = false,
  handleChangeValue,
  hasItemIcon = false
}) => {
  const [value, setValue] = useState<any>(defaultValue);
  const [textValue, setTextValue] = useState<string>('');

  const [show, setShow] = useState<boolean>(false);
  const wrapperRef: React.MutableRefObject<any> = useRef(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
      }
      if (onBlur) {
        onBlur();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onBlur]);

  const onChangeValue = (e: { target: { value: React.SetStateAction<string> } }) => {
    handleChangeValue && handleChangeValue(e.target.value as string);
    setTextValue(e.target.value);
    setValue(e.target.value);
  };

  // useEffect(() =>{
  //   if(hasbeenReset) {
  //     reset(setValue);
  //   }
  //   console.log('hey')
  // }, [hasbeenReset]);

  const handleChange = (item: T.Form.ListDropdownItem) => {
    setShow(false);
    if (onBlur) {
      onBlur();
    }
    setValue(item);
    if (onChange) onChange(item);
  };

  const handleShow = () => {
    if (disabled) return;
    if (!show) setShow(true);
    else setShow(false);
    if (onFocus) {
      onFocus();
    }
    if (hasSearch && handleChangeValue && !textValue) handleChangeValue('');
  };
  return (
    <S.inputContainer
      style={style}
      className={error ? `error ${className}` : success ? `success ${className}` : `${className}`}
      ref={wrapperRef}
    >
      {label && (
        <S.Label className={`input-label ${mini ? 'mini' : ''}`} htmlFor={id} style={labelStyle} onClick={handleShow}>
          {label}
        </S.Label>
      )}
      <S.InputBox>
        <S.Input
          id={id}
          disabled={disabled}
          placeholder={placeholder}
          onClick={handleShow}
          value={value ? value.title : ''}
          readOnly={!hasSearch}
          onChange={onChangeValue}
          style={{
            paddingLeft: value?.icon && hasItemIcon && !mini ? '40px' : '',
            ...inputStyle
          }}
          className={`${mini ? 'mini' : ''} ${disabled ? 'disabled' : ''}`}
          name={name}
        />

        {value?.icon && hasItemIcon ? (
          <S.iconSelect>
            <S.arrowImg src={value?.icon} />
          </S.iconSelect>
        ) : (
          <></>
        )}
        <S.icon onClick={handleShow} className={mini ? 'mini' : ''}>
          <ReactSVG
            src={'/icons/stroke/arrow-bottom.svg'}
            beforeInjection={(svg) => {
              svg.setAttribute('stroke', `#A4A6B3`);
            }}
          />
        </S.icon>

        {list?.length ? (
          <S.dropDownItems className={`drop-down__items ${show ? 'show' : ''}`}>
            {!hasSearchLocal
              ? list.map((item: any, index: number) => {
                  return (
                    <S.dropDownItem
                      key={index}
                      onClick={() => handleChange(item)}
                      className={`${value?.id === item.id ? 'active' : ''} ${activeCheck && 'active-check'}`}
                    >
                      {activeCheck && value?.id === item.id && (
                        <ReactSVG
                          src="/icons/stroke/check2.svg"
                          beforeInjection={(svg) => {
                            svg.setAttribute('stroke', `#52525B`);
                          }}
                        />
                      )}
                      {item.icon && hasItemIcon ? <S.img src={item.icon} /> : <></>}
                      {item.title ? <S.text>{item.title}</S.text> : <></>}
                    </S.dropDownItem>
                  );
                })
              : list
                  .filter((item: any) =>
                    textValue
                      ? item.title.toLowerCase().includes(textValue ? textValue?.toLowerCase() : textValue)
                      : true
                  )
                  .map((item: any, index: number) => {
                    return (
                      <S.dropDownItem
                        key={index}
                        onClick={() => handleChange(item)}
                        className={`${value?.id === item.id ? 'active' : ''} ${activeCheck && 'active-check'}`}
                      >
                        {activeCheck && value?.id === item.id && (
                          <ReactSVG
                            src="/icons/stroke/check2.svg"
                            beforeInjection={(svg) => {
                              svg.setAttribute('stroke', `#52525B`);
                            }}
                          />
                        )}
                        {item.icon && hasItemIcon ? <S.img src={item.icon} /> : <></>}
                        {item.title ? <S.text>{item.title}</S.text> : <></>}
                      </S.dropDownItem>
                    );
                  })}
          </S.dropDownItems>
        ) : (
          <></>
        )}
      </S.InputBox>

      {message ? (
        <S.message className={error ? 'error-message' : success ? 'success-message' : ''}>{message}</S.message>
      ) : (
        <></>
      )}
    </S.inputContainer>
  );
};

export default DropDown;
