import { Route, Routes } from 'react-router-dom';
// import { getAuth, signInWithCustomToken } from "firebase/auth";

import AuthLayout from 'components/layout/auth';
// import { panelRoutes, authRoutes } from "./routes";
import LoginPage from 'pages/auth/login';
import ForgePasswordPage from 'pages/auth/forget-password';
import PanelLayout from 'components/layout/panel';
import ForgetasswordChangePage from 'pages/auth/forget-password/change';
import DashboardPage from 'pages/panel/dashboard';
import CafeteriaMenuManagementPage from 'pages/panel/cafeteria/menuManagement';
import CafeteriaTeaboyManagementPage from 'pages/panel/cafeteria/teaboyManagement';
import CafeteriaStatisticsPage from 'pages/panel/cafeteria/statistics';
import Index from 'pages';
import TicketsPage from 'pages/panel/ticket/tickets';
import CategoriesPage from 'pages/panel/ticket/categories';
import CategoryDetailPage from 'pages/panel/ticket/categories/detail';
import CategoryCreatePage from 'pages/panel/ticket/categories/create';
import TicketDetailPage from 'pages/panel/ticket/tickets/detail';
import EventsPage from 'pages/panel/events';
import EventsContributorsPage from 'pages/panel/events/contributors';
import EventDetailPage from 'pages/panel/events/detail';
import EventCreatePage from 'pages/panel/events/create';
import AccessManagementActivitiesPage from 'pages/panel/accessManagement/activities';
import ActivitiesStatisticsPage from 'pages/panel/accessManagement/activities/statistics';
import AccessManagementLeaveRequestsPage from 'pages/panel/accessManagement/leaveRequests';
import SurveyPage from 'pages/panel/survey';
import SurveyCreatePage from 'pages/panel/survey/create';
import SurveyDetailPage from 'pages/panel/survey/detail';
import SurveyShowPage from 'pages/panel/survey/show';
import NotificationsPage from 'pages/panel/notification';
import NotificationCreatePage from 'pages/panel/notification/create';
import NewsPage from 'pages/panel/news';
import NewsCreatePage from 'pages/panel/news/create';
import NewsDetailPage from 'pages/panel/news/detail';
import UsersPage from 'pages/panel/users';
import UsersChangeRolePage from 'pages/panel/users/changeRole';
import FloorsPage from 'pages/panel/location/floors';
import EntranceExitTimesPage from 'pages/panel/users/entranceExitTimes';
import AdminsPage from 'pages/panel/users/admins';
import ProtectedRoute from 'components/protectedRoute';
import AccessPage from 'pages/panel/access';
import ReservationPage from 'pages/panel/reservation';
import ReservationDetailPage from 'pages/panel/reservation/detail';

const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Index />} index />

        <Route path="auth" element={<AuthLayout />}>
          {/* {
            authRoutes.map((item: any) => {
              console.log(item);
              
              return <Route path={item.path} element={<item.element />} />;
            })
          } */}
          <Route path="login" element={<LoginPage />} />
          <Route path="forget-password" element={<ForgePasswordPage />} />
          <Route path="forget-password/change" element={<ForgetasswordChangePage />} />
        </Route>
        <Route path="panel" element={<PanelLayout />}>
          <Route path="access" element={<AccessPage />} />

          <Route
            path="dashboard"
            element={
              <ProtectedRoute access={['admin', 'sAdmin']}>
                <DashboardPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="cafeteria/menu-management"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'cafeTeriaManager']}>
                <CafeteriaMenuManagementPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="cafeteria/teaboy-management"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'cafeTeriaManager']}>
                <CafeteriaTeaboyManagementPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="cafeteria/statistics"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'cafeTeriaManager']}>
                <CafeteriaStatisticsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="ticket/tickets"
            element={
              <ProtectedRoute access={['supporter', 'admin', 'sAdmin']}>
                <TicketsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="ticket/tickets/:id"
            element={
              <ProtectedRoute access={['supporter', 'admin', 'sAdmin']}>
                <TicketDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="ticket/categories"
            element={
              <ProtectedRoute access={['admin', 'sAdmin']}>
                <CategoriesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="ticket/categories/:id"
            element={
              <ProtectedRoute access={['admin', 'sAdmin']}>
                <CategoryDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="ticket/categories/create"
            element={
              <ProtectedRoute access={['admin', 'sAdmin']}>
                <CategoryCreatePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="events/:id"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'marketingManager']}>
                <EventDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="events"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'marketingManager']}>
                <EventsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="events/contributors/:id"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'marketingManager']}>
                <EventsContributorsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="events/create"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'marketingManager']}>
                <EventCreatePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="access/activities"
            element={
              <ProtectedRoute access={['admin', 'sAdmin']}>
                <AccessManagementActivitiesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="access/activities/:id"
            element={
              <ProtectedRoute access={['admin', 'sAdmin']}>
                <ActivitiesStatisticsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="access/leave-requests"
            element={
              <ProtectedRoute access={['admin', 'sAdmin']}>
                <AccessManagementLeaveRequestsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="survey"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'marketingManager']}>
                <SurveyPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="survey/create"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'marketingManager']}>
                <SurveyCreatePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="survey/:id"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'marketingManager']}>
                <SurveyDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="survey/show/:id"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'marketingManager']}>
                <SurveyShowPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="notifications"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'marketingManager']}>
                <NotificationsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="notifications/create"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'marketingManager']}>
                <NotificationCreatePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="news"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'marketingManager']}>
                <NewsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="news/create"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'marketingManager']}>
                <NewsCreatePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="news/:id"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'marketingManager']}>
                <NewsDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="users/user-list"
            element={
              <ProtectedRoute access={['admin', 'sAdmin']}>
                <UsersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="users/change-role/:id"
            element={
              <ProtectedRoute access={['sAdmin']}>
                <UsersChangeRolePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="users/attendance-list"
            element={
              <ProtectedRoute access={['admin', 'sAdmin']}>
                <EntranceExitTimesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="users/admin-list"
            element={
              <ProtectedRoute access={['admin', 'sAdmin']}>
                <AdminsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="location/floors"
            element={
              <ProtectedRoute access={['admin', 'sAdmin']}>
                <FloorsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="reservation"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'reservationManager']}>
                <ReservationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="reservation/:id"
            element={
              <ProtectedRoute access={['admin', 'sAdmin', 'reservationManager']}>
                <ReservationDetailPage />
              </ProtectedRoute>
            }
          />
        </Route>
      </Routes>
    </>
  );
};

export default App;
