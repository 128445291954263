import * as T from 'common/types';
import * as S from './styles';
import { Link } from 'react-router-dom';

const DangerButton: React.FC<T.Form.Button> = ({
  link,
  disabled,
  style,
  onClick,
  target,
  className,
  type,
  children
}) => {
  return (
    <>
      {link ? (
        <S.dangerButtonLink style={style} className={className}>
          {target ? (
            <a href={link} target={target} style={style}>
              {children}
            </a>
          ) : (
            <Link to={link} style={style}>
              {children}
            </Link>
          )}
        </S.dangerButtonLink>
      ) : (
        <S.dangerButton className={className} type={type} onClick={onClick} disabled={disabled} style={style}>
          {children}
        </S.dangerButton>
      )}
    </>
  );
};

export default DangerButton;
