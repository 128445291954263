/* eslint-disable react-hooks/exhaustive-deps */
import PanelBoxLayout from 'components/layout/panelBox';

import * as S from './styles';
import React, { useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import { useActions } from 'common/hooks/useActions';
import convertMsToMin from 'common/helpers/convertMsToMin';

export default function DashboardEvents() {
  const { dashboardEventsRequestAction } = useActions();
  const { events } = useTypedSelector((state: any) => state.Dashboard);

  useEffect(() => {
    dashboardEventsRequestAction({});
  }, []);

  console.log(events);

  return (
    <div>
      <PanelBoxLayout
        title="Events"
        style={{ padding: 16 }}
        titleStyle={{
          fontSize: 24,
          fontWeight: 700,
          color: `var(--primary-dark-two)`
        }}
      >
        <S.Cards>
          <S.Card style={{ background: '#BA8E1D' }}>
            <S.Content>
              <S.Text>Number of Tickets</S.Text>
              <S.Value>{events?.data?.totalCount}</S.Value>
            </S.Content>
            <ReactSVG src="/icons/headphone.svg" />
          </S.Card>
          <S.Card style={{ background: '#1D76BA' }}>
            <S.Content>
              <S.Text>Avg Response Time</S.Text>
              <S.Value>{convertMsToMin(Number(events?.data?.response_average), 'Min') || 'N/A'}</S.Value>
            </S.Content>
            <ReactSVG src="/icons/timer.svg" />
          </S.Card>
          <S.Card style={{ background: '#2B3674' }}>
            <S.Content>
              <S.Text>Avg Closing Time</S.Text>
              <S.Value>{convertMsToMin(events?.data?.close_average, 'Min') || 'N/A'}</S.Value>
            </S.Content>
            <ReactSVG src="/icons/timer.svg" />
          </S.Card>
          <S.Card style={{ background: '#1DBAAF' }}>
            <S.Content>
              <S.Text>Service Rating</S.Text>
              <S.Value>{events?.data?.service_rate_average}</S.Value>
            </S.Content>
            <ReactSVG src="/icons/star-i.svg" />
          </S.Card>
        </S.Cards>
      </PanelBoxLayout>
    </div>
  );
}
