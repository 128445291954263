import { Formik } from 'formik';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ValidationLogin } from 'common/validations';
import PrimaryButton from 'components/form/buttons/primaryButton';
import TextIput from 'components/form/textInput';
import Gap from 'components/gap';
import PasswordInput from 'components/form/passwordInput';
import { useActions } from 'common/hooks/useActions';
import CheckBox from 'components/form/checkbox';

import * as S from '../../styles';

const LoginForm: React.FC = () => {
  const { logInRequestAction } = useActions();
  const history = useNavigate();

  return (
    <Formik
      initialValues={{ mail: '', password: '', remember_me: false }}
      validationSchema={ValidationLogin}
      onSubmit={(values, { setSubmitting }) => {
        logInRequestAction({ ...values, remember_me: JSON.stringify(values.remember_me) }, (path: string) => {
          history(path);
        });
        setSubmitting(false);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        // isSubmitting,
        setValues
      }) => (
        <>
          <TextIput
            label="Email"
            type="text"
            name="mail"
            onChange={handleChange}
            placeholder="Please enter your mail"
            value={values.mail}
            message={touched.mail && errors.mail}
            error={errors.mail as unknown as boolean}
          />
          <Gap size="10px" />
          <PasswordInput
            label="Password"
            name="password"
            onChange={handleChange}
            placeholder="Please enter your password"
            // onBlur={handleBlur}
            value={values.password}
            message={touched.password && errors.password}
            error={errors.password as unknown as boolean}
          />
          <Gap size="10px" />
          <S.Links>
            <CheckBox
              theme="two"
              text={<>Remember me</>}
              style={{ alignItems: 'center' }}
              checked={values.remember_me}
              onChange={(val: boolean) => setValues({ ...values, remember_me: val })}
            />
            <Link to="/auth/forget-password">Forget Password?</Link>
          </S.Links>

          <Gap size="20px" />

          <PrimaryButton
            style={{ width: '100%' }}
            onClick={handleSubmit}
            type="submit"
            // disabled={isSubmitting}
          >
            Sign In
          </PrimaryButton>
        </>
      )}
    </Formik>
  );
};

export default LoginForm;
