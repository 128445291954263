// -- == //
import Gap from 'components/gap';
import AppLoading from 'components/appLoading';
import { useTypedSelector } from 'common/hooks/useTypedSelector';

import * as S from './styles';
import NotificationCreateTabs from './lib/tabs';

export default function NotificationCreatePage() {
  const { eventCreate, eventUpload, contributorUploadQuest } = useTypedSelector((state: any) => state.Events);
  const { departments } = useTypedSelector((state) => state.Departments);
  const { floorList } = useTypedSelector((state) => state.Floors);
  const { userList } = useTypedSelector((state) => state.Users);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Create Notification</S.Title>
      </S.Head>
      <Gap size="20px" />
      <NotificationCreateTabs departments={departments.data} floors={floorList.data} userList={userList.data} />

      <AppLoading
        loading={
          eventCreate.loading ||
          eventUpload.loading ||
          departments.loading ||
          floorList.loading ||
          contributorUploadQuest.loading
        }
      />
    </S.Container>
  );
}
