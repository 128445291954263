/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import moment from 'moment';

import AppTable from 'components/appTable';
import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import { useActions } from 'common/hooks/useActions';
import PrimaryButton from 'components/form/buttons/primaryButton';
import TicketFilterPopup from '../filterForm';
import PaginationApp from 'components/pagination';

import * as S from './styles';
import * as T from 'common/types';
import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';

interface IProps {
  data: { list: []; totalPages: number };
  loading: boolean;
}

export default function SurveyTable({ data, loading }: IProps) {
  const { surveysRequestAction } = useActions();
  const [meta, setMeta] = useState({
    page: 1,
    page_size: 10,
    search: '',
    type: ''
  });

  const fetchData = () => {
    surveysRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  const handleChangePage = (page: number) => {
    setMeta({ ...meta, page });
  };

  const handleChangePageSize = (page_size: number) => {
    setMeta({ ...meta, page_size });
  };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => setMeta({ ...meta, search: e })}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
            value={meta.search}
          />
          <TicketFilterPopup setFilter={setMeta} filter={meta} />
        </S.Head>
        <Gap size="20px" />
        <AppTable
          headers={[
            { id: 1, title: 'ID', dir: 'left' },
            { id: 2, title: 'Title', dir: 'left' },
            { id: 3, title: 'Start Date', dir: 'left' },
            { id: 4, title: 'End Date', dir: 'left' },
            { id: 5, title: 'Attendance' },
            { id: 6, title: 'Status' },
            { id: 7, title: 'Edit' },
            { id: 8, title: '' }
          ]}
          loading={loading}
          data={data.list}
          tdFields={{
            id: (item: T.Pages.SurveyItem) => <S.Div className="text-left">{item?.id}</S.Div>,

            title: (item: T.Pages.SurveyItem) => <S.Div className="text-left ">{item.title}</S.Div>,
            startsAt: (item: T.Pages.SurveyItem) => (
              <S.Div className="text-left ">{moment(item.startsAt).format('YYYY.MM.DD HH:MM')}</S.Div>
            ),
            endsAt: (item: T.Pages.SurveyItem) => (
              <S.Div className="text-left ">{moment(item.endsAt).format('YYYY.MM.DD HH:MM')}</S.Div>
            ),
            attendance: (item: T.Pages.SurveyItem) => <S.Div>{item.attendance}</S.Div>,
            status: (item: T.Pages.SurveyItem) => <S.Div>{item.status}</S.Div>,
            edit: (item: T.Pages.SurveyItem) => (
              <S.Div>
                <Link to={`/panel/survey/${item.id}`}>
                  <ReactSVG
                    src="/icons/stroke/pen-card.svg"
                    beforeInjection={(svg) => {
                      svg.setAttribute('stroke', `#8A93AC`);
                      // svg.setAttribute("width", `12px`);
                      // svg.setAttribute("heigth", `12px`);
                    }}
                    wrapper="span"
                  />
                </Link>
              </S.Div>
            ),
            show: (item: T.Pages.SurveyItem) => (
              <PrimaryButton
                style={{
                  height: 28,
                  fontSize: 12,
                  background: '#8A93AC',
                  color: 'var(--white)',
                  width: 94,
                  margin: 'auto',
                  borderRadius: 8
                }}
                link={`/panel/survey/show/${item.id}`}
              >
                Show
              </PrimaryButton>
            )
          }}
        />
      </PanelBoxLayout>
      {data.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.page_size * data.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={data.totalPages}
          pageSize={meta.page_size}
          handleChangeTake={handleChangePageSize}
        />
      ) : (
        <></>
      )}
    </S.Content>
  );
}
