import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.h2`
  font-size: 34px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: -0.02em;
  text-align: left;
  color: var(--primary-dark-two);
  margin: 0px 0;
`;

export const Name = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #0a214280;
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Div = styled.div``;
