/* eslint-disable react-hooks/exhaustive-deps */
// -- == //
import { useEffect } from 'react';
import { ReactSVG } from 'react-svg';

import { useActions } from 'common/hooks/useActions';
import TextInput from 'components/form/textInput';
import UploadIcon from 'components/form/uploadIcon';
import TextArea from 'components/form/texArea';
import Gap from 'components/gap';
import { toast } from 'common/helpers/toastConfig';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  setValues: any;
  values: any;
}

export default function EventFormGuestsList({ setValues, values }: IProps) {
  const { departmentsRequestAction, ticketCategoriesRequestAction, eventUploadGuestRequestAction } = useActions();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    departmentsRequestAction();
    ticketCategoriesRequestAction({ page: 1, page_size: 1000 });
  };

  const handleAddNew = () => {
    let flag = 0;
    let flagLinkdin = 0;
    let flagWeblink = 0;

    const expression =
      // eslint-disable-next-line no-useless-escape
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(expression);

    // eslint-disable-next-line array-callback-return
    values.guestsList.map((item: any) => {
      if (!item.fullName || !item.jobTitle || !item.description) {
        flag += 1;
      }
      if (!item.weblink.match(regex) && item.weblink) {
        flagWeblink += 1;
      }
      if (!item.linkedin.match(regex) && item.linkedin) {
        flagLinkdin += 1;
      }
    });
    if (flagWeblink > 0) {
      toast({
        message: `The website link format is wrong`,
        model: 'error'
      });
      return;
    }
    if (flagLinkdin > 0) {
      toast({
        message: `The linkedin link format is wrong`,
        model: 'error'
      });
      return;
    }
    if (flag !== 0) {
      toast({
        model: 'error',
        message: 'Full name, job title, Description fields are required'
      });
      return;
    }
    setValues({
      ...values,
      guestsList: [
        ...values.guestsList,
        {
          fullName: '',
          jobTitle: '',
          description: '',
          weblink: '',
          linkedin: '',
          avatar: '',
          image: '',
          id: JSON.stringify(Math.random() * 67)
        }
      ]
    });
  };

  const handleEdit = (value: string, key: string, guest: T.Pages.GuestListItem) => {
    setValues({
      ...values,
      guestsList: values.guestsList.map((item: T.Pages.GuestListItem) => {
        return item.id === guest.id
          ? {
              ...item,
              [key]: value
            }
          : item;
      })
    });
  };

  const handleDelete = (guest: T.Pages.GuestListItem) => {
    setValues({
      ...values,
      guestsList: values.guestsList.filter((item: T.Pages.GuestListItem) => item.id !== guest.id)
    });
  };

  console.log(values.guestsList);

  return (
    <S.Content>
      <S.Grid>
        <S.Title>Invited Users</S.Title>
        {values.guestsList.length ? (
          <S.Button className="flex-center" onClick={handleAddNew}>
            <ReactSVG
              src="/icons/stroke/plus.svg"
              beforeInjection={(svg) => {
                svg.setAttribute('fill', `var(--primary-green)`);
              }}
            />
          </S.Button>
        ) : (
          <></>
        )}
      </S.Grid>
      <Gap size="10px" />
      <S.List>
        {values.guestsList.length ? (
          values.guestsList.map((item: T.Pages.GuestListItem) => {
            return (
              <S.Item>
                <S.GridItem>
                  <S.Div style={{ minWidth: 'max-content' }}>
                    <UploadIcon
                      value={{
                        dataURL: item.image as string,
                        file: item.file
                      }}
                      iconStyle={{
                        top: 0,
                        left: '-15px',
                        right: 'auto',
                        bottom: 'auto'
                      }}
                      onChange={(e: T.Form.FileUpload) => {
                        eventUploadGuestRequestAction({ image: e?.file }, (fileNameOnly: string) => {
                          setValues({
                            ...values,
                            guestsList: values.guestsList.map((item2: T.Pages.GuestListItem) => {
                              return item2.id === item.id
                                ? {
                                    ...item2,
                                    avatar: fileNameOnly,
                                    image: e.dataURL,
                                    file: e.file as any
                                  }
                                : item2;
                            })
                          });
                        });
                      }}
                    />
                  </S.Div>
                  <S.Inputs>
                    <S.Div className="row">
                      <S.Div className="col-md-5">
                        <TextInput
                          value={item.fullName}
                          onChange={(e) => handleEdit(e.target.value, 'fullName', item)}
                          placeholder="Full name"
                          inputStyle={{
                            height: 31,
                            padding: 10,
                            fontSize: 11,
                            borderRadius: 10
                          }}
                        />
                      </S.Div>
                      <S.Div className="col-md-5">
                        <TextInput
                          value={item.jobTitle}
                          onChange={(e) => handleEdit(e.target.value, 'jobTitle', item)}
                          placeholder="Job Title"
                          inputStyle={{
                            height: 31,
                            padding: 10,
                            fontSize: 11,
                            borderRadius: 10
                          }}
                        />
                      </S.Div>
                      <S.Div className="col-md-2">
                        <S.ButtonDelete className="flex-center" onClick={() => handleDelete(item)}>
                          <ReactSVG
                            src="/icons/stroke/trash.svg"
                            beforeInjection={(svg) => {
                              svg.setAttribute('stroke', `#FF5757`);
                              svg.setAttribute('height', `12px`);
                              svg.setAttribute('width', `11px`);
                            }}
                          />
                        </S.ButtonDelete>
                      </S.Div>
                      <S.Div className="col-12">
                        <TextArea
                          onChange={(e) => handleEdit(e.target.value, 'description', item)}
                          value={item.description}
                          placeholder="Description"
                          inputStyle={{
                            padding: 6,
                            fontSize: 11,
                            borderRadius: 10
                          }}
                          height="35px"
                        />
                      </S.Div>
                      <S.Div className="col-md-6">
                        <TextInput
                          onChange={(e) => handleEdit(e.target.value, 'weblink', item)}
                          value={item.weblink}
                          placeholder="Website (Starting with http or https)"
                          inputStyle={{
                            height: 31,
                            padding: 10,
                            fontSize: 11,
                            borderRadius: 10
                          }}
                        />
                      </S.Div>
                      <S.Div className="col-md-6">
                        <TextInput
                          onChange={(e) => handleEdit(e.target.value, 'linkedin', item)}
                          value={item.linkedin}
                          placeholder="LinkedIn (Starting with http or https)"
                          inputStyle={{
                            height: 31,
                            padding: 10,
                            fontSize: 11,
                            borderRadius: 10
                          }}
                        />
                      </S.Div>
                    </S.Div>
                  </S.Inputs>
                </S.GridItem>
              </S.Item>
            );
          })
        ) : (
          <S.EmptyList>
            <S.Title>No Users Yet</S.Title>
            <S.Grid>
              <S.Span>Do you want to add?</S.Span>
              <S.Button className="flex-center" onClick={handleAddNew}>
                <ReactSVG
                  src="/icons/stroke/plus.svg"
                  beforeInjection={(svg) => {
                    svg.setAttribute('fill', `var(--primary-green)`);
                  }}
                />
              </S.Button>
            </S.Grid>
          </S.EmptyList>
        )}
      </S.List>
    </S.Content>
  );
}
