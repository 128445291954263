/* eslint-disable array-callback-return */
import SearchBox from 'components/form/searchBox';
import type { DataNode } from 'antd/es/tree';

import * as S from './styles';
// import * as T from "common/types";
import { Tree } from 'antd';
import Gap from 'components/gap';
import convertMsToMin from 'common/helpers/convertMsToMin';

type listItemType = {
  chartResultAvrCloseTime: number;
  chartResultAvrResponseTime: number;
  child: listItemType[];
  id: number;
  title: string;
  totalCount: string;
  parentTitle?: string;
};
interface IProps {
  data?: listItemType[];
  loading?: boolean;
  keyword?: string;
  setKeyword: (val: string) => void;
}

export default function MaintenanceTable({ data, loading, keyword, setKeyword }: IProps) {
  console.log(data);

  const renderList = (list: any[]) => {
    const treeData: DataNode[] = [];

    list?.map((item) => {
      treeData.push({
        title: (
          <S.Item className="flex justify-between w-full">
            <S.TdItem style={{ width: 'max-content' }}>
              {item?.parentTitle ? item?.parentTitle + '/' : ''}
              {item.title}
            </S.TdItem>
            <S.TdItem style={{ marginLeft: 'auto' }}>{item.totalCount}</S.TdItem>
            <S.TdItem style={{}}>{convertMsToMin(item.chartResultAvrResponseTime, 'Min') || 'N/A'}</S.TdItem>
            <S.TdItem style={{}}>{convertMsToMin(item.chartResultAvrCloseTime, 'Min') || 'N/A'}</S.TdItem>
          </S.Item>
        ),
        key: item.id,
        children: renderList(item.child)
      });
    });
    return treeData;
  };

  return (
    <S.Content>
      <SearchBox
        onChange={(e) => setKeyword(e)}
        placeholder="Search"
        inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
        value={keyword}
      />
      <Gap size="10px" />
      <S.Header>
        <S.Span>Category</S.Span>
        <S.Span style={{ marginLeft: 'auto' }}>Tickets</S.Span>
        <S.Span>Res Time</S.Span>
        <S.Span>Close Time</S.Span>
      </S.Header>
      <Tree
        defaultExpandAll
        // switcherIcon={<DownOutlined />}
        // defaultExpandedKeys={}
        key={data?.length}
        treeData={data?.length ? renderList(data) : []}
      />
    </S.Content>
  );
}
