import Gap from 'components/gap';

import * as S from '../../styles';

interface IProps {
  data: any;
}

export default function TicketDetailTypeStationaryInfo({ data }: IProps) {
  return (
    <S.Detail>
      <S.Item style={{ borderBottom: 'none' }}>
        <Gap size="15px" />
        <S.MainTitle>Requests</S.MainTitle>
        <Gap size="10px" />
        {data?.requests.map((item: any) => (
          <>
            <S.Grid>
              <S.IconCon>
                <S.Icon src={item.material.icon || '/images/default-avatar.jpg'} />
              </S.IconCon>
              <S.Div>
                <S.Text>
                  {item.material.title} ({item?.quantity})
                </S.Text>
                <S.P>{item.description}</S.P>
              </S.Div>
            </S.Grid>
            <Gap size="15px" />
          </>
        ))}
      </S.Item>
    </S.Detail>
  );
}
