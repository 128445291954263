// import TicketMenuManagementCreate from "./lib/create";
import SurveyShowTable from './lib/table';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function SurveyShowPage() {
  const { result } = useTypedSelector((state) => state.Survey);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Survey Title Lorem ipsum</S.Title>
      </S.Head>
      <Gap size="20px" />
      <SurveyShowTable data={result.data} loading={result.loading} />

      <AppLoading loading={result.loading} />
    </S.Container>
  );
}
