/* eslint-disable react-hooks/exhaustive-deps */
import PanelBoxLayout from 'components/layout/panelBox';

import { ReactSVG } from 'react-svg';
import { Divider } from 'antd';

import * as S from './styles';
import { Column } from '@ant-design/charts';
import Gap from 'components/gap';
import { useActions } from 'common/hooks/useActions';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import { useEffect } from 'react';
import convertMsToMin from 'common/helpers/convertMsToMin';

export default function DashboardStationary() {
  const { dashboardStationaryRequestAction } = useActions();
  const { stationary } = useTypedSelector((state: any) => state.Dashboard);

  useEffect(() => {
    dashboardStationaryRequestAction({});
  }, []);

  const config = {
    data: stationary?.data?.item_chart
      ? stationary?.data?.item_chart?.map((item: any) => ({
          ...item,
          totalcount: Number(item.totalcount),
          name: `${item.name}`
        }))
      : [],
    xField: 'name',
    yField: 'totalcount',
    color: '#1DBAAF',
    tooltip: {
      customContent: (title: string, data: any) => {
        return (
          <S.Tooltip>
            <S.TooltipGrid>
              <span>Number of Tickets: </span>
              <span style={{ color: '#1DBAAF' }}>{data[0]?.data?.totalcount}</span>
            </S.TooltipGrid>
            <Gap size="8px" />
            <S.TooltipGrid>
              <span>Number of Quantity: </span>
              <span style={{ color: '#1DBAAF' }}>{data[0]?.data?.totalquantitycount}</span>
            </S.TooltipGrid>
          </S.Tooltip>
        );
      }
    },
    meta: {
      name: {
        alias: 'name'
      },
      totalcount: {
        alias: 'Total count'
      }
    },
    minColumnWidth: 20,
    maxColumnWidth: 20
  };

  return (
    <S.Container>
      <PanelBoxLayout
        title="Stationary"
        afterTitle={
          <S.Div style={{ display: 'flex', gap: 8, marginLeft: 6 }}>
            <ReactSVG src="/icons/star-o-2.svg" />
            <S.Span>{stationary?.data?.service_rate_average}</S.Span>
          </S.Div>
        }
        headStyle={{ marginBottom: 0 }}
        style={{ padding: 16 }}
        titleStyle={{
          fontSize: 24,
          fontWeight: 700,
          color: `var(--primary-dark-two)`
        }}
      >
        <S.Grid>
          <S.Info>
            <S.Text>Number of Open Tickets:</S.Text>
            <S.Span>{stationary?.data?.openTicket}</S.Span>
          </S.Info>
          <S.Info>
            <ReactSVG
              src="/icons/fill/timer.svg"
              beforeInjection={(svg) => {
                svg.setAttribute('fill', `#2B3674`);
              }}
            />
            <S.Text>Average Closing Time:</S.Text>
            <S.Span>{convertMsToMin(stationary?.data?.avgClosedTime, 'Min') || 'N/A'}</S.Span>
          </S.Info>
        </S.Grid>
        <Divider style={{ margin: '20px 0' }} />
        <Column {...config} height={380} />
        <Gap size="20px" />
      </PanelBoxLayout>
    </S.Container>
  );
}
