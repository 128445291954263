/* eslint-disable react-hooks/exhaustive-deps */
// -- == //
import { SetStateAction, useEffect, useState } from 'react';
import { Formik } from 'formik';

import SecundButton from 'components/form/buttons/secundButton';
import MiniPopUp from 'components/modal/miniPopUp';
import PanelBoxLayout from 'components/layout/panelBox';
// import { useActions } from "common/hooks/useActions";

import Gap from 'components/gap';
import PrimaryButton from 'components/form/buttons/primaryButton';
import { useActions } from 'common/hooks/useActions';
import DropDown from 'components/form/dropDown';
import OutlineButton from 'components/form/buttons/outLineButton';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  setFilter: SetStateAction<any>;
  filter: any;
  roles: string[];
}

export default function AdminFilterPopup({ setFilter, filter, roles }: IProps) {
  const [flag, setFlag] = useState<number>(0);

  const { ticketCategoriesRequestAction, areaListRequestAction, rolesRequestAction } = useActions();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    areaListRequestAction();
    rolesRequestAction();
    ticketCategoriesRequestAction({ page: 1, page_size: 1000 });
  };

  return (
    <S.Content>
      <MiniPopUp
        popUpStyle={{ width: 245, padding: '0' }}
        buttonNode={
          <SecundButton
            hasIcon={true}
            icon="/icons/stroke/filter.svg"
            iconStyle={{ background: 'none' }}
            style={{ background: '#8A93AC' }}
            colorIcon="var(--white)"
          >
            Filters
          </SecundButton>
        }
        key={flag}
      >
        <PanelBoxLayout
          style={{ padding: 15 }}
          titleStyle={{ color: '#2D3748', fontSize: 14 }}
          title="Filters"
          icon="/icons/stroke/filter.svg"
        >
          <Formik
            initialValues={{
              role: ['sAdmin', 'admin', 'supporter', 'cafeTeriaManager'],
              roleTitle: ''
            }}
            // validationSchema={ValidationLogin}
            onSubmit={(values, { setSubmitting }) => {
              setFilter({
                ...filter,
                role: values.role,
                page: 1
              });
              setSubmitting(false);
              setFlag((val) => (val += 1));
            }}
          >
            {({
              values,
              errors,
              touched,
              // handleChange,
              handleSubmit,
              // isSubmitting,
              resetForm,
              setValues
            }) => (
              <>
                <DropDown
                  label="Role"
                  list={roles?.map((item: any) => {
                    return { id: item, title: item };
                  })}
                  onChange={(e: T.Form.ListDropdownItem) => {
                    setValues({
                      ...values,
                      role: [e.id] as any,
                      roleTitle: e.id as any
                    });
                  }}
                  defaultValue={{
                    id: values.roleTitle,
                    title: values.roleTitle
                  }}
                  placeholder="Select Role"
                  message={touched.roleTitle && (errors.roleTitle as any)}
                  error={errors.role as unknown as boolean}
                  inputStyle={{
                    height: 40,
                    borderRadius: 7,
                    fontSize: 12,
                    padding: '0 15px'
                  }}
                  labelStyle={{ fontSize: 12 }}
                />

                <Gap size="20px" />

                <PrimaryButton
                  style={{ width: '100%', height: 42, borderRadius: 8 }}
                  onClick={handleSubmit}
                  // disabled={isSubmitting}
                >
                  Filter
                </PrimaryButton>
                <Gap size="10px" />

                <OutlineButton
                  style={{ width: '100%', height: 42, borderRadius: 8 }}
                  onClick={() => {
                    setValues({
                      role: ['sAdmin', 'admin', 'supporter', 'cafeTeriaManager'],
                      roleTitle: ''
                    });
                    handleSubmit();
                  }}
                  // disabled={isSubmitting}
                >
                  Reset
                </OutlineButton>
              </>
            )}
          </Formik>
        </PanelBoxLayout>
      </MiniPopUp>
    </S.Content>
  );
}
