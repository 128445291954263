// -- == //
import * as types from 'common/constants';

const initialState = {
  surveys: {
    data: [],
    loading: false,
    error: null
  },
  surveyDetail: {
    data: {},
    loading: false,
    error: null
  },
  surveyCreate: {
    data: {},
    loading: false,
    error: null
  },
  result: {
    data: {},
    loading: false,
    error: null
  },
  questions: []
};

interface Reducer {
  type: string;
  payload: any;
}

const Surveys = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Survey List cases
     */
    case types.SURVEYS.REQUEST:
      return {
        ...state,
        surveys: {
          data: state.surveys.data,
          loading: true,
          error: null
        }
      };
    case types.SURVEYS.SUCCESS:
      return {
        ...state,
        surveys: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.SURVEYS.FAILURE:
      return {
        ...state,
        surveys: {
          data: [],
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Survey Detail cases
     */
    case types.SURVEY_DETAIL.REQUEST:
      return {
        ...state,
        surveyDetail: { data: {}, loading: true, error: null }
      };
    case types.SURVEY_DETAIL.SUCCESS:
      return {
        ...state,
        surveyDetail: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.SURVEY_DETAIL.FAILURE:
      return {
        ...state,
        surveyDetail: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Survey Create cases
     */
    case types.SURVEY_CREATE.REQUEST:
      return {
        ...state,
        surveyCreate: { data: {}, loading: true, error: null }
      };
    case types.SURVEY_CREATE.SUCCESS:
      return {
        ...state,
        surveyCreate: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.SURVEY_CREATE.FAILURE:
      return {
        ...state,
        surveyCreate: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Survey Result List cases
     */
    case types.SURVEYS_RESULT.REQUEST:
      return {
        ...state,
        result: {
          data: state.result.data,
          loading: true,
          error: null
        }
      };
    case types.SURVEYS_RESULT.SUCCESS:
      return {
        ...state,
        result: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.SURVEYS_RESULT.FAILURE:
      return {
        ...state,
        result: {
          data: [],
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Questions cases
     */
    case types.SURVEY_QUESTION.LIST:
      return {
        ...state,
        questions: payload
      };
    case types.SURVEY_QUESTION.ADD:
      return {
        ...state,
        questions: [...state.questions, { ...payload }]
      };
    case types.SURVEY_QUESTION.EDIT:
      return {
        ...state,
        questions: state.questions.map((item: any) => {
          return item.id === payload.id
            ? {
                ...item,
                ...payload,
                options: payload.type === 'optional' ? payload.options : []
              }
            : item;
        })
      };
    case types.SURVEY_QUESTION.DELETE:
      return {
        ...state,
        questions: state.questions.filter((item: any) => item.id !== payload.id)
      };
    case types.SURVEY_QUESTION_CHOICES.EDIT:
      return {
        ...state,
        questions: state.questions.map((item: any) => {
          return item.id === payload.parentId
            ? {
                ...item,
                options: item.options.map((item2: any) => {
                  return item2.id === payload.id
                    ? {
                        title: payload.value,
                        id: payload.id
                      }
                    : item2;
                })
              }
            : item;
        })
      };
    case types.SURVEY_QUESTION_CHOICES.DELETE:
      return {
        ...state,
        questions: state.questions.map((item: any) => {
          return item.id === payload.parentId
            ? {
                ...item,
                options: item.options.filter((item2: any) => item2.id !== payload.id)
              }
            : item;
        })
      };
    default:
      return state;
  }
};

export default Surveys;
