export enum NOTIFICATIONS {
  REQUEST = 'NOTIFICATIONS_REQUEST',
  SUCCESS = 'NOTIFICATIONS_SUCCESS',
  FAILURE = 'NOTIFICATIONS_FAILURE'
}

export enum NOTIFICATIONS_CUSTOM {
  REQUEST = 'NOTIFICATIONS_CUSTOM_REQUEST',
  SUCCESS = 'NOTIFICATIONS_CUSTOM_SUCCESS',
  FAILURE = 'NOTIFICATIONS_CUSTOM_FAILURE'
}

export enum NOTIFICATION_CREATE_CUSTOM {
  REQUEST = 'NOTIFICATION_CREATE_CUSTOM_REQUEST',
  SUCCESS = 'NOTIFICATION_CREATE_CUSTOM_SUCCESS',
  FAILURE = 'NOTIFICATION_CREATE_CUSTOM_FAILURE'
}

export enum NOTIFICATION_UPDATE_CUSTOM {
  REQUEST = 'NOTIFICATION_UPDATE_CUSTOM_REQUEST',
  SUCCESS = 'NOTIFICATION_UPDATE_CUSTOM_SUCCESS',
  FAILURE = 'NOTIFICATION_UPDATE_CUSTOM_FAILURE'
}

export enum NOTIFICATION_UPDATE_READ {
  REQUEST = 'NOTIFICATION_UPDATE_READ_REQUEST',
  SUCCESS = 'NOTIFICATION_UPDATE_READ_SUCCESS',
  FAILURE = 'NOTIFICATION_UPDATE_READ_FAILURE'
}

export enum NOTIFICATION_UPDATE {
  REQUEST = 'NOTIFICATION_UPDATE_REQUEST',
  SUCCESS = 'NOTIFICATION_UPDATE_SUCCESS',
  FAILURE = 'NOTIFICATION_UPDATE_FAILURE'
}
