/* eslint-disable react-hooks/exhaustive-deps */
// import TicketMenuManagementCreate from "./lib/create";
import { useEffect } from 'react';
import SurveyCreateQuestions from '../lib/questions';
import Gap from 'components/gap';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import SurveyCreateFormInfo from '../lib/info';
import { useActions } from 'common/hooks/useActions';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function SurveyCreatePage() {
  const { ticketDetail } = useTypedSelector((state) => state.Tickets);
  const { questions, surveyCreate } = useTypedSelector((state) => state.Survey);
  const { surveyQuestionListAction } = useActions();

  useEffect(() => {
    surveyQuestionListAction([]);
  }, []);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Create New Survey</S.Title>
      </S.Head>
      <Gap size="20px" />
      <S.Div className="row">
        <S.Div className="col-md-6">
          <SurveyCreateFormInfo questions={questions} />
        </S.Div>
        <S.Div className="col-md-6">
          <SurveyCreateQuestions data={ticketDetail.data} questions={questions} />
        </S.Div>
      </S.Div>

      <AppLoading loading={surveyCreate.loading} />
    </S.Container>
  );
}
