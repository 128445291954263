const timezoneToDate = (date: string) => {
  let newDate: string = '';
  let newTime: string = '';
  if (!date) return '';
  newTime = date?.split('T')[0];
  newDate = date?.split('T')[1];

  return newTime?.replaceAll('-', '/') + ' ' + newDate?.substring(0, 5);
};

export default timezoneToDate;
