import { ReactNode } from 'react';
import { ReactSVG } from 'react-svg';
import * as S from './styles';
// import * as Types from "../../common/types";

interface IProps {
  show: boolean;
  handleClose: () => void;
  children: ReactNode;
  offsetTop?: string;
  fixed?: boolean;
  title?: string;
  center?: boolean;
  style?: object;
}

const ModalApp: React.FC<IProps> = ({ show, handleClose, fixed, children, title, center, style }) => {
  return (
    <S.modalContainer className={`${fixed ? 'fixed' : 'absolute'} ${show ? 'show' : ''} ${center ? 'center' : ''}`}>
      <S.back onClick={handleClose} />
      <S.modalPaper className={`modal-paper ${show ? 'show' : ''} ${center ? 'center' : ''}`} style={style}>
        <S.closeBtn onClick={handleClose}>
          <ReactSVG
            src={'/icons/fill/close.svg'}
            beforeInjection={(svg) => {
              svg.setAttribute('fill', `#B6B6B6`);
              svg.setAttribute('width', `14px`);
              svg.setAttribute('height', `14px`);
            }}
          />
        </S.closeBtn>
        {title && <S.title className="titl">{title}</S.title>}
        {children}
      </S.modalPaper>
    </S.modalContainer>
  );
};

export default ModalApp;
