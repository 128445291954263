/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';

import AppTable from 'components/appTable';
import SearchBox from 'components/form/searchBox';
import Gap from 'components/gap';
import PanelBoxLayout from 'components/layout/panelBox';
import PaginationApp from 'components/pagination';
import { useActions } from 'common/hooks/useActions';
import ModalApp from 'components/modalApp';
import FloorCreateForm from '../create/lib/form';
import QuestionModal from 'components/modal/question';

import * as S from './styles';
import * as T from 'common/types';

interface IProps {
  menuList: { list: T.Pages.FloorListItem[]; totalPages: number };
  loading: boolean;
}

export default function FloorsTable({ menuList, loading }: IProps) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteData, setDeleteData] = useState<T.Pages.FloorListItem | null>();
  const [editData, setEditData] = useState<T.Pages.FloorListItem | null>();
  const [meta, setMeta] = useState({
    page: 1,
    page_size: 10,
    search: '',
    type: ''
  });
  const { floorsRequestAction, floorDeleteRequestAction } = useActions();

  const fetchData = () => {
    floorsRequestAction(meta);
  };

  useEffect(() => {
    fetchData();
  }, [meta]);

  const handleShowEdit = (item: T.Pages.FloorListItem) => {
    setShowEditModal(true);
    setEditData(item);
  };

  const handleCloseEdit = () => {
    setShowEditModal(false);
    setEditData(null);
  };

  const handleChangePage = (page: number) => {
    setMeta({ ...meta, page });
  };

  const handleChangeTake = (page_size: number) => {
    setMeta({ ...meta, page_size });
  };

  const handleFilter = (field: string, val: string | number) => {
    setMeta({ ...meta, page: 1, [field]: val });
  };

  const handleShowDeleteModal = (data: T.Pages.FloorListItem) => {
    setShowDeleteModal(true);
    setDeleteData(data);
  };

  const handleHideDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteData(null);
  };

  const handleDeleteFloor = () => {
    floorDeleteRequestAction(deleteData?.id as number, () => {
      setShowDeleteModal(false);
      fetchData();
    });
  };

  return (
    <S.Content>
      <PanelBoxLayout>
        <S.Head>
          <SearchBox
            onChange={(e) => handleFilter('search', e)}
            placeholder="Search"
            inputStyle={{ height: 36, borderRadius: 10, fontSize: 12 }}
            style={{ width: 500 }}
            value={meta.search}
          />
        </S.Head>
        <Gap size="20px" />
        <AppTable
          headers={[
            { id: 1, title: 'ID', dir: 'left', width: '5%' },
            { id: 3, title: 'Title', dir: 'left', width: '30%' },
            { id: 7, title: 'Manage', width: '30%' }
          ]}
          style={{ minWidth: 'auto' }}
          data={menuList?.list}
          loading={loading}
          tdFields={{
            id: (item: T.Pages.FloorListItem) => <S.Div className="text-left">{item?.id}</S.Div>,
            title: (item: T.Pages.FloorListItem) => <S.Div className="text-left captal">{item.name}</S.Div>,
            edit: (item: T.Pages.FloorListItem) => (
              <S.Grid>
                <S.Div style={{ cursor: 'pointer' }} onClick={() => handleShowEdit(item)}>
                  <ReactSVG
                    src="/icons/stroke/pen-card.svg"
                    beforeInjection={(svg) => {
                      svg.setAttribute('stroke', `#8A93AC`);
                    }}
                  />
                </S.Div>
                <S.Div style={{ cursor: 'pointer' }} onClick={() => handleShowDeleteModal(item)}>
                  <ReactSVG
                    src="/icons/stroke/trash.svg"
                    beforeInjection={(svg) => {
                      svg.setAttribute('stroke', `var(--danger)`);
                    }}
                  />
                </S.Div>
              </S.Grid>
            )
          }}
        />
      </PanelBoxLayout>
      {menuList?.totalPages ? (
        <PaginationApp
          page={meta.page}
          count={meta.page_size * menuList.totalPages}
          handleChangePage={(page: number) => handleChangePage(page)}
          key={menuList.totalPages}
          pageSize={meta.page_size}
          handleChangeTake={handleChangeTake}
        />
      ) : (
        <></>
      )}

      <ModalApp show={showEditModal} fixed handleClose={handleCloseEdit} style={{ width: 400, padding: '20px 40px' }}>
        <S.Div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <S.Icon>
            <ReactSVG
              src={'/icons/stroke/pen.svg'}
              beforeInjection={(svg) => {
                svg.setAttribute('stroke', `var(--white)`);
                svg.setAttribute('width', `14px`);
                svg.setAttribute('height', `14px`);
              }}
            />
          </S.Icon>
          <S.Title>Edit Floor {editData?.name}</S.Title>
          <FloorCreateForm
            data={editData as T.Pages.FloorListItem}
            closeModal={handleCloseEdit}
            fetchData={fetchData}
            // key={editData?.id}
          />
        </S.Div>
      </ModalApp>

      <QuestionModal
        handleClose={handleHideDeleteModal}
        show={showDeleteModal}
        model="delete"
        handleSuccess={handleDeleteFloor}
        title="Are you sure Delete This Floor?"
        successButtonText="Delete"
      />
    </S.Content>
  );
}
