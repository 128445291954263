// -- == //
import * as types from 'common/constants';

const initialState = {
  tickets: {
    data: [],
    loading: false,
    error: null
  },
  ticketDetail: {
    data: {},
    loading: false,
    error: null
  },
  ticketCategory: {
    data: [],
    loading: false,
    error: null
  },
  ticketCategoryDetail: {
    data: {},
    loading: false,
    error: null
  },
  ticketCategoryCreate: {
    data: {},
    loading: false,
    error: null
  },
  ticketCategoryEdit: {
    data: {},
    loading: false,
    error: null
  },
  ticketCategoryIcon: {
    data: {},
    loading: false,
    error: null
  },
  ticketStatistics: {
    data: {},
    loading: false,
    error: null
  },
  ticketCategoryDisable: {
    data: {},
    loading: false,
    error: null
  },
  ticketCategoryEnable: {
    data: {},
    loading: false,
    error: null
  },
  ticketClose: {
    data: {},
    loading: false,
    error: null
  },
  supporterList: []
};

interface Reducer {
  type: string;
  payload: any;
}

const Tickets = (state = initialState, { type, payload }: Reducer) => {
  switch (type) {
    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Ticket List cases
     */
    case types.TICKETS.REQUEST:
      return {
        ...state,
        tickets: {
          data: state.tickets.data,
          loading: true,
          error: null
        }
      };
    case types.TICKETS.SUCCESS:
      return {
        ...state,
        tickets: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.TICKETS.FAILURE:
      return {
        ...state,
        tickets: {
          data: [],
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Ticket Detail cases
     */
    case types.TICKET_DETAIL.REQUEST:
      return {
        ...state,
        ticketDetail: { data: {}, loading: true, error: null }
      };
    case types.TICKET_DETAIL.SUCCESS:
      return {
        ...state,
        ticketDetail: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.TICKET_DETAIL.FAILURE:
      return {
        ...state,
        ticketDetail: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Category List cases
     */
    case types.TICKET_CATEGORIES.REQUEST:
      return {
        ...state,
        ticketCategory: {
          data: [],
          loading: true,
          error: null
        }
      };
    case types.TICKET_CATEGORIES.SUCCESS:
      return {
        ...state,
        ticketCategory: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.TICKET_CATEGORIES.FAILURE:
      return {
        ...state,
        ticketCategory: {
          data: [],
          loading: false,
          error: payload
        }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Category Detail cases
     */
    case types.TICKET_CATEROY_DETAIL.REQUEST:
      return {
        ...state,
        ticketCategoryDetail: { data: {}, loading: true, error: null }
      };
    case types.TICKET_CATEROY_DETAIL.SUCCESS:
      return {
        ...state,
        ticketCategoryDetail: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.TICKET_CATEROY_DETAIL.FAILURE:
      return {
        ...state,
        ticketCategoryDetail: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Category Create cases
     */
    case types.TICKET_CATEROY_CREATE.REQUEST:
      return {
        ...state,
        ticketCategoryCreate: { data: {}, loading: true, error: null }
      };
    case types.TICKET_CATEROY_CREATE.SUCCESS:
      return {
        ...state,
        ticketCategoryCreate: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.TICKET_CATEROY_CREATE.FAILURE:
      return {
        ...state,
        ticketCategoryCreate: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Category Edit cases
     */
    case types.TICKET_CATEROY_EDIT.REQUEST:
      return {
        ...state,
        ticketCategoryEdit: { data: {}, loading: true, error: null }
      };
    case types.TICKET_CATEROY_EDIT.SUCCESS:
      return {
        ...state,
        ticketCategoryEdit: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.TICKET_CATEROY_EDIT.FAILURE:
      return {
        ...state,
        ticketCategoryEdit: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Category Icon cases
     */
    case types.TICKET_CATEROY_ICON.REQUEST:
      return {
        ...state,
        ticketCategoryIcon: { data: {}, loading: true, error: null }
      };
    case types.TICKET_CATEROY_ICON.SUCCESS:
      return {
        ...state,
        ticketCategoryIcon: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.TICKET_CATEROY_ICON.FAILURE:
      return {
        ...state,
        ticketCategoryIcon: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Ticket Statistics cases
     */
    case types.TICKET_STATISTICS.REQUEST:
      return {
        ...state,
        ticketStatistics: { data: {}, loading: true, error: null }
      };
    case types.TICKET_STATISTICS.SUCCESS:
      return {
        ...state,
        ticketStatistics: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.TICKET_STATISTICS.FAILURE:
      return {
        ...state,
        ticketStatistics: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Ticket Close cases
     */
    case types.TICKET_CLOSE.REQUEST:
      return {
        ...state,
        ticketClose: { data: {}, loading: true, error: null }
      };
    case types.TICKET_CLOSE.SUCCESS:
      return {
        ...state,
        ticketClose: {
          data: payload,
          loading: false,
          error: null
        }
      };
    case types.TICKET_CLOSE.FAILURE:
      return {
        ...state,
        ticketClose: { data: {}, loading: false, error: payload }
      };

    /** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
     * Supporter List cases
     */
    case types.TICKET_SUPPORTER_LIST.ADD:
      return {
        ...state,
        supporterList: payload
      };
    case types.TICKET_SUPPORTER_LIST.REMOVE:
      return {
        ...state,
        supporterList: state.supporterList.filter((item: any) => item.id !== payload.id)
      };
    case types.TICKET_SUPPORTER_LIST.RESET:
      return {
        ...state,
        supporterList: []
      };
    default:
      return state;
  }
};

export default Tickets;
