import { ThunkDispatch } from 'redux-thunk';
import { toast } from 'common/helpers/toastConfig';
import { RootState } from 'redux/reducers';
import { AnyAction } from 'redux';

import * as types from 'common/constants';
// import * as T from "common/types";
import * as services from 'common/services';

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * teaboy list actions
 */
export const teaboysRequestAction = (data?: object) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.TEABOYS.REQUEST,
        payload: {}
      });

      // -- //
      const response: any = await services.teaboysService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(teaboysSuccessAction(response.data?.data));
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(teaboysFailureAction(response.data));
      }
      // -- //
    } catch (error: any) {
      console.log(error);
      dispatch(teaboysFailureAction(error));
      throw error;
    }
  };
};

export const teaboysSuccessAction = (data: object) => {
  return {
    type: types.TEABOYS.SUCCESS,
    payload: data
  };
};

export const teaboysFailureAction = (error: object) => {
  return {
    type: types.TEABOYS.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/

/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---
 * Create teaboy actions
 */
export const teaboyAssignToFloorRequestAction = (data: object, callBack: () => void) => {
  return async (dispatch: ThunkDispatch<RootState, void, AnyAction>) => {
    try {
      dispatch({
        type: types.TEABOY_ASSIGN_TO_FLOOR.REQUEST,
        payload: {}
      });

      // -- //
      const response: any = await services.teaboyAssignToFloorService(data);

      if (response.status === 200 || response.status === 201) {
        dispatch(teaboyAssignToFloorSuccessAction(response.data));
        callBack && callBack();
      } else {
        toast({ model: 'error', message: response.data.clientMessage });
        dispatch(teaboyAssignToFloorFailureAction(response.data));
      }
      // -- //
    } catch (error: any) {
      console.log(error);
      dispatch(teaboyAssignToFloorFailureAction(error));
      throw error;
    }
  };
};

export const teaboyAssignToFloorSuccessAction = (data: object) => {
  return {
    type: types.TEABOY_ASSIGN_TO_FLOOR.SUCCESS,
    payload: data
  };
};

export const teaboyAssignToFloorFailureAction = (error: object) => {
  return {
    type: types.TEABOY_ASSIGN_TO_FLOOR.FAILURE,
    payload: error
  };
};
/** ⸻⸻⸻⸻⸻⸻⸻⸻⸻---*/
