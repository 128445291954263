import { ApiCaller } from 'common/helpers/apiCaller';
import convertToQueryString from 'common/helpers/convertToQueryString';

// ------ Reservations ------ //
export const reservationsService = (data: object) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/reservation?${convertToQueryString(data)}`
  });
};

// ------ Reservation Detail ------ //
export const reservationDetailService = (id?: string) => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/reservation/${id}`
  });
};

// ------ Reservation Update Time ------ //
export const reservationUpdateTimeService = (id: string | number, data: object) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/reservation/${id}`,
    data
  });
};

// ------ Reservation Cancel ------ //
export const reservationCancelService = (id: string | number) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/reservation/cancel/${id}`
  });
};

// ------ Reservation Cancel ------ //
export const reservationConfirmService = (id: string | number) => {
  return ApiCaller({
    method: 'PATCH',
    url: `${process.env.REACT_APP_API_URL}api/admin/reservation/confirm/${id}`
  });
};

// ------ Statistics ------ //
export const reservationStatisticsService = () => {
  return ApiCaller({
    method: 'GET',
    url: `${process.env.REACT_APP_API_URL}api/admin/reservation/statistics`
  });
};
