/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Formik } from 'formik';
import { useParams, useLocation } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import { toast } from 'common/helpers/toastConfig';
import { ValidationChnageRole } from 'common/validations';
import PrimaryButton from 'components/form/buttons/primaryButton';
import Gap from 'components/gap';
import { useActions } from 'common/hooks/useActions';
import PasswordInput from 'components/form/passwordInput';
import DropDown from 'components/form/dropDown';
import queryString from 'query-string';

import * as T from 'common/types';
import * as S from '../../styles';

interface IProps {
  data?: T.Pages.UserItem;
  roles: string[];
  closeModal?: () => void;
  fetchData?: () => void;
}

const ChangeRoleForm: React.FC<IProps> = ({ data, roles }) => {
  const nav = useNavigate();
  const params = useParams();
  const location = useLocation();

  const query = queryString.parse(location.search);

  const { changeRoleRequestAction, rolesRequestAction } = useActions();

  useEffect(() => {
    rolesRequestAction();
  }, []);

  const handleCopy = (val: string) => {
    navigator?.clipboard?.writeText(val);
    toast({ model: 'success', message: 'Copied the Password.' });
  };

  return (
    <>
      <Formik
        initialValues={{
          id: params?.id,
          password: '',
          repeat_password: '',
          role: data?.role || ''
        }}
        enableReinitialize
        validationSchema={ValidationChnageRole}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          changeRoleRequestAction(values, () => {
            resetForm();
            nav(query?.backPage ? query?.backPage : ('/panel/users/user-list' as any));
          });
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setValues }) => (
          <S.Div className="row">
            <S.Div className="col-md-6">
              <PasswordInput
                name="password"
                onChange={handleChange}
                placeholder="Password"
                label={
                  <S.Grid style={{ gap: 10 }}>
                    Password
                    <S.ButtonCopy
                      onClick={() => handleCopy(values.repeat_password)}
                      disabled={errors.password || errors.repeat_password ? true : false}
                    >
                      <ReactSVG
                        src="/icons/stroke/copy.svg"
                        beforeInjection={(svg) => {
                          svg.setAttribute('stroke', `#fff`);
                        }}
                      />
                    </S.ButtonCopy>
                  </S.Grid>
                }
                value={values.password}
                message={touched.password && (errors.password as any)}
                error={touched.password && (errors.password as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
                showHide
              />
              <Gap size="10px" />
              <PasswordInput
                name="repeat_password"
                onChange={handleChange}
                placeholder="Repeat Password"
                label="Repeat Password"
                value={values.repeat_password}
                message={touched.repeat_password && (errors.repeat_password as any)}
                error={touched.repeat_password && (errors.repeat_password as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
                showHide
              />
              <Gap size="10px" />
            </S.Div>
            <S.Div className="col-md-6">
              <DropDown
                label="Role"
                list={roles.map((item) => {
                  return { id: item, title: item };
                })}
                onChange={(val: T.Form.ListDropdownItem) => {
                  setValues({
                    ...values,
                    role: val?.id as string
                  });
                }}
                placeholder="Select Role"
                defaultValue={{ title: values.role, id: values.role }}
                message={touched.role && (errors.role as string)}
                error={errors.role as unknown as boolean}
                inputStyle={{
                  borderRadius: 7,
                  fontSize: 12,
                  padding: '0 15px'
                }}
              />
              <Gap size="20px" />
              <S.GridButton>
                <PrimaryButton
                  style={{
                    width: '216px',
                    height: 42,
                    borderRadius: 8
                  }}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  Save Changes
                </PrimaryButton>
              </S.GridButton>
            </S.Div>
          </S.Div>
        )}
      </Formik>
    </>
  );
};

export default ChangeRoleForm;
