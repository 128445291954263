import React from 'react';
import { useNavigate } from 'react-router';
import { Formik } from 'formik';

import { ValidationCreateNews } from 'common/validations';
import PrimaryButton from 'components/form/buttons/primaryButton';
import TextIput from 'components/form/textInput';
import Gap from 'components/gap';
import { useActions } from 'common/hooks/useActions';
import UploadBox from 'components/form/uploadBox';
import TextArea from 'components/form/texArea';

import * as S from '../../styles';
import * as T from 'common/types';

interface IProps {
  data?: T.Pages.NewsListItem;
  closeModal?: () => void;
  fetchData?: () => void;
  loading: boolean;
}

const NewsForm: React.FC<IProps> = ({ data, loading }) => {
  const nav = useNavigate();

  const { newsCreateRequestAction, newsUpdateRequestAction, newsUploadRequestAction, newsUpdateUploadRequestAction } =
    useActions();

  return (
    <>
      <Formik
        initialValues={{
          title: data?.title || '',
          body: data?.body || '',
          reference: data?.reference || '',
          image: data?.imageName || '',
          file: undefined,
          fileUrl: data?.image || ''
        }}
        enableReinitialize
        validationSchema={data?.id ? ValidationCreateNews : ValidationCreateNews}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const newData = {
            title: values.title,
            body: values.body,
            reference: values.reference
          };
          if (data?.id)
            newsUpdateRequestAction({ ...newData, id: data?.id }, () => {
              resetForm();
              nav('/panel/news');
            });
          else
            newsCreateRequestAction({ ...newData, image: values.image }, () => {
              resetForm();
              nav('/panel/news');
            });
          setSubmitting(false);
        }}
      >
        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setValues }) => (
          <S.Div className="row">
            <S.Div className="col-md-6">
              <Gap size="10px" />
              <TextIput
                type="text"
                name="title"
                onChange={handleChange}
                placeholder="Title"
                label="Title"
                value={values.title}
                message={touched.title && (errors.title as any)}
                error={touched.title && (errors.title as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
              />
              <Gap size="10px" />

              <TextArea
                label="Description"
                name="body"
                onChange={handleChange}
                placeholder="Description"
                value={values.body}
                message={touched.body && (errors.body as any)}
                error={touched.body && (errors.body as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
              />
              <Gap size="10px" />
              <TextIput
                type="text"
                name="reference"
                onChange={handleChange}
                placeholder="Reference"
                label="Reference"
                value={values.reference}
                message={touched.reference && (errors.reference as any)}
                error={touched.reference && (errors.reference as unknown as boolean)}
                inputStyle={{ borderRadius: 7 }}
              />
              <Gap size="10px" />

              <UploadBox
                placeholder="Please Upload Image with 1.7 : 1 dimension"
                label="Event Cover Photo"
                inputStyle={{ height: 56, borderRadius: 7 }}
                onChange={(e: T.Form.FileUpload) => {
                  if (data?.id)
                    newsUpdateUploadRequestAction({ image: e?.file, id: data?.id }, (fileNameOnly: string) => {
                      setValues({
                        ...values,
                        image: fileNameOnly,
                        fileUrl: e.dataURL,
                        file: e?.file as any
                      });
                    });
                  else
                    newsUploadRequestAction({ image: e?.file }, (fileNameOnly: string) => {
                      setValues({
                        ...values,
                        image: fileNameOnly,
                        fileUrl: e.dataURL,
                        file: e?.file as any
                      });
                    });
                }}
                message={touched.file && (errors.file as any)}
                error={touched.file && (errors.file as any)}
                key={data?.id}
                defaultImage={values.fileUrl}
                value={{ file: values.file, dataURL: values.fileUrl }}
              />
              <Gap size="20px" />

              <PrimaryButton
                style={{
                  width: '216px',
                  height: 42,
                  borderRadius: 8
                }}
                onClick={handleSubmit}
                disabled={isSubmitting || loading}
              >
                {data?.id ? 'Edit News' : 'Create News'}
              </PrimaryButton>
            </S.Div>
          </S.Div>
        )}
      </Formik>
    </>
  );
};

export default NewsForm;
