import { ReactSVG } from 'react-svg';
import * as S from './styles';
import { useEffect } from 'react';
import { useActions } from 'common/hooks/useActions';
import SecundButton from 'components/form/buttons/secundButton';
import { useNavigate } from 'react-router';

interface IProps {
  // -- //
  data?: any;
}

export default function SurveyCounts({ data }: IProps) {
  const navigate = useNavigate();

  const { ticketStatisticsRequestAction } = useActions();
  useEffect(() => {
    ticketStatisticsRequestAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(data);

  const handleRedirect = () => {
    navigate('/panel/survey/create');
  };

  return (
    <S.Content>
      <SecundButton hasIcon={true} onClick={handleRedirect}>
        Create new Survey
      </SecundButton>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-black.svg" />
          </S.Icon>
          <S.Title>All</S.Title>
        </S.Div>
        <S.Value style={{ color: '#2B3674' }}>0</S.Value>
      </S.Item>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-green.svg" />
          </S.Icon>
          <S.Title>Active</S.Title>
        </S.Div>
        <S.Value style={{ color: '#1DBAAF' }}>0</S.Value>
      </S.Item>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-blue.svg" />
          </S.Icon>
          <S.Title>Completed</S.Title>
        </S.Div>
        <S.Value style={{ color: '#20CAFF' }}>0</S.Value>
      </S.Item>
      <S.Item>
        <S.Div>
          <S.Icon>
            <ReactSVG src="/icons/folder-red.svg" />
          </S.Icon>
          <S.Title>Canceled</S.Title>
        </S.Div>
        <S.Value style={{ color: '#FF6464' }}>0</S.Value>
      </S.Item>
    </S.Content>
  );
}
