// -- == //
import React, { useState } from 'react';
import { TreeSelect } from 'antd';
// import { ReactSVG } from "react-svg";

import * as S from './styles';
import * as T from 'common/types';

const TreeSelectInput: React.FC<T.Form.TreeSelect> = ({
  label,
  id,
  style,
  error,
  success,
  message,
  list,
  onChange,
  className,
  placeholder,
  labelStyle,
  mini,
  disabled
}) => {
  const [value, setValue] = useState<string>();

  const findChild = (array: any[], id: string | number) => {
    let result;
    array.some(
      // eslint-disable-next-line eqeqeq
      (o: any) => (result = o.id == id ? o : findChild(o.child || [], id))
    );
    return result;
  };

  const onChangeC = (newValue: string) => {
    setValue(newValue);
    onChange && onChange(newValue, findChild(list, newValue) || {});
  };

  const renderList = (newList: any) => {
    return newList.map((item: any) => {
      return {
        // ...item,
        title: item.title,
        value: item.id,
        children: renderList(item.child)
      };
    });
  };

  return (
    <S.inputContainer
      style={style}
      className={error ? `error ${className}` : success ? `success ${className}` : `${className}`}
    >
      {label && (
        <S.Label className={`input-label ${mini ? 'mini' : ''}`} htmlFor={id} style={labelStyle}>
          {label}
        </S.Label>
      )}
      <S.InputBox>
        <TreeSelect
          showSearch
          style={{ width: '100%', height: 50 }}
          value={value}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          placeholder={placeholder}
          allowClear
          onChange={onChangeC}
          treeData={renderList(list)}
          disabled={disabled}
        />

        {/* {value?.icon ? (
          <S.iconSelect>
            <S.arrowImg src={value?.icon} />
          </S.iconSelect>
        ) : (
          <></>
        )}
        <S.icon onClick={handleShow} className={mini ? "mini" : ""}>
          <ReactSVG
            src={"/icons/stroke/arrow-bottom.svg"}
            beforeInjection={(svg) => {
              svg.setAttribute("stroke", `#A4A6B3`);
            }}
          />
        </S.icon> */}
      </S.InputBox>

      {message ? (
        <S.message className={error ? 'error-message' : success ? 'success-message' : ''}>{message}</S.message>
      ) : (
        <></>
      )}
    </S.inputContainer>
  );
};

export default TreeSelectInput;

// const treeData = [
//   {
//     value: "parent 1",
//     title: "parent 1",
//     children: [
//       {
//         value: "parent 1-0",
//         title: "parent 1-0",
//         children: [
//           {
//             value: "leaf1",
//             title: "leaf1",
//           },
//           {
//             value: "leaf2",
//             title: "leaf2",
//           },
//         ],
//       },
//       {
//         value: "parent 1-1",
//         title: "parent 1-1",
//         children: [
//           {
//             value: "leaf3",
//             title: <b style={{ color: "#08c" }}>leaf3</b>,
//           },
//         ],
//       },
//     ],
//   },
// ];
