import { device } from 'common/constants/mediaQueries';
import styled from 'styled-components';

export const inputContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  /* overflow: hidden; */
  border-radius: 12px;
  display: flex;
  flex-direction: column;

  .ant-picker {
    background: none;
    border: none;
    box-shadow: none !important;
    padding: 0;
  }

  .ant-picker-suffix {
    display: none;
  }

  .ant-picker-clear {
    right: 18px !important;
    font-size: 18px;
  }

  &.error {
    input {
      border: 1px solid var(--danger) !important;
    }
  }

  &.success {
    input {
      border: solid 1px var(--green-dark);
    }
  }
`;

export const input = styled.input`
  text-align: left;
  padding: 10px 20px;
  height: 50px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  color: var(--dark);
  font-size: 14px;

  @media ${device.tabletL} {
    height: 40px;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    padding: 10px 12px;
  }

  &:focus {
    border: 1px solid #367af7;
  }

  &:not(:placeholder-shown) ~ .input-label {
    color: #367af7;
  }

  &:focus {
    outline: none;
  }
`;

export const label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--dark);
  padding-bottom: 5px;
  transition: 0.2s ease all;
  z-index: 9;

  @media ${device.tabletL} {
    font-size: 12px;
    line-height: 20px;
  }

  &.active {
    top: 0px;
  }
`;

export const message = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: var(--dark-light);
  padding-left: 10px;
  margin-top: 5px;

  &.error-message {
    color: var(--danger);
  }

  &.success-message {
    color: var(--green-dark);
  }
`;

export const icon = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 9;
`;

export const InputBox = styled.div`
  position: relative;
  width: 100%;

  .MuiButtonBase-root {
    position: absolute;
    top: 7px;
    right: 20px;
  }
`;
