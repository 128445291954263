// -- == //
/* eslint-disable react-hooks/exhaustive-deps */
import Gap from 'components/gap';
import { useEffect } from 'react';
import { useParams } from 'react-router';

import NewsForm from '../lib/newsForm';
import { useActions } from 'common/hooks/useActions';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import AppLoading from 'components/appLoading';

import * as S from './styles';

export default function NewsDetailPage() {
  const params = useParams();

  const { newsDetailRequestAction } = useActions();

  const { newsDetail, newsUpdate, newsUpdateUpload } = useTypedSelector((state: any) => state.News);

  useEffect(() => {
    newsDetailRequestAction(params?.id);
  }, []);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Edit News</S.Title>
      </S.Head>
      <Gap size="20px" />
      <NewsForm data={newsDetail.data} loading={newsUpdate.loading} />

      <AppLoading loading={newsDetail.loading || newsUpdate.loading || newsUpdateUpload.loading} />
    </S.Container>
  );
}
