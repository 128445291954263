/* eslint-disable react-hooks/exhaustive-deps */
import PanelBoxLayout from 'components/layout/panelBox';

import { ReactSVG } from 'react-svg';
import { Divider } from 'antd';

import * as S from './styles';
import Gap from 'components/gap';
import MaintenanceTable from './lib/table';
import { useActions } from 'common/hooks/useActions';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
import { useEffect, useState } from 'react';
import convertMsToMin from 'common/helpers/convertMsToMin';
import DashboardMaintenanceChart from './lib/chart';
type listItemType = {
  chartResultAvrCloseTime: number;
  chartResultAvrResponseTime: number;
  child: listItemType[];
  id: number;
  title: string;
  totalCount: string;
  parentTitle?: string;
};

export default function DashboardMaintenance() {
  const { dashboardMaintenanceRequestAction } = useActions();
  const [keyword, setKeyword] = useState('');
  const [maintenanceList, setMaintenanceList] = useState<listItemType[]>([]);

  const { maintenance }: any = useTypedSelector((state: any) => state.Dashboard);

  useEffect(() => {
    dashboardMaintenanceRequestAction({});
  }, []);

  useEffect(() => {
    if (keyword) handleSearch();
    else setMaintenanceList(renderList());
  }, [keyword]);

  useEffect(() => {
    setMaintenanceList(renderList());
  }, [maintenance.data?.category_chart?.length]);

  const renderList = () => {
    let final = [];
    if (!maintenance.data?.category_chart?.length) return [];
    if (!maintenance.data?.category_chart[0]?.child?.length) return [];
    if (!maintenance.data?.category_chart[0]?.child[0]?.child?.length) return [];
    final = maintenance.data?.category_chart[0]?.child[0]?.child?.map((item: listItemType) => ({
      ...item,
      totalCount: Number(item.totalCount)
    }));
    // setMaintenanceList(final);
    return final;
  };

  const handleSearch = () => {
    const result: listItemType[] = [];
    const search = (_list: listItemType[], parent?: listItemType) => {
      _list?.forEach((item) => {
        if (item?.title?.toLowerCase().includes(keyword.toLowerCase())) {
          if (parent?.title) result.push({ ...item, parentTitle: parent?.title, child: [] });
          else result.push({ ...item, child: [] });
        }
        if (item.child?.length) {
          search(item.child, item);
        }
      });
    };
    search(maintenanceList);
    if (result?.length) {
      setMaintenanceList(result);
    }
  };

  return (
    <div>
      <PanelBoxLayout
        title="Maintenance"
        afterTitle={
          <S.Div style={{ display: 'flex', gap: 8, marginLeft: 6 }}>
            <ReactSVG src="/icons/star-o-2.svg" />
            <S.Span>{maintenance?.data?.service_rate_average}</S.Span>
          </S.Div>
        }
        headStyle={{ marginBottom: 0 }}
        style={{ padding: 16 }}
        titleStyle={{
          fontSize: 24,
          fontWeight: 700,
          color: `var(--primary-dark-two)`
        }}
      >
        <S.Grid>
          <S.Info>
            <S.Text>Number of Open Tickets:</S.Text>
            <S.Span>{maintenance?.data?.openTicket}</S.Span>
          </S.Info>
          <S.Info>
            <ReactSVG
              src="/icons/fill/timer.svg"
              beforeInjection={(svg) => {
                svg.setAttribute('fill', `#2B3674`);
              }}
            />
            <S.Text>Average Closing Time:</S.Text>
            <S.Span>{convertMsToMin(maintenance?.data?.avgClosedTime, 'Min') || 'N/A'}</S.Span>
          </S.Info>
        </S.Grid>
        <Divider style={{ margin: '20px 0' }} />
        <S.Grid style={{ gap: 60, alignItems: 'start' }}>
          <S.DivTable>
            <MaintenanceTable data={maintenanceList} setKeyword={(e) => setKeyword(e)} keyword={keyword} />
          </S.DivTable>
          <DashboardMaintenanceChart data={maintenance.data?.category_chart} />
        </S.Grid>
        <Gap size="20px" />
      </PanelBoxLayout>
    </div>
  );
}
