import CafeteriaStatistics from './lib/statistics';
import CafeteriaStatisticsSidebar from './lib/sidebar';
import Gap from 'components/gap';

import * as S from './styles';
import { useTypedSelector } from 'common/hooks/useTypedSelector';
// import AppLoading from "components/appLoading";

export default function CafeteriaStatisticsPage() {
  const { chart, menuList } = useTypedSelector((state) => state.Cafeteria);

  return (
    <S.Container>
      <S.Head>
        <S.Title>Statistics</S.Title>
      </S.Head>
      <Gap size="20px" />
      <S.Div className="row">
        <S.Div className="col-md-9">
          <CafeteriaStatistics
            data={chart.data.data?.chart || []}
            totalOrders={chart.data.data?.totalOrders}
            // loading={chart.loading}
          />
        </S.Div>
        <S.Div className="col-md-3">
          <CafeteriaStatisticsSidebar cafeteriaList={menuList.data} />
        </S.Div>
      </S.Div>

      {/* <AppLoading loading={chart.loading} /> */}
    </S.Container>
  );
}
