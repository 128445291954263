/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from 'formik';
import { useActions } from 'common/hooks/useActions';
import { ValidationFilterStatistics } from 'common/validations';
import Gap from 'components/gap';
import PrimaryButton from 'components/form/buttons/primaryButton';
import MultiDropdown from 'components/form/MultiDropdown';
import DateInput from 'components/form/dateInput';
import { useEffect } from 'react';
// import moment from "moment";
import { Dayjs } from 'dayjs';

import * as T from 'common/types';
import * as S from './styles';

interface IProps {
  cafeteriaList: { list: T.Pages.CafeteriaListItem[] };
  now?: Dayjs;
}

export default function CafeteriaStatisticsSidebarFilterForm({ cafeteriaList, now }: IProps) {
  const { cafeteriaChartRequestAction, cafeteriaMenuManagementRequestAction } = useActions();

  // const [data, setData] = useState(second)

  useEffect(() => {
    cafeteriaMenuManagementRequestAction({ getAll: true, page: 1, take: 1000 });
  }, []);

  useEffect(() => {
    if (cafeteriaList.list.length)
      cafeteriaChartRequestAction({
        items: cafeteriaList.list.map((item) => item.id),
        date: now
      });
  }, [cafeteriaList.list.length]);

  return (
    <S.Content>
      <Formik
        initialValues={{
          items: cafeteriaList.list as T.Form.ListDropdownItem[],
          date: ''
        }}
        enableReinitialize
        validationSchema={ValidationFilterStatistics}
        onSubmit={(values, { setSubmitting }) => {
          cafeteriaChartRequestAction({
            items: values.items.map((item) => item.id),
            date: values.date || now
          });
          setSubmitting(false);
        }}
        validateOnBlur={true}
      >
        {({ values, errors, touched, handleSubmit, setValues }) => (
          <>
            <MultiDropdown
              label="Items"
              list={cafeteriaList.list}
              onChange={(list: T.Form.ListDropdownItem[]) => {
                setValues({
                  ...values,
                  items: list
                });
              }}
              placeholder="Select or search Items"
              defaultValues={values.items.length ? values.items : cafeteriaList.list}
              // hasSearch
              message={touched.items && (errors.items as string)}
              error={errors.items as unknown as boolean}
              inputStyle={{
                height: 40,
                borderRadius: 7,
                fontSize: 12,
                padding: '0 15px'
              }}
              labelStyle={{ fontSize: 12 }}
              key={cafeteriaList.list.length}
            />

            <Gap size="10px" />

            <DateInput
              label="Date"
              onChange={(date) =>
                setValues({
                  ...values,
                  date
                  // moment(date).format( "YYYY-MM")
                })
              }
              defaultDate={values.date || now}
              format="YYYY/MM"
              inputStyle={{
                height: 40,
                borderRadius: 7,
                fontSize: 12,
                padding: '0 15px'
              }}
              picker="month"
              labelStyle={{ fontSize: 12 }}
              message={touched.date && (errors.date as any)}
              error={touched.date && (errors.date as any)}
            />
            <Gap size="10px" />

            <PrimaryButton
              style={{ width: '100%', height: 42, borderRadius: 8 }}
              onClick={handleSubmit}
              // disabled={isSubmitting}
            >
              Filter
            </PrimaryButton>
          </>
        )}
      </Formik>
    </S.Content>
  );
}
